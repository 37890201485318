import { Form, Formik } from 'formik';
import { Buttons } from '../../../components/Button';
import { FormInput } from '../../../components/TextInput';
import { Logo } from '../../../components/Logo';
import { ForgotPasswordValues } from '../../../utils/Form/InitialValues';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../../store/actions/authActions';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../../utils/analytics';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils';

const ForgotPasswordFormValidationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Required'),
});

export default function ForgotPasswordPage() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const submitForgotPassword = values => {
		dispatch(forgotPassword(values));
		sendGoogleEvent('reset_password_request');
	};

	useEffect(() => {
		setDocumentTitle(t('pageTitles.resetPassword'));
	}, [t]);

	return (
		<>
			<div className="h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<Logo className="h-12 w-auto mx-auto" />
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						{t('resetPasswordHeading')}
					</h2>
					<p className="mt-2 text-center text-sm text-gray-600">
						{t('Or')}{' '}
						<a
							href="/sign-in"
							className="font-medium text-indigo-600 hover:text-indigo-500"
						>
							{t('return to Sign In page')}
						</a>
					</p>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						<Formik
							initialValues={ForgotPasswordValues}
							validationSchema={ForgotPasswordFormValidationSchema}
							onSubmit={values => {
								submitForgotPassword(values);
							}}
						>
							{props => (
								<div className="mt-6">
									<Form className="space-y-6">
										<div className="space-y-1">
											<FormInput
												id="email"
												name="email"
												type="email"
												label={t('formLabels.email')}
												placeholder={t('formPlaceholders.enterEmail')}
												errorMessage={t(props.errors.email)}
											/>
										</div>
										<p className="text-sm text-gray-500">
											{t('forgotPassword.resetFormText')}
										</p>

										<Buttons type="submit" fullWidth>
											{t('forgotPassword.resetButton')}
										</Buttons>
									</Form>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
}
