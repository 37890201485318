import { useDispatch, useSelector } from 'react-redux';
import { setAudienceReach } from '../../store/projectEditorSlice';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import { AUDIENCE_REACH_THRESHOLDS } from '../../constants';

export default function AudienceSettings({ isEditable }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const audience = useSelector(state => state.projectEditor.audience);
	const projectNanoId = useSelector(
		state => state.projectEditor.project.nanoid,
	);
	const subscriptionData = useSelector(
		state => state.projectEditor.project.subscriptionDetail,
	);

	const [value, setValue] = useState('');

	const { minValue, maxValue } = AUDIENCE_REACH_THRESHOLDS;

	const maxAudienceValue = subscriptionData
		? subscriptionData.plan.response_limit - subscriptionData.usage.responses
		: maxValue;

	useEffect(() => {
		setValue(audience.reach);
	}, [audience]);

	const updateAudienceReach = val => {
		dispatch(
			setAudienceReach({
				projectNanoId: projectNanoId,
				audienceNanoid: audience.nanoid,
				reach: val,
			}),
		);
	};

	const updateCompletesValue = val => {
		if (val >= minValue && val <= maxAudienceValue) {
			updateAudienceReach(val);
		} else if (val > maxAudienceValue) {
			updateAudienceReach(maxAudienceValue);
			setValue(audience.reach);
		} else if (val < minValue && val != 0) {
			updateAudienceReach(minValue);
			setValue(audience.reach);
		} else {
			setValue(audience.reach);
		}
	};

	const handleOnChange = e => {
		const input = parseInt(e.target.value);
		setValue(input);
	};

	const handleOnKeyDown = e => {
		const filteredKeys = ['e', 'E', '+', '-', '.'];
		if (filteredKeys.includes(e.key)) e.preventDefault();

		if (e.key === 'Enter') {
			updateCompletesValue(value);
		}
	};

	const handleOnBlur = () => {
		updateCompletesValue(value);
	};

	return (
		<>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex" aria-label="Tabs">
						<button
							className="border-indigo-500 text-indigo-600 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
							aria-current={'page'}
						>
							{t('Settings')}
						</button>
					</nav>
				</div>
			</div>
			{audience.reach && (
				<div className="p-3">
					<label
						htmlFor="maxCharacters"
						className="block text-sm font-medium text-gray-700"
					>
						{t('audienceSettingsCompletes')}
					</label>
					<div className="mt-1">
						<input
							type="number"
							pattern="[0-9]*"
							name="reach"
							id="reach"
							className="disabled:cursor-not-allowed disabled:opacity-30 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
							value={value || ''}
							onChange={handleOnChange}
							onKeyDown={handleOnKeyDown}
							onBlur={handleOnBlur}
							disabled={!isEditable}
						/>
						<p className="mt-2 text-xs text-gray-500">
							{t('audienceSettingDescription')}
						</p>
					</div>
				</div>
			)}
		</>
	);
}
