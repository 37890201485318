import { classNames } from '../../utils';

export default function Pulse({ size }) {
	return (
		<>
			<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
			<span
				className={classNames(
					'relative inline-flex rounded-full bg-amber-500',
					`h-${size} w-${size}`,
				)}
			></span>
		</>
	);
}
