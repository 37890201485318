import { useTranslation } from 'react-i18next';
import { useParams, useOutletContext } from 'react-router-dom';
import { setDocumentTitle } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadAnalysisDataStart } from '../../../store/analysisSlice';
import { filter, tidy } from '@tidyjs/tidy';
import AnalysisQuestion from '../../../components/AnalysisQuestion';

import TourGuide from '../../../components/TourGuide';

export default function DefaultAnalysisPage() {
	const dispatch = useDispatch();

	const { setCurrentPageTab } = useOutletContext();
	const { nanoid } = useParams();

	useEffect(() => {
		setCurrentPageTab('questions');
	}, [setCurrentPageTab]);

	const { t } = useTranslation();

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);

	const rawData = projectAnalysis ? projectAnalysis.rawData : [];
	const filteredData = projectAnalysis ? projectAnalysis.filteredData : [];

	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const questions = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	let questionList = questions;

	if (nanoid === 'ZDOubh-1SkGSn5RM' && questionList[1]) {
		const newChoices = projectAnalysis?.labels.labelList.map((label, index) => {
			return { nanoid: label.nanoid, title: label.name, order: 14 + index };
		});
		const newQuestion = {
			nanoid: 'questionFour',
			title: 'Kombine',
			description: '',
			order: 4,
			question_type: 'multiple_selection',
			choice_type: 'default',
			is_qualified: false,
			media: null,
			config: {
				is_randomized: false,

				max_selection_count: 1000,

				min_selection_count: 1,

				required: true,

				required_other_question: false,

				selection_count_type: 'unlimited',

				show_other: false,

				shuffle: 'shuffle_all',
			},
			choices: [...questionList[1].choices, ...newChoices],
		};

		questionList = [...questionList, newQuestion];
	}

	if (nanoid === '1f3WJvW053eInEyR' && questionList[1]) {
		const newChoicesRow = questionList[3].choices.map(choice => ({
			...choice,
			choice_type: 'row',
		}));
		const newChoicesColumn5 = questionList[4].choices.map(choice => ({
			...choice,
			choice_type: 'column',
		}));
		const newChoicesColumn6 = questionList[5].choices.map(choice => ({
			...choice,
			choice_type: 'column',
		}));
		const newQuestion1 = {
			nanoid: 'matrix45',
			title: 'Soru 4 Soru 5 seçimleri',
			description: '',
			order: 9,
			question_type: 'matrix',
			choice_type: 'default',
			is_qualified: false,
			media: null,
			columns: newChoicesColumn5,
			rows: newChoicesRow,
			config: {
				input_type: 'selection',

				is_multiselection: false,

				required: true,
			},
			choices: [...newChoicesRow, ...newChoicesColumn5],
		};

		const newQuestion2 = {
			nanoid: 'matrix46',
			title: 'Soru 5 Soru 6 seçimleri',
			description: '',
			order: 10,
			question_type: 'matrix',
			choice_type: 'default',
			is_qualified: false,
			media: null,
			columns: newChoicesColumn6,
			rows: newChoicesRow,
			config: {
				input_type: 'selection',

				is_multiselection: false,

				required: true,
			},
			choices: [...newChoicesRow, ...newChoicesColumn6],
		};

		questionList = [...questionList, newQuestion1, newQuestion2];
	}

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.analysis'));
	}, [t]);

	useEffect(() => {
		dispatch(
			loadAnalysisDataStart({
				projectNanoId: nanoid,
			}),
		);
	}, [dispatch, nanoid]);

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.analysis.reporting.title'),
			content: t('tour.content.analysis.reporting.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-progress',
			placement: 'auto',
			title: t('tour.content.analysis.responseProgress.title'),
			content: t('tour.content.analysis.responseProgress.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-export',
			placement: 'auto',
			title: t('tour.content.analysis.export.title'),
			content: t('tour.content.analysis.export.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-question',
			placement: 'auto',
			title: t('tour.content.analysis.questions.title'),
			content: t('tour.content.analysis.questions.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-filter',
			placement: 'auto',
			title: t('tour.content.analysis.filters.title'),
			content: t('tour.content.analysis.filters.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-analysis-compare',
			placement: 'auto',
			title: t('tour.content.analysis.compare.title'),
			content: t('tour.content.analysis.compare.content'),
			disableBeacon: true,
		},
	];

	const filteredResponsesPerQuestion = {};
	const rawResponsesPerQuestion = {};
	questionList.forEach(question => {
		filteredResponsesPerQuestion[question.nanoid] = tidy(
			filteredData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
		rawResponsesPerQuestion[question.nanoid] = tidy(
			rawData,
			filter(d => {
				return d[question.nanoid] !== undefined;
			}),
		);
	});

	if (!projectAnalysis) {
		return <></>;
	}

	return (
		<>
			<div className="mx-auto pt-4 max-w-4xl flex flex-col gap-4">
				{questionList.map((question, index) => (
					<AnalysisQuestion
						key={question.nanoid}
						translations={translations}
						language={displayLanguage}
						filteredData={filteredResponsesPerQuestion[question.nanoid] || 0}
						index={index + 1}
						question={question}
						responseCount={
							rawResponsesPerQuestion[question.nanoid]?.length || 0
						}
						filteredResponseCount={
							filteredResponsesPerQuestion[question.nanoid].length
						}
					/>
				))}
			</div>
			<div className="mx-auto pt-4 max-w-4xl mb-40 text-xs text-gray-400 px-4 sm:px-0">
				{t('analysis.disclaimer')}
			</div>

			<TourGuide tourName={'analysisPage'} steps={steps} />
		</>
	);
}
