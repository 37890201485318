export default function CommsOptin() {
	return (
		<div className="text-sm text-gray-500 max-h-96">
			<b>
				Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
				Ticaret Anonim Şirketi
			</b>{' '}
			tarafından sunulacak her türlü ürün ve hizmetlere ilişkin olarak, bizzat
			kendisi ve/veya hizmet sağlayıcılar tarafından; her türlü reklam,
			kampanya, indirim, hediye, çekiliş, promosyonlara, anketlere ilişkin
			reklam, tanıtım ve bilgilendirme, ihtiyaçlarıma özel fırsatlar
			geliştirilmesi, benimle olan bağlantının devamının sağlanması, müşteri
			memnuniyeti araştırmaları, anket çalışmaları, tanıtım, kutlama, temenni,
			onay alma ve pazarlama çalışmaları, amaçları ile tarafıma kısa mesaj
			(SMS), görüntülü ve sesli mesaj (MMS), elektronik posta, mektup, telefon,
			çağrı merkezi, otomatik arama ve benzeri her türlü elektronik iletişim
			aracı ile ticari elektronik ileti gönderilmesini, benimle iletişime
			geçilmesini kabul ediyorum.
			<p className="mt-3 font-bold">
				Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
				Ticaret Anonim Şirketi.
			</p>
		</div>
	);
}
