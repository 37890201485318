const MEASURE_CLASSES = {
	xSmall: 'px-2.5 py-1.5 text-xs',
	small: 'px-3 py-2 text-sm leading-4',
	medium: 'px-4 py-2 text-sm',
	large: 'px-4 py-2 text-base',
	xLarge: 'px-6 py-3 text-base',
	circularXs: 'p-1 rounded-full',
	circularSm: 'p-1.5 rounded-full',
	circularMd: 'p-2 rounded-full',
	circularLg: 'p-3 rounded-full',
};

const MARGIN_CLASSES = {
	margin: 'none',
	sm: 'm-1',
	md: 'm-2',
	lg: 'm-3',
	xl: 'm-4',
	Xsm: 'mx-1',
	Ysm: 'my-1',
	Xmd: 'mx-2',
	Ymd: 'my-2',
	Xlg: 'mx-3',
	Ylg: 'my-3',
	Xxl: 'mx-4',
	Yxl: 'my-4',
};
const FOCUS_CLASSES = {
	primary:
		'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
	scondary:
		'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500',
};

const KIND_CLASSES = {
	primary:
		'bg-indigo-600 hover:bg-indigo-700 border-transparent disabled:hover:bg-indigo-600 disabled:opacity-30 text-white',
	darkGray:
		'bg-gray-800 hover:bg-gray-700 border-transparent text-white disabled:opacity-30  disabled:hover:bg-gray-800',
	gray: 'bg-gray-600 hover:bg-gray-700 border-transparent text-white disabled:opacity-30  disabled:hover:bg-gray-600',
	grayLight:
		'bg-gray-400 hover:bg-gray-700 border-transparent text-white disabled:opacity-30  disabled:hover:bg-gray-400',
	secondary:
		'text-indigo-700 bg-indigo-100 hover:bg-indigo-200 border-transparent disabled:opacity-30  disabled:hover:bg-indigo-100',
	white:
		'bg-white  border-gray-300 hover:bg-gray-50 text-gray-700 disabled:opacity-30  disabled:hover:bg-white',
};

export const Buttons = ({
	children,
	onClick = () => {},
	type = 'button',
	kind = 'primary',
	measure = 'medium',
	margin = 'none',
	focus = 'primary',
	fullWidth = false,
	Icon = null,
	iconPosition = 'end',
	rounded = false,
	...props
}) => {
	const fullWidthClass = fullWidth ? 'w-full' : '';
	const roundedClass = rounded ? 'rounded-full' : '';
	return (
		<button
			onClick={onClick}
			type={type}
			className={`inline-flex items-center justify-center gap-2 border ${fullWidthClass} ${MARGIN_CLASSES[margin]} ${MEASURE_CLASSES[measure]} ${roundedClass} font-medium rounded-md shadow-sm  ${KIND_CLASSES[kind]} ${FOCUS_CLASSES[focus]}`}
			{...props}
		>
			{iconPosition === 'start' && Icon}
			{children}
			{iconPosition === 'end' && Icon}
		</button>
	);
};
