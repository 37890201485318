import { call, put, takeLatest } from 'redux-saga/effects';
import {
	postSignOut,
	updateProfileInfo,
	verifyEmail,
} from '../../../api/services/Auth';

import { AUTH_STORAGE_KEY, RESENT_EMAIL_KEY } from '../../../utils';
import { LOCAL_STORAGE_COMMENTS } from '../../../constants';
import { push } from 'redux-first-history';
import {
	setEmailVerified,
	verifyEmailFailed,
	successUpdateProfile,
	failUpdateProfile,
} from '../../authSlice';

function* signOutSaga() {
	const result = yield postSignOut();

	if (result) {
		localStorage.removeItem(AUTH_STORAGE_KEY);
		localStorage.removeItem(RESENT_EMAIL_KEY);
		localStorage.removeItem(LOCAL_STORAGE_COMMENTS);

		yield put(push('/sign-in'));
	}
}

export function* watchSignOut() {
	yield takeLatest('auth/signOut', signOutSaga);
}

function* profileUpdateSaga(action) {
	try {
		const result = yield call(() =>
			updateProfileInfo({
				nanoid: action.payload.nanoid,
				values: action.payload.values,
			}),
		);

		yield put(successUpdateProfile(result));
	} catch (error) {
		yield put(failUpdateProfile(error.response.data.errors.email[0]));
	}
}

export function* watchProfileUpdate() {
	yield takeLatest('auth/startUpdateProfile', profileUpdateSaga);
}

function* verifyEmailSaga(action) {
	const result = yield call(() =>
		verifyEmail({
			key: action.payload.key,
		}),
	);

	if (result.status) {
		yield put(setEmailVerified());
	} else {
		yield put(verifyEmailFailed());
	}
}

export function* watchVerifyEmail() {
	yield takeLatest('auth/startEmailVerification', verifyEmailSaga);
}
