export default function PrivacyPolicyUK() {
	return (
		<div className="mt-3 sm:mt-5">
			<div className="text-sm text-gray-500">
				<h1 className="text-xl leading-6 font-medium text-gray-900 my-4">
					SORBUNU PRIVACY POLICY
				</h1>
				<div className="my-2">
					<p className="mb-2">
						Sorbunu respects your privacy and is committed to protecting your
						personal data. Please read this privacy policy carefully as it
						contains important information on who we are and how and why we
						collect, store, use and share your personal data. It also explains
						your rights in relation to your personal data and how to contact us
						or supervisory authorities should you need to.
					</p>
					<p className="mb-2">
						We collect, use and are responsible for certain personal data about
						you. When we do so we are subject to the UK General Data Protection
						Regulation (UK GDPR).
					</p>
					<p className="mb-2">
						This policy aims to provide you with information on how we collect
						and process your personal data through this website, sorbunu.com,
						when we interact with you to provide you with our products and
						services or otherwise throughout the course of our dealings with
						you.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
					Who we are
				</h3>
				<div className="my-2">
					<p className="mb-2">
						This Website is operated by Denebunu Limited trading as ”Sorbunu”
						(referred to as “Sorbunu”, “we”, “us” or “our” in this privacy
						policy). Our registered address is 6th Floor, Manfield House, 1
						Southampton Street, London, England, WC2R 0LR and our company number
						is 13265299. We are the controller responsible for your personal
						data. Denebunu is the company responsible for this website.
					</p>
					<p className="mb-2">
						We have appointed a data privacy manager who is responsible for
						overseeing questions in relation to this policy. If you have any
						questions about this privacy policy, including any requests to
						exercise your legal rights, please contact the data privacy manager
						using the details set out below (see ‘How to contact us’ below).
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
					Personal data we collect about you
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Personal data, or personal information, means any information about
						an individual from which that person can be identified. It does not
						include data where the identity has been removed (anonymous data). A
						data subject is the individual who the personal data relates to.
					</p>
					<p className="mb-2">
						We may collect and use the following kinds of personal data about
						you (some of which is subject to you choosing to provide us with
						it):
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							<strong>Identity Data</strong> including your first name, last
							name, marital status, title, date of birth (and age), gender and
							photo (if you choose to provide this to us).
						</li>
						<li className="mb-2">
							<strong>Contact Data</strong> including your address, email
							address and mobile phone number.
						</li>
						<li className="mb-2">
							<strong>Transaction Data</strong> including details about products
							and services you have purchased from us.
						</li>
						<li className="mb-2">
							<strong>Technical Data</strong> including internet protocol (IP)
							address, your login data, browser type and version, time zone
							setting and location, browser plug-in types and versions,
							operating system and platform, and other technology on the devices
							you use to access this website.
						</li>
						<li className="mb-2">
							<strong>Profile Data</strong> including your username and
							password, purchases or orders made by you.
						</li>
						<li className="mb-2">
							<em>Usage Data</em> including information about how you use our
							website, products and services, click movements, browsing time and
							details
						</li>
						<li className="mb-2">
							<em>Marketing and Communications Data</em> including your
							preferences in receiving marketing from us and our third parties
							and your communication preferences
						</li>
					</ul>
					<p className="mb-2">
						We collect and use this personal data to provide products and
						services to you. If you do not provide personal data we ask for, it
						may delay or prevent us from providing such products and services to
						you.
					</p>
					<p className="mb-2">
						This website is not intended for children and we do not knowingly
						collect data relating to children.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
					How your personal data is collected
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We use different methods to collect data from and about you
						including through:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							<strong>Direct interactions.</strong>You may give us your Identity
							and Contact Data by filling in forms, submitting or uploading
							information to our website, contracting with us to receive our
							products/services, or by corresponding with us through our
							website, social media, by phone, email or otherwise. This includes
							personal data you provide when you:
						</li>
						<ul className="list-disc ml-6">
							<li className="mb-2">purchase our products or services;</li>
							<li className="mb-2">create an account on our website</li>
							<li className="mb-2">request marketing to be sent to you;</li>
							<li className="mb-2">
								enter a competition, promotion or survey; or
							</li>
							<li className="mb-2">contact us.</li>
						</ul>
						<li className="mb-2">
							<strong>Automated technologies or interactions.</strong> As you
							interact with our website, we will automatically collect Technical
							Data about your equipment, browsing actions and patterns. We
							collect this personal data by using cookies and other similar
							technologies. Please see our Cookie Policy or further details.
						</li>
						<li className="mb-2">
							<strong>Third parties or publicly available sources.</strong> We
							may receive personal data about you from various third parties as
							set out below:
						</li>
						<ul className="list-disc ml-6">
							<li className="mb-2">sanctions screening providers;</li>
							<li className="mb-2">credit reference agencies;</li>
							<li className="mb-2">customer due diligence providers</li>
							<li className="mb-2">analytics provider, such as Google</li>
							<li className="mb-2">
								through automated monitoring of our websites and other technical
								systems, such as our computer networks and connections, access
								control systems, communications systems, email and instant
								messaging systems.
							</li>
						</ul>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
					How and why we use your personal data
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We will only use your personal data when the law allows us to. Most
						commonly, we will use your personal data in the following
						circumstances:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							to comply with our legal and regulatory obligations;
						</li>
						<li className="mb-2">
							for the performance of a contract with you or to take steps at
							your request before entering into a contract;
						</li>
						<li className="mb-2">
							Where it is necessary for our legitimate interests (or those of a
							third party) and your interests and fundamental rights do not
							override those interests; or
						</li>
						<li className="mb-2">where you have given consent.</li>
					</ul>
					<p className="mb-2">
						The table below explains what we use your personal data for and why.
					</p>

					<table className="border divide-x divide-y mt-2">
						<tr className="divide-x">
							<th className="p-2">What we use your personal data for</th>
							<th className="p-2">Our reasons</th>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">
								To register you and set you up as a new customer
							</td>
							<td className="p-2">
								To perform our contract with you or to take steps at your
								request before entering into a contract
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">
								Providing and distributing our products and services to you
							</td>
							<td className="p-2">
								To perform our contract with you or to take steps at your
								request before entering into a contract
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Preventing and detecting fraud against you or us
							</td>
							<td className="p-2">
								For our legitimate interests or those of a third party, i.e. to
								minimise fraud that could be damaging for you and/or us
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								<p className="mb-2">
									Conducting checks to identify our customers and verify their
									identity.
								</p>
								<p className="mb-2">
									{' '}
									Screening for financial and other sanctions or embargoes
								</p>
								<p className="mb-2">
									Other activities necessary to comply with professional, legal
									and regulatory obligations that apply to our business, e.g.
									under health and safety law or rules issued by our
									professional regulator
								</p>
							</td>
							<td className="p-2">
								<p className="mb-2">
									To comply with our legal and regulatory obligations
								</p>
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to minimise fraud and to prevent consumers registering more
									than one account in order to exploit our services.
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Gathering and providing information required by or relating to
								audits, enquiries or investigations by regulatory bodies
							</td>
							<td className="p-2">
								To comply with our legal and regulatory obligations
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">Management of your account with us</td>
							<td className="p-2">To perform our contract with you</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">Customer service</td>
							<td className="p-2">
								<p className="mb-2">
									To effectively perform our contract with you
								</p>
								<p className="mb-2">
									For our legitimate interests i.e. to make sure we can deliver
									the best service to you and respond to you when you need us
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Asking for your feedback on our website and services
							</td>
							<td className="p-2">
								<p className="mb-2">
									To effectively perform our contract with you
								</p>
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to be as effective as we can in delivering the best service to
									you at the best price
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Management of promotions or competitions that you choose to
								enter
							</td>
							<td className="p-2">
								<p className="mb-2">
									To effectively perform our contract with you
								</p>
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to study how customers use our products/services, to develop
									them and grow our business.
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Ensuring business policies are adhered to, e.g. policies
								covering security and internet use
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to make sure we are following our own internal procedures so
									we can deliver the best service to you
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Operational reasons, such as improving efficiency, training and
								quality control
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to be as efficient as we can so we can deliver the best
									service to you at the best price
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Ensuring the confidentiality of commercially sensitive
								information
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to protect trade secrets and other commercially valuable
									information
								</p>
								<p className="mb-2">
									To comply with our legal and regulatory obligations
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Statistical analysis to help us manage our business, e.g. in
								relation to our financial performance, customer base, product
								range or other efficiency measures
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to be as efficient as we can so we can deliver the best
									service to you at the best price
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								To use data analytics to improve our website, products/services,
								marketing, customer relationships and experiences
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to be as efficient as we can so we can deliver the best
									service to you at the best price
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								To use data analytics to improve our website, products/services,
								marketing, customer relationships and experiences
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests i.e. to define types of customers
									for our products and services, to keep our website updated and
									relevant, to develop our business and to inform our marketing
									strategy
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Preventing unauthorised access and modifications to systems
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to prevent and detect criminal activity that could be damaging
									for you and/or us
								</p>
								<p className="mb-2">
									To comply with our legal and regulatory obligations
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Updating and enhancing customer records and our service provided
								to you
							</td>
							<td className="p-2">
								<p className="mb-2">
									To perform our contract with you or to take steps at your
									request before entering into a contract
								</p>
								<p className="mb-2">
									To comply with our legal and regulatory obligations
								</p>
								<p className="mb-2">
									For our legitimate interests or those of a third party, e.g.
									making sure that we can keep in touch with our customers about
									existing orders and new products
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								Ensuring safe working practices, staff administration and
								assessments
							</td>
							<td className="p-2">
								<p className="mb-2">
									To comply with our legal and regulatory obligations
								</p>
								<p className="mb-2">
									For our legitimate interests or those of a third party, e.g.
									to make sure we are following our own internal procedures and
									working efficiently so we can deliver the best service to you
								</p>
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">
								<p className="mb-2">
									Marketing our services and those of selected third parties to:
								</p>
								<p className="mb-2">—existing and former customers;</p>
								<p className="mb-2">
									—third parties who have previously expressed an interest in
									our services;
								</p>
								<p className="mb-2">
									—third parties with whom we have had no previous dealings.
								</p>
							</td>
							<td className="p-2">
								<p className="mb-2">
									For our legitimate interests or those of a third party, i.e.
									to promote our business and services to existing and former
									customers
								</p>
								<p className="mb-2">
									In certain circumstances where you have given your consent
								</p>
							</td>
						</tr>
					</table>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Special Category Personal Data
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Special category person data includes personal data revealing racial
						or ethnic origin, political opinions, religious beliefs,
						philosophical beliefs or trade union membership; genetic and
						biometric data (when processed to uniquely identify an individual);
						and data concerning health, sex life or sexual orientation.
					</p>
					<p className="mb-2">
						Where we process special category personal data, we will also ensure
						we are permitted to do so under data protection laws, for example
						only where:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">we have your explicit consent;</li>
						<li className="mb-2">
							the processing is necessary to protect your (or someone else’s)
							vital interests where you are physically or legally incapable of
							giving consent; or
						</li>
						<li className="mb-2">
							the processing is necessary to establish, exercise or defend legal
							claims.
						</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Marketing
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You will receive marketing communications from us if you have
						requested information from us or purchased goods or services from us
						and you have not opted out of receiving that marketing.
					</p>
					<p className="mb-2">
						We may use your personal data to send you updates (by email, text
						message, telephone or post) about our products and services,
						including exclusive offers, promotions or new products and services.
					</p>
					<p className="mb-2">
						We have a legitimate interest in using your personal data for
						marketing purposes (see above{' '}
						<strong>‘How and why we use your personal data’</strong>). This
						means we do not usually need your consent to send you marketing
						information. However, where consent is needed, we will ask for this
						separately and clearly.
					</p>
					<p className="mb-2">
						You have the right to opt out of receiving marketing communications
						at any time by:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">contacting us at hello@sorbunu.com;</li>
						<li className="mb-2">using the ‘unsubscribe’ link in emails; or</li>
						<li className="mb-2">
							updating your marketing preferences on our website at
							http://www.Sorbunu.com/profile/update/?from_sidebar=1.
						</li>
					</ul>
					<p className="mb-2">
						We may ask you to confirm or update your marketing preferences if
						you ask us to provide further products and services in the future,
						or if there are changes in the law, regulation, or the structure of
						our business.
					</p>
					<p className="mb-2">
						We will always treat your personal data with the utmost respect and
						will obtain your express opt-in consent before we share your
						personal data with any third party for marketing purposes.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Change of purpose
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We will only use your personal data for the purposes for which we
						collected it, unless we reasonably consider that we need to use it
						for another reason and that reason is compatible with the original
						purpose.
					</p>
					<p className="mb-2">
						If we need to use your personal data for an unrelated purpose, we
						will notify you and we will explain the legal basis which allows us
						to do so.
					</p>
					<p className="mb-2">
						Please note that we may process your personal data without your
						knowledge or consent, in compliance with the above rules, where this
						is required or permitted by law.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Who we share your personal data with
				</h3>
				<div className="my-2">
					<p className="mb-2">We routinely share personal data:</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							with our parent company, Denebunu Bilgi Teknolojileri Reklam
							Hizmetleri Pazarlama Sanayi ve Ticaret A.Ş., with company number
							45256-5 and its address at Esentepe Mah. Büyükdere Cad. No:201 B
							Blok Kat:7 Daire:122 Şişli/İstanbul, Turkey;
						</li>
						<li className="mb-2">
							third parties we use to help deliver our products and services to
							you, e.g. payment service providers, delivery companies,
							database/cloud servers;
						</li>
						<li className="mb-2">
							other third parties we use to help us run our business, e.g.
							marketing agencies or website hosts;
						</li>
						<li className="mb-2">third party payment providers;</li>
						<li className="mb-2">credit reference agencies;</li>
						<li className="mb-2">our insurers and brokers;</li>
						<li className="mb-2">our bank.</li>
					</ul>
					<p className="mb-2">
						We only allow our service providers to handle your personal data if
						we are satisfied they take appropriate measures to protect your
						personal data. We also impose contractual obligations on service
						providers to ensure they can only use your personal data to provide
						services to us and to you.
					</p>
					<p className="mb-2">We may also need to:</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							share personal data with external auditors to ensure compliance
							with legal and regulatory requirements;
						</li>
						<li className="mb-2">
							disclose and exchange information with law enforcement agencies
							and regulatory bodies to comply with our legal and regulatory
							obligations;
						</li>
						<li className="mb-2">
							share some personal data with other parties, such as potential
							buyers of some or all of our business or during a
							restructuring—usually, information will be anonymised but this may
							not always be possible, however, the recipient of the information
							will be bound by confidentiality obligations.
						</li>
					</ul>
					<p className="mb-2">
						If you would like more information about who we share our data with
						and why, please contact us (see <strong>‘How to contact us’</strong>{' '}
						below).
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Where your personal data is held
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Personal data may be held at our offices and those of our parent
						company, Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama
						Sanayi ve Ticaret A.Ş., third party agencies, service providers,
						representatives and agents as described above (see above:{' '}
						<strong>‘Who we share your personal data with’</strong>).
					</p>
					<p className="mb-2">
						Some of these third parties may be based outside the UK. For more
						information, including on how we safeguard your personal data when
						this happens, see below:{' '}
						<strong>‘Transferring your personal data out of the UK’</strong>).
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					How long your personal data will be kept
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We will keep your personal data while you have an account with us or
						we are providing products and services to you. Thereafter, we will
						keep your personal data for as long as is necessary:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							to respond to any questions, complaints or claims made by you or
							on your behalf;
						</li>
						<li className="mb-2">
							to notify you of any health or safety risk in relation to any of
							the products that have been delivered to you
						</li>
						<li className="mb-2">to show that we treated you fairly;</li>
						<li className="mb-2">to keep records required by law.</li>
					</ul>
					<p className="mb-2">
						We will not keep your personal data for longer than necessary.
						Different retention periods apply for different types of personal
						data. When it is no longer necessary to keep your personal data, we
						will delete or anonymise it.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Transferring your personal data out of the UK
				</h3>
				<div className="my-2">
					<p className="mb-2">
						To deliver services to you, it is sometimes necessary for us to
						share your personal data outside the UK, for example:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">with our parent company located in Turkey;</li>
						<li className="mb-2">
							with our service providers located outside the UK;
						</li>
						<li className="mb-2">if you are based outside the UK.</li>
					</ul>
					<p className="mb-2">
						Under data protection law, we can only transfer your personal data
						to a country or international organisation outside the UK where:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							the UK government has decided the particular country or
							international organisation ensures an adequate level of protection
							of personal data (known as an ‘adequacy decision’);
						</li>
						<li className="mb-2">
							there are appropriate safeguards in place, together with
							enforceable rights and effective legal remedies for data subjects;
							or
						</li>
						<li className="mb-2">
							a specific exception applies under data protection law
						</li>
					</ul>
					<p className="mb-2">These are explained below.</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Adequacy decision
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We may transfer your personal data to certain countries, on the
						basis of an adequacy decision. These include:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							all European Union countries and countries of the European
							Economic Area (‘EEA’);
						</li>
						<li className="mb-2">
							countries that the UK government has made an adequacy decision in
							favour of, including, Andorra, Argentina, Canada, Faroe Islands,
							Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand,
							Switzerland and Uruguay.
						</li>
					</ul>
					<p className="mb-2">
						The list of countries that benefit from adequacy decisions will
						change from time to time. We will always seek to rely on an adequacy
						decision, where one exists.
					</p>
					<p className="mb-2">
						Other countries we are likely to transfer personal data to do not
						have the benefit of an adequacy decision. This does not necessarily
						mean they provide poor protection for personal data, but we must
						look at alternative grounds for transferring the personal data, such
						as ensuring appropriate safeguards are in place or relying on an
						exception, as explained below.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Transfers with appropriate safeguards
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Where there is no adequacy decision, we may transfer your personal
						data to another country if we are satisfied the transfer complies
						with data protection law, appropriate safeguards are in place, and
						enforceable rights and effective legal remedies are available for
						data subjects.
					</p>
					<p className="mb-2">
						The safeguards will usually include using legally-approved standard
						data protection contract clauses in the form of an international
						data transfer agreement, as for transfers to our parent company in
						Turkey.
					</p>
					<p className="mb-2">
						For further information about the applicable safeguards, please
						contact us (see <strong>‘How to contact us’</strong> below).
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Transfers under an exception
				</h3>
				<div className="my-2">
					<p className="mb-2">
						In the absence of an adequacy decision or appropriate safeguards, we
						may in exception circumstances transfer personal data to a third
						country or international organisation where an exception applies
						under relevant data protection law, e.g.:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							you have explicitly consented to the proposed transfer after
							having been informed of the possible risks;
						</li>
						<li className="mb-2">
							the transfer is necessary for the performance of a contract
							between us or to take pre-contract measures at your request;
						</li>
						<li className="mb-2">
							the transfer is necessary for a contract in your interests,
							between us and another person; or
						</li>
						<li className="mb-2">
							the transfer is necessary to establish, exercise or defend legal
							claims.
						</li>
						<p className="mb-2">
							We may also transfer information for the purpose of our compelling
							legitimate interests, so long as those interests are not
							overridden by your interests, rights and freedoms. Specific
							conditions apply to such transfers and we will provide relevant
							information if and when we seek to transfer your personal data on
							this ground.
						</p>
						<p className="mb-2">
							If you would like further information about data transferred
							outside the UK, please us (see{' '}
							<strong>‘How to contact us’</strong> below).
						</p>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Third-party links
				</h3>
				<div className="my-2">
					<p className="mb-2">
						This website may include links to third-party websites, plug-ins and
						applications. Clicking on those links or enabling those connections
						may allow third parties to collect or share data about you. We do
						not control these third-party websites and are not responsible for
						their privacy statements. When you leave our website, we encourage
						you to read the privacy policy of every website you visit.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Your rights
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You have the following rights, which you can exercise free of charge
						at any time:
					</p>
					<table className="border divide-x divide-y mt-2">
						<tr className=" divide-x">
							<td className="p-2">Access</td>
							<td className="p-2">
								The right to be provided with a copy of your personal data
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">Rectification</td>
							<td className="p-2">
								The right to require us to correct any mistakes in your personal
								data
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">
								Erasure (also known as the right to be forgotten)
							</td>
							<td className="p-2">
								The right to require us to restrict processing of your personal
								data in certain circumstances, for example, if you contest the
								accuracy of the data
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">Data portability</td>
							<td className="p-2">
								The right to receive the personal data you provided to us, in a
								structured, commonly used and machine-readable format and/or
								transmit that data to a third party—in certain situations
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">To object</td>
							<td className="p-2">
								<p className="mb-2">The right to object:</p>
								<p className="mb-2">
									—at any time to your personal data being processed for direct
									marketing (including profiling);
								</p>
								<p className="mb-2">
									—in certain other situations to our continued processing of
									your personal data, for example, processing carried out for
									the purpose of our legitimate interests.
								</p>
							</td>
						</tr>
						<tr className=" divide-x">
							<td className="p-2">
								Not to be subject to automated individual decision making
							</td>
							<td className="p-2">
								The right not to be subject to a decision based solely on
								automated processing (including profiling) that produces legal
								effects concerning you or similarly significantly affects you
							</td>
						</tr>
					</table>
					<p className="my-2">
						For further information on each of those rights, including the
						circumstances in which they apply, please contact us (see{' '}
						<strong>‘How to contact us’</strong> below) or see the Guidance from
						the UK Information Commissioner’s Office (ICO) on individuals’
						rights.
					</p>
					<p className="mb-2">
						If you would like to exercise any of those rights, please:
					</p>
					<ul className="list-disc ml-6">
						<li className="mb-2">
							contact us—see below: <strong>‘How to contact us’</strong>;
						</li>
						<li className="mb-2">
							provide enough information to identify yourself; and
						</li>
						<li className="mb-2">
							let us know what right you want to exercise and the information to
							which your request relates.
						</li>
					</ul>
					<p className="mb-2">
						We try to respond to all legitimate requests within one month.
						Occasionally it could take us longer than a month if your request is
						particularly complex or you have made a number of requests. In this
						case, we will notify you and keep you updated.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Keeping your personal data secure
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We have appropriate security measures to prevent personal data from
						being accidentally lost, used or accessed unlawfully. We limit
						access to your personal data to those who have a genuine business
						need to access it. Those processing your information will do so only
						in an authorised manner and are subject to a duty of
						confidentiality. We also have security procedures in place as well
						as technical and physical restrictions to access to ensure the your
						personal data is safeguarded at all times.
					</p>
					<p className="mb-2">
						We have procedures in place to deal with any data security breach.
						We will notify you and any applicable regulator of a data security
						breach where we are legally required to do so.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					How to make a complaint
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Please contact us if you have any query or concern about our use of
						your information (see below <strong>How to contact us’</strong>). We
						hope we will be able to resolve any issues you may have.
					</p>
					<p className="mb-2">
						You also have the right to make a complaint at any time to the
						Information Commissioner's Office (ICO), the UK supervisory
						authority for data protection issues (www.ico.org.uk). We would,
						however, appreciate the chance to deal with your concerns before you
						approach the ICO so please contact us in the first instance.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					Changes to this privacy policy
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We may change this privacy notice from time to time. Please ensure
						that you regularly check this privacy policy for any changes that
						may affect you.
					</p>
					<p className="mb-2">
						It is important that the personal data we hold about you is accurate
						and current. Please keep us informed if your personal data changes
						during your relationship with us
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900 my-2">
					How to contact us
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You can contact us and our data protection manager by post, email or
						telephone if you have any questions about this privacy policy or the
						information we hold about you, to exercise a right under data
						protection law or to make a complaint.
					</p>
					<p className="mb-2">Our contact details are shown below:</p>
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Our contact details
					</h3>
					<table className="my-2 border divide-x divide-y">
						<tr className="w-full divide-x">
							<td className="w-1/4 p-2">Address:</td>
							<td className="w-3/4 p-2">
								6th Floor, Manfield House, 1 Southampton Street, London,
								England, WC2R 0LR
							</td>
						</tr>
						<tr className="divide-x">
							<td className="p-2">Email address:</td>
							<td className="p-2">hello@sorbunu.com</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
}
