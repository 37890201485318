import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { tidy, filter, select } from '@tidyjs/tidy';
import { find, forEach, orderBy, indexOf } from 'lodash';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import Tabs from '../Tabs';
import BarChart from './BarChart';
import { calculateHeightForBarChart } from '../../utils/analysis';
import { useState, useRef, useMemo } from 'react';
import { EXCLUDED_WORDS } from '../../constants';
import { wordCountHistogram } from './utils';
import { getTranslatedResponseText } from '../../utils/Translation';
import LabelChart from './LabelChart';
import CountTable from './CountTable';
import OtherResponsesTable from './OtherResponsesTable';

const PAGE_SIZE = 5;

export function OtherResponsesToggleButton({
	className,
	showOtherResponses,
	setShowOtherResponses,
}) {
	const { t } = useTranslation();

	return (
		<button
			type="button"
			className={classNames(
				className,
				'inline-flex items-center align-middle rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
			)}
			onClick={() => setShowOtherResponses(!showOtherResponses)}
		>
			{showOtherResponses && (
				<>
					<ChevronDownIcon
						className="-ml-0.5 mr-0.5 h-4 w-4"
						aria-hidden="true"
					/>
					{t('Hide')}
				</>
			)}
			{!showOtherResponses && (
				<>
					<ChevronRightIcon
						className="-ml-0.5 mr-0.5 h-4 w-4"
						aria-hidden="true"
					/>
					{t('Show')}
				</>
			)}
		</button>
	);
}

// A component that renders a `<tr>` to show what people typed when they selected "Other"
// choice in a question with `show_other=true`

export function OtherResponsesList({
	data,
	question,
	filterKey,
	filterValue,
	filterRank,
	language,
	colSpan = 3,
}) {
	const { t } = useTranslation();
	const chartRef = useRef();

	const [page, setPage] = useState(1);
	const [selectedTab, setSelectedTab] = useState('responses');

	const startIndex = (page - 1) * PAGE_SIZE;
	const endIndex = startIndex + PAGE_SIZE;

	const responses = useMemo(() => {
		if (!question.questions || question.questions.length === 0) {
			return [];
		}

		// This is not really future-proof because there could be more than 1 question in question.questions list.
		const otherQuestion = question.questions[0];

		const otherChoice = find(question.choices, i => i.choice_type === 'other');

		// TODO: Try to refactor analysisFilter helper and use it here
		return tidy(
			data,
			filter(d => {
				if (filterKey && filterValue) {
					const dataType = typeof d[filterKey];
					if (dataType === 'string' || dataType === 'number') {
						// For single choice filter items
						return d[filterKey] == filterValue;
					} else if (dataType === 'object') {
						// For multiple choice filter items
						const innerDataType =
							d[filterKey][0] == null ? 'string' : typeof d[filterKey][0];

						if (innerDataType === 'string') {
							//for multiple choice
							return indexOf(d[filterKey], filterValue) > -1;
						} else if (innerDataType === 'object') {
							let foundItemIndex = d[filterKey].findIndex(
								answer =>
									answer.order == filterRank && answer.choice === filterValue,
							);

							return foundItemIndex > -1;
						}
					}
				}
				return true; // If we're not in comparison mode, ignore this filter
			}),
			filter(i => {
				if (question.question_type === 'single_selection') {
					return i[question.nanoid] === otherChoice.nanoid;
				} else if (question.question_type === 'multiple_selection') {
					return find(i[question.nanoid], c => c === otherChoice.nanoid);
				}
				return false;
			}),
			select(['nanoid', otherQuestion.nanoid, 'translations', 'labels']),
		);
	}, [
		data,
		filterKey,
		filterRank,
		filterValue,
		question.choices,
		question.nanoid,
		question.question_type,
		question.questions,
	]);

	const wordCount = useMemo(() => {
		let result = [];
		const otherQuestionNanoid = question.questions[0]?.nanoid;

		if (responses.length > 0) {
			const textAnswers = responses.map(response => {
				if (response[otherQuestionNanoid]) {
					return getTranslatedResponseText(
						response,
						otherQuestionNanoid,
						language,
					);
				} else {
					return '';
				}
			});

			result = wordCountHistogram(textAnswers, EXCLUDED_WORDS[language]);
		} else {
			result = [{ country: 0, count: 0 }];
		}
		return result;
	}, [language, responses, question.questions]);

	const sortedRows = useMemo(() => {
		if (!question.questions || question.questions.length === 0) {
			return [];
		}

		// This is not really future-proof because there could be more than 1 question in question.questions list.
		const otherQuestion = question.questions[0];

		const counts = {};
		const rows = [];

		forEach(responses, response => {
			const rawResponseText = getTranslatedResponseText(
				response,
				otherQuestion.nanoid,
				language,
			);

			const processedText = rawResponseText ? rawResponseText.trim() : '';

			const languageCode = !language || language === 'tr' ? 'tr-TR' : language;
			const itemText =
				processedText === ''
					? `(${t('blank')})`
					: processedText.toLocaleLowerCase(languageCode);

			if (!counts[itemText]) {
				counts[itemText] = {
					itemText: itemText,
					responseNanoId: [response.nanoid],
					labels: response.labels,
					itemCount: 1,
				};
			} else {
				counts[itemText]['itemCount'] += 1;
				counts[itemText]['responseNanoId'].push(response.nanoid);
			}
		});

		Object.keys(counts).forEach(key => {
			const perc =
				responses.length > 0 ? counts[key]['itemCount'] / responses.length : 0;
			rows.push({
				...counts[key],
				itemPercentage: `${parseFloat(perc * 100).toFixed(2)}%`,
			});
		});

		return orderBy(rows, ['itemCount'], ['desc']);
	}, [question.questions, responses, language, t]);

	return (
		<>
			<tr>
				<td className=" border-l-4 border-l-indigo-600 pl-6 pt-2 pb-2 ">
					<Tabs
						size="sm"
						tabs={[
							{
								key: 'responses',
								title: t('Responses'),
							},
							{
								key: 'labels',
								title: t('Labels'),
							},
							{
								key: 'wordCount',
								title: t('Word Count'),
							},
						]}
						className={''}
						selectedTab={selectedTab}
						onChange={newTab => {
							setSelectedTab(newTab);
						}}
					/>
				</td>
			</tr>
			<tr>
				<td
					className={classNames(
						filterKey && filterValue ? 'border-r-4' : 'border-l-4',
						'whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 border-indigo-600',
					)}
					colSpan={colSpan}
				>
					{selectedTab === 'labels' && (
						<LabelChart
							question={question.questions[0]}
							data={responses}
							totalCount={responses.length}
							isCompareMode={false}
						/>
					)}
					{selectedTab === 'responses' && (
						<OtherResponsesTable
							startIndex={startIndex}
							endIndex={endIndex}
							sortedRows={sortedRows}
							page={page}
							setPage={setPage}
							paginationItemName={t('paginationItem.responses')}
							firstColName={t('Answer')}
							questionNanoId={question.questions[0]?.nanoid}
						/>
					)}
					{selectedTab === 'wordCount' && (
						<div className="px-8">
							<div
								ref={chartRef}
								className="w-full mb-4"
								style={{
									height: calculateHeightForBarChart(wordCount.barData?.length),
								}}
							>
								<BarChart
									legend={t('Words')}
									type="count"
									minValue={0}
									maxValue={
										wordCount.barData?.length > 0
											? wordCount.barData[wordCount.barData.length - 1].count
											: 0
									}
									keys={['count']}
									data={wordCount.barData || []}
									legendLabels={[]}
								/>
								<CountTable
									firstColName={t('Words')}
									secondColName={t('Word Count')}
									sortedRows={wordCount.tableData || []}
									paginationItemName={t('paginationItem.words')}
									textSize="xSmall"
								/>
							</div>
						</div>
					)}
				</td>
			</tr>
		</>
	);
}
