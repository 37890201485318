import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store, { history } from './store';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import NiceModal from '@ebay/nice-modal-react';
import './i18n';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [new BrowserTracing()],

	environment: process.env.NODE_ENV,

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.9,
});

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<NiceModal.Provider>
					<App />
				</NiceModal.Provider>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);
