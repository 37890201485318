import moment from 'moment';
import Tooltip from '../Tooltip';
import getSymbolFromCurrency from 'currency-symbol-map';
import { classNames } from '../../utils';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSubscriptionStatusLabel } from '../../utils/Subscription';

const statuses = {
	active: 'text-green-700 bg-green-50 ring-green-600/20',
	canceling: 'text-gray-600 bg-gray-50 ring-gray-500/10',
	canceled: 'text-red-700 bg-red-50 ring-red-600/10',
	expired: 'text-red-700 bg-red-50 ring-red-600/10',
};

export default function SubscriptionDetails({ subscription }) {
	const { t } = useTranslation();

	const subscriptionData = useSelector(
		state => state.projectEditor.project.subscriptionDetail,
	);

	// These are here so when we run `yarn i18next-scanner`, they are picked up and properly added to translation files.
	// Statuses
	// t('subscription.statuses.active')
	// t('subscription.statuses.canceling')
	// t('subscription.statuses.canceled')
	// t('subscription.statuses.expired')
	// t('subscription.renewalPeriods.monthly')
	// t('subscription.renewalPeriods.six_months')
	// t('subscription.renewalPeriods.three_months')
	// t('subscription.renewalPeriods.yearly')
	// Segmentation Tiers
	// t('subscription.segmentationTiers.basic')
	// t('subscription.segmentationTiers.advanced')

	return (
		<>
			<div className="rounded-xl border border-gray-200">
				<div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
					<img
						src={'https://tailwindui.com/img/logos/48x48/tuple.svg'}
						alt={subscription.plan.name}
						className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
					/>
					<div className="flex flex-col gap-1">
						<div className="text-sm font-medium leading-6 text-gray-900 flex items-start gap-x-2">
							<div>{subscription.plan.name}</div>
							<div
								className={classNames(
									statuses[subscription.status],
									'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
								)}
							>
								{t(`subscription.statuses.${subscription.status}`)}
							</div>
						</div>
						{(subscription.status === 'active' ||
							subscription.status === 'canceling') && (
							<div className="text-xs text-gray-500">
								{getSubscriptionStatusLabel({
									t: t,
									subscription: subscription,
								})}
							</div>
						)}
					</div>
				</div>
				<dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-xs leading-6">
					<div className="flex justify-between gap-x-4 py-2">
						<dt className="text-gray-500">{t('subscription.startDate')}</dt>
						<dd className="text-gray-700">
							<time dateTime={subscription.start_date}>
								{moment(subscription.start_date).format('L')}
							</time>
						</dd>
					</div>
					{subscription.status === 'canceled' && (
						<div className="flex justify-between gap-x-4 py-2">
							<dt className="text-gray-500">{t('subscription.endDate')}</dt>
							<dd className="text-gray-700">
								<time dateTime={subscription.end_date}>
									{moment(subscription.end_date).format('L')}
								</time>
							</dd>
						</div>
					)}
					{subscription.expire_date && (
						<div className="flex justify-between gap-x-4 py-2">
							<dt className="text-gray-500">{t('subscription.expireDate')}</dt>
							<dd className="text-gray-700">
								<time dateTime={subscription.expire_date}>
									{moment(subscription.expire_date).format('L')}
								</time>
							</dd>
						</div>
					)}
					{subscription.status !== 'canceled' &&
						subscription.status !== 'expired' && (
							<div className="flex justify-between gap-x-4 py-2">
								<Tooltip
									className="hidden lg:block bottom-8 py-2 px-2 bg-white !text-gray-800 shadow text-xs border border-gray-300 group-hover:opacity-100"
									text={t('subscription.tooltipText')}
								>
									<dt className="text-gray-500 underline decoration-dotted">
										{t('subscription.usage')}
									</dt>
								</Tooltip>
								<dd className="text-gray-700">
									<div className="font-medium text-gray-700">
										{subscriptionData && subscriptionData.usage.responses}/
										{subscription.plan.response_limit}
									</div>
								</dd>
							</div>
						)}
					<div className="flex justify-between gap-x-4 py-2">
						<dt className="text-gray-500">{t('subscription.renewalPeriod')}</dt>
						<dd className="text-gray-700">
							<div>
								{t(
									`subscription.renewalPeriods.${subscription.plan.renewal_period}`,
								)}
							</div>
						</dd>
					</div>
					<div className="flex justify-between gap-x-4 py-2">
						<dt className="text-gray-500">{t('subscription.price')}</dt>
						<dd>
							<div className="text-gray-700">
								{getSymbolFromCurrency(subscription.plan.currency)}
								{subscription.plan.price}
							</div>
						</dd>
					</div>
					<div className="flex justify-between gap-x-4 py-2">
						<dt className="text-gray-500">
							{t('subscription.subscriptionId')}
						</dt>
						<dd className="text-gray-700">
							<code>{subscription.nanoid}</code>
						</dd>
					</div>
					<div className="flex justify-between gap-x-4 py-2">
						<dt className="text-gray-500 w-8/12">
							{t('subscription.planBenefits')}
						</dt>
						<dd className=" text-xs">
							<div className="flex flex-col gap-2">
								<div className="flex gap-0.5">
									<CheckIcon className="w-4 h-4 text-green-700" />
									<div className="text-gray-700">
										{t(
											`subscription.segmentationTiers.${subscription.plan.audience_tier}`,
										)}
									</div>
								</div>
								<div className="flex gap-0.5">
									<CheckIcon className="w-4 h-4 text-green-700" />
									<div className="text-gray-700">
										{t('subscription.questionsPerProject', {
											count: subscription.plan.max_num_questions,
										})}
									</div>
								</div>
								<div className="flex gap-0.5">
									<div>
										{subscription.plan.max_num_screening_questions === 0 && (
											<XMarkIcon className="w-4 h-4 text-red-700" />
										)}
										{subscription.plan.max_num_screening_questions > 0 && (
											<CheckIcon className="w-4 h-4 text-green-700" />
										)}
									</div>
									<div className="text-gray-700">
										{t('subscription.screeningQuestionsPerProject', {
											count: subscription.plan.max_num_screening_questions,
										})}
									</div>
								</div>
								<div className="flex gap-0.5">
									<div>
										{!subscription.plan.quotas_enabled && (
											<XMarkIcon className="w-4 h-4 text-red-700" />
										)}
										{subscription.plan.quotas_enabled && (
											<CheckIcon className="w-4 h-4 text-green-700" />
										)}
									</div>
									<div className="text-gray-700">
										{subscription.plan.quotas_enabled && (
											<>{t('subscription.quotasEnabled.yes')}</>
										)}
										{!subscription.plan.quotas_enabled && (
											<>{t('subscription.quotasEnabled.no')}</>
										)}
									</div>
								</div>
							</div>
						</dd>
					</div>
				</dl>
			</div>
		</>
	);
}
