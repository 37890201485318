import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getSubscriptionList() {
	const result = await sorbunuApi.get(
		endPoints.subscription.LIST_SUBSCRIPTION(),
	);
	return result.data.results;
}

export async function getSubscriptionDetail({ subscriptionNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.subscription.GET_SUBSCRIPTION_DETAIL(subscriptionNanoId),
	);
	return result.data;
}
