import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function updateQuestionnaire({
	questionnaireNanoId,
	isRandomized,
}) {
	const data = {
		is_randomized: isRandomized,
	};

	const result = await sorbunuApi.patch(
		endPoints.questionnaires.QUESTIONNAIRE(questionnaireNanoId),
		data,
	);

	return result.data;
}

export async function getQuestionnairePreview({ questionnaireNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.questionnaires.PREVIEW(questionnaireNanoId),
	);

	return result.data;
}
