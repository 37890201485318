import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function createPreQuestion(questionnaireNanoId, payload) {
	const data = {
		title: payload.title,
		description: payload.description,
		question_type: payload.question_type,
		order: payload.order,
		config: payload.config,
		choices: payload.choices,
		is_screening: payload.is_screening,
	};

	const result = await sorbunuApi.post(
		endPoints.preQuestion.CREATE_PRE_QUESTION(questionnaireNanoId),
		data,
	);
	return result.data;
}

export async function getPreQuestionList(questionnaireNanoId) {
	const result = await sorbunuApi.get(
		endPoints.preQuestion.LIST_PRE_QUESTIONS(questionnaireNanoId),
	);
	return result.data.results;
}

export async function getPreQuestion({
	questionnaireNanoId,
	preQuestionNanoId,
}) {
	const result = await sorbunuApi.get(
		endPoints.preQuestion.RETRIEVE_PRE_QUESTION(
			questionnaireNanoId,
			preQuestionNanoId,
		),
	);
	return result.data;
}

export async function updatePreQuestion({
	questionnaireNanoId,
	preQuestionNanoId,
	payload,
}) {
	const data = {
		title: payload.title,
		description: payload.description,
		question_type: payload.question_type,
		order: payload.order,
		config: payload.config,
		choices: payload.choices,
		is_screening: payload.is_screening,
	};

	if (payload.image !== undefined) {
		data['image'] = payload.image;
	} else if (payload.video !== undefined) {
		data['video'] = payload.video;
	}

	const result = await sorbunuApi.put(
		endPoints.preQuestion.UPDATE_PRE_QUESTION(
			questionnaireNanoId,
			preQuestionNanoId,
		),
		data,
	);

	return result.data;
}

export async function deletePreQuestion(
	questionnaireNanoId,
	preQuestionNanoId,
) {
	const result = await sorbunuApi.delete(
		endPoints.preQuestion.DELETE_PRE_QUESTION(
			questionnaireNanoId,
			preQuestionNanoId,
		),
	);

	return result.data;
}

export async function bulkOrderPreQuestionList(
	questionnaireNanoId,
	orderedList,
) {
	const result = await sorbunuApi.post(
		endPoints.preQuestion.BULK_ORDER(questionnaireNanoId),
		orderedList,
	);
	return result.data;
}
