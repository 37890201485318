import Spinner from '../Spinner';
import { ClockIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { useSelector } from 'react-redux';

export default function FeasibilityIndicator() {
	const { t } = useTranslation();
	const data = useSelector(state => state.projectEditor.feasibility.data);
	const feasibilityMetaData = {
		0: {
			time: t('1 day'),
			iconColour: 'text-green-600',
			helperText: t('estimatedTimeHelperText'),
		},

		1: {
			time: t('1-2 days'),
			iconColour: 'text-green-500',
			helperText: t('estimatedTimeHelperText'),
		},
		2: {
			time: t('2-3 days'),
			iconColour: 'text-green-400',
			helperText: t('estimatedTimeHelperText'),
		},
		3: {
			time: t('3-5 days'),
			iconColour: 'text-lime-500',
			helperText: t('estimatedTimeHelperText'),
		},
		5: {
			time: t('5-7 days'),
			iconColour: 'text-lime-400',
			helperText: t('estimatedTimeHelperText'),
		},
		7: {
			time: t('7+ days'),
			iconColour: 'text-yellow-600',
			helperText: t('cannotEstimateTimeHelpText'),
		},
	};

	const notFeasibleData = {
		time: t('Not Doable'),
		iconColour: 'text-red-600',
		helperText: t('notDoableEstimateHelpText'),
	};

	return (
		<div className="xl:mr-3 py-0.5">
			<div className="inline-flex items-center  rounded-md border border-gray-300 bg-white px-1.5  py-2 sm:py-1.5  text-sm font-medium leading-4 text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
				{data ? (
					<>
						<ClockIcon
							className={classNames(
								data.is_feasible
									? feasibilityMetaData[data.completion_days.lower].iconColour
									: notFeasibleData.iconColour,
								'h-5 w-5 mr-1 ',
							)}
						/>
						<span className="pr-1">
							{data.is_feasible
								? feasibilityMetaData[data.completion_days.lower].time
								: notFeasibleData.time}
						</span>

						<span className="group relative">
							{data && (
								<span className="w-60 shadow hidden lg:block z-50 pointer-events-none absolute border border-gray-300 text-xs mb-1 top-12 left-1/2 -translate-x-1/2 whitespace-normal rounded bg-white text-gray-800 opacity-0 transition  before:absolute before:left-1/2 before:bottom-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-b-gray-400 group-hover:opacity-100">
									{!data.is_feasible && (
										<div className="p-4 ">{notFeasibleData.helperText}</div>
									)}
									{data.is_feasible && (
										<div className="py-4 pr-2 pl-4">
											{
												feasibilityMetaData[data.completion_days.lower]
													.helperText
											}
										</div>
									)}
								</span>
							)}

							<QuestionMarkCircleIcon className="hidden lg:block  h-5 w-5 text-gray-500 " />
						</span>
					</>
				) : (
					<Spinner className="py-1 h-6 w-6" />
				)}
			</div>
		</div>
	);
}
