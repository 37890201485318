import {
	call,
	put,
	all,
	delay,
	take,
	takeLatest,
	takeEvery,
	actionChannel,
	select,
	fork,
	cancel,
	debounce,
	retry,
} from 'redux-saga/effects';
import {
	startSearchProjects,
	completeSearchProjects,
	createProjectFinished,
	getAllProjectsFinished,
	getAllProjectsFailed,
	setProjectFeedback,
	postProjectFeedbackFinish,
	deleteProjectFeedbackFinish,
} from '../../projectSlice';
import {
	changeProjectNameFinished,
	addPromoCodeFinish,
	deletePromoCodeFinish,
	addPromoCodeFail,
	loadPromoCodesFinish,
	finishCreatingChoice,
	finishCreatingQuestion,
	finishUpdatingProjectStatus,
	loadPreviewDataComplete,
	setQuestionnaire,
	setAvailableAudienceCategories,
	setSelectedAudienceCategories,
	setAudienceDetails,
	calculateProjectFeasibilityStart,
	calculateProjectFeasibilityFinish,
	calculateProjectCostStart,
	calculateProjectCostFinish,
	finishCreatingMatrixRow,
	finishCreatingMatrixColumn,
	resetProjectEditor,
	updateQuestionErrors,
	loadSubscriptionsFinish,
	loadTransactionsStart,
	loadTransactionsFinish,
	setProjectSubmissionStatus,
	setSubscriptionDetail,
	setQuotaFields,
	addQuota,
	deleteQuota,
	updateQuotaResponseFinish,
	finishBulkChoiceUpdate,
	updateChoice,
	deleteChoice,
} from '../../projectEditorSlice';

import { sorbunuApi } from '../../../api/axios';
import { endPoints } from '../../../endPoints';
import {
	setProject,
	setQuestionList,
	setScreeningQuestionList,
} from '../../projectEditorSlice';
import {
	createPreQuestion,
	updatePreQuestion,
	getPreQuestionList,
	getPreQuestion,
	deletePreQuestion,
	bulkOrderPreQuestionList,
} from '../../../api/services/PreQuestion';
import { PROJECT_STATUSES } from '../../../constants';
import {
	getAllProjects,
	changeProjectName,
	updateProjectStatus,
	createProject,
	getProjectAudienceList,
	getProjectCost,
	retrieveProject,
} from '../../../api/services/Project';
import {
	getQuestionnairePreview,
	updateQuestionnaire,
} from '../../../api/services/Questionnaire';
import {
	bulkOrderPreChoiceList,
	createPreChoice,
	deletePreChoice,
	updatePreChoice,
} from '../../../api/services/PreChoice';
import _ from 'lodash';
import { getQuestionList } from '../../../api/services/Questions';
import { getQuestionByNanoId, newChoiceTemplate } from '../../../utils';
import {
	getAudienceDetailed,
	getAvailableAudienceCategories,
	updateAudienceReach,
	getProjectFeasibility,
} from '../../../api/services/Audience';
import { push } from 'redux-first-history';
import { getActivitiesList } from '../../../api/services/Activities';
import { loadActivitiesSuccess } from '../../activitiesSlice';
import { sendGoogleEvent } from '../../../utils/analytics';
import { getOrderList } from '../../../api/services/Order';
import { getTransactionList } from '../../../api/services/Transaction';
import {
	addPromoCode,
	getPromoCodes,
	deletePromoCode,
} from '../../../api/services/Checkout';
import { loadAnalysisDataStart } from '../../analysisSlice';
import {
	getSubscriptionDetail,
	getSubscriptionList,
} from '../../../api/services/Subscriptions';
import {
	ELCA_PROBLEMATIC_QUESTION_NANOID,
	ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID,
	ELCA_PROJECT_NANOID,
} from '../../../utils/analysis';
import {
	getProjectFeedback,
	postProjectFeedback,
	deleteProjectFeedback,
	updateProjectFeedback,
} from '../../../api/services/Feedback';
import {
	addQuotaField,
	addQuotaToAudience,
	deleteQuotaFromAudience,
	deleteQuotaField,
	getAudienceQuotas,
	getQuotaFields,
	updateQuotaField,
} from '../../../api/services/Quota';

function constructConfig(question) {
	return question.config;
}

function* searchProjects({ payload }) {
	if (payload.searchString === '') {
		yield put(completeSearchProjects({ projects: [] }));
	} else {
		yield delay(250);
		sendGoogleEvent('project_search', { term: payload.searchString });
		const { data } = yield call(
			sorbunuApi.get,
			endPoints.projects.SEARCH_PROJECTS(payload.searchString),
		);

		yield put(completeSearchProjects({ projects: data.results }));
	}
}

export function* searchProjectsSaga() {
	yield takeLatest(startSearchProjects().type, searchProjects);
}

function* getAllProjectsData(action) {
	try {
		const response = yield call(getAllProjects, action.payload);
		yield put(getAllProjectsFinished(response));
	} catch (error) {
		yield put(getAllProjectsFailed(action.payload));
	}
}

export function* watchGetAllProjectsData() {
	yield takeLatest('project/getAllProjectsStarted', getAllProjectsData);
}

function* changeProjectNameData(action) {
	const response = yield call(changeProjectName, action.payload);
	const { nanoid, title } = response;
	yield put(changeProjectNameFinished({ nanoid, title }));
}

export function* watchChangeProjectName() {
	yield takeLatest(
		'projectEditor/changeProjectNameStarted',
		changeProjectNameData,
	);
}

function* updateProjectStatusData(action) {
	const response = yield call(updateProjectStatus, action.payload);
	const { nanoid, status } = response;

	const oldStatus = yield select(state => state.projectEditor.project.status);

	yield put(finishUpdatingProjectStatus({ nanoid, status }));

	sendGoogleEvent('project_status_update', {
		old_status: oldStatus,
		new_status: status,
	});

	if (status === 'under_approval') {
		yield put(setProjectSubmissionStatus());
	}

	yield put({
		type: 'project/getAllProjectsStarted',
	});
}

export function* watchUpdateProjectStatus() {
	yield takeLatest(
		'projectEditor/updateProjectStatus',
		updateProjectStatusData,
	);
}

function* saveQuestion(action) {
	// Transform data for backend
	// TODO: This could go to API layer?

	const config = constructConfig(action.payload.newQuestion);
	const question = action.payload.newQuestion;

	const choices = _.cloneDeep(question.choices);

	if (question.question_type === 'matrix') {
		question.rows.forEach(row => {
			choices.push({
				title: row.title,
				choice_type: 'row',
			});
		});
		question.columns.forEach(column => {
			choices.push({
				title: column.title,
				choice_type: 'column',
			});
		});
	}

	const result = yield createPreQuestion(action.payload.questionnaireNanoId, {
		question_type: action.payload.newQuestion.question_type,
		title: action.payload.newQuestion.title,
		description: action.payload.newQuestion.description,
		config: config,
		choices: choices,
		is_screening: action.payload.newQuestion.is_screening,
	});

	yield put(
		finishCreatingQuestion({
			newQuestion: result,
		}),
	);

	const projectNanoId = yield select(
		state => state.projectEditor.project.nanoid,
	);

	yield put({
		type: 'projectEditor/calculateProjectCostRequest',
		payload: {
			projectNanoId: projectNanoId,
		},
	});

	if (action.payload.newQuestion.is_screening) {
		const screeningQuestionList = yield select(
			state => state.projectEditor.questionnaire.screeningQuestionList,
		);
		yield put(
			setScreeningQuestionList({
				questionList: screeningQuestionList,
				activeQuestion: {
					nanoid: result.nanoid,
					index:
						screeningQuestionList.length > 0
							? screeningQuestionList.length - 1
							: 0,
				},
			}),
		);
	} else {
		const questionList = yield select(
			state => state.projectEditor.questionnaire.questionList,
		);
		yield put(
			setQuestionList({
				questionList: questionList,
				activeQuestion: {
					nanoid: result.nanoid,
					index: questionList.length > 0 ? questionList.length - 1 : 0,
				},
			}),
		);
	}
}

function* deleteQuestion(action) {
	yield call(() =>
		deletePreQuestion(
			action.payload.questionnaireNanoId,
			action.payload.question.nanoid,
		),
	);
	if (action.payload.question.is_screening) {
		const screeningQuestionList = yield select(
			state => state.projectEditor.questionnaire.screeningQuestionList,
		);
		yield put(
			setScreeningQuestionList({ questionList: screeningQuestionList }),
		);
	} else {
		const questionList = yield select(
			state => state.projectEditor.questionnaire.questionList,
		);
		yield put(setQuestionList({ questionList: questionList }));
	}

	const projectNanoId = yield select(
		state => state.projectEditor.project.nanoid,
	);

	yield put({
		type: 'projectEditor/calculateProjectCostRequest',
		payload: {
			projectNanoId: projectNanoId,
		},
	});
}

export function* watchSaveQuestion() {
	yield takeEvery('projectEditor/createQuestion', saveQuestion);
}

export function* watchDeleteQuestion() {
	yield takeEvery('projectEditor/deleteQuestion', deleteQuestion);
}

export function* watchReOrderQuestionList() {
	const chan = yield actionChannel([
		'projectEditor/reorderQuestionList',
		'projectEditor/moveQuestionToTop',
		'projectEditor/moveQuestionToBottom',
	]);
	while (true) {
		yield take(chan);

		const questionList = yield select(
			state => state.projectEditor.questionnaire.questionList,
		);

		const orderList = [];

		_.map(questionList, (question, index) => {
			orderList.push({
				pre_question_nanoid: question.nanoid,
				order: index + 1,
			});
		});

		const screeningQuestionList = yield select(
			state => state.projectEditor.questionnaire.screeningQuestionList,
		);

		const orderedList = [];

		_.map(screeningQuestionList, (question, index) => {
			orderedList.push({
				pre_question_nanoid: question.nanoid,
				order: questionList.length + index + 1,
			});
		});
		const wholeList = orderList.concat(orderedList);

		// TODO: Why not payload parameter?
		const questionnaireNanoid = yield select(
			state => state.projectEditor.questionnaire.nanoid,
		);

		yield bulkOrderPreQuestionList(questionnaireNanoid, wholeList);
	}
}

function* updateQuestion(payload) {
	// Combined with cancel(), this debounces the calls
	yield delay(1000);
	let list = [];
	// Find the current state of the question
	if (payload.question.is_screening) {
		list = yield select(
			state => state.projectEditor.questionnaire.screeningQuestionList,
		);
	} else {
		list = yield select(
			state => state.projectEditor.questionnaire.questionList,
		);
	}

	const foundIndex = list.findIndex(element => {
		return element.nanoid === payload.question.nanoid;
	});

	const question = list[foundIndex];

	// TODO: Why not payload parameter?
	const questionnaireNanoid = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	const config = constructConfig(question);

	let choices = question.choices;

	if (question.question_type === 'matrix') {
		choices = [];
		question.rows.forEach(row => {
			choices.push({
				title: row.title,
				nanoid: row.nanoid,
				order: row.order,
				choice_type: 'row',
			});
		});
		question.columns.forEach(column => {
			choices.push({
				title: column.title,
				nanoid: column.nanoid,
				order: column.order,
				choice_type: 'column',
			});
		});
	}

	const updatePreQuestionResult = yield call(() =>
		updatePreQuestion({
			questionnaireNanoId: questionnaireNanoid,
			preQuestionNanoId: question.nanoid,
			payload: {
				question_type: question.question_type,
				title: question.title,
				description: question.description,
				config: config,
				choices: choices,
				is_screening: question.is_screening,
			},
		}),
	);

	yield put(
		updateQuestionErrors({
			question: question,
			errors: updatePreQuestionResult.errors,
		}),
	);

	delete questionUpdateTasks[payload.question.nanoid];
}

const questionUpdateTasks = {};

function* updateQuestionSaga({ payload }) {
	/*
	 * Idea: https://marmelab.com/blog/2016/10/18/using-redux-saga-to-deduplicate-and-group-actions.html
	 */
	const { question } = payload;

	if (questionUpdateTasks[question.nanoid]) {
		yield cancel(questionUpdateTasks[question.nanoid]);
	}
	questionUpdateTasks[question.nanoid] = yield fork(updateQuestion, payload);
	if (payload.setting) {
		sendGoogleEvent('questionnaire_question_setting_change', {
			question_type: payload.question.question_type,
			setting: payload.setting,
		});
	}
}

export function* watchUpdateQuestion() {
	yield takeEvery(
		[
			'projectEditor/updateQuestionTitle',
			'projectEditor/updateQuestionDescription',
			'projectEditor/updateQuestionSetting',
		],
		updateQuestionSaga,
	);
}

function* updateMatrixChoiceTitle(payload) {
	// Combined with cancel(), this debounces the calls
	yield delay(1000);

	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield call(() =>
		updatePreChoice({
			questionnaireNanoId: questionnaireNanoId,
			preQuestionNanoId: payload.question.nanoid,
			preChoiceNanoId: payload.choice.nanoid,
			payload: {
				title: payload.title,
				choice_type: payload.choice.choice_type,
			},
		}),
	);
	yield 1;
}

const matrixChoiceTitleUpdateTasks = {};

function* updateMatrixChoiceTitleSaga(action) {
	/*
	 * Idea: https://marmelab.com/blog/2016/10/18/using-redux-saga-to-deduplicate-and-group-actions.html
	 */

	if (matrixChoiceTitleUpdateTasks[action.payload.choice.nanoid]) {
		yield cancel(matrixChoiceTitleUpdateTasks[action.payload.choice.nanoid]);
	}
	matrixChoiceTitleUpdateTasks[action.payload.choice.nanoid] = yield fork(
		updateMatrixChoiceTitle,
		action.payload,
	);
}

export function* watchUpdateMatrixChoiceTitle() {
	yield takeEvery(
		[
			'projectEditor/updateMatrixRowTitle',
			'projectEditor/updateMatrixColumnTitle',
		],
		updateMatrixChoiceTitleSaga,
	);
}

function* createMatrixRowSaga(action) {
	const result = yield call(() =>
		createPreChoice(
			action.payload.question.questionnaire,
			action.payload.question.nanoid,
			{
				title: action.payload.newChoice.title,
				choice_type: action.payload.newChoice.choice_type,
			},
		),
	);

	yield put(
		finishCreatingMatrixRow({
			question: action.payload.question,
			newChoice: result,
		}),
	);
}

export function* watchCreateMatrixRow() {
	yield takeEvery(['projectEditor/createMatrixRow'], createMatrixRowSaga);
}

function* deleteMatrixRowSaga(action) {
	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield call(deletePreChoice, {
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
		preChoiceNanoId: action.payload.choice.nanoid,
	});
}

export function* watchDeleteMatrixRow() {
	yield takeEvery(['projectEditor/deleteMatrixRow'], deleteMatrixRowSaga);
}

function* createMatrixColumnSaga(action) {
	const result = yield call(() =>
		createPreChoice(
			action.payload.question.questionnaire,
			action.payload.question.nanoid,
			{
				title: action.payload.newChoice.title,
				choice_type: action.payload.newChoice.choice_type,
			},
		),
	);

	yield put(
		finishCreatingMatrixColumn({
			question: action.payload.question,
			newChoice: result,
		}),
	);
}

export function* watchCreateMatrixColumn() {
	yield takeEvery(['projectEditor/createMatrixColumn'], createMatrixColumnSaga);
}

function* deleteMatrixColumnSaga(action) {
	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield call(deletePreChoice, {
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
		preChoiceNanoId: action.payload.choice.nanoid,
	});
}

export function* watchDeleteMatrixColumn() {
	yield takeEvery(['projectEditor/deleteMatrixColumn'], deleteMatrixColumnSaga);
}

function* updateChoiceSaga(action) {
	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	function* tryUpdatingChoice() {
		const data = { choice_type: action.payload.choice.choice_type };

		if (action.payload.title !== undefined) {
			data['title'] = action.payload.title;
		}

		if (action.payload.media !== undefined) {
			data['media'] = action.payload.media;
		}
		data['is_qualified'] = action.payload.is_qualified;

		yield call(updatePreChoice, {
			questionnaireNanoId: questionnaireNanoId,
			preQuestionNanoId: action.payload.question.nanoid,
			preChoiceNanoId: action.payload.choice.nanoid,
			payload: data,
		});

		const questionResult = yield getPreQuestion({
			questionnaireNanoId: questionnaireNanoId,
			preQuestionNanoId: action.payload.question.nanoid,
		});

		yield put(
			updateQuestionErrors({
				question: action.payload.question,
				errors: questionResult.errors,
			}),
		);
	}

	// We use this retry pattern here because the choice we're trying to update may not be created in the backend by the time this method runs
	// so in some cases we expect this to fail, e.g. when the user creates a choie and quickly types.
	try {
		yield retry(10, 2000, tryUpdatingChoice);
	} catch (err) {
		// TODO: Add this choice to a state-level list and periodically try to send the latest state of this choice to backend?
	}
}

export function* watchUpdateChoice() {
	yield debounce(1000, ['projectEditor/updateChoice'], updateChoiceSaga);
}

function* createChoiceSaga(action) {
	const result = yield call(() =>
		createPreChoice(
			action.payload.question.questionnaire,
			action.payload.question.nanoid,
			{
				title: action.payload.newChoice.title,
				choice_type: action.payload.newChoice.choice_type,
			},
		),
	);

	yield put(
		finishCreatingChoice({
			question: action.payload.question,
			newChoice: result,
		}),
	);

	const questionResult = yield getPreQuestion({
		questionnaireNanoId: action.payload.question.questionnaire,
		preQuestionNanoId: action.payload.question.nanoid,
	});

	yield put(
		updateQuestionErrors({
			question: action.payload.question,
			errors: questionResult.errors,
		}),
	);
}

export function* watchCreateChoice() {
	yield takeEvery('projectEditor/createChoice', createChoiceSaga);
}

function* deleteChoiceSaga(action) {
	yield put(
		deleteChoice({
			question: action.payload.question,
			choice: action.payload.choice,
		}),
	);
	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield call(deletePreChoice, {
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
		preChoiceNanoId: action.payload.choice.nanoid,
	});

	const questionResult = yield getPreQuestion({
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
	});

	yield put(
		updateQuestionErrors({
			question: action.payload.question,
			errors: questionResult.errors,
		}),
	);
}

export function* watchDeleteChoice() {
	yield takeEvery('deleteQuestionChoice', deleteChoiceSaga);
}

function* bulkUpdateChoicesSaga(action) {
	const { question, newChoices } = action.payload;

	const originalChoiceLength = question.choices.length;
	const newChoicesLength = newChoices.length;

	if (originalChoiceLength > newChoicesLength) {
		const choices = [...question.choices];
		const choicesToDelete = choices.splice(newChoicesLength);

		//delete choices
		yield all(
			[...choicesToDelete].map(choice =>
				call(deleteChoiceSaga, {
					payload: { question: question, choice: choice },
				}),
			),
		);

		//update rest
		for (let [index, choiceToUpdate] of choices.entries()) {
			yield call(updateChoiceSaga, {
				payload: {
					question: question,
					title: newChoices[index],
					choice: choiceToUpdate,
				},
			});
			yield put(
				updateChoice({
					question: question,
					choice: choiceToUpdate,
					title: newChoices[index],
				}),
			);
		}
	} else {
		//update choice titles
		for (let [index, choiceToUpdate] of question.choices.entries()) {
			yield call(updateChoiceSaga, {
				payload: {
					question: question,
					title: newChoices[index],
					choice: choiceToUpdate,
				},
			});

			yield put(
				updateChoice({
					question: question,
					choice: choiceToUpdate,
					title: newChoices[index],
				}),
			);
		}
		//add new choice
		if (newChoicesLength > originalChoiceLength) {
			const choicesToAdd = [...newChoices].splice(originalChoiceLength);
			//create new choices
			for (let newChoice of choicesToAdd) {
				if (newChoice.trim()) {
					const newChoiceTemp = newChoiceTemplate({ title: newChoice.trim() });
					yield call(createChoiceSaga, {
						payload: { question: question, newChoice: newChoiceTemp },
					});
				}
			}
		}
	}
	sendGoogleEvent('questionnaire_question_bulk_edit');
	yield put(finishBulkChoiceUpdate({ question: question }));
}

export function* watchBulkUpdateChoices() {
	yield takeEvery('projectEditor/startBulkChoiceUpdate', bulkUpdateChoicesSaga);
}

function* updateQuestionnaireIsRandomizedSaga(action) {
	yield call(() =>
		updateQuestionnaire({
			questionnaireNanoId: action.payload.nanoid,
			isRandomized: action.payload.isRandomized,
		}),
	);
}

function* doBulkOrderPreChoiceList(payload) {
	let question = '';
	let list = [];

	if (payload.question.is_screening) {
		list = yield select(
			state => state.projectEditor.questionnaire.screeningQuestionList,
		);
	} else {
		list = yield select(
			state => state.projectEditor.questionnaire.questionList,
		);
	}

	question = getQuestionByNanoId(list, payload.question.nanoid);

	const orderList = [];

	_.map(question.choices, (choice, index) => {
		orderList.push({
			nanoid: choice.nanoid,
			order: index + 1,
		});
	});

	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield bulkOrderPreChoiceList({
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: question.nanoid,
		orderList: orderList,
	});
}

export function* watchReorderChoiceList() {
	yield takeEvery(
		['projectEditor/reorderChoiceList'],
		bulkOrderPreChoiceListSaga,
	);
}

const choiceReorderTasks = {};

function* tryBulkOrderPreChoiceList(payload) {
	// Combined with cancel(), this debounces the calls
	yield delay(1500);

	// We use this retry pattern here because one or more choices we're trying to order may not be created in the backend by the time this method runs
	// so in some cases we expect this to fail, e.g. when the user creates a choie and quickly reorders.
	try {
		yield retry(10, 2000, doBulkOrderPreChoiceList, payload);
	} catch (err) {
		// TODO: Add this choice to a state-level list and periodically try to send the latest state of this choice to backend?
	}
}

function* bulkOrderPreChoiceListSaga({ payload }) {
	/*
	 * Idea: https://marmelab.com/blog/2016/10/18/using-redux-saga-to-deduplicate-and-group-actions.html
	 */
	const { question } = payload;

	if (choiceReorderTasks[question.nanoid]) {
		yield cancel(choiceReorderTasks[question.nanoid]);
	}
	choiceReorderTasks[question.nanoid] = yield fork(
		tryBulkOrderPreChoiceList,
		payload,
	);
}

export function* watchQuestionnaireIsRandomizedChange() {
	yield debounce(
		1000,
		'projectEditor/updateQuestionnaireIsRandomized',
		updateQuestionnaireIsRandomizedSaga,
	);
}

function* loadProjectSaga(action) {
	yield put(resetProjectEditor());

	const project = yield call(() =>
		retrieveProject(action.payload.projectNanoId),
	);

	yield put(
		setProject({
			nanoid: project.nanoid,
			title: project.title,
			status: project.status,
			questionnaire: project.questionnaire,
			metrics: project.metrics,
			payment_mode: project.payment_mode,
			subscription: project.subscription,
			country_code: project.country_code,
		}),
	);

	if (project.payment_mode === 'subscription') {
		const res = yield getSubscriptionDetail({
			subscriptionNanoId: project.subscription.nanoid,
		});
		yield put(setSubscriptionDetail(res));
	}

	const questionnaireRes = yield call(() =>
		sorbunuApi.get(
			endPoints.questionnaires.QUESTIONNAIRE(project.questionnaire),
		),
	);

	yield put(
		setQuestionnaire({
			isRandomized: questionnaireRes.data.is_randomized,
		}),
	);

	let questionList = null;

	if (PROJECT_STATUSES[project.status].usePreQuestions) {
		const questionListRes = yield call(() =>
			getPreQuestionList(project.questionnaire),
		);
		questionList = questionListRes;
	} else {
		const questionListRes = yield call(() =>
			getQuestionList(project.questionnaire),
		);

		if (project.nanoid === ELCA_PROJECT_NANOID) {
			const r = questionListRes.findIndex(
				question => question.nanoid === ELCA_PROBLEMATIC_QUESTION_NANOID,
			);
			const q = questionListRes[r].choices.findIndex(
				choice =>
					choice.nanoid === ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID,
			);
			questionListRes[r].choices.splice(q, 1);
		}

		questionList = questionListRes;
	}

	const screeningQuestionList = questionList.filter(
		question => question.is_screening === true,
	);
	const generalQuestionList = questionList.filter(
		question => question.is_screening === false,
	);

	yield put(
		setQuestionList({
			questionList: generalQuestionList,
		}),
	);
	if (screeningQuestionList.length > 0) {
		yield put(
			setScreeningQuestionList({
				questionList: screeningQuestionList,
			}),
		);
	}

	const projectFeedback = yield call(() => getProjectFeedback(project.nanoid));
	let feedback =
		projectFeedback.results.length > 0 ? projectFeedback.results[0] : null;

	yield put(setProjectFeedback(feedback));

	const audienceCategories = yield call(() =>
		getAvailableAudienceCategories(project.country_code),
	);

	yield put(
		setAvailableAudienceCategories({
			categoryList: audienceCategories,
		}),
	);

	const projectAudienceList = yield call(() =>
		getProjectAudienceList(project.nanoid),
	);

	const audienceQuotas = yield call(() =>
		getAudienceQuotas({
			projectNanoId: project.nanoid,
			audienceNanoId: projectAudienceList[0].nanoid,
		}),
	);

	const audience = yield call(() =>
		getAudienceDetailed({
			projectNanoId: project.nanoid,
			audienceNanoId: projectAudienceList[0].nanoid,
		}),
	);

	yield put(
		setAudienceDetails({
			audienceNanoId: projectAudienceList[0].nanoid,
			reach: projectAudienceList[0].limit,
			quotas: audienceQuotas,
			errors: projectAudienceList[0].errors,
			audienceFields: audience.audiencefield_set,
		}),
	);

	for (let audienceQuota of audienceQuotas) {
		const quotaFields = yield call(() =>
			getQuotaFields({
				projectNanoId: project.nanoid,
				audienceNanoId: projectAudienceList[0].nanoid,
				quotaNanoId: audienceQuota.nanoid,
			}),
		);

		yield put(
			setQuotaFields({
				quotaFields: quotaFields,
				quotaNanoId: audienceQuota.nanoid,
			}),
		);
	}

	const selectedAudienceCategories = {};

	audience.audiencefield_set.forEach(audienceField => {
		if (!selectedAudienceCategories[audienceField.category.nanoid]) {
			selectedAudienceCategories[audienceField.category.nanoid] = [
				audienceField.value.nanoid,
			];
		} else {
			selectedAudienceCategories[audienceField.category.nanoid].push(
				audienceField.value.nanoid,
			);
		}
	});

	yield put(
		setSelectedAudienceCategories({
			selectedCategories: selectedAudienceCategories,
		}),
	);

	yield put(loadTransactionsStart());
	let transactionList = [];

	const orders = yield call(() =>
		getOrderList({ projectNanoId: project.nanoid }),
	);

	// Currently, a project can only have a single order at any time.
	// However, the relationship in backend is set up in a way where a project can have multiple orders.
	// That's why we get the first one here.
	const firstOrder = orders.length > 0 ? orders[0] : null;

	if (firstOrder) {
		transactionList = yield getTransactionList({
			projectNanoId: project.nanoid,
			orderNanoId: firstOrder.nanoid,
		});
	}

	yield put(
		loadTransactionsFinish({
			data: transactionList,
		}),
	);
}

export function* watchLoadProject() {
	yield takeEvery('projectEditor/loadProject', loadProjectSaga);
}

function* loadPreviewDataSaga(action) {
	const result = yield call(() =>
		getQuestionnairePreview({
			questionnaireNanoId: action.payload.questionnaireNanoId,
		}),
	);

	yield put(
		loadPreviewDataComplete({
			previewData: result,
		}),
	);
}

export function* watchLoadPreviewStart() {
	yield takeLatest('projectEditor/loadPreviewDataStart', loadPreviewDataSaga);
}

function* projectCostSaga(action) {
	const result = yield call(() => getProjectCost(action.payload.projectNanoId));

	yield put(
		calculateProjectCostFinish({
			data: result,
		}),
	);
}

export function* watchProjectCostCalculate() {
	yield takeLatest('projectEditor/calculateProjectCostStart', projectCostSaga);
}

function* projectCostRequestSaga(action) {
	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
}

export function* watchProjectCostRequestCalculate() {
	yield debounce(
		1000,
		'projectEditor/calculateProjectCostRequest',
		projectCostRequestSaga,
	);
}

function* updateAudienceReachSaga(action) {
	yield updateAudienceReach({
		projectNanoId: action.payload.projectNanoId,
		audienceNanoId: action.payload.audienceNanoid,
		reach: action.payload.reach,
	});
	sendGoogleEvent('audience_reach_change', { reach: action.payload.reach });

	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
	yield put(
		calculateProjectFeasibilityStart({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoid,
		}),
	);
}

export function* watchAudienceReachChange() {
	yield debounce(
		1000,
		'projectEditor/setAudienceReach',
		updateAudienceReachSaga,
	);
}

function* removeSelectedAudienceCategorySaga(action) {
	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
	yield put(
		calculateProjectFeasibilityStart({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);
}

export function* watchSelectedAudienceCategoryRemoval() {
	yield debounce(
		1000,
		'projectEditor/removeSelectedAudienceCategory',
		removeSelectedAudienceCategorySaga,
	);
}

function* projectCreationSaga(action) {
	const res = yield createProject(action.payload.values);

	yield put(createProjectFinished(res.data));
	sendGoogleEvent('project_create');

	yield put(resetProjectEditor());

	yield put(push(`/project/${res.nanoid}/audience`));

	// The old code had the part below, we need to think about how to handle failure cases
	/*
	.catch(err => {
		return dispatch(failCreateProject(err));
	});
	*/
}

export function* watchProjectCreation() {
	yield takeLatest('project/createProjectStarted', projectCreationSaga);
}

function* loadSubscriptionListSaga() {
	const res = yield getSubscriptionList();
	yield put(loadSubscriptionsFinish(res));
}

function* addQuotaToAudienceSaga(action) {
	const payload = {
		category: action.payload.categoryNanoId,
	};
	const res = yield addQuotaToAudience({
		projectNanoId: action.payload.projectNanoId,
		audienceNanoId: action.payload.audienceNanoId,
		payload,
	});

	const audience = yield call(() =>
		getAudienceDetailed({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);

	yield put(addQuota({ quota: res, errors: audience.errors }));

	//recalculate project cost and feasibility
	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
	yield put(
		calculateProjectFeasibilityStart({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);
}

export function* watchAddQuotaToAudience() {
	yield takeLatest('addQuotaToAudience', addQuotaToAudienceSaga);
}

function* deleteQuotaFromAudienceSaga(action) {
	yield deleteQuotaFromAudience({
		projectNanoId: action.payload.projectNanoId,
		audienceNanoId: action.payload.audienceNanoId,
		quotaNanoId: action.payload.quotaNanoId,
	});

	yield put(deleteQuota({ quotaNanoId: action.payload.quotaNanoId }));

	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
	yield put(
		calculateProjectFeasibilityStart({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);
}

export function* watchDeleteQuotaFromAudience() {
	yield takeLatest('deleteQuotaFromAudience', deleteQuotaFromAudienceSaga);
}

function* addDeleteQuotaFieldSaga(action) {
	if (action.payload.type === 'add') {
		const payload = {
			audience_field: action.payload.audienceField,
			response_quota: action.payload.response_quota,
		};

		yield call(() =>
			addQuotaField({
				projectNanoId: action.payload.projectNanoId,
				audienceNanoId: action.payload.audienceNanoId,
				quotaNanoId: action.payload.quotaNanoId,
				payload: payload,
			}),
		);
	} else {
		yield call(() =>
			deleteQuotaField({
				projectNanoId: action.payload.projectNanoId,
				audienceNanoId: action.payload.audienceNanoId,
				quotaNanoId: action.payload.quotaNanoId,
				quotaFieldNanoId: action.payload.quotaFieldNanoId,
			}),
		);
	}

	const result = yield getQuotaFields({
		projectNanoId: action.payload.projectNanoId,
		audienceNanoId: action.payload.audienceNanoId,
		quotaNanoId: action.payload.quotaNanoId,
	});

	const audience = yield call(() =>
		getAudienceDetailed({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);

	yield put(
		setQuotaFields({
			quotaFields: result,
			quotaNanoId: action.payload.quotaNanoId,
			audienceErrors: audience.errors,
			audienceField: action.payload.audienceField,
		}),
	);
	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
	yield put(
		calculateProjectFeasibilityStart({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);
}

export function* watchAddDeleteQuotaField() {
	yield takeLatest(
		'projectEditor/updateQuotaFieldStart',
		addDeleteQuotaFieldSaga,
	);
}

function* updateQuotaFieldSaga(action) {
	const payload = {
		response_quota: action.payload.responseQuota,
	};

	yield call(() =>
		updateQuotaField({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
			quotaNanoId: action.payload.quotaNanoId,
			quotaFieldNanoId: action.payload.quotaFieldNanoId,
			payload: payload,
		}),
	);

	const audience = yield call(() =>
		getAudienceDetailed({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);

	yield put(
		updateQuotaResponseFinish({
			quotaNanoId: action.payload.quotaNanoId,
			audienceErrors: audience.errors,
			responseQuota: action.payload.responseQuota,
			quotaFieldNanoId: action.payload.quotaFieldNanoId,
		}),
	);
}

export function* watchUpdateQuotaField() {
	yield debounce(
		1000,
		'projectEditor/updateQuotaResponseStart',
		updateQuotaFieldSaga,
	);
}

export function* watchLoadSubscription() {
	yield takeLatest(
		'projectEditor/loadSubscriptionsStart',
		loadSubscriptionListSaga,
	);
}

function* loadActivitiesSaga(action) {
	const result = yield getActivitiesList(action.payload.pageNumber);

	yield put(
		loadActivitiesSuccess({ activities: result.results, next: result.next }),
	);
}

function* postProjectFeedbackSaga(action) {
	const feedback = yield select(
		state => state.project.projectFeedback.feedback,
	);
	if (!feedback) {
		yield postProjectFeedback({
			projectNanoId: action.payload.projectNanoid,
			rating: action.payload.rating,
			comment: action.payload.comment,
		});
	} else {
		yield updateProjectFeedback({
			projectNanoId: action.payload.projectNanoid,
			feedbackNanoId: feedback.nanoid,
			rating: action.payload.rating,
			comment: action.payload.comment,
		});
	}

	const result = yield getProjectFeedback(action.payload.projectNanoid);

	yield put(postProjectFeedbackFinish(result.results[0]));
}

export function* watchPostProjectFeedback() {
	yield takeLatest('project/postProjectFeedbackStart', postProjectFeedbackSaga);
}

function* deleteProjectFeedbackSaga(action) {
	yield deleteProjectFeedback({
		projectNanoId: action.payload.projectNanoId,
		feedbackNanoId: action.payload.feedbackNanoId,
	});

	yield put(deleteProjectFeedbackFinish());
}

export function* watchDeleteProjectFeedback() {
	yield takeLatest(
		'project/deleteProjectFeedbackStart',
		deleteProjectFeedbackSaga,
	);
}

function* getPromoCodesSaga() {
	const res = yield getPromoCodes();

	yield put(loadPromoCodesFinish(res.results));
}

export function* watchLoadPromoCodes() {
	yield takeLatest('projectEditor/getPromoCodesStart', getPromoCodesSaga);
}
function* addPromoCodeSaga(action) {
	try {
		yield addPromoCode({
			projectNanoId: action.payload.projectNanoId,
			promoCode: action.payload.promoCode,
		});

		yield put(addPromoCodeFinish());

		yield put(
			calculateProjectCostStart({
				projectNanoId: action.payload.projectNanoId,
			}),
		);
	} catch (error) {
		if (error.response.status === 400) {
			if (error.response.data.errors) {
				yield put(addPromoCodeFail(error.response.data.errors));
			} else {
				yield put(addPromoCodeFail(error.response.data.non_field_errors));
			}
		}
		if (error.response.status === 404) {
			yield put(addPromoCodeFail(['invalid_code_error']));
		}
	}
}

export function* watchAddPromoCode() {
	yield takeLatest('projectEditor/addPromoCodeStart', addPromoCodeSaga);
}

function* deletePromoCodeSaga(action) {
	yield deletePromoCode({
		projectNanoId: action.payload.projectNanoId,
		promoCode: action.payload.promoCode,
	});

	yield put(deletePromoCodeFinish());

	yield put(
		calculateProjectCostStart({
			projectNanoId: action.payload.projectNanoId,
		}),
	);
}

export function* watchDeletePromoCode() {
	yield takeLatest('projectEditor/deletePromoCodeStart', deletePromoCodeSaga);
}

export function* watchLoadActivities() {
	yield takeLatest('activities/loadActivitiesStart', loadActivitiesSaga);
}

function* projectFeasibilitySaga(action) {
	const result = yield call(() =>
		getProjectFeasibility({
			projectNanoId: action.payload.projectNanoId,
			audienceNanoId: action.payload.audienceNanoId,
		}),
	);

	yield put(
		calculateProjectFeasibilityFinish({
			data: result,
		}),
	);
}

export function* watchProjectFeasibilityCalculate() {
	yield debounce(
		1000,
		'projectEditor/calculateProjectFeasibilityStart',
		projectFeasibilitySaga,
	);
}

function* setQuestionListSaga() {
	const projectNanoId = yield select(
		state => state.projectEditor.project.nanoid,
	);

	yield put(
		loadAnalysisDataStart({
			projectNanoId: projectNanoId,
		}),
	);
}

export function* watchQuestionListChanges() {
	yield takeLatest('projectEditor/setQuestionList', setQuestionListSaga);
}
