import { QUESTION_META_DATA } from '../../../constants';

const addQuestionDropdownItems = [
	{
		items: [
			{
				title: 'Single Selection', //t('Single Selection')
				type: 'single_selection',
				icon: QUESTION_META_DATA['single_selection'].icon,
			},
			{
				title: 'Multiple Selection', //t('Multiple Selection')
				type: 'multiple_selection',
				icon: QUESTION_META_DATA['multiple_selection'].icon,
			},
			{
				title: 'Yes/No', //t('Yes/No')
				type: 'yes_no',
				icon: QUESTION_META_DATA['yes_no'].icon,
			},
		],
	},
	{
		items: [
			{
				title: 'Text', //t('Text')
				type: 'text',
				icon: QUESTION_META_DATA['text'].icon,
			},
			{
				title: 'Number', //t('Number')
				type: 'number',
				icon: QUESTION_META_DATA['number'].icon,
			},
		],
	},
	{
		items: [
			{
				title: 'Rating', //t('Rating')
				type: 'rating',
				icon: QUESTION_META_DATA['rating'].icon,
			},
			{
				title: 'Ranking', //t('Ranking')
				type: 'ranking',
				icon: QUESTION_META_DATA['ranking'].icon,
			},
			{
				title: 'NPS®', //t('NPS®')
				type: 'nps',
				icon: QUESTION_META_DATA['nps'].icon,
			},
			{
				title: 'Opinion Scale', //t('Opinion Scale')
				type: 'opinion_scale',
				icon: QUESTION_META_DATA['opinion_scale'].icon,
			},
		],
	},
	{
		items: [
			{
				title: 'Matrix', //t('Matrix')
				type: 'matrix',
				icon: QUESTION_META_DATA['matrix'].icon,
			},
			{
				title: 'File Upload', //t('File Upload')
				type: 'file_upload',
				icon: QUESTION_META_DATA['file_upload'].icon,
			},
			{
				title: 'Description', //t('Description')
				type: 'description',
				icon: QUESTION_META_DATA['description'].icon,
			},
		],
	},
];

export default addQuestionDropdownItems;
