export default function TermsOfUse() {
	return (
		<div className="mt-3 sm:mt-5">
			<div className="text-sm text-gray-500">
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 1 TARAFLAR
				</h3>
				<div className="my-2">
					İşbu Kullanıcı Sözleşmesi (“Sözleşme”), Sorbunu (sorbunu.com) internet
					sitesinin (“Platform”) sahibi, Esentepe Mahallesi Büyükdere Cad. Loft
					Residance Apt. No: 201/122 Şişli/İstanbul adresinde faaliyette bulunan
					Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
					Ticaret Anonim Şirketi (“Şirket”) ile Platform’a üye olan kullanıcı
					(“Üye”) arasında elektronik ortamda akdedilmiştir. Şirket ve Üye
					bundan böyle birlikte “Taraflar” olarak anılacaktır.
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 2 SÖZLEŞME’NİN KONUSU
				</h3>
				<div className="my-2">
					İşbu Sözleşme’nin konusu Şirket’in sahibi olduğu Platform üzerinden
					Üye’nin alacağı hizmetin yararlanma ve kullanma koşullarının
					belirlenmesidir.
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 3 SÖZLEŞME’NİN KURULMASI
				</h3>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-1">
							ÜYE SÖZLEŞME’Yİ OKUDUĞUNU, ANLADIĞINI, HAKLARININ VE
							YÜKÜMLÜLÜKLERİNİN BİLİNCİNDE OLDUĞUNU KABUL EDER.
						</li>
						<li className="mb-1">
							TARAFLAR, SÖZLEŞME İLE KARARLAŞTIRILAN EDİMLER ARASINDA HİÇBİR
							ORANSIZLIK BULUNMADIĞINI VE KARŞILIKLI EDİMLERİN İŞİN NİTELİĞİNE
							UYGUN OLDUĞUNU, SÖZLEŞME KONUSUNA GİREN İŞLEMLER KAPSAMINDA
							HERHANGİ BİR TECRÜBESİZLİKLERİNİN BULUNMADIĞINI KABUL EDERLER.
						</li>
						<li className="mb-1">
							ÜYE, SÖZLEŞME KAPSAMINDA YER ALAN İŞLEMLERİN KENDİ MENFAATİNE
							UYGUN OLDUĞU KONUSUNDA TAM BİR KANAATE VARDIĞINI VE TÜM ŞARTLARA
							KENDİ ÖZGÜR İRADESİ İLE, HİÇBİR GÜÇLÜK VEYA SIKINTI İÇİNDE
							OLMADAN, DÜŞÜNEREK, İSTEYEREK VE BİLEREK UYACAĞINI KABUL EDER.
						</li>
						<li className="mb-1">
							TARAFLAR, SÖZLEŞME’NİN HÜKÜMLERİNİN HAKSIZ ŞART SAYILABİLECEK BİR
							ÖZELLİK TAŞIMADIĞINI, MENFAATLER DENGESİ BAKIMINDAN BİR HAKSIZLIK
							OLMADIĞINI KABUL EDER.
						</li>
						<li className="mb-1">
							İŞBU SÖZLEŞME HÜKÜMLERİ HERHANGİ BİR HAKSIZ ŞART İÇERMEMEKTEDİR.
							HÜKÜMLER DÜRÜSTLÜK VE İYİNİYET KURALINA AYKIRILIK TEŞKİL ETMEMEKTE
							OLUP İLGİLİ MEVZUATA UYGUN OLARAK HAZIRLANMIŞTIR.
						</li>
						<li>
							İŞBU SÖZLEŞME HÜKÜMLERİ TÜRK BORÇLAR KANUNU HÜKÜMLERİ DE DİKKATE
							ALINARAK HAZIRLANMIŞTIR. TÜRK BORÇLAR KANUNU’NUN 21. MADDESİNDE
							ÖNGÖRÜLEN BAĞLAYICILIK VE İÇERİK DENETİMİ ÜYE TARAFINDAN
							YAPILMIŞTIR. İŞBU SÖZLEŞME HÜKÜMLERİNDEN HİÇBİRİ İŞBU SÖZLEŞME’NİN
							NİTELİĞİNE VE İŞİN ÖZELLİĞİNE YABANCI (ŞAŞIRTICI ŞARTLAR) NİTELİK
							TAŞIMAZ. İŞBU SÖZLEŞME HÜKÜMLERİ AÇIK VE ANLAŞILIR BİR ŞEKİLDE
							YAZILMIŞ OLUP BİRDEN ÇOK ANLAMI İFADE ETMEMEKTEDİR.
						</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 4 TANIMLAR
				</h3>
				<div className="my-2">
					<p className="mb-1">
						<b>“Hizmet”</b> Platform üzerinde Şirket tarafından yapılacak online
						Sorbunu Anket Araştırma Hizmet’i ve ürün satışı amacı ile Üyeler’in
						ürün ve hizmetlerle ilgili bilgi almasını sağlamak için verilen
						hizmeti ifade eder.
					</p>
					<p className="mb-1">
						<b>“Resmi Makam”</b> Her türlü adli veya idari makamı, resmi, yarı
						resmi veya özerk kurum, kuruluş, komisyon ve kurulu ifade eder;
					</p>
					<p>
						<b>“Ürünler”</b> Platform’da satışa sunulmuş olan Sorbunu Anket
						Araştırma Hizmeti’ni ve ürünleri ifade eder.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 5 ÜYELİK
				</h3>
				<div className="my-2">
					<p className="mb-1">
						<b>5.1</b> Üyelik, Platform’da yer alan üyelik işlemlerinin
						tamamlanmasıyla (ve bu Sözleşme’nin akdedilmesiyle) kazanılır.
					</p>
					<p className="mb-1">
						<b>5.2</b> Üyeler’in reşit olmaları gerekir.
					</p>
					<p className="mb-1">
						<b>5.3</b> Üyeler, üyelik işlemleri ile ilgili olarak doğru, gerçek
						ve güncel bilgiler vermekle yükümlüdür. Bu bilgilerin kapsamı Şirket
						tarafından belirlenir ve gerekmesi halinde değiştirilebilir, kapsamı
						genişletilebilir. Bu bilgileri vermek istemeyen kişi Platform’a Üye
						olamaz. Üye’nin üyeliğinin devam etmesi için genişletilmiş bilgi
						listesinde yer alan bilgileri temin etmesi ve herhangi bir
						değişiklik olduğu takdirde bilgilerini güncellemesi gerekir.
					</p>
					<p className="mb-1">
						<b>5.4</b> Şirket, Resmi Makamlar’ın düzenleyici veya icrai
						işlemleri veya yargı kararlarının gereklerini yerine getirmek
						amacıyla ve taleple sınırlı olarak Üye’nin bilgilerini ilgili Resmi
						Makamlar ile paylaşabilir. İlgili Resmi Makam’ın bir gizlilik kararı
						mevcut değilse söz konusu işlemden Üye haberdar edilir.
					</p>
					<p className="mb-1">
						<b>5.5</b> Şirket, Üyeler’in Hizmet kapsamında verdikleri tanıtıcı
						ve/veya şahsi bilgilerinin ve Hizmet’ten yararlanırken yaptıkları
						tercihlerin, anlaşma veya anlaşamama durumlarının Şirket tarafından
						sistemin işlerliğinin denetlenmesi amacıyla kullanılabilir (ve/veya
						bu bilgileri işleyerek Şirket ile gizlilik sözleşmesi imzalamış
						şirketlere gönderebilir); değerlendirmeler ve istatistik çalışmalar
						yapabilir, bunları genel bir şekilde Platform üzerinden ilan
						edebilir.
					</p>
					<p className="mb-1">
						<b>5.6</b> Üyeler’in, Hizmet’i alırken bir rumuz (nick name)
						kullanmaları durumunda söz konusu rumuz, yürürlükteki mevzuata ve
						ahlaka aykırı olamaz; hakaret, küfür, sövme, aşağılayıcı, kişilik
						haklarına zarar veren sözler içeremez.
					</p>
					<p>
						<b>5.7</b> Üyeler, üyelikten diledikleri zaman çıkabilirler. Bu
						durumda Üye, Şirket’e Platform üzerinden üyelikten çıkma isteğini
						bildirir ve üyelikten silinmesi için gerekli işlemlerin yapılmasını
						sağlar. Bu prosedürler tamamlandığında üyelikten çıkış işlemi
						gerçekleşir. Üyelikten çıkış tarihine kadar Sözleşme’den kaynaklanan
						hak ve yükümlülüklere ve Sözleşme feshedilse dahi nitelikleri gereği
						yürürlükte kalması gereken hükümlere (aykırılıklar sebebiyle
						tazminat, son hükümler vs.) üyelikten çıkmanın bir etkisi olmaz.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 6 TARAFLAR’IN HAK VE YÜKÜMLÜLÜKLERİ
				</h3>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-1">
							Üye, Ürün ve Hizmet kapsamında elde ettiği verilerin anket verisi
							olduğunu ve bu anket verilerine ilişkin Şirket’in hiçbir
							sorumluğunun olmadığını peşinen kabul eder. Üye, Hizmet ve diğer
							her türlü içerik ve
							<i>“olduğu şekilde”</i>
							kendisine sunulduğunu, Şirket’in Hizmet ve Ürün kapsamında elde
							edilen verilerin doğruluğunu ve geçerliliğini taahhüt etmediğini
							kabul ve beyan eder.
						</li>
						<li className="mb-1">
							Üye, Ürün ve Hizmet kapsamında elde ettiği verileri bireysel bilgi
							almak amacıyla kullanacağını, Şirket’in yazılı izni olmadıkça
							yayınlamayacağını, çoğaltmayacağını, 3.taraflarla paylaşmayacağını
							ve ticari amaçla kullanmayacağını kabul, beyan ve taahhüt eder.
							Üye ilgili yükümlülüğe aykırı davranması durumunda ortaya çıkan
							her türlü zarardan kendisinin sorumlu olacağını ve Şirket’e bu
							kapsamda tevcih edilecek bir sorumluluk veya talep halinde
							Şirket’e derhal tazmin edeceğini beyan ve taahhüt eder.
						</li>
						<li className="mb-1">
							Üye, Sözleşme’de yer alan herhangi bir hükmün ihlalinden
							kaynaklanan hallerde, kendisinin Hizmet kapsamında elde ettiği
							anket verilerini kullandığı hallerde veya bu haller ile bağlantılı
							olarak ortaya çıkabilecek durumlarda veya işbu Sözleşme’de bulunan
							herhangi bir taahhüt nedeniyle doğacak ihtilaflarda; üçüncü
							kişilerin her türlü taleplerinin tek muhatabının kendisi olduğunu
							kabul, beyan ve taahhüt eder. Üye, bu talepler sonucu ortaya çıkan
							sair yükümlülüklerin (masraf ve/veya mahkeme masrafı tazmini,
							zarar tazmini, tazminat yükümlülüğünün ve fer’ilerinin tazmini
							vb.), takibi ve tahsili sorumluluğunun münhasıran kendisine ait
							olduğunu kabul, beyan ve taahhüt eder.
						</li>
						<li className="mb-1">
							Üye, Platform’a üye olurken verdiği kişisel ve diğer sair
							bilgilerin gerçeğe uygun olduğunu, bu bilgilerin gerçeğe
							aykırılığı nedeniyle ve/veya Üye bilgilerinde değişiklik olmasına
							rağmen Üye tarafından güncellenmemesi nedeniyle Şirket’in
							uğrayacağı tüm zararları derhal tazmin edeceğini ve beyan ve
							taahhüt eder. Ayrıca Üye tarafından verilen yanlış bilgi
							kapsamında ödeme işlemlerinin ve teslimatın doğru şekilde
							gerçekleşmemesi sebebiyle tüm sorumluluk Üye’ye aittir.
						</li>
						<li className="mb-1">
							Üye’nin Platform’un kullanılması için üyelik kapsamında aldığı
							şifreyi kullanma hakkı münhasıran Üye’ye aittir. Üye bu şifreyi
							herhangi bir üçüncü şahsa veremez. Şifrenin ve Platform’dan
							yararlanabilmek amacıyla kullanılan sisteme erişim araçlarının
							(kullanıcı ismi vb.) kullanımına ilişkin tüm hukuki ve cezai
							sorumluluk Üye’ye aittir.
						</li>
						<li className="mb-1">
							Üye, Platform’u kullanırken tüm yasal mevzuat hükümlerine uymayı
							ve söz konusu hükümleri ihlal etmemeyi kabul, beyan ve taahhüt
							eder. Aksi durumda, oluşacak tüm hukuki ve cezai sorumluluk
							tamamen ve münhasıran Üye’ye ait olacaktır.
						</li>
						<li className="mb-1">
							Üye, Hizmet ve Ürün’ü hiçbir şekilde başta T.C. Anayasası olmak
							üzere Kişisel Verilerin Korunması Kanunu ve mevzuatı, Türk Ceza
							Kanunu, Türk Medeni Kanunu ve ismen belirtilmeyen ancak yürürlükte
							bulunan birincil ve/veya ikincil mevzuatlara (“Mevzuat”) aykırı
							şekilde kullanamaz şekilde kullanmayacağını kabul, beyan ve
							taahhüt eder.
						</li>
						<li className="mb-1">
							Üye, Platform’u ve Hizmet’en elde ettiği veriyi hiçbir şekilde
							kamu düzenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve
							taciz edici şekilde, yasalara aykırı bir amaç için, başkalarının
							fikir ve telif haklarına tecavüz edecek, haksız rekabete neden
							olacak şekilde kullanamaz. Ayrıca Üye başkalarının hizmetleri
							kullanmasını önleyici veya zorlaştırıcı faaliyet (spam, virus,
							truva atı, vb.) ve işlemlerde bulunamayacağı gibi Platform’un veya
							yazılımın güvenliğini tehdit edecek, çalışmasını engelleyecek
							faaliyetlerde de bulunamaz.
						</li>
						<li className="mb-1">
							Şirket, gerekli bilgi güvenliği önlemlerini almasına karşın Üye
							bilgi ve verilerinin yetkisiz kişilerce ele geçirilmesinden ve Üye
							bilgi ve verilerine gelebilecek zararlardan dolayı sorumlu
							tutulmayacaktır.
						</li>
						<li className="mb-1">
							Platform’un, Hizmet ve Ürün’ün Üye tarafından kullanılmasından
							dolayı uğranabilecek herhangi bir doğrudan veya dolaylı zarardan
							Şirket sorumlu değildir.
						</li>
						<li className="mb-1">
							Platform, Şirket’in kontrolünde bulunmayan, Şirket tarafından
							kurulmamış, işletilmeyen veya düzenlenmemiş diğer internet
							sitelerine veya uygulamalara link verebilir, bu sitelere veya
							uygulamalara ilişkin referans içerebilir. Söz konusu diğer
							uygulamaların veya internet sitelerinin içeriğinden veya
							içerdikleri diğer link ve/veya referanslardan veya bunların
							sunduğu ürün/hizmetlerden Şirket sorumlu değildir. Bu linkler,
							bağlantı yapılan internet sitesinin sahibini veya söz konusu
							siteyi işleten kişiyi destekleme amacı veya içeriğindeki herhangi
							bir bilgi, ifade veya görsele yönelik herhangi bir beyan veya
							taahhüt niteliği taşımamaktadır. Aynı şekilde Platform’da yer alan
							veya Platform üzerinden link ile bağlantı sağlanan reklamların,
							tanıtımların veya bannerların tanıttığı, taahhüt ettiği ürün,
							hizmet ve diğer içerikten de Şirket sorumlu değildir.
						</li>
						<li className="mb-1">
							Üye, kendisi tarafından oluşturulan kullanıcı profilini üçüncü bir
							kişiye devredemez veya üçüncü kişinin kullanımına izin veremez.
							Üyeler bu Sözleşme’den kaynaklanan hak ve yükümlülüklerini
							Şirket’in onayı olmadan devredemezler. Şirket Sözleşme’den
							kaynaklanan hak ve yükümlülüklerini 3. kişilere devredebilir
						</li>
						<li className="mb-1">
							Üye’nin işbu Sözleşme hükümlerini ihlal etmesi halinde,
							ihlallerden doğan cezai ve hukuki sorumluluk şahsen Üye’ye aittir.
							Üye, ihlalleri dolayısıyla ortaya çıkabilecek tüm zarar, dava,
							talep ve iddialardan Şirket’i ari tutacaktır. Ayrıca; söz konusu
							ihlaller nedeniyle Şirket’in Üye’den tazminat talebinde bulunma
							hakkı saklıdır.
						</li>
						<li className="mb-1">
							Şirket’in her zaman tek taraflı olarak herhangi bir sorumluluk
							üstlenmeksizin ve tazminat ödemek zorunda olmaksızın Üye’nin
							üyeliğini askıya alma veya sonlandırma ve Üye’ye ait tüm bilgi,
							veri, belge ve dosyaları silme hakkı mevcuttur.
						</li>
						<li className="mb-1">
							Platform genel görünüm, tasarım ve yazılımı ile site içerisindeki
							metin ve görsel içerikler ile diğer tüm içeriklere, marka, logo,
							know-how ve diğer ögelere ilişkin telif hakkı ve/veya her türlü
							fikri mülkiyet hakları Şirket’e aittir veya Şirket tarafından
							lisans alınarak kullanılmaktadır. Bunlar Üye tarafından Şirket’in
							yazılı izni olmaksızın hiçbir surette kullanılamaz, iktisap
							edilemez, değiştirilemez, kopyalanamaz veya çoğaltılamaz. Platform
							veya içeriğinin Şirket tarafından sağlanacak koşullar dahilinde
							Üye tarafından kullanılması, erişilebilir olması Üye’ye hiçbir
							fikri ve sınai mülkiyet hakkı ve/veya herhangi bir hak sahipliği,
							tasarruf yetkisi vermez.
						</li>
						<li className="mb-1">
							Şirket, Üye’nin kişisel bilgilerini{' '}
							<b>
								<i>(a)</i>
							</b>{' '}
							yürürlükte olan kanun veya düzenlemeler ya da verilmiş olan bir
							mahkeme kararı ya da idari emir gereğince açıklaması gerektiği
							durumlarda ve/veya{' '}
							<b>
								<i>(b)</i>
							</b>{' '}
							Üye’nin rızasının bulunduğu takdirde ve/veya{' '}
							<b>
								<i>(c)</i>
							</b>{' '}
							Platform üzerinden ulaşılabilecek olan Gizlilik Politikası’na
							uygun şekilde ve/veya{' '}
							<b>
								<i>(d)</i>
							</b>{' '}
							işbu Sözleşme’de ayrık olarak düzenlenen hallere uygun şekilde
							üçüncü kişilere açıklayabilir. Şirket, soruşturma için talep
							edilen bilgi ve belgelere erişmek amacıyla Üye’nin gizli
							bilgilerinde veya mobil uygulama kayıtlarında inceleme yapabilir.
						</li>
						<li className="mb-1">
							Platform’un virüs ve benzeri amaçlı yazılımlardan arındırılmış
							olması için mevcut imkanlar dahilinde tedbir alınmıştır. Bunun
							yanında nihai güvenliğin sağlanması için Üye’nin kendi virüs
							koruma sistemini tedarik etmesi ve gerekli korumayı sağlaması
							gerekmektedir. Bu bağlamda Üye, Platform’a üye olmakla, kendi
							yazılım ve işletim sistemlerinde oluşabilecek (spam, virus, truva
							atı da dahil ve fakat bunlarla sınırlı olmaksızın) tüm hata ve
							bunların doğrudan ya da dolaylı sonuçlarından kendisinin sorumlu
							olduğunu kabul etmiş sayılır.
						</li>
						<li className="mb-1">
							Şirket, Hizmet’in kapsamını, Platform’un içeriğini, tasarımını ve
							yazılımını dilediği zaman değiştirme, Üyeler’e sağlanan herhangi
							bir hizmeti değiştirme, durdurma ya da sonlandırma ve dilediği
							zaman Platform üzerinde kayıtlı olan kullanıcı bilgi ve verilerini
							silme hakkını saklı tutar.
						</li>
						<li className="mb-1">
							Şirket, işbu Sözleşme’nin koşullarını hiçbir şekil ve surette ön
							ihbara ve/veya ihtara gerek kalmaksızın her zaman güncelleyebilir,
							değiştirebilir veya yürürlükten kaldırabilir. Güncellenen,
							değiştirilen ya da yürürlükten kaldırılan her hüküm, yayın
							tarihinde Üye için hüküm ifade edecektir.
						</li>
						<li className="mb-1">
							Hizmet’ten faydalanabilmek için gerekli olan ağ erişimini
							sağlamaktan Üye sorumludur. Üye, kablosuz internet erişimi olan
							bir cihazdan Platform’a erişmesi veya Hizmet’i kullanması
							durumunda mobil ağ verisinin kullanılabileceğinin, mesajlaşma
							tarifeleri ve ücretleri uygulanabileceğinin bilincindedir.
							Hizmet’ten faydalanabilmek için gerekli olan uygun yazılım ve
							cihazlarla güncellemeleri sağlamak ve Platform’u güncel tutmak
							Üye’nin sorumluluğundadır. Şirket, hiçbir surette sunduğu
							Hizmet’in veya Platform’un belirli bir cihaz veya yazılım ile
							çalışacağını garanti etmemektedir. Üye, Hizmet’in ve/veya
							Platform’un internet kullanımına bağlı olarak arızalara ve
							gecikmelere maruz kalabileceğini kabul eder. Teknik sorunlar
							sebebiyle Platform erişiminde yaşanan kesintilerden dolayı Üye’nin
							yaşayacağı sorunlardan Şirket sorumluluk üstlenmez.
						</li>
						<li className="mb-1">
							İşbu Sözleşme kapsamında Şirket için yazılan sorumsuzluk kayıtları
							Şirket çalışanları ve ortaklarına da şamildir.
						</li>
						<li className="mb-1">
							Şirket’in önceden onayı olmaksızın, Üye’nin Platform üzerinden
							yapacağı herhangi bir işlemde, başka bir internet sitesine, bir
							internet sitesinin içeriğine veya ağ üzerindeki herhangi bir
							veriye link vermesi yasaktır.
						</li>
						<li className="mb-1">
							Üye, internet sitesinde satışı yapılan Hizmet ve Ürün’e ait
							belirtilen temel özellikleri, tüm vergiler dahil satış fiyatı,
							ödeme şekli ve teslimata ilişkin ön bilgileri okuyup bilgi sahibi
							olduğunu ve elektronik ortamda bu hususlara ilişkin gerekli teyidi
							verdiğini beyan ve kabul eder.
						</li>
						<li className="mb-1">
							Üye, tüketici olmadığı sürece tüketici için geçerli olan, Tüketici
							Mevzuatından doğan hükümlerin (cayma hakkı, ön bilgilendirme formu
							vb.) kendisi için geçerli olmadığını bildiğini ve hiçbir surette
							bu gibi hususları ileri sürmeyeceğini kabul, beyan ve taahhüt
							eder.
						</li>
						<li>
							Üye’nin bu anlaşmaya aykırı davranması halinde doğabilecek tüm
							sorumluluk ve zarara karşı, Şirket’in tazminat ve sair talep hakkı
							saklıdır.
						</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 7 SÖZLEŞME’NİN FESHİ
				</h3>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-1">
							Üye ve Şirket’ten herhangi biri işbu Sözleşme’yi diledikleri zaman
							diğer tarafa bildirimde bulunarak feshedebilir.
						</li>
						<li>
							Şu kadar ki; Üye’nin fesih işlemi Platform üzerinden fesih
							usullerinin takip edilerek gerçekleştirilmesi suretiyle yapılır
							Fesih tarihine kadar Sözleşme’den kaynaklanan hak ve
							yükümlülüklere ve Sözleşme feshedilse dahi nitelikleri gereği
							yürürlükte kalması gereken hükümlere (aykırılıklar sebebiyle
							tazminat, son hükümler vs.) feshin bir etkisi olmaz.
						</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					MADDE – 8 SON HÜKÜMLER
				</h3>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-1">
							<b>
								<u>Uygulanacak Hukuk:</u>
							</b>{' '}
							İşbu Sözleşme Türkiye Cumhuriyeti kanunlarına tabidir ve ona göre
							yorumlanacaktır.
						</li>
						<li className="mb-1">
							<b>
								<u>Yetkili Mahkeme:</u>
							</b>{' '}
							İşbu Sözleşme’den doğan veya işbu Sözleşme ile bağlantılı tüm
							uyuşmazlıkların çözümünde İstanbul Merkez (Çağlayan) Mahkemeleri
							ve İcra Daireleri yetkilidir.
						</li>
						<li className="mb-1">
							<b>
								<u>Sözleşme ve Eklerinin Bütünlüğü:</u>
							</b>{' '}
							Sözleşmenin ekleri bu Sözleşme’nin ayrılmaz bir parçasını teşkil
							edecek olup birbirlerinden ayrı olarak yorumlanamaz.
						</li>
						<li className="mb-1">
							<b>
								<u>Münhasır Delil Niteliği:</u>
							</b>{' '}
							Taraflar işbu Sözleşme çerçevesinde ortaya çıkabilecek
							uyuşmazlıklarda, Şirket’e ait belge ve elektronik kayıtların, söz
							konusu uyuşmazlık bakımından münhasır delil niteliğinde olacağını
							kabul ederler.
						</li>
						<li className="mb-1">
							<b>
								<u>Şirket İletişim Bilgileri:</u>
							</b>{' '}
							Şirket’in iletişim bilgileri aşağıdaki gibidir:
							<div className="my-4 text-xs">
								<p>
									<b>
										<u>Unvan:</u>
									</b>{' '}
									Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama
									Sanayi Ve Ticaret Anonim Şirketi
								</p>
								<p>
									<b>
										<u>Adres:</u>
									</b>{' '}
									Esentepe Mahallesi Büyükdere Cad. Loft Resıdance Apt. No:
									201/122 Şişli/İstanbul
								</p>
								<p>
									<b>
										<u>Telefon:</u>
									</b>{' '}
									0850 888 13 65
								</p>
								<p>
									<b>
										<u>E-posta:</u>
									</b>{' '}
									info@sorbunu.com
								</p>
							</div>
						</li>
						<li className="mb-1">
							<b>
								<u>Platform Üzerinden Yapılan İşlemler:</u>
							</b>{' '}
							Platform üzerinden yapılan ve irade açıklaması niteliğindeki
							işlemler Türk Borçlar Kanunu ve yürürlükte bulunan sair mevzuat
							uyarınca Taraflar’ı bağlayıcı irade açıklamaları olarak kabul
							edilecektir.
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
