export default function PrivacyPolicy() {
	return (
		<div className="text-sm text-gray-900">
			<div>
				İşbu Gizlilik Politikası’nın amacı,{' '}
				<b>
					Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
					Ticaret Anonim Şirketi
				</b>
				’ne (“<b>Şirket</b>”) ait Sorbunu (http://www.sorbunu.com/) internet
				sitesine (“
				<b>Platform</b>”) üye olarak Şirket ürünlerini satın alan kişilerden (“
				<b>Kullanıcılar</b>”) elektronik ticaret işlemleri nedeniyle elde edilen
				kişisel verilere ilişkin gizlilik kurallarını tespit etmektir.
			</div>{' '}
			<br />
			<p>
				<b>1.</b> Şirket, Kullanıcılar tarafından üyelik formları ile veya sair
				surette kendisine iletilen kişisel verileri; Gizlilik Politikası,
				kişisel veri elde edilmesi esnasında Kullanıcı’ya sunulan Kişisel
				Verilere İlişkin Aydınlatma Metni ve Kullanıcı’nın açık rızasında
				belirtilen haller haricinde üçüncü şahıslarla paylaşmamakta, belirtilen
				amaçlar dışında hiçbir ticari amaçla kullanmamakta ve üçüncü kişilere
				aktarmamaktadır.
			</p>
			<br />
			<p>
				<b>2.</b> Kullanıcı’ya ait kişisel veriler ancak resmi makamlarca talep
				edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince
				açıklama yapılmak zorunda olunduğu durumlarda resmi makamlara
				açıklanacaktır.
			</p>{' '}
			<br />
			<p>
				<b>3.</b> Web Sitesi’ne üyelik, ürün satın alma ve bilgi güncelleme
				amaçlı girilen kredi kartı ve banka kartlarına ilişkin kişisel veriler,
				Kullanıcı ile ilgili banka veya kart kuruluşları arasında, Şirket’ten
				bağımsız olarak gerçekleştirilmekte olup kredi kartı şifresi gibi
				bilgiler Şirket veya diğer Web Sitesi kullanıcıları tarafından
				görüntülenememektedir.
			</p>{' '}
			<br />
			<p>
				<b>4.</b> Ödeme sayfasında talep edilen Kullanıcı kredi kartı bilgileri,
				Kullanıcılar’ın güvenliğini en üst seviyede tutmak amacıyla hiçbir
				şekilde Web Sitesi’nde veya hizmet veren üçüncü şirketlerin
				sunucularında tutulmamaktadır. Bu şekilde ödemeye yönelik tüm
				işlemlerin, Web Sitesi üzerinden ilgili banka ve Kullanıcı’nın
				kullanmakta olduğu cihaz arasında gerçekleşmesi sağlanmaktadır.
			</p>{' '}
			<br />
			<p>
				<b>5.</b> Şirket, Kullanıcı’nın Web Sitesi üzerinde gerçekleştirdiği
				kullanım ve işlem bilgilerini anonim hale getirerek; istatistiki
				değerlendirmelerde, performans değerlendirmelerinde, Şirket ve iş
				ortaklarının pazarlama kampanyalarında ve bağış kampanyalarında, yıllık
				rapor ve benzeri raporlarda kullanmak üzere bu amaçların
				gerçekleştirilmesi için gereken sürede saklayabilir, işleyebilir ve iş
				ortaklarına iletebilir.
			</p>{' '}
			<br />
			<p>
				<b>6.</b> Kullanıcılar tarafından Şirket’e sağlanan veya Şirket
				tarafından edinilen kişisel verilerin ve Web Sitesi üzerinden
				gerçekleşen tüm işlemlerin güvenliği için bilgi ve işlemin mahiyetine
				göre Şirket veya ilgili kuruluşça sistemlerde ve internet altyapısında,
				teknolojik imkânlar ve maliyet unsurları dâhilinde, uygun teknik ve
				idari tedbirler alınmıştır.
			</p>{' '}
			<br />
			<p>
				{' '}
				<b>7.</b> Kullanıcı’nın, Şirket ile paylaşmış olduğu kişisel verilerinin
				Kullanıcı’ya ait olduğu kabul edilmektedir.
			</p>{' '}
			<br />
			<p>
				<b>8.</b> Şirket, Gizlilik Politikası’nda ve Kullanıcı’ya sunacağı ürün,
				hizmet, fırsat ve kampanyalarda gerekli görebileceği her türlü
				değişikliği yapma hakkını saklı tutar; bu değişiklikler Şirket
				tarafından Web Sitesi’nde veya diğer uygun yöntemler ile duyurulduğu
				andan itibaren geçerli olur.
			</p>{' '}
			<br />
			<p className="mb-4">
				<b>
					Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
					Ticaret Anonim Şirketi
				</b>
			</p>
		</div>
	);
}
