import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAnalysisMeta({ projectNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.analysis.ANALYSIS_META(projectNanoId),
	);

	return result.data;
}

export async function getAnalysisResponses({ projectNanoId }) {
	const result = await sorbunuApi.get(
		`/projects/${projectNanoId}/analysis/responses/`,
	);
	return result.data;
}
