import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../utils';
import { find } from 'lodash';

export default function RadioGroup2({ data, selected, setSelected, disabled }) {
	const selectedItem = selected
		? find(data, item => item.id === selected.nanoid)
		: null;

	return (
		<RadioGroup value={selectedItem} onChange={setSelected}>
			<RadioGroup.Label className="sr-only"> Privacy setting </RadioGroup.Label>
			<div className="-space-y-px rounded-md bg-white">
				{data.map((item, itemIndex) => (
					<RadioGroup.Option
						key={item.id}
						value={item}
						disabled={disabled}
						className={({ checked }) =>
							classNames(
								itemIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '',
								itemIndex === data.length - 1
									? 'rounded-bl-md rounded-br-md'
									: '',
								checked
									? 'bg-indigo-50 border-indigo-200 z-10'
									: 'border-gray-200',
								'relative border p-4 flex cursor-pointer focus:outline-none',
							)
						}
					>
						{({ active, checked }) => (
							<>
								<span
									className={classNames(
										checked
											? 'bg-indigo-600 border-transparent'
											: 'bg-white border-gray-300',
										active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
										'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
									)}
									aria-hidden="true"
								>
									<span className="rounded-full bg-white w-1.5 h-1.5" />
								</span>
								<span className="ml-3 flex flex-col">
									<RadioGroup.Label
										as="span"
										className={classNames(
											checked ? 'text-indigo-900' : 'text-gray-900',
											'block text-sm font-medium',
										)}
									>
										{item.name}
									</RadioGroup.Label>
									<RadioGroup.Description
										as="span"
										className={classNames(
											checked ? 'text-indigo-700' : 'text-gray-500',
											'mt-1 block text-sm',
										)}
									>
										{item.description}
									</RadioGroup.Description>
								</span>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
}
