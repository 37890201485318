import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getQuestionnaireLanguages({ questionnaireNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.translations.LIST_QUESTIONNAIRE_LANGUAGES(questionnaireNanoId),
	);
	return result.data.results;
}

export async function getTranslatedQuestionTexts({
	questionnaireNanoId,
	questionnaireLanguageNanoId,
}) {
	const result = await sorbunuApi.get(
		endPoints.translations.LIST_TRANSLATED_QUESTION_TEXTS(
			questionnaireNanoId,
			questionnaireLanguageNanoId,
		),
	);
	return result.data.results;
}
