import moment from 'moment';

export function getSubscriptionStatusLabel({ t, subscription }) {
	if (subscription.status === 'active') {
		if (subscription.expire_date) {
			if (
				moment(subscription.period.end_date).diff(
					moment(subscription.expire_date),
				) > 0
			) {
				return t('subscription.expiresOn', {
					date: moment(subscription.expire_date).format('L'),
				});
			} else {
				return t('subscription.renewsOn', {
					date: moment(subscription.period.end_date).format('L'),
				});
			}
		} else {
			return t('subscription.renewsOn', {
				date: moment(subscription.period.end_date).format('L'),
			});
		}
	} else if (subscription.status === 'canceling') {
		return t('subscription.cancelsOn', {
			date: moment(subscription.period.end_date).format('L'),
		});
	} else if (subscription.status === 'expired') {
		return t('subscription.expxiredOn', {
			date: moment(subscription.period.expire_date).format('L'),
		});
	}
}
