// https://tailwindui.com/components/application-ui/navigation/pagination#component-0797a02a34692167c369d134e7a6f9c5

import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
import { classNames } from '../../utils';
//import { Trans } from 'react-i18next';

const TEXTSIZE_CLASSES = {
	xSmall: 'text-xs',
	small: 'text-sm',
	medium: 'text-md',
	large: 'text-lg',
};

export default function Pagination({
	className,
	currentPage,
	startIndex,
	endIndex,
	totalItemCount,
	handlePageChange,
	textSize,
	itemName,
	previousBtnText,
	nextBtnText,
	fetching,
}) {
	const { t } = useTranslation();
	return (
		<nav
			className={classNames(
				className,
				'flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6',
			)}
			aria-label="Pagination"
		>
			<div className="hidden sm:block">
				<p className={`${TEXTSIZE_CLASSES[textSize]}  text-gray-700`}>
					{t('paginationText', {
						totalItemCount: totalItemCount,
						startIndex: startIndex,
						endIndex: endIndex,
						itemName: itemName,
					})}
				</p>
			</div>

			<div className="flex flex-1 justify-between sm:justify-end items-center">
				{fetching && <Spinner className="h-4 w-4 mr-2" />}
				<button
					disabled={currentPage === 1 || fetching}
					onClick={() => handlePageChange(currentPage - 1)}
					className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 ${TEXTSIZE_CLASSES[textSize]} font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30`}
				>
					{previousBtnText ? previousBtnText : t('Previous')}
				</button>
				<button
					disabled={endIndex >= totalItemCount || fetching}
					onClick={() => handlePageChange(currentPage + 1)}
					className={`relative ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 ${TEXTSIZE_CLASSES[textSize]} font-medium text-gray-700 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30`}
				>
					{nextBtnText ? nextBtnText : t('Next')}
				</button>
			</div>
		</nav>
	);
}
