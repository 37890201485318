import React from 'react';
import AnalysisFilterCompareOptions from '../../../components/AnalysisFilterCompareOptions';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { classNames } from '../../../utils';
import { useSearchParams, useParams, Outlet } from 'react-router-dom';
import FeedbackSlideover from '../../../components/FeedbackSlideover';
import SlideOver from '../../../components/SlideOver';
import { toggleProjectFeedbackSlideOver } from '../../../store/projectSlice';
import Badge from '../../../components/Badge';
import { useTranslation } from 'react-i18next';
import AnalysisHeader from '../../../components/AnalysisHeader';
import useFilterOptions from '../../../hooks/useFilterOptions';

export default function AnalysisLayout() {
	const { nanoid } = useParams();
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const urlRating = searchParams.get('rating');
	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);
	const isFeedbackOpen = useSelector(
		state => state.project.projectFeedback.isSlideOverOpen,
	);
	const rawData = projectAnalysis ? projectAnalysis.rawData : [];
	const filteredData = projectAnalysis ? projectAnalysis.filteredData : [];
	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const [currentTab, setCurrentTab] = useState('filter');
	const [currentPageTab, setCurrentPageTab] = useState('');
	const [isFilterOpen, setIsFilterOpen] = useState(false);
	const [isCompareOpen, setIsCompareOpen] = useState(false);

	const [
		displayOption,
		changeDisplayOption,
		handleAppliedFiltersChange,
		availableFilterOptions,
		appliedFilters,
		appliedComparisonItems,
		handleAppliedComparisonItemsChange,
	] = useFilterOptions(nanoid);

	const appliedFilterCount = projectAnalysis
		? projectAnalysis.filterData.appliedFilterCount
		: 0;

	const appliedComparisonItemCount = projectAnalysis
		? projectAnalysis.comparisonData.appliedComparisonItemCount
		: 0;
	const tabs = [
		{
			name: t('Filter'),
			key: 'filter',
			className: 'tour-analysis-filter',
			current: currentTab === 'filter',
		},
		{
			name: t('Compare'),
			key: 'compare',
			className: 'tour-analysis-compare',
			current: currentTab === 'compare',
		},
	];

	useEffect(() => {
		if (urlRating !== null) {
			dispatch(toggleProjectFeedbackSlideOver(true));
		}
	}, [urlRating, dispatch]);

	const toggleFeedbackSlideOver = bool => {
		dispatch(toggleProjectFeedbackSlideOver(bool));
	};

	return (
		<>
			<div className="flex">
				<div className="w-1/4 hidden md:flex flex-col h-screen bg-white shadow-md z-20">
					<div className="border-b border-gray-200">
						<nav className="-mb-px flex" aria-label="Tabs">
							{tabs.map(tab => (
								<button
									key={tab.name}
									onClick={() => setCurrentTab(tab.key)}
									className={classNames(
										tab.className ? tab.className : '',
										tab.current
											? 'border-indigo-500 text-indigo-600'
											: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
										'w-1/2 h-14 px-1 text-center border-b-2 font-medium text-sm',
									)}
									aria-current={tab.current ? 'page' : undefined}
								>
									{tab.name}{' '}
									{tab.key === 'filter' && appliedFilterCount > 0 && (
										<Badge type={'complete'}>{appliedFilterCount}</Badge>
									)}
									{tab.key === 'compare' && appliedComparisonItemCount > 0 && (
										<Badge type={'complete'}>
											{appliedComparisonItemCount}
										</Badge>
									)}
								</button>
							))}
						</nav>
					</div>
					{/* ***mobile******* */}
					<div className="grow overflow-y-auto p-3 mb-10">
						{currentTab === 'filter' && (
							<AnalysisFilterCompareOptions
								mode={'filter'}
								availableOptions={availableFilterOptions}
								selectedOptions={appliedFilters}
								handleSelectedOptionChange={handleAppliedFiltersChange}
								translations={translations}
								language={displayLanguage}
								nanoid={nanoid}
								changeDisplayOption={changeDisplayOption}
								displayOption={displayOption}
							/>
						)}
						{currentTab === 'compare' && (
							<AnalysisFilterCompareOptions
								mode={'compare'}
								availableOptions={availableFilterOptions}
								selectedOptions={appliedComparisonItems}
								handleSelectedOptionChange={handleAppliedComparisonItemsChange}
								translations={translations}
								language={displayLanguage}
								nanoid={nanoid}
								changeDisplayOption={changeDisplayOption}
								displayOption={displayOption}
							/>
						)}
					</div>
				</div>

				<div className="grow overflow-y-auto  md:hidden">
					{currentTab === 'filter' && isFilterOpen && (
						<>
							<SlideOver
								title={t('Filter')}
								open={isFilterOpen}
								setOpen={setIsFilterOpen}
							>
								<AnalysisFilterCompareOptions
									mode={'filter'}
									availableOptions={availableFilterOptions}
									selectedOptions={appliedFilters}
									handleSelectedOptionChange={handleAppliedFiltersChange}
									translations={translations}
									language={displayLanguage}
									nanoid={nanoid}
									changeDisplayOption={changeDisplayOption}
									displayOption={displayOption}
								/>
							</SlideOver>
						</>
					)}
					{currentTab === 'compare' && isCompareOpen && (
						<>
							<SlideOver
								title={t('Compare')}
								open={isCompareOpen}
								setOpen={setIsCompareOpen}
							>
								<AnalysisFilterCompareOptions
									mode={'compare'}
									availableOptions={availableFilterOptions}
									selectedOptions={appliedComparisonItems}
									handleSelectedOptionChange={
										handleAppliedComparisonItemsChange
									}
									translations={translations}
									language={displayLanguage}
									nanoid={nanoid}
									changeDisplayOption={changeDisplayOption}
									displayOption={displayOption}
								/>
							</SlideOver>
						</>
					)}
				</div>

				{/* ***feedback****** */}
				<FeedbackSlideover
					isFeedbackOpen={isFeedbackOpen}
					toggleFeedbackSlideOver={toggleFeedbackSlideOver}
				/>
				<div className="w-full relative h-screen overflow-y-auto">
					<div className="bg-white flex flex-row items-center w-full pl-4 pr-7 pb-4 md:pb-0 shadow-sm md:h-14 z-20 border-b border-gray-100 sticky top-0">
						<AnalysisHeader
							selectedTab={currentPageTab}
							setCurrentPageTab={setCurrentPageTab}
							rawData={rawData}
							filteredData={filteredData}
							hasFilter={appliedFilterCount !== 0}
							translations={translations}
							language={displayLanguage}
							setIsFilterOpen={setIsFilterOpen}
							setIsCompareOpen={setIsCompareOpen}
							setCurrentTab={setCurrentTab}
							appliedFilterCount={appliedFilterCount}
							appliedComparisonItemCount={appliedComparisonItemCount}
						/>
					</div>
					<Outlet context={{ setCurrentPageTab }} />
				</div>
			</div>
		</>
	);
}
