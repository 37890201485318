import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export default function NumberPicker({
	defaultValue,
	minValue,
	label,
	handleValueChange,
}) {
	const [currentValue, setCurrentValue] = useState(defaultValue);

	const changeValue = newValue => {
		setCurrentValue(newValue);
		handleValueChange(newValue);
	};

	return (
		<div className="flex flex-row items-center gap-3">
			<span className="text-xs font-medium">{label}</span>

			<span className="isolate inline-flex rounded-md shadow-sm">
				<button
					type="button"
					disabled={currentValue === minValue}
					onClick={() => changeValue(currentValue - 1)}
					className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 disabled:cursor-not-allowed"
				>
					<MinusIcon className="h-3 w-3" aria-hidden="true" />
				</button>
				<span className="text-xs font-mono m-auto px-3 py-1.5 border-y border-gray-300">
					{currentValue}
				</span>
				<button
					type="button"
					onClick={() => changeValue(currentValue + 1)}
					className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
				>
					<PlusIcon className="h-3 w-3" aria-hidden="true" />
				</button>
			</span>
		</div>
	);
}
