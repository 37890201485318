import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAvailableAudienceCategories(country_code) {
	const result = await sorbunuApi.get(
		endPoints.audiences.AVAILABLE_CATEGORIES(country_code),
	);
	return result.data.results;
}

export async function getAudienceDetailed({ projectNanoId, audienceNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.audiences.AUDIENCE_DETAILED(projectNanoId, audienceNanoId),
	);

	return result.data;
}
export async function getProjectFeasibility({ projectNanoId, audienceNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.audiences.PROJECT_FEASIBILITY(projectNanoId, audienceNanoId),
	);

	return result.data;
}

export async function updateAudienceReach({
	projectNanoId,
	audienceNanoId,
	reach,
}) {
	const result = await sorbunuApi.put(
		endPoints.audiences.AUDIENCE(projectNanoId, audienceNanoId),
		{ limit: reach },
	);
	return result.data;
}

export async function getCategoryFields({ categoryNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.audiences.CATEGORY_FIELDS(categoryNanoId),
	);
	return result.data.results;
}

export async function searchCategoryFields({ categoryNanoId, term }) {
	const result = await sorbunuApi.get(
		endPoints.audiences.SEARCH_CATEGORY_FIELDS(categoryNanoId, term),
	);
	return result.data.results;
}

export async function getAudienceFields({ projectNanoId, audienceNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.audiences.AUDIENCE_FIELDS(projectNanoId, audienceNanoId),
	);
	return result.data.results;
}

export async function deleteAudienceField({
	projectNanoId,
	audienceNanoId,
	audienceFieldNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.audiences.DELETE_AUDIENCE_FIELD(
			projectNanoId,
			audienceNanoId,
			audienceFieldNanoId,
		),
	);
	return result.data;
}

export async function deleteAudienceFieldsByCategory({
	projectNanoId,
	audienceNanoId,
	categoryNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.audiences.DELETE_AUDIENCE_FIELDS_BY_CATEGORY(
			projectNanoId,
			audienceNanoId,
			categoryNanoId,
		),
	);
	return result.data;
}

export async function createAudienceField({
	projectNanoId,
	audienceNanoId,
	categoryNanoId,
	categoryFieldNanoId,
	audienceFieldNanoId,
}) {
	const result = await sorbunuApi.post(
		endPoints.audiences.AUDIENCE_FIELDS(projectNanoId, audienceNanoId),
		{
			category: categoryNanoId,
			category_field: categoryFieldNanoId,
			value: audienceFieldNanoId,
		},
	);
	return result.data;
}
