import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { newQuestionTemplate } from '../../utils';
import { createQuestion } from '../../store/projectEditorSlice';
import { sendGoogleEvent } from '../../utils/analytics';
import Dropdown from '../Dropdown';
import addQuestionDropdownItems from '../../pages/projects/ProjectEditor/addQuestionDropdownItems';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

export default function AddQuestionButton({
	isEditable,
	isScreeningQuestion,
	length,
	totalQuestions,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const questionnaire = useSelector(state => state.projectEditor.questionnaire);
	const isAddingQuestion = useSelector(
		state => state.projectEditor.questionnaire.isAddingQuestion,
	);

	const project = useSelector(state => state.projectEditor.project);

	const handleAddQuestion = item => {
		const newQuestion = newQuestionTemplate({
			t: t,
			type: item.type,
			isScreening: isScreeningQuestion,
		});

		dispatch(
			createQuestion({
				type: item.type,
				questionnaireNanoId: questionnaire.nanoid,
				newQuestion: newQuestion,
			}),
		);
		sendGoogleEvent('questionnaire_question_add', {
			question_type: item.type,
			is_screening: isScreeningQuestion,
		});
	};

	const questionLimit = project.subscription
		? project.subscription.plan.max_num_questions
		: 50;
	const screeningQuestionLimit = project.subscription
		? project.subscription.plan.max_num_screening_questions
		: 3;
	const isDisabled =
		isAddingQuestion ||
		!isEditable ||
		(isScreeningQuestion && length >= screeningQuestionLimit) ||
		totalQuestions === questionLimit;

	return (
		<div className="tour-question-editor-add-question">
			<span className="group relative">
				{isScreeningQuestion && length >= screeningQuestionLimit && (
					<span className="w-52 p-2 pl-4 shadow hidden sm:block z-50 pointer-events-none absolute border border-gray-300 text-sm mb-1 top-10 -translate-x-1/2 rounded bg-white text-gray-700 opacity-0 transition  before:absolute before:right-10 before:bottom-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-b-gray-400 group-hover:opacity-100">
						{project.subscription
							? t(
									'screeningQuestions.addScreeningQuestionSubscriptionWarning',
									{
										screeningQuestionLimit,
									},
							  )
							: t('screeningQuestions.addScreeningQuestionWarning', {
									screeningQuestionLimit,
							  })}
					</span>
				)}
				{totalQuestions === questionLimit && (
					<span className="mr-2 border-l-2 border-gray-300 p-3 rounded-md bg-gray-50 text-gray-400 text-sm">
						<ExclamationCircleIcon
							className="text-gray-400 h-6 w-6 pr-1 -ml-1 -mt-1 inline"
							aria-hidden="true"
						/>
						{project.subscription
							? t('addQuestionBtnTotalQuestionSubscriptionError', {
									questionCount: questionLimit,
							  })
							: t('addQuestionBtnTotalQuestionError', {
									questionCount: questionLimit,
							  })}
					</span>
				)}
				<Dropdown
					label={
						<div className=" flex flex-auto">
							{isAddingQuestion && (
								<span className="text-gray-700">
									<Spinner className="-ml-1 mr-2 h-5 w-5" />
								</span>
							)}
							{t('Add Question')}
						</div>
					}
					direction={isScreeningQuestion ? 'down' : 'up'}
					disabled={isDisabled}
					items={
						isScreeningQuestion
							? addQuestionDropdownItems.slice(0, 1)
							: addQuestionDropdownItems
					}
					onChange={handleAddQuestion}
				/>
			</span>
		</div>
	);
}
