import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import Spinner from '../Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import FormAlert from '../FormAlert';
import { deletePromoCodeErrors } from '../../store/projectEditorSlice';

export default function PromoCodeDialogue({
	promoCodes,
	open,
	setOpen,
	handlePromoCodeSubmit,
	promoCodeApplied,
	isApplyingPromoCode,
	promoCodeType,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [errorMessage, setErrorMessage] = useState('');
	const loading = useSelector(state => state.projectEditor.promoCodes.loading);
	const error = useSelector(state => state.projectEditor.promoCodes.error);
	const actionType = promoCodeApplied ? 'delete' : 'add';

	const orderedPromoCodes = _.orderBy(
		promoCodes,
		function (promoCode) {
			return new Date(promoCode.valid_until);
		},
		['asc'],
	);

	useEffect(() => {
		setOpen(false);
	}, [promoCodeApplied, setOpen]);

	useEffect(() => {
		if (error) {
			if (promoCodeType === 'auto') {
				let errorMessage = error;
				if (!Array.isArray(error)) {
					errorMessage = [error.promo_code];
				}
				setErrorMessage(errorMessage);
			}
		} else {
			setErrorMessage('');
		}
	}, [error, promoCodeType]);

	const promocodeText = (
		type,
		percentage,
		percentage_amount_limit,
		direct_discount_amount,
	) => {
		if (type === 'percentage') {
			return `%${percentage} discount`;
		}
		if (type === 'percentage_with_limit') {
			return `%${percentage} discount up to ${percentage_amount_limit} TL`;
		}
		if (type === 'direct_amount') {
			return `${direct_discount_amount} TL discount`;
		}
	};
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={
					isApplyingPromoCode
						? () => {}
						: () => {
								setOpen(false);
								dispatch(deletePromoCodeErrors());
						  }
				}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity block" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full justify-center text-center items-center md:px-2 lg:px-4">
						{/* This element is to trick the browser into centering the modal contents. */}
						<span
							className="hidden md:inline-block md:h-screen md:align-middle"
							aria-hidden="true"
						>
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
							enterTo="opacity-100 translate-y-0 md:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 md:scale-100"
							leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
						>
							<Dialog.Panel className="flex transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
								<div className="relative flex w-full items-center justify-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:pr-6 md:pl-12 lg:pt-10 lg:pr-8 lg:pl-16">
									{!isApplyingPromoCode && (
										<button
											type="button"
											className="absolute top-4 right-4 text-gray-900 hover:text-gray-500 "
											onClick={() => {
												setOpen(false);
												dispatch(deletePromoCodeErrors());
											}}
										>
											<span className="sr-only">{t('Close')}</span>
											<XMarkIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									)}
									<div className="flex flex-col">
										<div>
											<p className="text-sm text-gray-500 pb-2">
												{t('promoCodeText')}
											</p>
											<div className="my-2">
												{errorMessage && (
													<FormAlert errorMessage={errorMessage} />
												)}
											</div>
											<div className="inline-block min-w-full py-2 align-middle md:pr-6 lg:pr-8 ">
												<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
													<table className="min-w-full divide-y divide-gray-300">
														<thead className="bg-gray-50">
															<tr>
																<th
																	scope="col"
																	className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
																>
																	{t('Promo Code')}
																</th>

																<th
																	scope="col"
																	className="px-3 py-3 text-left text-xs uppercase font-medium tracking-wide text-gray-500"
																>
																	{t('Promo Type')}
																</th>
																<th
																	scope="col"
																	className="px-3 py-3 text-left text-xs uppercase font-medium tracking-wide text-gray-500"
																>
																	{t('Valid Until')}
																</th>
																<th
																	scope="col"
																	className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
																></th>
															</tr>
														</thead>
														{loading ? (
															<Spinner className="mx-auto mt-10 w-10 h-10 text-indigo-600" />
														) : (
															<tbody className="divide-y divide-gray-200 bg-white">
																{promoCodes.length === 0 && (
																	<tr>
																		<td
																			className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
																			colSpan={5}
																		>
																			{t('noValidPromoCodeText')}
																		</td>
																	</tr>
																)}
																{orderedPromoCodes.map(item => (
																	<tr key={item.nanoid}>
																		<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
																			{item.name}
																		</td>

																		<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
																			{promocodeText(
																				item.calculation_type,
																				item.percentage,
																				item.percentage_amount_limit,
																				item.direct_discount_amount,
																			)}
																		</td>

																		<td className="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 ">
																			{moment
																				.utc(item.valid_until)
																				.format('DD/M/YYYY')}
																		</td>

																		<td className="whitespace-nowrap pr-6 py-4 text-sm  text-center">
																			<button
																				className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm enabled:hover:bg-indigo-700 disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
																				onClick={() => {
																					dispatch(deletePromoCodeErrors());
																					handlePromoCodeSubmit(
																						item.code,
																						actionType,
																					);
																				}}
																				disabled={
																					promoCodeApplied &&
																					promoCodeApplied.code != item.code
																				}
																			>
																				{isApplyingPromoCode &&
																					isApplyingPromoCode === item.code && (
																						<Spinner className="w-4 h-4" />
																					)}
																				{!isApplyingPromoCode &&
																					promoCodeApplied &&
																					promoCodeApplied.code === item.code &&
																					t('Unapply')}
																				{!isApplyingPromoCode &&
																					(!promoCodeApplied ||
																						promoCodeApplied.code !==
																							item.code) &&
																					t('Apply')}
																				{isApplyingPromoCode &&
																					isApplyingPromoCode !== item.code &&
																					t('Apply')}
																			</button>
																		</td>
																	</tr>
																))}
															</tbody>
														)}
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}
