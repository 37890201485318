import { useState, useEffect } from 'react';
import i18n from 'i18next';
import { useSearchParams } from 'react-router-dom';

export default function LanguageSwitch({ getSelectedLang }) {
	const [searchParams] = useSearchParams();
	const language = searchParams.get('lang');

	const [isActive, setIsActive] = useState({
		en: localStorage.getItem('i18nextLng') === 'en-US' ? true : false,
		tr: localStorage.getItem('i18nextLng') === 'tr-TR' ? true : false,
	});

	const changeLanguage = language => {
		const lang = language === 'en' ? 'en-US' : 'tr-TR';

		setIsActive({
			en: language === 'en' ? true : false,
			tr: language === 'tr' ? true : false,
		});
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
	};

	useEffect(() => {
		if (language) {
			changeLanguage(language);
		}
	}, [language]);

	useEffect(() => {
		isActive.en ? getSelectedLang('en-US') : getSelectedLang('tr-TR');
	}, [isActive, getSelectedLang]);

	const handleChangeLanguageClick = e => {
		const selectedLanguage = e.target.value;
		changeLanguage(selectedLanguage);
	};

	return (
		<div className="flex items-center">
			<div className="text-sm font-light">
				<button
					type="button"
					value="en"
					className={isActive.en ? 'font-bold' : ''}
					onClick={handleChangeLanguageClick}
				>
					EN
				</button>{' '}
				|{' '}
				<button
					type="button"
					value="tr"
					className={isActive.tr ? 'font-bold' : ''}
					onClick={handleChangeLanguageClick}
				>
					TR
				</button>
			</div>
		</div>
	);
}
