import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
	ExclamationCircleIcon,
	CheckCircleIcon,
} from '@heroicons/react/24/outline';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { classNames } from '../../utils';

function Modal({
	title,
	message,
	primaryButtonLabel,
	onPrimaryButtonClick,
	showPrimaryButton = true,
	secondaryButtonLabel,
	onSecondaryButtonClick,
	closeOnOutsideClick = true,
	buttonColorClass = 'bg-red-600 hover:bg-red-700',
	icon = 'warning',
}) {
	const cancelButtonRef = useRef(null);

	const modal = useModal();

	const handlePrimaryButtonClick = () => {
		onPrimaryButtonClick();
		modal.hide();
	};

	const handleSecondaryButtonClick = () => {
		if (onSecondaryButtonClick) {
			onSecondaryButtonClick();
		}
		modal.hide();
	};

	return (
		<Transition.Root show={modal.visible} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				initialFocus={cancelButtonRef}
				onClose={closeOnOutsideClick ? () => modal.hide() : () => {}}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div className="sm:flex sm:items-start">
									<div
										className={classNames(
											icon === 'warning' ? 'bg-yellow-200' : '',
											icon === 'error' ? 'bg-red-100' : '',
											icon === 'success' ? 'bg-green-100' : '',
											`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10`,
										)}
									>
										{icon === 'warning' && (
											<ExclamationCircleIcon
												className="h-6 w-6 text-yellow-500"
												aria-hidden="true"
											/>
										)}

										{icon === 'error' && (
											<ExclamationCircleIcon
												className="h-6 w-6 text-red-600"
												aria-hidden="true"
											/>
										)}

										{icon === 'success' && (
											<CheckCircleIcon
												className="h-6 w-6 text-green-600"
												aria-hidden="true"
											/>
										)}
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900"
										>
											{title}
										</Dialog.Title>
										<div className="mt-2">
											{Array.isArray(message) ? (
												message.map((item, idx) => {
													return (
														<p key={idx} className="text-sm text-gray-500 my-3">
															{item}
														</p>
													);
												})
											) : (
												<p className="text-sm text-gray-500">{message}</p>
											)}
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 justify-end">
									{showPrimaryButton && (
										<button
											type="button"
											className={classNames(
												buttonColorClass,
												'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm  sm:mr-3',
											)}
											onClick={handlePrimaryButtonClick}
										>
											{primaryButtonLabel}
										</button>
									)}
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
										onClick={handleSecondaryButtonClick}
										ref={cancelButtonRef}
									>
										{secondaryButtonLabel}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
}

export default NiceModal.create(
	({
		title,
		message,
		primaryButtonLabel,
		onPrimaryButtonClick,
		showPrimaryButton,
		secondaryButtonLabel,
		onSecondaryButtonClick,
		closeOnOutsideClick,
		icon,
		buttonColorClass,
	}) => {
		return (
			<Modal
				title={title}
				message={message}
				primaryButtonLabel={primaryButtonLabel}
				onPrimaryButtonClick={onPrimaryButtonClick}
				showPrimaryButton={showPrimaryButton}
				secondaryButtonLabel={secondaryButtonLabel}
				onSecondaryButtonClick={onSecondaryButtonClick}
				closeOnOutsideClick={closeOnOutsideClick}
				icon={icon}
				buttonColorClass={buttonColorClass}
			/>
		);
	},
);
