import { useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';
import { selectProjectFilterCategories } from '../../../utils';
import AnalysisQuestion from '../../../components/AnalysisQuestion';
import { filter, tidy } from '@tidyjs/tidy';
import { useEffect } from 'react';

export default function DemographicsPage() {
	const { nanoid } = useParams();
	const { setCurrentPageTab } = useOutletContext();

	const projectAnalysis = useSelector(state => state.analysis.projects[nanoid]);
	const rawData = projectAnalysis ? projectAnalysis.rawData : [];
	const filteredData = projectAnalysis ? projectAnalysis.filteredData : [];
	const translations = projectAnalysis?.translations;
	const displayLanguage = projectAnalysis?.translations.displayLanguage;

	const availableFilterOptions = projectAnalysis
		? projectAnalysis.filterData.availableFilterOptions
		: [];

	useEffect(() => {
		setCurrentPageTab('demographics');
	}, [setCurrentPageTab]);

	const demographicList = availableFilterOptions.reduce(
		(demographics, filter) => {
			//check if it's NOT a question filter
			if (filter.nanoid) {
				const filters = filter.category_set
					.filter(category =>
						selectProjectFilterCategories(nanoid, category.slug),
					)
					.reduce((acc, curr) => {
						const demographyFilter = {
							name: curr.name,
							question_type: 'demographics',
							slug: curr.slug,
							choices: curr.categoryfield_set,
						};
						acc.push(demographyFilter);
						return acc;
					}, []);
				demographics.push(...filters);
			}
			return demographics;
		},
		[],
	);

	const filteredResponsesPerQuestion = {};
	const rawResponsesPerQuestion = {};
	demographicList.forEach(demographic => {
		filteredResponsesPerQuestion[demographic.slug] = tidy(
			filteredData,
			filter(d => {
				return d[demographic.slug];
			}),
		);
		rawResponsesPerQuestion[demographic.slug] = tidy(
			rawData,
			filter(d => {
				return d[demographic.slug];
			}),
		);
	});

	return (
		<>
			<div className="mx-auto pt-4 max-w-4xl mb-40 flex flex-col gap-4">
				{demographicList.map((demographic, index) => {
					return (
						<div key={index}>
							<AnalysisQuestion
								key={demographic.nanoid}
								translations={translations}
								language={displayLanguage}
								filteredData={filteredResponsesPerQuestion[demographic.slug]}
								index={index + 1}
								question={demographic}
								responseCount={rawResponsesPerQuestion[demographic.slug].length}
								filteredResponseCount={
									filteredResponsesPerQuestion[demographic.slug].length
								}
							/>
						</div>
					);
				})}
			</div>
		</>
	);
}
