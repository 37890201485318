import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendGoogleEvent } from '../utils/analytics';
import { applyFilter, removeFilter } from '../store/analysisSlice';
import {
	applyComparisonItem,
	removeComparisonItem,
} from '../store/analysisSlice';

export default function useFilterOptions(nanoid) {
	const [displayOption, setDisplayOption] = useState('percentage');
	const dispatch = useDispatch();

	const project = useSelector(state => state.analysis.projects[nanoid]);
	const availableFilterOptions = project
		? project.filterData.availableFilterOptions
		: [];

	const appliedFilters = project ? project.filterData.appliedFilters : {};
	const appliedComparisonItems = project
		? project.comparisonData.appliedComparisonItems
		: {};

	const changeDisplayOption = option => {
		setDisplayOption(option);
	};

	const handleAppliedFiltersChange = ({
		filterItem,
		filterField,
		rank,
		selected,
	}) => {
		if (selected) {
			dispatch(
				applyFilter({
					projectNanoId: nanoid,
					filterItem: filterItem,
					filterField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_filter_add', {
				filter: filterItem,
				option: filterField.name,
			});
		} else {
			dispatch(
				removeFilter({
					projectNanoId: nanoid,
					filterItem: filterItem,
					filterField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_filter_remove', {
				filter: filterItem,
				option: filterField.name,
			});
		}
	};

	const handleAppliedComparisonItemsChange = ({
		filterItem,
		filterField,
		rank,
		selected,
	}) => {
		if (selected) {
			dispatch(
				applyComparisonItem({
					projectNanoId: nanoid,
					comparisonItem: filterItem,
					comparisonField: filterField,
					rank: rank,
				}),
			);
			sendGoogleEvent('analysis_comparison_add', {
				filter: filterItem,
				option: filterField.name,
			});
		} else {
			dispatch(
				removeComparisonItem({
					projectNanoId: nanoid,
					comparisonItem: filterItem,
					comparisonField: filterField,
					rank,
				}),
			);
			sendGoogleEvent('analysis_comparison_remove', {
				filter: filterItem,
				option: filterField.name,
			});
		}
	};

	return [
		displayOption,
		changeDisplayOption,
		handleAppliedFiltersChange,
		availableFilterOptions,
		appliedFilters,
		appliedComparisonItems,
		handleAppliedComparisonItemsChange,
	];
}
