import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import NextButton from '../NextButton';
import { classNames } from '../utils/utils';

export default function MatrixQuestion({
	question,
	showPreviousButton,
	handlePrevious,
	isPreviousDisabled,
	handleQuestionSubmit,
	isEditable = true,
	lastQuestion,
}) {
	const isRequired = question.config.required;
	const inputType = question.config.input_type;
	const isMultipleSelection = question.config.is_multiselection;
	const [error, setError] = useState(isRequired);

	const validate = values => {
		const inputValues = values;

		if (inputType === 'number' && isRequired) {
			// eslint-disable-next-line no-unused-vars
			for (let [rowIndex, row] of question.rows.entries()) {
				// eslint-disable-next-line no-unused-vars
				for (let [columnIndex, column] of question.columns.entries()) {
					const key = `${row['nanoid']}________${column['nanoid']}`;
					if (values[key] === undefined) {
						setError(true);
						return;
					}
				}
			}

			setError(false);
			return;
		}

		if (isRequired) {
			if (inputValues) {
				if (isMultipleSelection) {
					if (
						Object.keys(inputValues).length !== question.rows.length ||
						Object.values(inputValues).find(value => value.length === 0)
					) {
						setError(true);
					} else {
						setError(false);
					}
				}
				if (!isMultipleSelection) {
					Object.keys(inputValues).length !== question.rows.length
						? setError(true)
						: setError(false);
				}
			} else {
				setError(true);
			}
		} else {
			if (Object.keys(inputValues).length > 0) {
				if (isMultipleSelection) {
					if (
						Object.keys(inputValues).length !== question.rows.length ||
						Object.values(inputValues).find(value => value.length === 0)
					) {
						setError(true);
					} else {
						setError(false);
					}
				}
				if (!isMultipleSelection) {
					Object.keys(inputValues).length !== question.rows.length
						? setError(true)
						: setError(false);
				}
			} else {
				Object.keys(inputValues).length === 0 && setError(false);
			}
		}
	};

	const formSubmissionHandler = values => {
		let entries = Object.entries(values);
		let res = null;

		if (inputType === 'number') {
			res = entries.reduce((acc, [key, value]) => {
				const [outerKey, innerKey] = key.split('________');
				if (!acc[outerKey]) {
					acc[outerKey] = {};
				}
				acc[outerKey][innerKey] = value;
				return acc;
			}, {});
		} else if (inputType === 'selection') {
			let entries = Object.entries(values);
			res = entries.map(entry => {
				// If multiple selection is not enabled, Formik returns a `string `here instead of an `Array`. We fix this if necessary.
				let columnsValue = entry[1];
				if (typeof entry[1] === 'string') {
					columnsValue = [
						{
							choice: entry[1],
						},
					];
				} else {
					columnsValue = entry[1].map(c => {
						return {
							choice: c,
						};
					});
				}

				return {
					choice: entry[0],
					columns: columnsValue,
				};
			});
		}

		handleQuestionSubmit({
			question: question,
			answer: res,
		});
	};

	return (
		<>
			<Formik
				initialValues={{}}
				enableReinitialize={true}
				onSubmit={(values, { resetForm }) => {
					formSubmissionHandler(values);
					resetForm();
				}}
				validate={validate}
			>
				{({ values, handleChange }) => (
					<Form>
						<div className="mt-3 flex flex-col">
							<div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
									<div className="w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
										<table className="min-w-full divide-y divide-gray-300">
											<thead className="bg-gray-50">
												<tr className="divide-x divide-gray-200">
													<th scope="col">&nbsp;</th>
													{question.columns.map((column, index) => (
														<th
															key={column.nanoid}
															scope="col"
															className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6 group"
														>
															<div className="flex">
																<span className="pl-0 block w-full border-0 border-b border-transparent bg-transparent focus:outline-none focus:border-mint-600 focus:ring-0 sm:text-sm">
																	{question.columns[index].title}
																</span>
															</div>
														</th>
													))}
												</tr>
											</thead>
											<tbody className="divide-y divide-gray-200 bg-white">
												{question.rows.map((row, index) => (
													<tr
														key={row.nanoid}
														className="divide-x divide-gray-200"
													>
														<td className="whitespace-wrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 group">
															<div className="flex">
																<span className="pl-0 block w-full border-0 border-b border-transparent focus:outline-none focus:border-mint-600 focus:ring-0 sm:text-sm">
																	{question.rows[index].title}
																</span>
															</div>
														</td>
														{question.columns.map(column => (
															<td
																key={column.nanoid}
																className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 text-center"
															>
																{inputType === 'selection' && (
																	<Field
																		type={
																			isMultipleSelection ? 'checkbox' : 'radio'
																		}
																		name={row.nanoid}
																		value={column.nanoid}
																		disabled={!isEditable}
																		className={classNames(
																			isMultipleSelection ? 'rounded' : '',
																			'h-4 w-4 border-gray-300 text-mint-600 focus:ring-mint-500 cursor-pointer disabled:pointer-events-none disabled:opacity-30',
																		)}
																	/>
																)}
																{inputType === 'number' && (
																	<>
																		<Field
																			type={'number'}
																			name={`${row.nanoid}________${column.nanoid}`}
																			value={
																				values[
																					`${row.nanoid}________${column.nanoid}`
																				] || 0
																			}
																			onChange={event => {
																				handleChange(event);
																			}}
																			disabled={!isEditable}
																			className={classNames(
																				'block w-full rounded-md border-0 py-1.5 text-mint-600 focus:ring-mint-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm/6',
																			)}
																		/>
																	</>
																)}
															</td>
														))}
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<NextButton
							disabled={error}
							showPreviousButton={showPreviousButton}
							isPreviousDisabled={isPreviousDisabled}
							handlePrevious={handlePrevious}
							lastQuestion={lastQuestion}
						/>
					</Form>
				)}
			</Formik>
		</>
	);
}
