import { useEffect, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox as HeadlessCombobox } from '@headlessui/react';
import { classNames } from '../utils/utils';

export default function Combobox({
	label,
	data,
	selectedItem,
	onChange,
	disabled,
}) {
	const [query, setQuery] = useState('');
	const [selectedPerson, setSelectedPerson] = useState(null);

	const handleChange = item => {
		onChange(item);
		setQuery('');
	};

	useEffect(() => {
		if (selectedItem) {
			setSelectedPerson(selectedItem);
		} else {
			setSelectedPerson(null);
		}
	}, [selectedItem]);

	const filteredData =
		query === ''
			? data
			: data.filter(item => {
					return item.name.toLowerCase().includes(query.toLowerCase());
			  });

	return (
		<HeadlessCombobox
			as="div"
			value={selectedPerson}
			onChange={handleChange}
			disabled={disabled}
		>
			<HeadlessCombobox.Label className="block text-sm font-medium text-gray-700">
				{label}
			</HeadlessCombobox.Label>
			<div className="relative mt-1">
				<HeadlessCombobox.Input
					className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-mint-500 focus:outline-none focus:ring-1 focus:ring-mint-500 sm:text-sm"
					onChange={event => setQuery(event.target.value)}
					displayValue={item => item?.name}
				/>
				<HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<ChevronUpDownIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</HeadlessCombobox.Button>

				{filteredData.length > 0 && (
					<HeadlessCombobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredData.map(item => (
							<HeadlessCombobox.Option
								key={item.id}
								value={item}
								className={({ active }) =>
									classNames(
										'relative cursor-default select-none py-2 pl-3 pr-7',
										active ? 'bg-mint-600 text-white' : 'text-gray-900',
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span
											className={classNames(
												'block truncate',
												selected && 'font-semibold',
											)}
										>
											{item.name}
										</span>

										{selected && (
											<span
												className={classNames(
													'absolute inset-y-0 right-0 flex items-center pr-3',
													active ? 'text-white' : 'text-mint-600',
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</HeadlessCombobox.Option>
						))}
					</HeadlessCombobox.Options>
				)}
			</div>
		</HeadlessCombobox>
	);
}
