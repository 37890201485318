import {
	signIn,
	startSpin,
	stopSpin,
	successLoadProfile,
	failSignIn,
	failSignUp,
	successResetPassword,
	failResetPassword,
} from '../authSlice';
import { sorbunuApi } from '../../api/axios';
import { push } from 'redux-first-history';
import {
	AUTH_STORAGE_KEY,
	getItemFromLocalStorage,
	saveToLocalStorage,
} from '../../utils';
import { DASHBOARD_PATH, SIGN_IN_PATH } from '../../routes';
import { endPoints } from '../../endPoints';

export const userSignIn =
	({ values }) =>
	dispatch => {
		dispatch(startSpin());
		sorbunuApi
			.post(endPoints.auth.SIGN_IN(), values)
			.then(res => {
				saveToLocalStorage(AUTH_STORAGE_KEY, res?.data);
				dispatch(signIn(res?.data));
				dispatch(stopSpin());
				dispatch(push(DASHBOARD_PATH));
			})
			.catch(err => {
				dispatch(stopSpin());
				if (err.response.status === 400) {
					if (err.response.data.errors) {
						dispatch(failSignIn(err.response.data.errors));
					} else {
						dispatch(failSignIn(err.response.data.non_field_errors));
					}
				} else {
					dispatch(failSignIn(err.response.data.details));
				}
			});
	};

export const userSignUp =
	({ values }) =>
	dispatch => {
		dispatch(startSpin());
		sorbunuApi
			.post(endPoints.auth.SIGN_UP(), values)
			.then(res => {
				dispatch(stopSpin());
				saveToLocalStorage(AUTH_STORAGE_KEY, res?.data);
				dispatch(signIn(res?.data));
				dispatch(push(DASHBOARD_PATH));
			})
			.catch(err => {
				dispatch(stopSpin());
				if (err.response.status === 400) {
					if (err.response.data.errors) {
						dispatch(failSignUp(err.response.data.errors));
					} else {
						dispatch(failSignUp(err.response.data.non_field_errors));
					}
				} else {
					dispatch(failSignUp(err.response.data.details));
				}
			});
	};

export const getUserProfile = nanoid => dispatch => {
	sorbunuApi
		.get(endPoints.users.GET_USER_PROFILE(nanoid))
		.then(res => {
			dispatch(successLoadProfile(res.data));
		})
		.catch(err => err.message);
};

export const changePassword = values => async dispatch => {
	dispatch(startSpin());
	try {
		const result = await sorbunuApi.post(
			endPoints.auth.CHANGE_PASSWORD(),
			values,
		);
		dispatch(stopSpin());
		return { message: result.data.detail, success: true };
	} catch (error) {
		dispatch(stopSpin());
		if (error.response.data.detail) {
			return { message: error.response.data.detail, success: false };
		}
		return {
			message: error.response.data.errors.old_password[0],
			success: false,
		};
	}
};

export const forgotPassword = values => dispatch => {
	sorbunuApi
		.post(endPoints.auth.FORGOT_PASSWORD(), values)
		.then(() => {
			dispatch(push(SIGN_IN_PATH));
		})
		.catch(err => err?.message);
};
export const resetPassword = (values, uid, token) => dispatch => {
	sorbunuApi
		.post(endPoints.auth.RESET_PASSWORD(), { ...values, uid, token })
		.then(res => {
			dispatch(successResetPassword(res.data));
			dispatch(push(SIGN_IN_PATH));
		})
		.catch(err => {
			let formError = '';
			let tokenError = false;

			if (err.response.status === 400) {
				if (err.response.data.errors['new_password2']) {
					formError = err.response.data.errors.new_password2[0];
				} else if (err.response.data.errors['token']) {
					tokenError = true;
				}
			}
			dispatch(
				failResetPassword({
					serverError: err.response.status === 500,
					tokenError: tokenError,
					formError: formError,
				}),
			);
		});
};

sorbunuApi.interceptors.request.use(
	config => {
		const auth = getItemFromLocalStorage(AUTH_STORAGE_KEY);
		const { access_token } = auth ?? {};
		if (access_token) {
			config.headers['Authorization'] = 'Bearer ' + access_token;
		}
		return config;
	},
	error => {
		return Promise.reject(error);
	},
);
