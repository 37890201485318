import { useDispatch, useSelector } from 'react-redux';
import { addRule } from '../../store/projectEditorSlice';
import { BoltIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid';
import RulePill from '../RulePill';
import { newRuleTemplate } from '../../utils';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../utils/analytics';
import TourGuide from '../TourGuide';

export default function LogicDesigner({ question, isEditable = true }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const steps = [
		{
			target: '.tour-logic-designer-rule',
			placement: 'auto',
			title: t('tour.content.logicDesigner.logicRules.title'),
			content: t('tour.content.logicDesigner.logicRules.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-logic-designer-condition',
			placement: 'auto',
			title: t('tour.content.logicDesigner.conditions.title'),
			content: t('tour.content.logicDesigner.conditions.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-logic-designer-condition-question',
			placement: 'auto',
			title: t('tour.content.logicDesigner.checkQuestion.title'),
			content: t('tour.content.logicDesigner.checkQuestion.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-logic-designer-condition-check',
			placement: 'auto',
			title: t('tour.content.logicDesigner.checkOperator.title'),
			content: t('tour.content.logicDesigner.checkOperator.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-logic-designer-condition-value',
			placement: 'auto',
			title: t('tour.content.logicDesigner.checkValue.title'),
			content: t('tour.content.logicDesigner.checkValue.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-logic-designer-condition-action',
			placement: 'auto',
			title: t('tour.content.logicDesigner.flow.title'),
			content: t('tour.content.logicDesigner.flow.content'),
			disableBeacon: true,
		},
	];

	const isQuestionnaireRandomized = useSelector(
		state => state.projectEditor.questionnaire.isRandomized,
	);

	// TODO: Questionnaire.nanoid should be Questionnaire.nanoId
	const questionnaireNanoId = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);

	const handleAddRule = () => {
		const newRule = newRuleTemplate({
			question: question,
		});

		dispatch(
			addRule({
				questionnaireNanoId: questionnaireNanoId,
				question: question,
				rule: newRule,
			}),
		);
		sendGoogleEvent('questionnaire_question_logic_rule_add', {
			question_type: question.question_type,
		});
	};

	return (
		<>
			<div className="flex flex-col gap-3">
				{question.logic.rules.map((rule, index) => (
					<RulePill
						key={index}
						question={question}
						rule={rule}
						index={index}
						isEditable={isEditable}
					/>
				))}
				<button
					disabled={
						question.question_type === 'description' ||
						question.question_type === 'file_upload' ||
						question.logic.isAddingRule ||
						isQuestionnaireRandomized ||
						!isEditable
					}
					type="button"
					className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					onClick={handleAddRule}
				>
					<div className="inline-flex px-4 py-2">
						{question.logic.isAddingRule && (
							<Spinner className="-ml-1 mr-2 h-5 w-5" />
						)}
						{!question.logic.isAddingRule && (
							<BoltIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
						)}
						{t('Add Rule')}
					</div>
				</button>
			</div>
			{isQuestionnaireRandomized && (
				<div className="border-l-4 border-gray-400 p-3 rounded-md mt-2 bg-gray-50 text-gray-500 flex">
					<ExclamationCircleIcon
						className="text-gray-400 h-6 w-6 pr-1 -ml-1 -mt-1"
						aria-hidden="true"
					/>
					<p className="text-xs">{t('logicDesignerErrorButton')}</p>
				</div>
			)}
			{question.logic.rules.length > 0 && (
				<TourGuide tourName={'logicDesigner'} steps={steps} />
			)}
		</>
	);
}
