import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TrashIcon } from '@heroicons/react/24/solid';
import SlideOver from '../SlideOver';
import { useSearchParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Buttons } from '../Button';
import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../utils';
import * as _ from 'lodash';
import Spinner from '../Spinner';
import toast from 'react-hot-toast';
import { checkIfNumber, checkUrlRating } from './constant';
import {
	deleteProjectFeedbackStart,
	resetFeedbackPostingStatus,
	toggleProjectFeedbackSlideOver,
	postProjectFeedbackStart,
	resetFeedbackDeletingStatus,
} from '../../store/projectSlice';

export default function FeedbackSlideover({
	isFeedbackOpen,
	toggleFeedbackSlideOver,
}) {
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();
	const urlRating = searchParams.get('rating');

	const project = useSelector(state => state.projectEditor.project);
	const { feedback, loadingStatus } = useSelector(
		state => state.project.projectFeedback,
	);

	const { t } = useTranslation();
	const [value, setValue] = useState(null);
	const [text, setText] = useState('');

	let projectTitle = project ? project.title : '';

	useEffect(() => {
		if (feedback) {
			const comment = feedback.comment ? feedback.comment : '';
			setValue(feedback.rating);
			setText(comment);
		} else if (urlRating) {
			setValue(checkUrlRating(urlRating));
		} else {
			setValue(null);
			setText('');
		}
	}, [feedback, urlRating]);

	const deleteSearchParams = useCallback(() => {
		searchParams.delete('rating');
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	useEffect(() => {
		if (loadingStatus.post === 'success') {
			setTimeout(() => {
				dispatch(toggleProjectFeedbackSlideOver(false));
				const message = t('feedback.toast.postSuccess');
				toast.success(message, { title: t('Success') });
				dispatch(resetFeedbackPostingStatus());
			}, 500);
		}
		if (loadingStatus.delete === 'success') {
			setTimeout(() => {
				dispatch(toggleProjectFeedbackSlideOver(false));
				const message = t('feedback.toast.deleteSuccess');
				toast.success(message, { title: t('Success') });
				dispatch(resetFeedbackDeletingStatus());
			}, 500);
		}
	}, [loadingStatus, t, dispatch]);

	useEffect(() => {
		if (
			loadingStatus.delete === 'success' ||
			loadingStatus.post === 'success'
		) {
			deleteSearchParams();
		}
	}, [deleteSearchParams, loadingStatus]);

	const submitFeedback = () => {
		dispatch(
			postProjectFeedbackStart({
				projectNanoid: project.nanoid,
				rating: value,
				comment: text ? text : null,
			}),
		);
	};

	const deleteFeedback = () => {
		dispatch(
			deleteProjectFeedbackStart({
				projectNanoId: project.nanoid,
				feedbackNanoId: feedback.nanoid,
			}),
		);
	};

	const showCommentLabel = value => {
		let label = '';
		if (value >= 0 && value <= 6) {
			label = t('feedback.commentLabel.0-6');
		}
		if (value > 6 && value < 9) {
			label = t('feedback.commentLabel.7-8');
		}
		if (value >= 9) {
			label = t('feedback.commentLabel.9-10');
		}
		return label;
	};

	return (
		<SlideOver
			title={t('Rate your experience')}
			description={t('feedback.description')}
			open={isFeedbackOpen}
			setOpen={toggleFeedbackSlideOver}
			footerComponent=<FeedbackFooter
				loadingStatus={loadingStatus}
				feedback={feedback}
				value={value}
				deleteFeedback={deleteFeedback}
				submitFeedback={submitFeedback}
				setOpen={toggleFeedbackSlideOver}
			/>
		>
			<div className="h-full">
				<div className="w-full h-full flex flex-col justify-between ">
					<div className="flex flex-col gap-10">
						<div className="flex flex-col gap-2">
							<div>
								<Trans i18nKey="feedback.question">
									On a scale from 0 to 10, how likely are you to recommend
									Sorbunu to a friend or colleague based on your experience with{' '}
									<strong>{{ projectTitle }}</strong>?
								</Trans>
							</div>
							<div>
								<RadioGroup value={value} onChange={setValue} className="mt-2">
									<div className="flex gap-2">
										{_.range(0, 11).map(i => (
											<RadioGroup.Option
												key={i}
												value={i}
												className={({ active, checked }) =>
													classNames(
														active
															? 'ring-2 ring-offset-2 ring-indigo-500'
															: '',
														checked
															? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
															: 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
														'w-full cursor-pointer focus:outline-none border rounded-md py-2 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
													)
												}
											>
												<RadioGroup.Label as="span">{i}</RadioGroup.Label>
											</RadioGroup.Option>
										))}
									</div>
								</RadioGroup>
								<div className="flex justify-between mt-3 text-sm text-indigo-800">
									<span className="w-1/4 text-left">
										{t('questionEditor.questionTypes.nps.minLabel')}
									</span>

									<span className="w-1/4 text-right">
										{t('questionEditor.questionTypes.nps.maxLabel')}
									</span>
								</div>
							</div>
						</div>
						{checkIfNumber(value) && (
							<div className="flex flex-col gap-2">
								<span className="text-medium">{showCommentLabel(value)}</span>

								<textarea
									rows={4}
									className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
									placeholder={t('feedback.commentPlaceholder')}
									onChange={e => setText(e.target.value)}
									value={text}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</SlideOver>
	);
}

function FeedbackFooter({
	loadingStatus,
	feedback,
	value,
	deleteFeedback,
	submitFeedback,
	setOpen,
}) {
	const { t } = useTranslation();
	return (
		<div className="w-full flex flex-row gap-2 justify-between py-3 bg-white text-right px-2 md:px-0">
			<button
				disabled={
					!feedback ||
					loadingStatus.post === 'loading' ||
					loadingStatus.delete === 'loading'
				}
				className="disabled:cursor-not-allowed disabled:opacity-30 relative inline-flex items-center rounded-md border border-red-300 bg-white px-4 py-2 text-sm font-medium text-red-700 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
				onClick={deleteFeedback}
			>
				{loadingStatus.delete === 'loading' ? (
					<Spinner className="w-4 h-4" />
				) : (
					<>
						<TrashIcon
							className="-ml-1 mr-1 h-5 w-5 text-red-600 hover:text-red-700"
							aria-hidden="true"
						/>
						<span>{t('Delete')}</span>
					</>
				)}
			</button>

			<div className="flex gap-1">
				<Buttons kind="secondary" onClick={() => setOpen(false)}>
					{t('Close')}
				</Buttons>
				<Buttons
					disabled={
						!checkIfNumber(value) ||
						loadingStatus.post === 'loading' ||
						loadingStatus.delete === 'loading'
					}
					kind="primary"
					onClick={submitFeedback}
				>
					{loadingStatus.post === 'loading' ? (
						<Spinner className="w-4 h-4" />
					) : (
						t('Save')
					)}
				</Buttons>
			</div>
		</div>
	);
}
