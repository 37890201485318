import { findIndex } from 'lodash';

export default function AudienceCategoryFieldList({
	category,
	categoryFields,
	audienceFields,
	isEditable,
	handleSelectionChange,
}) {
	const getAudienceFieldNanoId = categoryField => {
		const r = findIndex(audienceFields, function (o) {
			return o.value.nanoid === categoryField.value.nanoid;
		});
		if (audienceFields[r]) {
			return audienceFields[r].nanoid;
		}
		return '';
	};

	const handleChange = ({ categoryField }) => {
		if (!isEditable) return;

		handleSelectionChange({
			mode: getAudienceFieldNanoId(categoryField) !== '' ? 'delete' : 'create',
			audienceFieldNanoId:
				getAudienceFieldNanoId(categoryField) !== ''
					? getAudienceFieldNanoId(categoryField)
					: categoryField.value.nanoid,
			categoryNanoId: category.nanoid,
			categoryFieldNanoId: categoryField.nanoid,
			categoryFieldSlug: categoryField.slug,
		});
	};

	return (
		<>
			{categoryFields.map((categoryField, index) => (
				<div
					key={index}
					className="flex items-start bg-indigo-100 rounded border-indigo-600 border-1 border px-3 py-2"
				>
					<div className="flex h-5 items-center">
						<input
							id={categoryField.nanoid}
							name={categoryField.nanoid}
							type="checkbox"
							disabled={!isEditable}
							checked={getAudienceFieldNanoId(categoryField) !== ''}
							onChange={() => handleChange({ categoryField: categoryField })}
							value={
								getAudienceFieldNanoId(categoryField) !== ''
									? getAudienceFieldNanoId(categoryField)
									: categoryField.nanoid
							}
							className="disabled:cursor-not-allowed disabled:opacity-30 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
						/>
					</div>
					<div className="ml-1 text-sm">
						<label htmlFor={categoryField.nanoid} className="text-gray-700">
							{categoryField.name}
						</label>
					</div>
				</div>
			))}
		</>
	);
}
