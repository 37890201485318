import { useState, useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../Spinner';

export default function BulkEditTextArea({
	isEditable,
	question,
	hideTextArea,
}) {
	const textareaRef = useRef(null);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [rowNumber, setRowNumber] = useState(question.choices.length + 1);

	const { isBulkUpdating } = useSelector(
		state =>
			state.projectEditor.questionnaire.questionStatuses[question.nanoid],
	);

	useEffect(() => {
		focusTextarea();
	});

	function focusTextarea() {
		textareaRef.current.focus();
	}

	const initialTextValue = question.choices.reduce((text, choice) => {
		const row = choice.title + '\n';
		return text + row;
	}, '');

	useEffect(() => {
		if (isBulkUpdating === 'complete') {
			hideTextArea();
		}
	}, [isBulkUpdating, hideTextArea]);

	const handleUpdate = values => {
		const newChoices = values.text.trim().split('\n');
		setRowNumber(newChoices.length + 1);

		dispatch({
			type: 'projectEditor/startBulkChoiceUpdate',
			payload: { question, newChoices },
		});
	};

	const cancelBulkEdit = () => {
		hideTextArea();
	};

	return (
		<Formik
			initialValues={{ text: initialTextValue }}
			onSubmit={values => {
				handleUpdate(values);
			}}
		>
			{props => {
				return (
					<Form>
						<>
							<textarea
								ref={textareaRef}
								id="text"
								name="text"
								disabled={isBulkUpdating === 'updating'}
								rows={rowNumber}
								className="disabled:opacity-30 w-full sm:w-9/12 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
								onChange={props.handleChange('text')}
								value={props.values.text}
							></textarea>

							<div className="flex gap-2 justify-start items-center mt-4 flex-wrap">
								<button
									type={props.dirty ? 'submit' : 'button'}
									disabled={!isEditable || isBulkUpdating === 'updating'}
									onClick={props.dirty ? () => {} : hideTextArea}
									className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center justify-center w-24  py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
								>
									{props.dirty && isBulkUpdating === 'updating' ? (
										<Spinner className="w-4 h-4" />
									) : (
										t('Save')
									)}
								</button>

								<button
									type="button"
									onClick={cancelBulkEdit}
									disabled={isBulkUpdating === 'updating'}
									className="disabled:cursor-not-allowed disabled:hover:bg-gray-100 disabled:opacity-30 inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white px-12 py-2 text-sm leading-4 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto "
								>
									{t('Cancel')}
								</button>
							</div>
						</>
					</Form>
				);
			}}
		</Formik>
	);
}
