import React from 'react';

export default function CircularProgress({ progress }) {
	// Calculate the stroke dash array and offset for the SVG circle
	const radius = 40;
	const circumference = 2 * Math.PI * radius;
	const strokeDasharray = circumference.toFixed(2);
	const strokeDashoffset = (((100 - progress) / 100) * circumference).toFixed(
		2,
	);

	return (
		<div className="relative w-full h-full flex justify-center items-center">
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 120 120"
				className="transform -rotate-90"
			>
				<circle
					cx="60"
					cy="60"
					r={radius}
					fill="none"
					className="stroke-gray-300"
					strokeWidth="20"
				/>
				<circle
					cx="60"
					cy="60"
					r={radius}
					fill="none"
					className="stroke-indigo-500"
					strokeWidth="20"
					strokeDasharray={strokeDasharray}
					strokeDashoffset={strokeDashoffset}
				/>
			</svg>
		</div>
	);
}
