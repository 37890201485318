import { classNames } from '../../utils';
import { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
	ChevronDownIcon,
	CheckIcon,
	ExclamationCircleIcon,
} from '@heroicons/react/20/solid';

export default function TabDropdown({
	tabs,
	setCurrentTab,
	currentTab,
	conditionChecks = () => {
		return false;
	},
	width = 'sm',
	breakpoint = 'xl',
}) {
	const WIDTH_CLASSES = {
		sm: 'w-40',
		md: 'w-44',
		lg: 'w-48',
		xl: 'w-54',
	};
	const MEDIA_BREAKPOINT = {
		sm: 'sm:hidden',
		md: 'md:hidden',
		lg: 'lg:hidden',
		xl: 'xl:hidden',
	};
	const tabLabel = tabs.find(el => el.value === currentTab).label;
	const [selected, setSelected] = useState(tabLabel);

	useEffect(() => {
		setSelected(tabLabel);
	}, [currentTab, tabLabel]);

	return (
		<Menu
			as="div"
			className={`relative block ${MEDIA_BREAKPOINT[breakpoint]} text-left`}
		>
			<div>
				<Menu.Button
					className={`inline-flex ${WIDTH_CLASSES[width]} sm:w-54 justify-between  rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100`}
				>
					{conditionChecks({ tab: currentTab }) ? (
						<div className="flex align-center ">
							{selected}

							<ExclamationCircleIcon
								className="h-4 w-4 text-gray-500 ml-1 mt-0.5 m-auto block "
								aria-hidden="true"
							/>
						</div>
					) : (
						selected
					)}
					<ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					className={`absolute left-0 z-50 mt-2 ${WIDTH_CLASSES[width]} sm:w-54 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
				>
					<div className="py-1">
						{tabs.map((tab, index) => (
							<Menu.Item key={index}>
								{({ active }) => (
									<button
										onClick={() => {
											setSelected(tab.label);
											setCurrentTab(tab.value);
										}}
										className={classNames(
											active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
											selected === tab.label
												? 'bg-indigo-600 hover:bg-indigo-600'
												: '',
											'flex w-full justify-between px-2 py-2 text-sm',
										)}
									>
										<div
											className={classNames(
												selected === tab.label ? 'text-white' : '',
												'mx-1 ',
											)}
										>
											{conditionChecks({ tab: tab.value }) ? (
												<div className="flex align-center">
													{tab.label}
													<ExclamationCircleIcon
														className={classNames(
															currentTab === tab.value
																? 'text-white'
																: 'text-gray-500',
															'h-4 w-4 ml-1 mt-0.5 block m-auto',
														)}
														aria-hidden="true"
													/>
												</div>
											) : (
												tab.label
											)}
										</div>
										{selected === tab.label && (
											<CheckIcon className="w-5 h-5 text-white" />
										)}
									</button>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
