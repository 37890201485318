// Functions to be used for running operation chain

const logicOperator = (activeQuestion, questionList, answers) => {
	const executeLogic = rules => {
		let match;

		match = rules.find(rule => {
			return executeOperator(rule, answers, questionList);
		});

		if (match) {
			return {
				// action is either `end_survey` or `go_to_question`
				action: match.then.action,
				question: match.then.question,
			};
		} else {
			return {
				action: 'go_to_next_question',
			};
		}
	};

	const executeOperator = (rule, answers, questionList) => {
		let isMatched;

		if (rule.operator === 'and') {
			isMatched = rule.conditions.every(condition => {
				const questionInRule = questionList.find(
					question => question.nanoid === condition.question,
				);
				const answer = answers[questionInRule.nanoid];
				return executeCondition(
					questionInRule,
					condition.value,
					answer,
					condition.check,
				);
			});
		} else if (rule.operator === 'or') {
			isMatched = rule.conditions.some(condition => {
				const questionInRule = questionList.find(
					question => question.nanoid === condition.question,
				);
				const answer = answers[questionInRule.nanoid];
				return executeCondition(
					questionInRule,
					condition.value,
					answer,
					condition.check,
				);
			});
		}

		return isMatched;
	};

	const executeCondition = (questionInRule, values, response, check) => {
		if (questionInRule.question_type === 'ranking') {
			let match = response.find(res => res.choice === values.nanoid);
			values = values.order;
			response = match.order;
		}
		let isMatched = false;

		switch (check) {
			case 'is_one_of':
				if (
					questionInRule.question_type === 'single_selection' ||
					questionInRule.question_type === 'yes_no'
				) {
					isMatched = values.some(value => isOneOf(value, response));
				}
				if (questionInRule.question_type === 'multiple_selection') {
					isMatched = values.some(value => {
						return response.some(res => isOneOf(value, res));
					});
				}
				if (questionInRule.question_type === 'matrix') {
					const matchedRow = response.find(res => res.choice === values.choice);

					if (!matchedRow) {
						// The row is not within user's response for some reason
						isMatched = false;
					} else {
						isMatched =
							matchedRow.columns.find(selectedColumn => {
								return (
									values.columns.find(checkedColumn => {
										return checkedColumn === selectedColumn.choice;
									}) !== undefined
								);
							}) !== undefined;
					}
				}
				break;
			case 'is_not_any_of':
				if (questionInRule.question_type === 'yes_no') {
					isMatched = isNotAnyOf(values[0], response);
				}
				if (questionInRule.question_type === 'single_selection') {
					isMatched = values.some(value => isNotAnyOf(value, response));
				}
				if (questionInRule.question_type === 'multiple_selection') {
					isMatched = values.every(value => {
						return response.every(res => isNotAnyOf(value, res));
					});
				}
				if (questionInRule.question_type === 'matrix') {
					const matchedRow = response.find(res => res.choice === values.choice);

					if (!matchedRow) {
						// The row is not within user's response for some reason
						isMatched = true;
					} else {
						isMatched =
							matchedRow.columns.find(selectedColumn => {
								return (
									values.columns.find(checkedColumn => {
										return checkedColumn === selectedColumn.choice;
									}) === undefined
								);
							}) !== undefined;
					}
				}
				break;
			case 'equal':
				isMatched = isEqual(values, response);
				break;
			case 'not_equal':
				isMatched = isNotEqual(values, response);
				break;
			case 'contains':
				isMatched = contains(values, response);
				break;
			case 'not_contains':
				isMatched = notContains(values, response);
				break;
			case 'smaller':
				isMatched = isSmaller(values, response);
				break;
			case 'smaller_or_equal':
				isMatched = isSmallerOrEqual(values, response);
				break;
			case 'greater':
				isMatched = isGreater(values, response);
				break;
			case 'greater_or_equal':
				isMatched = isGreaterOrEqual(values, response);
				break;
			default:
				throw new Error('An undefined operation type is detected.');
		}
		return isMatched;
	};

	return executeLogic(activeQuestion.logic.rules);
};

// Functions to be used for evaluating user's choice

const isOneOf = (values, response) => {
	return values === response;
};

const isNotAnyOf = (values, response) => {
	return values !== response;
};

const contains = (value, response) => {
	return response.toLowerCase().includes(value.toLowerCase());
};

const notContains = (value, response) => {
	return !response.toLowerCase().includes(value.toLowerCase());
};

const isEqual = (value, response) => {
	return value === response;
};

const isNotEqual = (value, response) => {
	return value !== response;
};

const isSmaller = (value, response) => {
	return value > response;
};

const isSmallerOrEqual = (value, response) => {
	return value >= response;
};

const isGreater = (value, response) => {
	return value < response;
};

const isGreaterOrEqual = (value, response) => {
	return value <= response;
};

// Functions to be used for non-logic-related actions

export default logicOperator;
