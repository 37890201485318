export const SignInValues = {
	email: '',
	password: '',
};
export const SignUpValues = {
	first_name: '',
	last_name: '',
	email: '',
	company_name: '',
	password1: '',
	password2: '',
	comms_optin_email: false,
};

export const ChangePasswordValues = {
	old_password: '',
	new_password1: '',
	new_password2: '',
};
export const ResetPasswordValues = {
	new_password1: '',
	new_password2: '',
};
export const ForgotPasswordValues = {
	email: '',
};
