import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getProjectFeedback(projectNanoId) {
	const result = await sorbunuApi.get(
		endPoints.feedback.GET_FEEDBACK(projectNanoId),
	);
	return result.data;
}

export async function postProjectFeedback({ projectNanoId, rating, comment }) {
	const data = { rating, comment };

	const result = await sorbunuApi.post(
		endPoints.feedback.POST_FEEDBACK(projectNanoId),
		data,
	);
	return result.data;
}

export async function deleteProjectFeedback({ projectNanoId, feedbackNanoId }) {
	const result = await sorbunuApi.delete(
		endPoints.feedback.DELETE_FEEDBACK(projectNanoId, feedbackNanoId),
	);
	return result.data;
}

export async function updateProjectFeedback({
	projectNanoId,
	feedbackNanoId,
	rating,
	comment,
}) {
	const data = { rating, comment };
	const result = await sorbunuApi.patch(
		endPoints.feedback.UPDATE_FEEDBACK(projectNanoId, feedbackNanoId),
		data,
	);
	return result.data;
}
