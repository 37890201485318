import { Link } from 'react-router-dom';
import { object, string } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { userSignIn } from '../../../store/actions/authActions';
import { Formik, Form } from 'formik';
import { SignInValues } from '../../../utils/Form/InitialValues';
import { FormInput } from '../../../components/TextInput';
import { Buttons } from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import FormAlert from '../../../components/FormAlert';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../../components/Logo';
import { sendGoogleEvent } from '../../../utils/analytics';
import LanguageSwitch from '../../../components/LanguageSwitch';
import { useState, useCallback, useEffect } from 'react';
import { setDocumentTitle } from '../../../utils';

const signInValidationSchema = object({
	email: string()
		.nullable()
		.required('You need to provide {{name}}') //t('You need to provide {{name}}')
		.email('Please enter a valid email'), //t('Please enter a valid email')
	password: string().nullable().required('You need to provide {{name}}'),
});

export default function SignInPage() {
	const { t } = useTranslation();
	const [selectedLang, setSelectedLang] = useState('');
	const getSelectedLang = useCallback(lng => {
		setSelectedLang(lng);
	}, []);

	const dispatch = useDispatch();
	const submitSignIn = values => {
		dispatch(userSignIn({ values: values, selectedLang: selectedLang }));
		sendGoogleEvent('login', { method: 'native' });
	};

	const spin = useSelector(state => state.auth.spin);

	const errorMessage = useSelector(state => state.auth.failAuthData.errors);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.signIn'));
	}, [t]);

	return (
		<>
			<div className="bg-white min-h-screen flex relative ">
				<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
					<div className="mx-auto w-full max-w-sm lg:w-96  relative lg:static">
						<div className="absolute top-0 -translate-y-full -mt-5 lg:mt-2 lg:translate-y-0 lg:left-20 xl:left-24">
							<LanguageSwitch getSelectedLang={getSelectedLang} />
						</div>
					</div>
					<div className="mx-auto w-full max-w-sm lg:w-96 relative ">
						<div>
							<Logo className="h-12 w-auto" />
							<h2 className="mt-6 text-3xl font-extrabold text-gray-900">
								{t('signIn.heading')}
							</h2>
						</div>
						<div className="mt-5">
							{errorMessage && <FormAlert errorMessage={errorMessage} />}
						</div>

						<div className="mt-8">
							<Formik
								initialValues={SignInValues}
								onSubmit={values => {
									submitSignIn(values);
								}}
								validateOnChange={true}
								validateOnBlur={false}
								validationSchema={signInValidationSchema}
							>
								{({ errors, touched }) => (
									<div className="mt-6">
										<Form className="space-y-6">
											<div className="space-y-1">
												<FormInput
													id="email"
													name="email"
													type="text"
													label={t('formLabels.email')}
													placeholder={t('formPlaceholders.enterEmail')}
													errorMessage={
														touched.email &&
														t(errors.email, {
															name: t('formLabels.email'),
														})
													}
												/>
											</div>

											<div className="space-y-1">
												<FormInput
													id="password"
													name="password"
													type="password"
													label={t('formLabels.password')}
													placeholder={t('formPlaceholders.password')}
													errorMessage={
														touched.password &&
														t(errors.password, {
															name: t('formLabels.password'),
														})
													}
												/>
											</div>

											<div className="flex items-center justify-end">
												<div className="text-xs">
													<Link
														to="/forgot-password"
														className="font-medium text-indigo-600 hover:text-indigo-500"
													>
														{t('signIn.forgotPasswordLink')}
													</Link>
												</div>
											</div>

											<div>
												<Buttons type="submit" disabled={spin} fullWidth>
													{spin ? (
														<Spinner className="w-5 h-5" />
													) : (
														<span>{t('Sign In')}</span>
													)}
												</Buttons>
											</div>
											<div className="flex justify-center text-sm">
												{t('signIn.noAccountText')}
												<Link
													to="/sign-up"
													className="font-medium ml-3 text-indigo-600 hover:text-indigo-500"
												>
													{t('Sign Up')}
												</Link>
											</div>
										</Form>
									</div>
								)}
							</Formik>
						</div>
					</div>
				</div>
				{/* </div> */}

				<div className="hidden lg:block relative w-0 flex-1">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src="/assets/images/signin/futuristic.jpg"
					/>
				</div>
			</div>
		</>
	);
}
