export default function TermsOfUseUK() {
	return (
		<div className="mt-3 sm:mt-5">
			<div className="text-sm text-gray-500">
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Sorbunu.com Terms and Conditions of Use
				</h3>
				<h1 className="text-xl leading-6 font-medium text-gray-900 my-4">
					PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
					WEBSITE AND CREATING AN ACCOUNT WITH US OR PURCHASING A SERVICE FROM
					US
				</h1>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					The Terms
				</h3>
				<div className="my-2">
					<p className="mb-2">
						These Terms and Conditions of Use (“Terms”) tell you the rules for
						using our Sorbunu website (“Website”), including the permitted uses
						and prohibited uses.
					</p>
					<p className="mb-2">
						If you decide to create an Account (as defined below) with us, these
						Terms will also apply to your interactions with us as an Account
						holder and tell you how you can cancel your Account with us. The
						Terms will also apply alongside our Terms and Conditions of Sale and
						Purchase, where you purchase a Service from our Website
						(“Service/s”).
					</p>
				</div>
				<p className="mb-2">
					These Terms are divided into the following sections:
				</p>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-2">Acceptable Use of our Website</li>
						<li className="mb-2">
							Terms specifically for Sorbunu Account Holders
						</li>
						<li className="mb-2">General Terms</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Who We Are
				</h3>
				<div className="my-2">
					<p className="mb-2">
						This Website is operated by Denebunu Limited trading as ”Sorbunu”
						(referred to as “Sorbunu”, “we”, “us” or “our” in these Terms). Our
						registered address is 6th Floor, Manfield House, 1 Southampton
						Street, London, England, WC2R 0LR and our company number is
						13265299.
					</p>
					<p className="mb-2">
						{' '}
						When we refer to “you” or “your”, we mean the person accessing or
						using the Website or its content.{' '}
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Your Acceptance of these Terms
				</h3>
				<div className="my-2">
					<p className="mb-2">
						By using our Website and by registering for an Account on our
						Website, you confirm that you accept these Terms and that you agree
						to comply with them and confirm that you have read and understood
						our Privacy Policy and Cookie Policy. If you do not agree to these
						Terms, you must stop using our Website immediately.
					</p>
					<p className="mb-2">
						The content on our Website is provided for general information only.
						It is not intended to amount to advice on which you should rely.{' '}
					</p>
					<p className="mb-2">
						Although we make reasonable efforts to update the information on our
						Website, we make no representations, warranties or guarantees,
						whether express or implied, that the content on our Website is
						accurate, complete or up to date.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Indemnity
				</h3>
				<div className="my-2">
					<p className="uppercase mb-2 font-medium">
						You agree to fully indemnify, defend and hold us, and our officers,
						directors, employees, agents, affiliates and suppliers, harmless
						immediately on demand, from and against all claims, liability,
						damages, losses, costs and expenses, including reasonable legal
						fees, arising out of any breach of the Terms by you or any other
						liabilities arising out of your use of our Website, your Sorbunu
						Account, or the use by any other person accessing the Website or
						your Account using your internet connection.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Changes to these Terms
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We may amend these Terms from time to time. Every time you wish to
						use our Website, it is your responsibility to check these Terms to
						ensure you understand the terms and conditions that apply at that
						time.
					</p>
					<p className="mb-2">
						We reserve the right to vary these Terms from time to time. Our
						updated Terms will be displayed on the Website and by continuing to
						use and access the Website following such changes, you agree to be
						bound by any variation made by us. It is your responsibility to
						check these Terms from time to time to verify such variations.
					</p>
				</div>
				<h1 className="text-xl leading-6 font-medium text-gray-900 my-4">
					ACCEPTABLE USE OF OUR WEBSITE
				</h1>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Using the Website
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You agree that you are solely responsible for all costs and expenses
						you may incur in relation to your use of the Website.
					</p>
					<p className="mb-2">
						We make no promise that the Website is appropriate or available for
						use in locations outside of the UK. If you choose to access the
						Website from locations outside the UK, you acknowledge you do so at
						your own initiative and are responsible for compliance with local
						laws where they apply.{' '}
					</p>
					<p className="mb-2">
						We try to make the Website as accessible as possible. If you have
						any difficulties using the Website, please contact us using the
						contact details at the bottom of this page.{' '}
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Our Rights
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We may transfer our rights and obligations under these Terms to
						another organisation. We will always inform you if this happens and
						we will ensure that the transfer will not affect your rights under
						the contract.
					</p>
					<p className="mb-2">
						We do not guarantee that our Website, or any content on it, will
						always be available or be uninterrupted. We may suspend or withdraw
						or restrict the availability of all or any part of our Website for
						business and operational reasons.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Your Personal Data and Links to other Policies
				</h3>
				<div className="my-2">
					<p className="mb-2">
						The privacy and protection of your personal data is important to us
						and we take it very seriously. We will keep it secure and we will
						fully comply with all applicable privacy regulations and consumer
						legislation.
					</p>
					<p className="mb-2">
						If you would like further information on how we may collect or use
						your personal data, please see our Privacy Policy. Please see our
						Cookie Policy to learn more about the cookies we use, their nature,
						purpose and related usage of your personal data on our Website.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Third Party Sites
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Where our Website contains links to other websites and resources
						provided by third parties, these links are provided for your
						information only. Such links should not be interpreted as approval
						by us of those linked websites or information you may obtain from
						them. We have no control over the content of those sites or
						resources. Your use of a third party site may be governed by the
						terms and conditions of that third-party site and is at your own
						risk.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					User-generated Content
				</h3>
				<div className="my-2">
					<p className="mb-2">
						This Website may include information and materials uploaded by other
						users of the Website, for example to bulletin boards, chat rooms or
						other communication services available on the Website. This
						information and these materials have not been verified or approved
						by us.
					</p>
					<p className="mb-2">
						We are not obliged to monitor or moderate any text, images, video,
						audio or other multimedia content, information or material submitted
						to these sections of our Website. The views expressed by other users
						on our Website do not represent our views or values. We may remove
						or edit any submission to any of our interactive services whether
						they are moderated or not.
					</p>
					<p className="mb-2">
						If you wish to complain about content uploaded by other users,
						please contact us as per the details at the bottom of these Terms.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					How you may use Material on our Website
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We, or in some cases our parent company, Denebunu Bilgi
						Teknolojileri Reklam Hizmetleri Pazarlama Sanayi ve Ticaret A.Ş.,
						with company number 45256-5 and its address at Esentepe Mah.
						Büyükdere Cad. No:201 B Blok Kat:7 Daire:122 Şişli/İstanbul, Turkey,
						are the owner or the licensee of all intellectual property rights in
						our Website, and in the material published on it. Those works are
						protected by applicable laws and treaties around the world. All such
						rights are reserved.{' '}
					</p>
					<p className="mb-2">
						You may print off one copy, and may download extracts, of any
						page(s) from our Website for your personal use and you may draw the
						attention of others to content posted on our Website.
					</p>
					<p className="mb-2">
						You must not modify the paper or digital copies of any materials you
						have printed off or downloaded in any way, and you must not use any
						illustrations, photographs, video or audio sequences or any graphics
						separately from any accompanying text.
					</p>
					<p className="mb-2">
						Our status (and that of any identified contributors) as the authors
						of content on our Website must always be acknowledged.
					</p>
					<p className="mb-2">
						You must not use any part of the content on our Website for
						commercial purposes without obtaining a license to do so from us or
						our licensors.
					</p>
					<p className="mb-2">
						If you print off, copy or download any part of our Website in breach
						of these Terms of use, your right to use our Website will cease
						immediately and you must, at our option, return or destroy any
						copies of the materials you have made.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					{' '}
					Uploading Content to our Website
				</h3>
				<div className="my-2">
					<p className="mb-2">
						Whenever you make use of a feature that allows you to upload content
						to our Website, or to make contact with other users of our Website,
						or when making use of the Services that we offer on our Website (all
						referred to as a “Contribution”), you must comply with the content
						standards set out in the Acceptable Usage of our Website section
						below.
					</p>
					<p className="mb-2">
						You warrant that any such Contribution complies with these
						standards, and you will be liable to us and indemnify us for any
						breach of that warranty. This means you will be responsible for any
						loss or damage we suffer as a result of your breach of warranty.
					</p>
					<p className="mb-2">
						Other than any personal information which will be dealt with in
						accordance with our Privacy Policy, any Contribution will be
						considered non-confidential and non-proprietary. You retain all of
						your ownership rights in your Contribution content, but you hereby
						agree to grant us and other users of our Website a royalty-free,
						perpetual, unlimited, irrevocable, non-exclusive, transferable
						license to use, reproduce, modify, edit, translate, distribute,
						perform, display and store that content and to distribute and make
						it available to third parties.
					</p>
					<p className="mb-2">
						We have the right to remove any Contribution you make on our Website
						if, in our opinion, your post does not comply with the standards set
						out in these Terms.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					{' '}
					Acceptable Usage of our Website and Services
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You may use our Website and Services offered only for lawful
						purposes.
					</p>{' '}
					<p className="mb-2">
						{' '}
						You may not use our Website and Services offered:
					</p>
				</div>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-2">
							In any way that breaches any applicable law or regulation.
						</li>
						<li className="mb-2">
							{' '}
							In any way that is unlawful or fraudulent or has any unlawful or
							fraudulent purpose or effect.
						</li>
						<li className="mb-2">
							for the purpose of building a competitive product or service or
							any other competitive purposes.
						</li>
						<li className="mb-2">
							For the purpose of harming or attempting to harm minors in any
							way.
						</li>
						<li className="mb-2">
							To bully, insult, intimidate or humiliate any person.
						</li>
						<li className="mb-2">
							To send, knowingly receive, upload, download, use or re-use any
							material which does not comply with our content standards set out
							below.
						</li>
						<li className="mb-2">
							To transmit, or procure the sending of, any unsolicited or
							unauthorised advertising or promotional material or any other form
							of similar solicitation (spam).
						</li>
						<li className="mb-2">
							To reverse engineer the Services, or assist anyone else to do so.
						</li>
						<li className="mb-2">
							To knowingly transmit any data, send or upload any material that
							contains viruses, Trojan horses, worms, time-bombs, keystroke
							loggers, spyware, adware or any other harmful programs or similar
							computer code designed to adversely affect the operation of any
							computer software or hardware.{' '}
						</li>
					</ul>
				</div>
				<div className="my-2">
					<p className="mb-2">
						You also agree not to access without authority, interfere with,
						damage or disrupt:
					</p>

					<ul className="list-disc ml-3">
						<li className="mb-2">any part of our Website;</li>
						<li className="mb-2">
							any equipment or network on which our Website is stored;
						</li>
						<li className="mb-2">
							any software used in the provision of our Website; or
						</li>
						<li className="mb-2">
							any equipment or network or software owned or used by any third
							party.
						</li>
					</ul>
				</div>
				<div className="my-2">
					<p className="mb-2">
						You must further comply with the following content standards in
						relation to any Contribution you make, and to any interactive
						services associated with the Website.
					</p>
					<p className="mb-2"> A Contribution must:</p>
					<ul className="list-disc ml-3">
						<li className="mb-2">Be accurate (where it states facts).</li>
						<li className="mb-2">
							Be genuinely held (where it states opinions).
						</li>
						<li className="mb-2">
							Be your own original work and be lawfully submitted.
						</li>
						<li className="mb-2">
							Comply with all applicable laws and regulations.{' '}
						</li>
					</ul>
				</div>
				<div className="my-2">
					<p className="mb-2">A Contribution must not:</p>
					<ul className="list-disc ml-3">
						<li className="mb-2">Be defamatory of any person.</li>
						<li className="mb-2">
							{' '}
							Be obscene, offensive, hateful or inflammatory.
						</li>
						<li className="mb-2">Bully, insult, intimidate or humiliate.</li>
						<li className="mb-2">Promote sexually explicit material. </li>
						<li className="mb-2"> Include child sexual abuse material. </li>
						<li className="mb-2">
							Include graphic violent material or pornography
						</li>
						<li className="mb-2">Promote violence. </li>
						<li className="mb-2">
							Promote discrimination based on race, sex, religion, nationality,
							disability, sexual orientation or age.
						</li>
						<li className="mb-2">
							Express a political opinion or be political in nature.
						</li>
						<li className="mb-2">
							Infringe any copyright, database right or trade mark of any other
							person.
						</li>
						<li className="mb-2">Be likely to deceive any person.</li>
						<li className="mb-2">
							Breach any legal duty owed to a third party, such as a contractual
							duty or a duty of confidence.
						</li>
						<li className="mb-2">Promote any illegal activity. </li>
						<li className="mb-2">Be in contempt of court. </li>
						<li className="mb-2">
							Be threatening, abusive or invade another’s privacy, or cause
							annoyance, inconvenience or needless anxiety or be likely to
							harass, upset, embarrass, alarm or annoy any other person.
						</li>
						<li className="mb-2">
							Impersonate any person or misrepresent your identity or
							affiliation with any person.
						</li>
						<li className="mb-2">
							Give the impression that the Contribution emanates from us, if
							this is not the case.
						</li>
						<li className="mb-2">
							Advocate, promote, incite any party to commit, or assist any
							unlawful or criminal act such as (by way of example only)
							copyright infringement or computer misuse.
						</li>
						<li className="mb-2">
							Contain a statement which you know or believe, or have reasonable
							grounds for believing, that members of the public to whom the
							statement is, or is to be, published are likely to understand as a
							direct or indirect encouragement or other inducement to the
							commission, preparation or instigation of acts of terrorism.{' '}
						</li>
						<li className="mb-2">
							Contain any advertising or promote any services or web links to
							other third party sites.
						</li>
					</ul>
				</div>
				<div className="my-2 uppercase">
					<p className="mb-2">
						Notwithstanding anything else contained in the Terms, a breach of
						this section of the Terms, or otherwise, may result in our taking
						all or any of the following actions:
					</p>
					<ul className="list-disc ml-3">
						<li className="mb-2">
							Immediate, temporary or permanent withdrawal of your right to use
							our Website and/or restriction/suspension or cancellation of your
							Account.
						</li>
						<li className="mb-2">
							{' '}
							Immediate, temporary or permanent removal of any Contribution
							uploaded by you to our Website/Services.
						</li>
						<li className="mb-2"> Issue of a warning to you.</li>
						<li className="mb-2">
							{' '}
							Legal proceedings against you for reimbursement of all costs on an
							indemnity basis (including, but not limited to, reasonable
							administrative and legal costs) resulting from the breach.
						</li>
						<li className="mb-2"> Further legal action against you.</li>
						<li className="mb-2">
							{' '}
							Disclosure of such information to law enforcement authorities as
							we reasonably feel is necessary or as required by law.
						</li>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Viruses{' '}
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We do not guarantee that our Website will be secure or free from
						bugs or viruses.{' '}
					</p>
					<p className="mb-2">
						You are responsible for configuring your information technology,
						computer programmes and platform to access our Website. You should
						use your own virus protection software.
					</p>
					<p className="mb-2">
						You must not misuse our Website by knowingly introducing viruses,
						trojans, worms, logic bombs or other material that is malicious or
						technologically harmful. You must not attempt to gain unauthorised
						access to our Website, the server on which our Website is stored or
						any server, computer or database connected to our Website.
					</p>
				</div>
				<h1 className="text-xl leading-6 font-medium text-gray-900 my-4">
					{' '}
					TERMS SPECIFICALLY FOR SORBUNU ACCOUNT HOLDERS
				</h1>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Becoming a Sorbunu Account Holder{' '}
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You become a Sorbunu Account holder when you create an account on
						our Website (“Account”).
					</p>
					<p className="mb-2">
						Creating an Account is always voluntary. If you do not agree to
						these Terms, please do not choose to create an Account.
					</p>
					<p className="mb-2">
						{' '}
						To create an Account on our Website, you must be 18 years old.
					</p>
					<p className="mb-2">
						{' '}
						In order to create an Account, you must fill in the information
						requested completely and accurately.
					</p>
					<p className="mb-2">
						{' '}
						When you create an Account, we ask for personal information such as
						your name and email address. We also ask for your company name, if
						applicable. This information is also used for the purpose of
						contract fulfilment. Please be assured that this information is
						securely kept and is strictly confidential.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Your commitment to us{' '}
				</h3>
				<div className="my-2">
					<p className="mb-2">
						{' '}
						You warrant and accept that the personal and other information you
						provide while creating an Account is accurate and complies with
						these Terms and all applicable laws and regulations. In the event
						that you create an Account by using information belonging to others
						or information that is false, we may at our discretion suspend,
						restrict or stop your access to the Website and cancel your Account
						immediately without notice.
					</p>
					<p className="mb-2">
						{' '}
						You agree to use the features offered by the Website for your own
						benefit only, for non-commercial purposes and in accordance with
						these Terms.
					</p>
					<p className="mb-2">
						{' '}
						Account registration is limited to one per individual. Multiple
						individual registrations of the same person by any means are
						prohibited. If we determine that you have created or attempted to
						create more than one Account, for example by using different names
						for registration purposes, we may at our discretion suspend,
						restrict or stop your access to the Website and cancel your existing
						Account immediately without notice. For this purpose we utilise
						email and/or SMS verification, in accordance with our Privacy
						Policy.{' '}
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Registration and Password Security
				</h3>
				<div className="my-2">
					<p className="mb-2">
						{' '}
						Use of certain parts of the Website requires registration,
						particularly in order to access restricted areas of the Website. We
						are not obliged to permit anyone to register with the Website and we
						may refuse, terminate or suspend registration to anyone at any time.
					</p>
					<p className="mb-2">
						You are responsible for making sure that your password and any other
						Account details are kept secure and confidential. If we have reason
						to believe there is likely to be a breach of security or misuse of
						the Website through your Account or use of your password, we may
						notify you and require you to change your password, or we may
						suspend or terminate your Account.{' '}
					</p>
					<p className="mb-2">
						{' '}
						You must keep your email address and, where applicable, your contact
						details and other details associated with your Account current and
						accurate. You warrant the accuracy of your Account details.
					</p>
					<p className="mb-2">
						{' '}
						Any personal information you provide to us as part of the
						registration process will be processed in accordance with our
						Privacy Policy.{' '}
					</p>
					<p className="mb-2">
						{' '}
						You expressly agree the your use of the Website and your Account is
						at your own risk.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Sorbunu Services
				</h3>
				<div className="my-2">
					<p className="mb-2">
						{' '}
						If you choose to register for a Sorbunu Account, you will have the
						opportunity purchase Services offered for sale. Such sale and
						purchase will be governed by our Sorbunu Terms and Conditions of
						Sale and Purchase, which you will have the chance to review and
						agree before purchase.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Account Duration and Cancellation
				</h3>
				<div className="my-2">
					<p className="mb-2">
						{' '}
						Your Account will remain in effect unless cancelled by us or you.
					</p>
					<p className="mb-2">
						{' '}
						Registering for and holding an Account is strictly voluntary. Both
						you and we may discontinue your Account at any time for any reason,
						or indeed no reason. You have the right to cancel your Account at
						any time. You can do this by contacting us.
					</p>
					<p className="mb-2">
						{' '}
						We reserve the right to restrict, suspend and/or cancel your
						Account, including your right to access the Website and the Services
						it offers, at any time and without giving any reason.
					</p>
					<p className="mb-2">
						In the event that your Account is terminated for any reason, you
						must immediately stop using our Website and the Services offered.
						However, this will not affect any contract you have entered which is
						governed by our{' '}
						<i>Sorbunu Terms and Conditions of Sale and Purchase.</i>{' '}
					</p>
				</div>
				<h1 className="text-xl leading-6 font-medium text-gray-900 my-4">
					GENERAL TERMS{' '}
				</h1>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Our Responsibility for Loss or Damage Suffered by You
				</h3>
				<div className="my-2">
					<ul className="list-disc ml-3">
						<li className="mb-1">
							Nothing in these Terms shall be construed as an attempt to exclude
							our liability for death or personal injury by negligence, fraud or
							fraudulent misrepresentation or any other liability which cannot
							by be excluded by law.
						</li>
						<li className="mb-2">
							In the event that you make a claim against us for whatever reason,
							our liability (if any) for any loss or damage suffered by you
							shall not exceed £1000 (one thousand pounds).
						</li>
						<li className="mb-2">
							{' '}
							We exclude all implied conditions, warranties, representations or
							other terms that may apply to our Website or any content on it,
							including, but not limited to, the implied warranties of
							merchantability, fitness for a particular purpose and
							non-infringement. We make no warranty with regard to any Services
							obtained through your use of this Website, or that your
							participation will meet your requirements or expectations.
						</li>
						<li className="mb-2">
							{' '}
							We will not be liable to you for any loss or damage, whether in
							contract, tort (including negligence), breach of statutory duty,
							or otherwise, even if foreseeable, arising under or in connection
							with:{' '}
						</li>
						<ul className="list-disc ml-6">
							<li className="mb-2">
								Use of, or inability to use, our Website or the Services offered
								therein; or
							</li>
							<li className="mb-2">
								{' '}
								Use of or reliance on any content displayed on our Website.
							</li>
						</ul>
						<li className="mb-2"> In particular, we will not be liable for:</li>
						<ul className="list-disc ml-6">
							<li className="mb-2">
								Loss of profits, sales, business, or revenue;
							</li>
							<li className="mb-2"> Business interruption;</li>
							<li className="mb-2"> Loss of anticipated savings;</li>
							<li className="mb-2">
								{' '}
								Loss of business opportunity, goodwill or reputation;
							</li>
							<li className="mb-2">
								{' '}
								Losses that were not foreseeable to you and us when these Terms
								were formed;
							</li>
							<li className="mb-2">
								{' '}
								Losses that were not caused by any breach on our part; and/or
							</li>
							<li className="mb-2">
								{' '}
								Any special or indirect or consequential loss or damage.
							</li>
						</ul>
					</ul>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Events Beyond our Control
				</h3>
				<div className="my-2">
					<p className="mb-2">
						We are not liable to you if we fail to comply with these Terms
						because of circumstances beyond our reasonable control, including
						but not limited to, strikes, lock-outs, or other industrial
						disputes, breakdown of systems or network access, flood, fire,
						explosion or accident, or epidemics or pandemics.
					</p>
					<div className="my-2"></div>

					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Severance
					</h3>
					<div className="my-2"></div>
					<p className="mb-2">
						If any part of these Terms shall be deemed unlawful, void or for any
						reason unenforceable, then that provision shall be deemed to be
						severable from the Terms and shall not affect the validity and
						enforceability of any of the remaining provisions of the Terms.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">Waiver</h3>
				<div className="my-2">
					<p className="mb-2">
						No waiver by us shall be construed as a waiver of any proceeding or
						succeeding breach of any provision of these Terms.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					Applicable Laws and Governing Jurisdiction
				</h3>
				<div className="my-2">
					<p className="mb-2">
						These Terms, their subject matter and their formation, are governed
						by and construed in accordance with the laws of England and Wales.
						You agree that the courts of London, England will have exclusive
						jurisdiction.
					</p>
				</div>
				<h3 className="text-lg leading-6 font-medium text-gray-900">
					{' '}
					How to Contact us
				</h3>
				<div className="my-2">
					<p className="mb-2">
						You can contact us by post, email or telephone if you have any
						questions about these Terms or just want to get in touch.
					</p>
					<p className="mb-2"> Our contact details are shown below:</p>
					<h3 className="text-lg leading-6 font-medium text-gray-900">
						Our contact details
					</h3>
					<table className="my-2">
						<tr className="w-full">
							<td className="w-1/4">Address:</td>
							<td className="w-3/4">
								6th Floor, Manfield House, 1 Southampton Street, London,
								England, WC2R 0LR
							</td>
						</tr>
						<tr>
							<td>Email address:</td>
							<td>hello@sorbunu.com</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	);
}
