import { RATING_META_DATA } from '../../constants';

// eslint-disable-next-line import/no-unused-modules
export default function RatingIcon({ iconType, className }) {
	const CustomTag = RATING_META_DATA[iconType].icon;
	return <CustomTag className={className} />;
}
export function RatingHoverIcon({ iconType, className }) {
	const CustomTag = RATING_META_DATA[iconType].hover;
	return <CustomTag className={className} />;
}
