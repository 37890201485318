import { Fragment, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import {
	CogIcon,
	ArrowLeftOnRectangleIcon,
	Bars3Icon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import DashboardPage from '../../DashboardPage';
import { Routes, Route, Link } from 'react-router-dom';
import ProfilePage from '../../Account/ProfilePage';
import PasswordSettingsPage from '../../Account/PasswordSettingsPage';
import CreateProjectPage from '../../projects/CreateProjectPage';
import NotificationSettingsPage from '../../Account/NotificationSettingsPage';
import { getUserProfile } from '../../../store/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import ProjectEditorPage from '../../projects/ProjectEditor';
import ProfilePicture from '../../../components/ProfilePicture';
import ProjectSearch from '../../../components/ProjectSearch';
import { Logo } from '../../../components/Logo';
import AccountPage from '../../Account';
import SettingsPage from '../../Account/SettingsPage';
import BillingPage from '../../Account/BillingPage';
import BillingAddressPage from '../../Account/BillingPage/BillingAddressPage';
import Spinner from '../../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';

export default function SignedInLayout() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { user, auth } = useSelector(state => state.auth);

	const nanoid = auth?.user?.nanoid;

	useEffect(() => {
		nanoid && dispatch(getUserProfile(nanoid));
		dispatch({
			type: 'project/getAllProjectsStarted',
			payload: { limit: +process.env.REACT_APP_PROJECT_LIMIT || 15, page: 1 },
		});
	}, [dispatch, nanoid]);

	const handleLogOut = () => {
		dispatch({
			type: 'auth/signOut',
		});
	};

	if (!user)
		return (
			<div className="flex h-screen items-center justify-center">
				<Spinner className="w-20 h-20 text-indigo-800" />
			</div>
		);

	return (
		<>
			<div className="bg-gray-100 flex flex-col min-h-screen w-full z-10 fixed">
				<Disclosure as="nav" className="bg-indigo-600">
					{({ open }) => (
						<>
							<div className="mx-auto px-4 sm:px-6 lg:px-8">
								<div className="flex items-center justify-between h-16">
									<div className="flex items-center">
										<div className="flex-shrink-0 pr-1.5">
											<Link to={'/dashboard'}>
												<Logo className="h-6 w-auto " color="#fff" />
											</Link>
										</div>
									</div>
									{/* Search section */}
									<div className="flex-1 flex justify-center lg:justify-en max-w-3xl">
										<ProjectSearch />
									</div>
									<div className="hidden md:block">
										<div className="flex items-center">
											<div className="flex items-baseline">
												<div className="flex pr-3 pl-1.5 text-indigo-200 hover:text-white font-medium">
													{user.first_name} {user.last_name}
												</div>
											</div>

											{/* Profile dropdown */}

											<Menu
												as="div"
												className="relative inline-block z-50 text-left"
											>
												<div>
													<Menu.Button className="max-w-xs ml-2 bg-gray-700 rounded-full flex items-center text-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
														<ProfilePicture
															firstName={user.first_name}
															lastName={user.last_name}
															profilePicture={user.profile_picture}
															aria-hidden="true"
														/>
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items className="absolute right-0 mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
														<div className="py-1 ">
															<Menu.Item>
																<Link
																	to={'/account'}
																	className="flex text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
																	role="menuitem"
																	tabIndex="-1"
																	id="menu-item-0"
																	aria-hidden="true"
																>
																	<CogIcon className="w-5 mr-1" />{' '}
																	{t('signedInLayout.accountLink')}
																</Link>
															</Menu.Item>
															<Menu.Item>
																<button
																	className="flex text-gray-700 block w-full px-4 py-2 text-sm hover:bg-gray-100"
																	onClick={handleLogOut}
																>
																	<ArrowLeftOnRectangleIcon className="w-5 mr-1 " />{' '}
																	{t('signedInLayout.logOutButton')}
																</button>
															</Menu.Item>
														</div>
													</Menu.Items>
												</Transition>
											</Menu>
										</div>
									</div>
									<div className="-mr-2 ml-1 flex md:hidden">
										{/* Mobile menu button */}
										<Disclosure.Button className="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
											<span className="sr-only">
												{t('signedInLayout.openMenuButton')}
											</span>
											{open ? (
												<XMarkIcon
													className="block h-6 w-6"
													aria-hidden="true"
												/>
											) : (
												<Bars3Icon
													className="block h-6 w-6"
													aria-hidden="true"
												/>
											)}
										</Disclosure.Button>
									</div>
								</div>
							</div>

							<Disclosure.Panel className="md:hidden">
								<div className="pt-4 pb-2 border-t border-indigo-700 text-gray-200">
									<div className="flex items-center px-5">
										<div className="max-w-xs ml-3 bg-gray-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
											<ProfilePicture
												firstName={user.first_name}
												lastName={user.last_name}
												profilePicture={user.profile_picture}
												aria-hidden="true"
											/>
										</div>
										<div className="ml-3">
											<div className="flex items-baseline">
												<div className="flex font-medium">
													{user.first_name} {user.last_name}
												</div>
											</div>
										</div>
									</div>
									<div className="mt-3 px-2 space-y-1">
										<Disclosure.Button
											className="block w-full px-3 py-2 rounded-md text-base font-medium"
											as={Link}
											to={'/account'}
										>
											<div className="px-2 space-y-1 sm:px-3 flex ml-3.5 mt-2 mb-2">
												<CogIcon className="w-5 mr-1" />{' '}
												{t('signedInLayout.accountLink')}
											</div>
										</Disclosure.Button>
									</div>
									<div className="mt-3 px-2 space-y-1">
										<Disclosure.Button
											className="block w-full px-3 py-2 rounded-md text-base font-medium"
											onClick={handleLogOut}
										>
											<div className="px-2 pb-3  sm:px-3 flex ml-4 mt-2">
												<ArrowLeftOnRectangleIcon className="w-5 mr-1 " />{' '}
												{t('signedInLayout.logOutButton')}
											</div>
										</Disclosure.Button>
									</div>
								</div>
							</Disclosure.Panel>
						</>
					)}
				</Disclosure>

				<hr />
				<Routes>
					<Route path="dashboard" element={<DashboardPage />} />

					<Route
						path="project/:nanoid/:tab/*"
						element={<ProjectEditorPage />}
					/>

					<Route path="project/create" element={<CreateProjectPage />} />
					<Route path="account" element={<AccountPage />}>
						<Route index element={<ProfilePage />} />
						<Route path="password" element={<PasswordSettingsPage />} />
						<Route
							path="notifications"
							element={<NotificationSettingsPage />}
						/>
						<Route path="settings" element={<SettingsPage />} />
						<Route path="billing" element={<BillingPage />} />
						<Route
							path="billing/addresses/create"
							element={<BillingAddressPage mode={'create'} />}
						/>
						<Route
							path="billing/addresses/:nanoid"
							element={<BillingAddressPage mode={'edit'} />}
						/>
					</Route>
				</Routes>
			</div>
		</>
	);
}
