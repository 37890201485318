import Alert from '../Alert';
import { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { resendVerificationEmail } from '../../api/services/Auth';

export default function EmailVerificationStatus({
	className,
	email,
	initialVerificationStatus,
}) {
	const [isSending, setSendingStatus] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			{!initialVerificationStatus && !isSending && (
				<Alert
					type={'warning'}
					className={className}
					actionLabel={t('emailVerification.sendLinkButton')}
					actionOnClick={async () => {
						resendVerificationEmail({ email: email });
						setSendingStatus(true);
					}}
				>
					<Trans t={t} i18nKey={'emailVerification.notVerifiedYetMessage'}>
						Your email address is not verified yet. You can continue to use the
						product but won't be able to submit a project until you verify your
						email address.
					</Trans>
				</Alert>
			)}
		</>
	);
}
