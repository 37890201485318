import { classNames } from '../utils/utils';
import { cloneDeep, findIndex } from 'lodash';
import Combobox from '../Combobox';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChoicePill({
	question,
	choice,
	setValues,
	values,
	ranks,
	otherTextValue,
	setOtherTextValue,
	noneChoice,
}) {
	const { t } = useTranslation();
	const otherInput = useRef();

	const handleChange = e => {
		const choiceValue = e.target.value;

		if (question.question_type === 'multiple_selection') {
			// First, create a copy of the state to work in
			const newValues = cloneDeep(values);

			const existingItemIndex = findIndex(
				newValues,
				item => item.value === choiceValue,
			);

			if (noneChoice) {
				if (choiceValue === noneChoice && e.target.checked) {
					setValues([
						{
							value: choiceValue,
							checked: e.target.checked,
						},
					]);
				}
				if (choiceValue !== noneChoice && e.target.checked) {
					const choiceIndex = newValues.findIndex(
						value => value.value === noneChoice,
					);
					if (choiceIndex > -1) newValues.splice(choiceIndex, 1);
					newValues.push({
						value: choiceValue,
						checked: e.target.checked,
					});

					setValues(newValues);
				}
				if (!e.target.checked && existingItemIndex > -1) {
					newValues.splice(existingItemIndex, 1);
					setValues(newValues);
				}
			} else {
				if (e.target.checked) {
					newValues.push({
						value: choiceValue,
						checked: e.target.checked,
					});
					setValues(newValues);
				} else {
					if (existingItemIndex > -1) {
						newValues.splice(existingItemIndex, 1);
						setValues(newValues);
					}
				}
			}
		} else {
			setValues({
				value: choiceValue,
				checked: e.target.checked,
			});
		}
		if (choice.choice_type === 'other' && e.target.checked) {
			otherInput.current.focus();
		}
	};

	const handleRankChange = (choice, newSelectedItem) => {
		// First, create a copy of the state to work in
		const newValues = cloneDeep(values);

		// Find the choice to update
		const selectedChoiceIndex = findIndex(
			newValues,
			item => item.choice === choice.nanoid,
		);

		// check if rank has been given to another choice before
		const existingValueIndex = findIndex(
			newValues,
			value => value.order === newSelectedItem.id,
		);

		if (existingValueIndex < 0) {
			newValues[selectedChoiceIndex].order = newSelectedItem.id;
		} else {
			newValues[selectedChoiceIndex].order = newSelectedItem.id;
			newValues[existingValueIndex].order = null;
		}

		setValues(newValues);
	};

	let choiceChecked = false;
	if (question.question_type === 'multiple_selection') {
		choiceChecked = values.find(
			item => item.value === choice.nanoid && item.checked,
		)
			? true
			: false;
	} else if (
		question.question_type === 'single_selection' ||
		question.question_type === 'yes_no'
	) {
		choiceChecked = values ? values.value === choice.nanoid : false;
	}

	let rank = null;
	if (question.question_type === 'ranking') {
		const value = values.find(item => item.choice === choice.nanoid);
		rank = ranks.find(item => item.id === value.order);
	}

	return (
		<div className="">
			<div
				className={
					'relative w-full sm:w-9/12 group-one p-2 bg-mint-100 border border-1 border-mint-600 rounded'
				}
			>
				<div className="flex flex-row gap-2 items-start">
					{(question.question_type === 'single_selection' ||
						question.question_type === 'multiple_selection' ||
						question.question_type === 'yes_no') && (
						<input
							checked={choiceChecked}
							type={
								question.question_type === 'multiple_selection'
									? 'checkbox'
									: 'radio'
							}
							value={choice.nanoid ? choice.nanoid : ''}
							name={
								question.question_type === 'multiple_selection'
									? choice.nanoid
									: `question-choices-${question.nanoid}`
							}
							id={
								question.question_type === 'multiple_selection'
									? choice.nanoid
									: `question-choices-${question.nanoid}-${choice.nanoid}`
							}
							onChange={handleChange}
							className={classNames(
								question.question_type === 'multiple_selection'
									? 'rounded'
									: 'rounded-full',
								'h-4 w-4 border-gray-300 text-mint-600 focus:ring-mint-500 mt-0.5 cursor-pointer',
							)}
						/>
					)}

					<label
						htmlFor={
							question.question_type === 'multiple_selection'
								? choice.nanoid
								: `question-choices-${question.nanoid}-${choice.nanoid}`
						}
						className=" w-[calc(100%-6rem)] break-words flex-grow text-sm text-gray-800 cursor-pointer"
					>
						{choice.title}
						{choice.media && (
							<img src={choice.media.url} className="rounded max-h-48 mt-2" />
						)}
					</label>

					{question.question_type === 'ranking' && (
						<div className="flex-shrink-0 w-20">
							<Combobox
								data={ranks}
								selectedItem={rank ? rank : null}
								onChange={item => handleRankChange(choice, item)}
							/>
						</div>
					)}
				</div>
				{choice.choice_type === 'other' && (
					<div className="mt-2 pl-5">
						<input
							ref={otherInput}
							onClick={() => {
								if (!choiceChecked) {
									handleChange({
										target: {
											value: choice.nanoid,
											checked: true,
										},
									});
								}
							}}
							value={otherTextValue}
							onChange={e => setOtherTextValue(e.target.value)}
							type="text"
							placeholder={t(
								'previewQuestionnaire.questionTypes.choiceList.other.placeholder',
							)}
							className="block w-full shadow-sm focus:ring-mint-500 focus:border-mint-500 sm:text-sm border-gray-300 rounded-md"
						/>
					</div>
				)}
			</div>
		</div>
	);
}
