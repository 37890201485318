import { useTranslation } from 'react-i18next';
import Joyride from 'react-joyride';
import TourTooltip from '../../components/TourTooltip';
import { getItemFromLocalStorage, saveToLocalStorage } from '../../utils';

export default function TourGuide({ tourName, steps }) {
	const { t } = useTranslation();

	const localStorageKey = `onboarding_${tourName}`;
	const r = getItemFromLocalStorage(localStorageKey);

	const callback = data => {
		if (data.lifecycle === 'complete') {
			saveToLocalStorage(localStorageKey, true);
		}
	};

	return (
		<Joyride
			tooltipComponent={TourTooltip}
			continuous={true}
			run={r !== true}
			callback={callback}
			steps={steps}
			locale={{
				back: t('tour.buttons.back'),
				close: t('tour.buttons.close'),
				last: t('tour.buttons.finish'),
				next: t('tour.buttons.next'),
				open: t('tour.buttons.openDialog'),
				skip: t('tour.buttons.skip'),
			}}
		/>
	);
}
