import { useEffect, useState } from 'react';
import NextButton from '../NextButton';
import RatingIcon, { RatingHoverIcon } from '../RatingIcon';

export default function RatingQuestion({
	question,
	showPreviousButton,
	handlePrevious,
	isPreviousDisabled,
	handleQuestionSubmit,
	isEditMode,
	lastQuestion,
}) {
	const isRequired = question.config.required;
	const [error, setError] = useState(isRequired);
	const [values, setValues] = useState(null);

	useEffect(() => {
		if (isRequired) {
			values !== null && values !== undefined
				? setError(false)
				: setError(true);
		}
	}, [isRequired, values]);

	const formSubmissionHandler = () => {
		handleQuestionSubmit({
			question: question,
			answer: values,
		});
	};

	return (
		<>
			<div className="flex flex-row gap-2">
				{Array.from({ length: question.config.number_of_shape }, (_, i) => {
					const ratingValue = i + 1;
					return (
						<label
							key={i}
							className="cursor-pointer flex flex-col items-center gap-2"
						>
							<input
								type="radio"
								name="rating"
								value={ratingValue}
								onClick={() => setValues(ratingValue)}
								className="hidden"
								disabled={isEditMode}
							/>
							{ratingValue > values ? (
								<RatingIcon
									iconType={question.config.shape}
									className={`text-stone-200 ${
										question.config.shape === 'heart'
											? 'hover:text-red-400'
											: 'hover:text-amber-400'
									}  w-7 h-7`}
								/>
							) : (
								<RatingHoverIcon
									iconType={question.config.shape}
									className={`text-amber-200 ${
										question.config.shape === 'heart'
											? 'fill-red-400'
											: 'fill-amber-400'
									} hover:text-amber-400 w-7 h-7`}
								/>
							)}
							<span className="text-stone-600 text-sm font-mono">{i + 1}</span>
						</label>
					);
				})}
			</div>
			{!isEditMode && (
				<NextButton
					disabled={error}
					showPreviousButton={showPreviousButton}
					isPreviousDisabled={isPreviousDisabled}
					handlePrevious={handlePrevious}
					handleNext={formSubmissionHandler}
					lastQuestion={lastQuestion}
				/>
			)}
		</>
	);
}
