import {
	CheckIcon,
	NoSymbolIcon,
	HashtagIcon,
	PencilIcon,
	ArrowsUpDownIcon,
	ChatBubbleLeftRightIcon,
	ScaleIcon,
	ArrowUpTrayIcon,
	TableCellsIcon,
	StarIcon,
} from '@heroicons/react/24/solid';

import { DocumentTextIcon } from '@heroicons/react/24/outline';

import {
	HeartIcon,
	StarIcon as StarIconOutline,
	HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import {
	HeartIcon as HeartIconSolid,
	HandThumbUpIcon as HandThumbUpIconSolid,
} from '@heroicons/react/24/solid';

const questionMetaData = {
	single_selection: {
		title: 'Single Selection',
		icon: CheckIcon,
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
		analysisBgColor: 'bg-indigo-200',
		analysisHoverBgColor: 'bg-indigo-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-indigo-600 focus:ring-indigo-500',
		isRecallable: true,
	},
	multiple_selection: {
		title: 'Multiple Selection',
		icon: CheckIcon,
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
		analysisBgColor: 'bg-indigo-200',
		analysisHoverBgColor: 'bg-indigo-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-indigo-600 focus:ring-indigo-500',
		isRecallable: true,
	},
	yes_no: {
		title: 'Yes/No',
		icon: NoSymbolIcon,
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-600',
		textColor: 'text-indigo-600',
		analysisBgColor: 'bg-indigo-200',
		analysisHoverBgColor: 'bg-indigo-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-indigo-600 focus:ring-indigo-500',
		isRecallable: true,
	},
	text: {
		title: 'Text',
		icon: PencilIcon,
		bgColor: 'bg-red-600',
		borderColor: 'border-red-600',
		textColor: 'text-red-600',
		ringColor: 'ring-red-500',
		focusRingColor: 'focus:ring-red-500',
		checkboxBgColor: 'bg-red-100',
		isRecallable: true,
	},
	number: {
		title: 'Number',
		icon: HashtagIcon,
		bgColor: 'bg-red-600',
		borderColor: 'border-red-600',
		textColor: 'text-red-600',
		ringColor: 'ring-red-500',
		checkboxBgColor: 'bg-red-100',
		inputCheckboxColor: 'text-yellow-400 focus:ring-yellow-500',
		isRecallable: true,
	},
	rating: {
		title: 'Rating',
		icon: StarIcon,
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
		analysisBgColor: 'bg-yellow-200',
		analysisHoverBgColor: 'bg-yellow-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-yellow-400 focus:ring-yellow-500',
		isRecallable: true,
	},
	ranking: {
		title: 'Ranking',
		icon: ArrowsUpDownIcon,
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
		analysisBgColor: 'bg-yellow-200',
		analysisHoverBgColor: 'bg-yellow-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-yellow-400 focus:ring-yellow-500',
		isRecallable: true,
	},
	nps: {
		title: 'NPS®',
		icon: ChatBubbleLeftRightIcon,
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
		analysisBgColor: 'bg-yellow-200',
		analysisHoverBgColor: 'bg-yellow-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-yellow-400 focus:ring-yellow-500',
		isRecallable: true,
	},
	opinion_scale: {
		title: 'Opinion Scale',
		icon: ScaleIcon,
		bgColor: 'bg-yellow-400',
		borderColor: 'border-yellow-400',
		textColor: 'text-yellow-600',
		analysisBgColor: 'bg-yellow-200',
		analysisHoverBgColor: 'bg-yellow-300',
		analysisTextColor: 'text-gray-700',
		inputCheckboxColor: 'text-yellow-400 focus:ring-yellow-500',
		isRecallable: true,
	},
	matrix: {
		title: 'Matrix',
		icon: TableCellsIcon,
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
		isRecallable: true,
	},
	file_upload: {
		title: 'File Upload',
		icon: ArrowUpTrayIcon,
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
		ringColor: 'ring-green-600',
		checkboxBgColor: 'bg-green-100',
		isRecallable: false,
	},
	description: {
		title: 'Description',
		icon: DocumentTextIcon,
		bgColor: 'bg-green-700',
		borderColor: 'border-green-700',
		textColor: 'text-green-700',
		isRecallable: false,
	},
	demographics: {
		bgColor: 'bg-indigo-600',
		borderColor: 'border-indigo-700',
		textColor: 'text-indigo-700',
	},
};

const ratingMetaData = {
	stars: {
		icon: StarIconOutline,
		hover: StarIcon,
		label: 'Stars', //t('Stars')
	},
	hearts: {
		icon: HeartIcon,
		hover: HeartIconSolid,
		label: 'Hearts', //t('Hearts')
	},
	thumbs_up: {
		icon: HandThumbUpIcon,
		hover: HandThumbUpIconSolid,
		label: 'Thumbs-up', //t('Thumbs-up')
	},
};

const matrixInputTypeOptions = [
	{ value: 'selection', label: 'Selection' }, //t('Selection')
	{ value: 'number', label: 'Number' }, //t('Number')
];

const multipleSelectionOptions = [
	{ value: 'unlimited', label: 'Unlimited' }, //t('Unlimited')
	{ value: 'exact_number', label: 'Exact number' }, //t('Exact number')
	{ value: 'range', label: 'Range' }, //t('Range')
];

const shuffleOptions = [
	{
		value: 'shuffle_all',
		label: 'questionSettings.shuffle.options.shuffleAll',
	}, //t('questionSettings.shuffle.options.shuffleAll')
	{
		value: 'shuffle_first_one_fixed',
		label: 'questionSettings.shuffle.options.shuffleFirstOneFixed',
	}, //t('questionSettings.shuffle.options.shuffleFirstOneFixed')
	{
		value: 'shuffle_first_two_fixed',
		label: 'questionSettings.shuffle.options.shuffleFirstTwoFixed',
	}, //t('questionSettings.shuffle.options.shuffleFirstTwoFixed')
	{
		value: 'shuffle_last_one_fixed',
		label: 'questionSettings.shuffle.options.shuffleLastOneFixed',
	}, //t('questionSettings.shuffle.options.shuffleLastOneFixed')
	{
		value: 'shuffle_last_two_fixed',
		label: 'questionSettings.shuffle.options.shuffleLastTwoFixed',
	}, //t('questionSettings.shuffle.options.shuffleLastTwoFixed')
];

const projectStatuses = {
	all: {
		label: 'All', //t('All')
		value: 'all',
	},
	approved: {
		label: 'Approved', //t('Approved')
		isEditable: false,
		usePreQuestions: false,
		badgeType: 'success',
		value: 'approved',
	},
	completed: {
		label: 'Completed', //t('Completed')
		isEditable: false,
		usePreQuestions: false,
		badgeType: 'complete',
		value: 'completed',
	},
	draft: {
		label: 'Draft', //t('Draft')
		isEditable: true,
		usePreQuestions: true,
		badgeType: 'info',
		value: 'draft',
	},
	paused: {
		label: 'Paused', //t('Paused')
		isEditable: false,
		usePreQuestions: false,
		badgeType: 'error',
		value: 'paused',
	},
	running: {
		label: 'Running', //t('Running')
		isEditable: false,
		usePreQuestions: false,
		badgeType: 'success',
		value: 'running',
	},
	under_approval: {
		label: 'Under approval', //t('Under approval')
		isEditable: false,
		usePreQuestions: true,
		badgeType: 'warning',
		value: 'under_approval',
	},
	under_edit: {
		label: 'Under edit', //t('Under edit')
		isEditable: true,
		usePreQuestions: true,
		badgeType: 'warning',
		value: 'under_edit',
	},
};

const shortingOptions = {
	newest_first: {
		label: 'Newest first', //t('Newest first')
		option: 'newest_first',
		value: '-created',
	},
	oldest_first: {
		label: 'Oldest first', //t('Oldest first')
		option: 'oldest_first',
		value: 'created',
	},
	name_za: {
		label: 'Name, Z to A', //t('Name, Z to A')
		option: 'name_za',
		value: '-title',
	},
	name_az: {
		label: 'Name, A to Z', //t('Name, A to Z')
		option: 'name_az',
		value: 'title',
	},
};

const CONDITION_CHECKS = {
	single_selection: [
		{ value: 'is_one_of', label: 'is one of' }, //t('is one of')
		{ value: 'is_not_any_of', label: 'is not any of' }, //t('is not any of')
	],
	multiple_selection: [
		{ value: 'is_one_of', label: 'is one of' },
		{ value: 'is_not_any_of', label: 'is not any of' },
	],
	yes_no: [
		{ value: 'is_one_of', label: 'is one of' },
		{ value: 'is_not_any_of', label: 'is not any of' },
	],
	text: [
		{ value: 'contains', label: 'contains' }, //t('contains')
		{ value: 'not_contains', label: 'does not contain' }, //t('does not contain')
	],
	number: [
		{ value: 'equal', label: 'equals' }, //t('equals')
		{ value: 'not_equal', label: 'does not equal' }, //t('does not equal')
		{ value: 'smaller', label: 'is smaller than' }, //t('is smaller than')
		{ value: 'smaller_or_equal', label: 'is smaller or equal to' }, //t('is smaller or equal to')
		{ value: 'greater', label: 'is greater' }, //t('is greater')
		{ value: 'greater_or_equal', label: 'is greater or equal to' }, //t('is greater or equal to')
	],
	rating: [
		{ value: 'equal', label: 'equals' },
		{ value: 'not_equal', label: 'does not equal' },
		{ value: 'smaller', label: 'is smaller than' },
		{ value: 'smaller_or_equal', label: 'is smaller or equal to' },
		{ value: 'greater', label: 'is greater' },
		{ value: 'greater_or_equal', label: 'is greater or equal to' },
	],
	ranking: [
		{ value: 'equal', label: 'equals' },
		{ value: 'not_equal', label: 'does not equal' },
		{ value: 'smaller', label: 'is smaller than' },
		{ value: 'smaller_or_equal', label: 'is smaller or equal to' },
		{ value: 'greater', label: 'is greater' },
		{ value: 'greater_or_equal', label: 'is greater or equal to' },
	],
	opinion_scale: [
		{ value: 'equal', label: 'equals' },
		{ value: 'not_equal', label: 'does not equal' },
		{ value: 'smaller', label: 'is smaller than' },
		{ value: 'smaller_or_equal', label: 'is smaller or equal to' },
		{ value: 'greater', label: 'is greater' },
		{ value: 'greater_or_equal', label: 'is greater or equal to' },
	],
	nps: [
		{ value: 'equal', label: 'equals' },
		{ value: 'not_equal', label: 'does not equal' },
		{ value: 'smaller', label: 'is smaller than' },
		{ value: 'smaller_or_equal', label: 'is smaller or equal to' },
		{ value: 'greater', label: 'is greater' },
		{ value: 'greater_or_equal', label: 'is greater or equal to' },
	],
	matrix: [
		{ value: 'is_one_of', label: 'is one of' },
		{ value: 'is_not_any_of', label: 'is not any of' },
	],
};

const excludedWords = {
	tr: [
		've',
		'veya',
		'de',
		'da',
		'bir',
		'çok',
		'bu',
		'şu',
		'o',
		'için',
		'daha',
		'gayet',
		'her',
		'ile',
		'ama',
		'bence',
		'ne',
		'göre',
		'mi',
		'mı',
		'mu',
	],
	en: [
		'i',
		'a',
		'an',
		'that',
		'this',
		'and',
		'so',
		'is',
		'am',
		'are',
		'as',
		'of',
		'the',
		'more',
		'to',
		'from',
		'for',
		'not',
		'with',
	],
};

const AUDIENCE_REACH_THRESHOLDS = { minValue: 100, maxValue: 5000 };

const VAT_RATE = 20;

const LOCAL_STORAGE_COMMENTS = 'comments';

export {
	excludedWords as EXCLUDED_WORDS,
	questionMetaData as QUESTION_META_DATA,
	ratingMetaData as RATING_META_DATA,
	matrixInputTypeOptions as MATRIX_INPUT_TYPE_OPTIONS,
	multipleSelectionOptions as MULTIPLE_SELECTION_OPTIONS,
	shuffleOptions as SHUFFLE_OPTIONS,
	projectStatuses as PROJECT_STATUSES,
	shortingOptions as SORTING_OPTIONS,
	CONDITION_CHECKS,
	LOCAL_STORAGE_COMMENTS,
	AUDIENCE_REACH_THRESHOLDS,
	VAT_RATE,
};
