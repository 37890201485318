import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	activities: [],
	isLoading: false,
	next: '',
};

// eslint-disable-next-line import/no-unused-modules
export const activitiesSlice = createSlice({
	name: 'activities',
	initialState,
	reducers: {
		resetActivities: state => {
			state.activities = [];
		},
		loadActivitiesStart: state => {
			state.isLoading = true;
		},
		loadActivitiesSuccess: (state, action) => {
			state.activities = state.activities.concat([
				...action.payload.activities,
			]);
			state.next = state.next = action.payload.next;

			state.isLoading = false;
		},
	},
});

export const { loadActivitiesStart, loadActivitiesSuccess, resetActivities } =
	activitiesSlice.actions;

export default activitiesSlice.reducer;
