import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Spinner from '../Spinner';
import { classNames } from '../../utils';

export default function VideoPlayer({ url }) {
	const [isReady, setIsReady] = useState(false);
	const [renderPlayer, setRenderPlayer] = useState(false);

	useEffect(() => {
		if (!isReady) {
			const interval = setInterval(() => {
				setRenderPlayer(true);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [isReady]);

	return (
		<>
			{!isReady && (
				<div className="border-2 border-dashed p-14 rounded">
					<Spinner className="mx-auto text-gray-600 w-10 h-10" />
				</div>
			)}

			<div className={classNames(!isReady ? 'hidden' : '')}>
				{renderPlayer && (
					<ReactPlayer
						controls={true}
						url={url}
						width={'100%'}
						height={'100%'}
						onProgress={() => setIsReady(true)}
						onError={() => {
							setRenderPlayer(false);
							setIsReady(false);
						}}
					/>
				)}
			</div>
		</>
	);
}
