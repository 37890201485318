import { Outlet } from 'react-router-dom';
import {
	BellIcon,
	KeyIcon,
	UserCircleIcon,
	Cog6ToothIcon,
	CreditCardIcon,
} from '@heroicons/react/24/outline';
import MenuLink from '../../components/MenuLink';
import { useTranslation } from 'react-i18next';

const subNavigation = [
	{
		name: 'Account', //t('Account')
		href: '/account',
		icon: UserCircleIcon,
		current: false,
	},
	{
		name: 'Billing', //t('Billing')
		href: '/account/billing',
		icon: CreditCardIcon,
		current: false,
	},
	{
		name: 'Password', //t('Password')
		href: '/account/password',
		icon: KeyIcon,
		current: false,
	},
	{
		name: 'Notifications', //t('Notifications')
		href: '/account/notifications',
		icon: BellIcon,
		current: false,
	},
	{
		name: 'Settings', //t('Settings')
		href: '/account/settings',
		icon: Cog6ToothIcon,
		current: false,
	},
];

export default function AccountPage() {
	const { t } = useTranslation();
	return (
		<>
			<div className="h-screen overflow-y-auto pt-8">
				<main className="max-w-7xl mx-auto pb-10 lg:px-8">
					<div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
						<aside className="px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
							<nav className="space-y-1">
								{subNavigation.map(item => (
									<MenuLink
										key={item.name}
										name={t(item.name)}
										href={item.href}
										item={item}
									/>
								))}
							</nav>
						</aside>

						<div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 mb-40">
							<Outlet />
						</div>
					</div>
				</main>
			</div>
		</>
	);
}
