import React from 'react';
import BarChart from '../AnalysisQuestion/BarChart';
import { useEffect, useState, useRef } from 'react';
import { tidy, arrange, asc } from '@tidyjs/tidy';
import {
	analysisFilter,
	calculateHeightForBarChart,
} from '../../utils/analysis';
import CountTable from '../AnalysisQuestion/CountTable';
import {
	calculateChartKeys,
	comparisonChoiceCount,
} from '../AnalysisQuestion/utils';
import { useTranslation } from 'react-i18next';
import { generateTableData } from '../AnalysisQuestion/utils';

export default function DemographicsCard({
	data,
	demographic,
	labels,
	comparison,
	isCompareMode,
}) {
	const { t } = useTranslation();
	const chartRef = useRef();
	const [keys, setKeys] = useState([]);
	const [didi, setDidi] = useState([]);

	const tableHeader = demographic.choices.map(choice => {
		return { country: choice.name };
	});

	useEffect(() => {
		const calculateChoiceResponseCountForComparison = ({
			data,
			question,
			choice,
			comparisonData,
			comparisonKey,
		}) => {
			let sum = 0;
			Object.keys(comparisonData[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					// TODO: Try to refactor analysisFilter helper and use it here

					const comparedChoiceCount = comparisonChoiceCount(
						data,
						question.slug,
						choice,
						comparisonKey,
						comparisonItemKey,
						rankKey,
					);

					sum += comparedChoiceCount.length;
				});
			});
			return sum;
		};
		const calculatedKeys = calculateChartKeys(comparison);

		let di = demographic.choices.map(choice => {
			// TODO: Use analysisFilter util

			const choices = analysisFilter(data, demographic.slug, choice.nanoid);

			const returnData = {
				country: choice.name,
			};

			if (Object.keys(comparison).length > 0) {
				Object.keys(comparison).forEach(comparisonKey => {
					let choiceResponseCount = calculateChoiceResponseCountForComparison({
						data: choices,
						question: demographic,
						choice: choice,
						comparisonData: comparison,
						comparisonKey: comparisonKey,
					});

					Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
						const rankKeys = Object.keys(
							comparison[comparisonKey][comparisonItemKey],
						);
						const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
						rankArray.forEach(rankKey => {
							// TODO: Try to refactor analysisFilter helper and use it here
							const comparedChoiceCount = comparisonChoiceCount(
								choices,
								demographic.slug,
								choice,
								comparisonKey,
								comparisonItemKey,
								rankKey,
							);

							const key =
								rankKey == -1
									? `${comparisonKey}_${comparisonItemKey}`
									: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
							returnData[key] =
								choiceResponseCount > 0
									? comparedChoiceCount.length / choiceResponseCount
									: 0;
						});
					});
				});
			} else {
				returnData['responseCount'] = choices.length;
				returnData['responsePercentage'] =
					data.length > 0 ? choices.length / data.length : 0;
				returnData['count'] =
					data.length > 0
						? `${parseFloat((choices.length / data.length) * 100).toFixed(2)}%`
						: `0%`;
			}

			return returnData;
		});

		di = tidy(di, arrange(asc('responseCount')));

		setKeys(calculatedKeys);
		setDidi(di);
	}, [comparison, data, demographic, labels]);

	const height = calculateHeightForBarChart(
		demographic.choices.length,
		isCompareMode,
		keys.length,
	);

	const tableData = generateTableData(
		data,
		labels,
		demographic,
		comparison,
		'country',
		'count',
	);

	return (
		<div className="px-4 pb-4 sm:px-6">
			<div
				ref={chartRef}
				className="w-full mb-4"
				style={{
					height: height,
				}}
			>
				<BarChart
					legend={t('% of all responses')}
					keys={keys}
					data={didi}
					legendLabels={labels}
					isCompareMode={isCompareMode}
				/>
			</div>
			<CountTable
				firstColName={t('Category')}
				secondColName={t('Percentage')}
				isCompareMode={isCompareMode}
				sortedRows={isCompareMode ? tableHeader : tableData}
				compareRows={tableData}
				paginationItemName={t('paginationItem.categories')}
				textSize="small"
			/>
		</div>
	);
}
