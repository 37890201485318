import RatingIcon from './RatingIcon';

export default function RatingQuestion({ question }) {
	return (
		<>
			<form>
				<div className="flex flex-row gap-2">
					{Array.from({ length: question.config.number_of_shape }, (_, i) => {
						const ratingValue = i + 1;
						return (
							<label
								key={i}
								className="cursor-pointer flex flex-col items-center gap-2"
							>
								<input
									type="radio"
									name="rating"
									value={ratingValue}
									className="hidden"
									disabled={true}
								/>
								<RatingIcon
									iconType={question.config.shape}
									className={`text-gray-200 ${
										question.config.shape === 'heart'
											? 'hover:text-red-400'
											: 'hover:text-amber-400'
									}  w-7 h-7`}
								/>
								<span className="text-gray-700 text-sm font-mono">{i + 1}</span>
							</label>
						);
					})}
				</div>
			</form>
		</>
	);
}
