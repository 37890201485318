import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

export default function PaymentCompletePage() {
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const message = searchParams.get('message');
	const project = searchParams.get('project');

	const { t } = useTranslation();

	useEffect(() => {
		if (message === null) {
			const timeoutID = setTimeout(() => {
				window.top.location.href = '/';
			}, 3000);

			return () => {
				clearTimeout(timeoutID);
			};
		} else {
			const timeoutID2 = setTimeout(() => {
				window.top.location.href = `/project/${project}/checkout`;
			}, 3000);
			return () => {
				clearTimeout(timeoutID2);
			};
		}
	}, [dispatch, t, message, project]);

	return (
		<div className="flex justify-center">
			<div className="bg-white absolute top-1/3">
				<div className="flex flex-col justify-center">
					{message === null ? (
						<>
							<div className="flex justify-center">
								<CheckCircleIcon className="h-20 w-20 text-green-400" />
							</div>
							<div className="text-center">
								<p>{t('Payment success')}</p>
								<p>{t('redirectDashboard')}</p>
							</div>
						</>
					) : (
						<>
							<div className="flex justify-center">
								<XCircleIcon className="h-20 w-20 text-red-400" />
							</div>
							<div className="text-center">
								<p>{t('Payment failed')}</p>
								<p>{t('paymentFailText')}</p>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
