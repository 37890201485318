import { put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
	getAnalysisMeta,
	getAnalysisResponses,
} from '../../../api/services/Analysis';
import { tidy, map, filter } from '@tidyjs/tidy';
import {
	loadAnalysisDataFinish,
	setAppliedFilterCount,
	setAppliedComparisonItemCount,
	setAvailableFilterOptions,
	setFilteredData,
	setRawData,
	updateCrossTabTable,
	loadQuestionnaireLanguagesStart,
	loadQuestionnaireLanguagesFinish,
	loadTranslatedQuestionTextsFinish,
	loadTranslatedQuestionTextsStart,
	addResponseLabelFinish,
	loadLabelsFinish,
	loadLabelsStart,
	deleteLabelFinish,
	addQuestionnaireLabelFinish,
} from '../../analysisSlice';
import { cloneDeep, indexOf } from 'lodash';
import {
	getQuestionnaireLanguages,
	getTranslatedQuestionTexts,
} from '../../../api/services/Translations';
import { sendGoogleEvent } from '../../../utils/analytics';
import {
	addLabelToQuestionnaire,
	addLabelToResponse,
	deleteResponseLabel,
	getQuestionnaireLabels,
} from '../../../api/services/Labels';
import {
	ELCA_PROBLEMATIC_QUESTION_NANOID,
	ELCA_PROBLEMATIC_QUESTION_RIGHT_CHOICE_NANOID,
	ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID,
	ELCA_PROJECT_NANOID,
} from '../../../utils/analysis';

function* loadAnalysisDataSaga(action) {
	const analysisMeta = yield getAnalysisMeta({
		projectNanoId: action.payload.projectNanoId,
	});

	/*
	 * Below we loop through the questionnaire question list and add them as available
	 * filter option if they are one of the following question types:
	 * * single_selection
	 * * multiple_selection
	 * * yes_no
	 * * rating
	 * * nps
	 * * opinion_scale
	 */

	// First we create a clone of the filter list we get from the backend
	const filters = cloneDeep(analysisMeta.filters);

	// Backend data is structed in a way where every filter option is a child of parent filter option
	// They are called "audience category"
	// This is why we create a dummy parent category here – this is not visible in the UI
	const parentCategory = {
		nanoid: '',
		name: '',
		slug: '',
		category_set: [],
		categoryfield_set: [],
	};

	const _questionList = yield select(
		state => state.projectEditor.questionnaire.questionList,
	);

	const screeningQuestionList = yield select(
		state => state.projectEditor.questionnaire.screeningQuestionList,
	);

	const questionList = [...screeningQuestionList, ..._questionList];

	questionList.forEach(question => {
		if (
			question.question_type === 'single_selection' ||
			question.question_type === 'multiple_selection' ||
			question.question_type === 'yes_no'
		) {
			parentCategory.category_set.push({
				nanoid: question.nanoid,
				name: question.title,
				slug: question.nanoid,
				category_set: [],
				type: 'question',
				question_type: question.question_type,
				categoryfield_set: question.choices.map(choice => ({
					nanoid: choice.nanoid,
					name: choice.title,
					slug: choice.slug,
				})),
			});
		}
		if (question.question_type === 'ranking') {
			parentCategory.category_set.push({
				nanoid: question.nanoid,
				name: question.title,
				slug: question.nanoid,
				category_set: [],
				type: 'question',
				question_type: question.question_type,
				categoryfield_set: question.choices.map(choice => ({
					nanoid: choice.nanoid,
					name: choice.title,
					slug: choice.slug,
					ranks: Array.from({ length: question.choices.length }, (_, i) => {
						return { rank: i + 1, count: 0 };
					}),
				})),
			});
		}

		if (question.question_type === 'matrix') {
			parentCategory.category_set.push({
				nanoid: question.nanoid,
				name: question.title,
				slug: question.nanoid,
				category_set: [],
				type: 'question',
				question_type: question.question_type,
				isMultiselection: question.config.is_multiselection ? true : false,
				categoryfield_set: question.choices
					.filter(choice => choice.choice_type === 'row')
					.map(choice => ({
						nanoid: choice.nanoid,
						name: choice.title,
						slug: choice.title,
					})),
				columns: question.columns,
			});
		}

		if (question.question_type === 'rating') {
			const ratingNumber = question.config.number_of_shape;

			let ratingCategoryFieldSet = Array(ratingNumber)
				.fill()
				.map((_, i) => {
					return { nanoid: ++i, name: i, slug: '' };
				});

			parentCategory.category_set.push({
				nanoid: question.nanoid,
				name: question.title,
				slug: question.nanoid,
				category_set: [],
				type: 'question',
				categoryfield_set: ratingCategoryFieldSet,
			});
		}

		if (
			question.question_type === 'opinion_scale' ||
			question.question_type === 'nps'
		) {
			let min = question.config.minimum;
			const max = question.config.maximum;
			let npsOpinioncategoryFieldSet = Array(max - min + 1)
				.fill()
				.map((_, i) => {
					return { nanoid: i + min, name: (min + i++).toString(), slug: '' };
				});

			parentCategory.category_set.push({
				nanoid: question.nanoid,
				name: question.title,
				slug: question.nanoid,
				category_set: [],
				type: 'question',
				categoryfield_set: npsOpinioncategoryFieldSet,
			});
		}
	});

	filters.unshift(parentCategory);

	yield put(
		setAvailableFilterOptions({
			projectNanoId: action.payload.projectNanoId,
			availableFilterOptions: filters,
			questionList: questionList,
		}),
	);

	const questionnaire = yield select(
		state => state.projectEditor.questionnaire,
	);

	yield put(
		loadLabelsStart({
			projectNanoId: action.payload.projectNanoId,
			questionnaireNanoId: questionnaire.nanoid,
		}),
	);

	const responses = yield getAnalysisResponses({
		projectNanoId: action.payload.projectNanoId,
	});

	let allResponses = responses;
	if (allResponses[0]?.questionnaire.nanoid === 'ZtZ38LillSjiNk8I') {
		const newResponses = allResponses.map(response => {
			const label = JSON.parse(response.labels);

			const questionFour = {
				questionFour: [
					response.answers['RPvkS-wAeo3vOgMK'],
					label['L5R7tHLZFTP20DVd'][0],
				],
			};
			const newAnswers = { ...response.answers, ...questionFour };
			return { ...response, answers: newAnswers };
		});
		allResponses = newResponses;
	}

	if (allResponses[0]?.questionnaire.nanoid === 'IxLhkC2qNGD672jX') {
		//Q4 nanoid: FRYr7oBINe2O4NAy   Q5 nanoid: 4vV8rLV0MQ8r9kwR  Q6 nanoid: 1Jh5fy3yMK9CWVsZ
		const matrixRowAnswers =
			questionList[5]?.choices.map(choice => ({
				choice: choice.nanoid,
			})) || []; //4th question choices

		const newResponses = allResponses.map(response => {
			const ans45 = matrixRowAnswers.map(answer => {
				if (response.answers['FRYr7oBINe2O4NAy'] === answer.choice) {
					return {
						...answer,
						columns: [{ choice: response.answers['4vV8rLV0MQ8r9kwR'] }],
					};
				}
				return { ...answer, columns: [{ choice: null }] };
			});

			const matrix1 = {
				matrix45: ans45,
			};

			const ans46 = matrixRowAnswers.map(answer => {
				if (response.answers['FRYr7oBINe2O4NAy'] === answer.choice) {
					return {
						...answer,
						columns: [{ choice: response.answers['1Jh5fy3yMK9CWVsZ'] }],
					};
				}
				return { ...answer, columns: [{ choice: null }] };
			});

			const matrix2 = { matrix46: ans46 };
			const newAnswers = { ...response.answers, ...matrix1, ...matrix2 };
			return { ...response, answers: newAnswers };
		});
		allResponses = newResponses;
	}

	const formattedResponses = tidy(
		allResponses,
		map(d => {
			if (action.payload.projectNanoId === ELCA_PROJECT_NANOID) {
				const wrongChoice = ELCA_PROBLEMATIC_QUESTION_WRONG_CHOICE_NANOID;
				const rightChoice = ELCA_PROBLEMATIC_QUESTION_RIGHT_CHOICE_NANOID;

				const indexOfWrongChoice =
					d.answers[ELCA_PROBLEMATIC_QUESTION_NANOID].indexOf(wrongChoice);

				if (indexOfWrongChoice > -1) {
					const indexOfRightChoice =
						d.answers[ELCA_PROBLEMATIC_QUESTION_NANOID].indexOf(rightChoice);
					d.answers[ELCA_PROBLEMATIC_QUESTION_NANOID].splice(
						indexOfWrongChoice,
						1,
					);

					if (indexOfRightChoice === -1) {
						d.answers[ELCA_PROBLEMATIC_QUESTION_NANOID].push(rightChoice);
					}
				}
			}

			let answers = d.answers;
			//start   for project HFR-1inavZVTBqqi
			if (
				d.answers['Gt7vP1BGJ9cSwyt1'] &&
				d.answers['Gt7vP1BGJ9cSwyt1'] === 'Z0HZb7CCxV5JHF0y' &&
				d.demographics['cinsiyet'] === 'zZSmdQInlGDNPWb0' //cinsiyet === kadin
			) {
				answers['Gt7vP1BGJ9cSwyt1'] = '-4uvVEkgUn93UNOA';
			}
			//end  for project HFR-1inavZVTBqqi

			return {
				created: d.created,
				nanoid: d.response,
				translations: d.translations ? JSON.parse(d.translations) : {},
				labels: JSON.parse(d.labels),
				...d.demographics,
				...answers,
			};
		}),
	);

	yield put(
		loadAnalysisDataFinish({ projectNanoId: action.payload.projectNanoId }),
	);

	yield put(
		setRawData({
			projectNanoId: action.payload.projectNanoId,
			data: formattedResponses,
		}),
	);

	yield put(
		loadQuestionnaireLanguagesStart({
			projectNanoId: action.payload.projectNanoId,
			questionnaireNanoId: questionnaire.nanoid,
		}),
	);
}

export function* watchLoadAnalysisData() {
	yield takeLatest('analysis/loadAnalysisDataStart', loadAnalysisDataSaga);
}

function* filterDataSaga(action) {
	const rawData = yield select(
		state => state.analysis.projects[action.payload.projectNanoId].rawData,
	);

	const questionList = yield select(
		state => state.projectEditor.questionnaire.questionList,
	);

	const newAppliedFilters = yield select(
		state =>
			state.analysis.projects[action.payload.projectNanoId].filterData
				.appliedFilters,
	);

	let newFilteredData = rawData;

	Object.keys(newAppliedFilters).forEach(filterItem => {
		// TODO: Try to refactor analysisFilter helper and use it here
		newFilteredData = tidy(
			newFilteredData,
			filter(d => {
				let found = false;
				Object.keys(newAppliedFilters[filterItem]).forEach(filterField => {
					if (found) return true;

					const dataType = typeof d[filterItem];

					if (dataType === 'string' || dataType === 'number') {
						// For single choice filter items and rating
						found = d[filterItem] == filterField;
					} else if (dataType === 'object') {
						// For multiple choice & ranking filter items
						const innerDataType =
							d[filterItem][0] == null ? 'string' : typeof d[filterItem][0];

						if (innerDataType === 'string') {
							//for multiple choice
							found = indexOf(d[filterItem], filterField) > -1;
						} else if (innerDataType === 'object');
						//for ranking questions
						{
							Object.keys(newAppliedFilters[filterItem][filterField]).forEach(
								rank => {
									const responseMatchingFilter = d[filterItem].find(
										answer =>
											answer.order == rank && answer.choice === filterField,
									);
									found = responseMatchingFilter ? true : false;
								},
							);
						}
					}
				});
				return found;
			}),
		);
	});

	const availableFilterOptions = yield select(
		state =>
			state.analysis.projects[action.payload.projectNanoId].filterData
				.availableFilterOptions,
	);

	const availableFilterOptionsClone = cloneDeep(availableFilterOptions);

	availableFilterOptionsClone.forEach(section => {
		section.category_set.forEach(filterItem => {
			filterItem.categoryfield_set.forEach(filterField => {
				// TODO: Use analysisFilter util here

				if (filterItem.question_type === 'ranking') {
					//reset count values to 0 before calculation
					filterField.ranks.map(rank => (rank.count = 0));
					newFilteredData.forEach(d => {
						if (d[filterItem.slug]) {
							const filteredValue = d[filterItem.slug].find(
								el => el.choice === filterField.nanoid,
							);

							const filteredRank = filterField.ranks.find(
								rank => rank.rank === filteredValue.order,
							);

							filteredRank.count = filteredRank.count + 1;
						}
					});

					filterField.ranks.map(
						rank => (rank['percentage'] = rank.count / newFilteredData.length),
					);
				} else {
					const countResult = tidy(
						newFilteredData,
						filter(d => {
							const dataType = typeof d[filterItem.slug];

							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[filterItem.slug] == filterField.nanoid;
							} else if (dataType === 'object') {
								// For multiple choice filter items
								return indexOf(d[filterItem.slug], filterField.nanoid) > -1;
							}
						}),
					);

					// We alter `availableFilterOptionsClone` here
					// We update the percentages shown next to the filter categories
					// We only update the percentages if the filterItem we're working on here (e.g. "age") is not being filtered
					if (!newAppliedFilters[filterItem.slug]) {
						filterField['percentage'] =
							newFilteredData.length > 0
								? countResult.length / newFilteredData.length
								: 0;

						filterField['count'] =
							newFilteredData.length > 0 ? countResult.length : 0;
					}
				}
			});
		});
	});

	yield put(
		setFilteredData({
			projectNanoId: action.payload.projectNanoId,
			data: newFilteredData,
		}),
	);

	yield put(
		setAvailableFilterOptions({
			projectNanoId: action.payload.projectNanoId,
			availableFilterOptions: availableFilterOptionsClone,
			questionList: questionList,
		}),
	);
}

export function* watchAppliedFilterChange() {
	yield takeLatest(
		[
			'analysis/setRawData',
			'analysis/applyFilter',
			'analysis/removeFilter',
			'analysis/resetFilters',
		],
		filterDataSaga,
	);
}

function* updateFilterAndComparisonItemCountsSaga(action) {
	const appliedFilters = yield select(
		state =>
			state.analysis.projects[action.payload.projectNanoId].filterData
				.appliedFilters,
	);
	const appliedComparisonItems = yield select(
		state =>
			state.analysis.projects[action.payload.projectNanoId].comparisonData
				.appliedComparisonItems,
	);

	let total = 0;
	Object.keys(appliedFilters).forEach(filterItem => {
		Object.keys(appliedFilters[filterItem]).forEach(filterKey => {
			const dataType = typeof appliedFilters[filterItem][filterKey];
			if (dataType === 'object') {
				total += Object.keys(appliedFilters[filterItem][filterKey]).length;
			} else {
				total += 1;
			}
		});
	});

	yield put(
		setAppliedFilterCount({
			projectNanoId: action.payload.projectNanoId,
			count: total,
		}),
	);

	total = 0;
	Object.keys(appliedComparisonItems).forEach(comparisonItem => {
		Object.keys(appliedComparisonItems[comparisonItem]).forEach(
			comparisonKey => {
				const dataType =
					typeof appliedComparisonItems[comparisonItem][comparisonKey];
				if (dataType === 'object') {
					total += Object.keys(
						appliedComparisonItems[comparisonItem][comparisonKey],
					).length;
				} else {
					total += 1;
				}
			},
		);
	});

	yield put(
		setAppliedComparisonItemCount({
			projectNanoId: action.payload.projectNanoId,
			count: total,
		}),
	);
}

export function* watchFilterAndComparisonItemCountsChange() {
	yield takeLatest(
		[
			'analysis/applyFilter',
			'analysis/removeFilter',
			'analysis/applyComparisonItem',
			'analysis/removeComparisonItem',
		],
		updateFilterAndComparisonItemCountsSaga,
	);
}

function* updateCrossTabTableSaga(action) {
	yield put(
		updateCrossTabTable({ projectNanoId: action.payload.projectNanoId }),
	);
}

export function* watchCrossTabSettingsChange() {
	yield takeLatest(
		[
			'analysis/addCrossTabRow',
			'analysis/removeCrossTabRow',
			'analysis/addCrossTabColumn',
			'analysis/swapCrossTabRowsAndColumns',
			'analysis/removeCrossTabColumn',
			'analysis/moveCrossTabFilterItemBetweenRowsAndColumns',
		],
		updateCrossTabTableSaga,
	);
}

function* loadQuestionnaireLanguagesSaga(action) {
	const questionnaireLanguages = yield getQuestionnaireLanguages({
		questionnaireNanoId: action.payload.questionnaireNanoId,
	});
	yield put(
		loadQuestionnaireLanguagesFinish({
			projectNanoId: action.payload.projectNanoId,
			results: questionnaireLanguages,
		}),
	);
}

export function* watchLoadQuestionnaireLanguages() {
	yield takeLatest(
		['analysis/loadQuestionnaireLanguagesStart'],
		loadQuestionnaireLanguagesSaga,
	);
}

function* loadTranslatedQuestionTextsSaga(action) {
	const questionnaire = yield select(
		state => state.projectEditor.questionnaire,
	);

	const translatedQuestionTexts = yield getTranslatedQuestionTexts({
		questionnaireNanoId: questionnaire.nanoid,
		questionnaireLanguageNanoId: action.payload.questionnaireLanguageNanoId,
	});
	yield put(
		loadTranslatedQuestionTextsFinish({
			projectNanoId: action.payload.projectNanoId,
			language: action.payload.language,
			results: translatedQuestionTexts,
		}),
	);
}

function* setDisplayLanguageSaga(action) {
	const translations = yield select(
		state => state.analysis.projects[action.payload.projectNanoId].translations,
	);

	const questionList = yield select(
		state => state.projectEditor.questionnaire.questionList,
	);

	if (
		translations?.translatedQuestionTexts[action.payload.language]?.status !==
		'loaded'
	) {
		yield put(
			loadTranslatedQuestionTextsStart({
				projectNanoId: action.payload.projectNanoId,
				language: action.payload.language,
				questionnaireLanguageNanoId: action.payload.questionnaireLanguageNanoId,
			}),
		);
	} else {
		const availableFilterOptions = yield select(
			state =>
				state.analysis.projects[action.payload.projectNanoId].filterData
					.availableFilterOptions,
		);

		yield put(
			setAvailableFilterOptions({
				projectNanoId: action.payload.projectNanoId,
				availableFilterOptions: availableFilterOptions,
				questionList: questionList,
			}),
		);
	}

	sendGoogleEvent('analysis_language_change', {
		language: action.payload.language,
	});
}

function* loadTranslatedQuestionTextsFinishSaga(action) {
	const availableFilterOptions = yield select(
		state =>
			state.analysis.projects[action.payload.projectNanoId].filterData
				.availableFilterOptions,
	);
	const questionList = yield select(
		state => state.projectEditor.questionnaire.questionList,
	);

	yield put(
		setAvailableFilterOptions({
			projectNanoId: action.payload.projectNanoId,
			availableFilterOptions: availableFilterOptions,
			questionList: questionList,
		}),
	);
}

function* loadLabelsStartSaga(action) {
	const labelList = yield getQuestionnaireLabels({
		questionnaireNanoId: action.payload.questionnaireNanoId,
	});

	yield put(
		loadLabelsFinish({
			projectNanoId: action.payload.projectNanoId,
			data: labelList,
		}),
	);
}

function* deleteResponseLabelSaga(action) {
	const { responseNanoId, labelNanoId, questionNanoId, projectNanoId } =
		action.payload;

	for (let nanoId of responseNanoId) {
		yield deleteResponseLabel({
			responseNanoId: nanoId,
			labelNanoId,
			questionNanoId,
		});
		sendGoogleEvent('labels_remove');

		yield put(
			deleteLabelFinish({
				responseNanoId: nanoId,
				projectNanoId,
				questionNanoId,
				labelNanoId,
			}),
		);
	}
}

export function* watchDeleteLabel() {
	yield takeLatest(['analysis/deleteLabelStart'], deleteResponseLabelSaga);
}

function* addResponseLabelSaga(action) {
	const data = {
		question: action.payload.questionNanoId,
		label: action.payload.labelNanoId,
	};

	const responseNanoId = action.payload.responseNanoId;

	for (let nanoId of responseNanoId) {
		yield addLabelToResponse({
			responseNanoId: nanoId,
			payload: data,
		});
		sendGoogleEvent('labels_add');
		yield put(
			addResponseLabelFinish({
				responseNanoId: nanoId,
				projectNanoId: action.payload.projectNanoId,
				questionNanoId: action.payload.questionNanoId,
				labelNanoId: action.payload.labelNanoId,
			}),
		);
	}
}

export function* watchAddResponseLabel() {
	yield takeLatest(['analysis/addResponseLabelStart'], addResponseLabelSaga);
}

function* addQuestionnaireLabelSaga(action) {
	const {
		questionnaireNanoId,
		questionNanoId,
		labelName,
		projectNanoId,
		responseNanoId,
	} = action.payload;
	const data = { question: questionNanoId, name: labelName };

	const newLabelData = yield addLabelToQuestionnaire({
		questionnaireNanoId: questionnaireNanoId,
		payload: data,
	});
	sendGoogleEvent('labels_create');

	const questionnaireLabelsResult = yield getQuestionnaireLabels({
		questionnaireNanoId: questionnaireNanoId,
	});

	const payload = {
		question: questionNanoId,
		label: newLabelData.nanoid,
	};

	for (let nanoId of responseNanoId) {
		yield addLabelToResponse({ responseNanoId: nanoId, payload });

		yield put(
			addQuestionnaireLabelFinish({
				projectNanoId: projectNanoId,
				questionnaireLabels: questionnaireLabelsResult,
				labelNanoId: newLabelData.nanoid,
				questionNanoId: questionNanoId,
				responseNanoId: nanoId,
			}),
		);
	}
}

export function* watchAddQuestionnaireLabel() {
	yield takeLatest(
		['analysis/addQuestionnaireLabelStart'],
		addQuestionnaireLabelSaga,
	);
}

export function* watchSetLanguage() {
	yield takeLatest(['analysis/setDisplayLanguage'], setDisplayLanguageSaga);
}

export function* watchLoadTranslatedQuestionTexts() {
	yield takeEvery(
		['analysis/loadTranslatedQuestionTextsStart'],
		loadTranslatedQuestionTextsSaga,
	);
}

export function* watchLoadTranslatedQuestionTextsFinish() {
	yield takeLatest(
		['analysis/loadTranslatedQuestionTextsFinish'],
		loadTranslatedQuestionTextsFinishSaga,
	);
}

export function* watchLoadLabelsStart() {
	yield takeLatest('analysis/loadLabelsStart', loadLabelsStartSaga);
}
