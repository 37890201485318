import { TrashIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import {
	createMatrixRow,
	deleteMatrixRow,
	updateMatrixRowTitle,
	createMatrixColumn,
	updateMatrixColumnTitle,
	deleteMatrixColumn,
} from '../../../store/projectEditorSlice';
import { isChoiceUsedInLogic, newChoiceTemplate } from '../../../utils';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../../Modal';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';

export default function MatrixQuestion({ question, isEditable = true }) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const { isAddingMatrixColumn, isAddingMatrixRow } = useSelector(
		state =>
			state.projectEditor.questionnaire.questionStatuses[question.nanoid],
	);

	return (
		<>
			<div className="flex justify-end">
				<button
					type="button"
					disabled={!isEditable || isAddingMatrixColumn}
					className="inline-flex items-center px-2.5 py-1.5 border border-transparent shadow-sm text-xs leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					onClick={() => {
						const newChoice = newChoiceTemplate({
							title: t('Column {{number}}', {
								number: question.columns.length + 1,
							}),
							choice_type: 'column',
						});

						dispatch(
							createMatrixColumn({
								question: question,
								newChoice: newChoice,
							}),
						);
					}}
				>
					{isAddingMatrixColumn ? (
						<Spinner className="h-3.5 w-3.5" />
					) : (
						t('Add Column')
					)}
				</button>
			</div>

			<div className="mt-3 flex flex-col">
				<div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="w-full shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr className="divide-x divide-gray-200">
										<th scope="col">&nbsp;</th>
										{question.columns.map((column, index) => (
											<th
												key={column.nanoid}
												scope="col"
												className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 sm:pl-6 group"
											>
												<div className="flex">
													<input
														value={question.columns[index].title}
														disabled={!isEditable}
														onChange={event => {
															dispatch(
																updateMatrixColumnTitle({
																	question: question,
																	columnIndex: index,
																	choice: question.columns[index],
																	title: event.target.value,
																}),
															);
														}}
														className="pl-0 block w-16 border-0 border-b border-transparent bg-transparent focus:outline-none focus:border-indigo-600 focus:ring-0 sm:text-sm"
													/>
													{question.columns.length > 1 && (
														<button
															type="button"
															disabled={!isEditable || !isEditable}
															onClick={() => {
																if (
																	isChoiceUsedInLogic(
																		question.columns[index],
																		questionList,
																	)
																) {
																	NiceModal.show(Modal, {
																		icon: 'warning',
																		title: t('Cannot Delete {{option}}', {
																			option: t('Column'),
																		}),
																		message: t(
																			'questionEditor.modal.deleteErrorMsgColumn',
																		),
																		showPrimaryButton: false,
																		secondaryButtonLabel: t('Close'),
																	});
																} else {
																	dispatch(
																		deleteMatrixColumn({
																			question: question,
																			index: index,
																			choice: question.columns[index],
																		}),
																	);
																}
															}}
														>
															<TrashIcon className="w-5 h-5 transition-opacity ease-in-out opacity-0 group-hover:opacity-100 text-indigo-600 cursor-pointer" />
														</button>
													)}
												</div>
											</th>
										))}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{question.rows.map((row, index) => (
										<tr key={row.nanoid} className="divide-x divide-gray-200">
											<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 group">
												<div className="flex">
													<input
														value={question.rows[index].title}
														disabled={!isEditable}
														onChange={event => {
															dispatch(
																updateMatrixRowTitle({
																	question: question,
																	rowIndex: index,
																	choice: question.rows[index],
																	title: event.target.value,
																}),
															);
														}}
														className="pl-0 block w-16 border-0 border-b border-transparent focus:outline-none focus:border-indigo-600 focus:ring-0 sm:text-sm"
													/>
													{question.rows.length > 1 && (
														<button
															type="button"
															onClick={() => {
																if (
																	isChoiceUsedInLogic(
																		question.rows[index],
																		questionList,
																	)
																) {
																	NiceModal.show(Modal, {
																		icon: 'warning',
																		title: t('Cannot Delete {{option}}', {
																			option: t('Row'),
																		}),
																		message: t(
																			'questionEditor.modal.deleteErrorMsgRow',
																		),
																		showPrimaryButton: false,
																		secondaryButtonLabel: t('Close'),
																	});
																} else {
																	dispatch(
																		deleteMatrixRow({
																			question: question,
																			index: index,
																			choice: question.rows[index],
																		}),
																	);
																}
															}}
														>
															<TrashIcon className="w-5 h-5 transition-opacity ease-in-out opacity-0 group-hover:opacity-100 text-indigo-600 cursor-pointer" />
														</button>
													)}
												</div>
											</td>
											{question.columns.map(column => (
												<td
													key={column.nanoid}
													className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6 text-center"
												>
													{question.config.input_type === 'selection' && (
														<input
															type={
																question.config.is_multiselection
																	? 'checkbox'
																	: 'radio'
															}
															name={row.nanoid}
															value={column.nanoid}
															disabled={!isEditable}
															className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500  cursor-pointer"
														/>
													)}
													{question.config.input_type === 'number' && (
														<input
															type={'number'}
															className={
																'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6'
															}
														/>
													)}
												</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<button
				type="button"
				disabled={!isEditable || isAddingMatrixRow}
				className="mt-3 inline-flex items-center px-2.5 py-1.5 border border-transparent shadow-sm text-xs leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				onClick={() => {
					const newChoice = newChoiceTemplate({
						title: t('Row {{number}}', {
							number: question.rows.length + 1,
						}),
						choice_type: 'row',
					});

					dispatch(
						createMatrixRow({
							question: question,
							newChoice: newChoice,
						}),
					);
				}}
			>
				{isAddingMatrixRow ? <Spinner className="h-3.5 w-3.5" /> : t('Add Row')}
			</button>
		</>
	);
}
