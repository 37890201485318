import React from 'react';
import ChoiceList from '../ChoiceList';

const SingleSelectionQuestion = ({
	question,
	showPreviousButton,
	handlePrevious,
	isPreviousDisabled,
	handleQuestionSubmit,
	questionList,
	completeQuestionnaire,
	isEditable = true,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	lastQuestion,
}) => {
	return (
		<ChoiceList
			question={question}
			showPreviousButton={showPreviousButton}
			isPreviousDisabled={isPreviousDisabled}
			handlePrevious={handlePrevious}
			handleQuestionSubmit={handleQuestionSubmit}
			questionList={questionList}
			completeQuestionnaire={completeQuestionnaire}
			isEditable={isEditable}
			showOther={question.config.show_other}
			handleCreateChoice={handleCreateChoice}
			handleReorderChoiceList={handleReorderChoiceList}
			handleChoiceTitleChange={handleChoiceTitleChange}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			handleDeleteChoice={handleDeleteChoice}
			lastQuestion={lastQuestion}
		/>
	);
};

export default SingleSelectionQuestion;
