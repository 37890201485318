import { classNames } from '../../utils';

export default function Table2({ columnHeads, rows }) {
	return (
		<div className="overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
			<table className="min-w-full divide-y divide-gray-300">
				<thead className="bg-gray-50">
					<tr>
						{columnHeads.map((columnHead, index) => {
							return (
								<th
									key={index}
									scope="col"
									className={classNames(
										columnHead.alignLeft ? 'text-left' : 'text-right',
										'py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6',
									)}
								>
									{columnHead.title}
								</th>
							);
						})}
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 bg-white">
					{rows.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{row.map((col, colIndex) => (
								<td
									key={colIndex}
									className={classNames(
										col.alignLeft ? 'text-left' : 'text-right',
										col.mono ? 'font-mono' : '',
										'whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6',
									)}
								>
									{col.value}
									{col.value2 && (
										<>
											<br />
											{col.value2}
										</>
									)}
									{col.media && (
										<a href={col.media.url} target="_blank" rel="noreferrer">
											<img src={col.media.url} className="w-32 rounded" />
										</a>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}
