import { useState, useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { Combobox } from '@headlessui/react';
import { classNames } from '../../utils';
import {
	getCategoryFields,
	searchCategoryFields,
} from '../../api/services/Audience';
import { useTranslation } from 'react-i18next';
import { shouldAudienceCategoryShowInitialAutoComplete } from '../../utils/Audience';

export default function AudienceCategorySearchSelector({
	category,
	handleSelectionChange,
	isEditable,
	categoryFields,
}) {
	const [searchResults, setSearchResults] = useState([]);
	const [initialResults, setInitialResults] = useState([]);
	const [selectedResults, setSelectedResults] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			const result = await getCategoryFields({
				categoryNanoId: category.nanoid,
			});
			if (isMounted) {
				if (shouldAudienceCategoryShowInitialAutoComplete(category.slug)) {
					setSearchResults(result);
				}

				setInitialResults(result);
			}
		};

		fetchData();

		return function cleanup() {
			isMounted = false;
		};
	}, [category.nanoid, category.slug]);

	useEffect(() => {
		const selectedItems =
			categoryFields.length &&
			initialResults.length &&
			initialResults.filter(searchResult =>
				categoryFields.some(
					categoryField =>
						searchResult.value.nanoid === categoryField.value.nanoid,
				),
			);

		setSelectedResults(selectedItems.length > 0 ? selectedItems : []);
	}, [categoryFields, initialResults]);

	const handleChange = async e => {
		const searchTerm = e.target.value.trim();

		const result = await searchCategoryFields({
			categoryNanoId: category.nanoid,
			term: searchTerm,
		});

		if (
			shouldAudienceCategoryShowInitialAutoComplete(category.slug) ||
			searchTerm !== ''
		) {
			setSearchResults(result);
		} else {
			setSearchResults([]);
		}
	};

	const handleClick = async item => {
		handleSelectionChange({
			mode: 'create',
			categoryNanoId: category.nanoid,
			categoryFieldNanoId: item.nanoid,
			audienceFieldNanoId: item.value.nanoid,
			categoryFieldSlug: item.slug,
		});
	};

	return (
		<Combobox
			as="div"
			onChange={item => {
				handleClick(item[item.length - 1]);
			}}
			disabled={!isEditable}
			className="disabled:cursor-not-allowed disabled:opacity-30"
			value={selectedResults}
			multiple
		>
			<Combobox.Label className="block text-sm font-medium text-gray-700">
				{t('Search')}
			</Combobox.Label>

			<div className="relative mt-1">
				<Combobox.Button disabled={true} as="div" className="flex items-center">
					<Combobox.Input
						className="disabled:cursor-not-allowed disabled:opacity-30 w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
						onChange={handleChange}
						displayValue={''}
					/>

					<span className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronUpDownIcon
							className="h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
					</span>
				</Combobox.Button>

				{searchResults.length > 0 && (
					<Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{searchResults.map(person => (
							<Combobox.Option
								key={person.nanoid}
								value={person}
								className={({ active }) =>
									classNames(
										'relative cursor-default select-none py-2 pl-3 pr-9',
										active ? 'bg-indigo-600 text-white' : 'text-gray-900',
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span
											className={classNames(
												'block truncate',
												selected && 'font-semibold',
											)}
										>
											{person.name}
										</span>

										{selected && (
											<span
												className={classNames(
													'absolute inset-y-0 right-0 flex items-center pr-4',
													active ? 'text-white' : 'text-indigo-600',
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	);
}
