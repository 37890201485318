import React from 'react';
import ChoiceList from '../ChoiceList';

const RankingQuestion = ({
	question,
	showPreviousButton,
	isPreviousDisabled,
	handlePrevious,
	handleQuestionSubmit,
	isEditable = true,
	isEditMode,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	lastQuestion,
}) => {
	return (
		<ChoiceList
			question={question}
			showPreviousButton={showPreviousButton}
			isPreviousDisabled={isPreviousDisabled}
			handlePrevious={handlePrevious}
			handleQuestionSubmit={handleQuestionSubmit}
			isEditable={isEditable}
			isEditMode={isEditMode}
			handleReorderChoiceList={handleReorderChoiceList}
			handleCreateChoice={handleCreateChoice}
			handleChoiceTitleChange={handleChoiceTitleChange}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			handleDeleteChoice={handleDeleteChoice}
			lastQuestion={lastQuestion}
		/>
	);
};
export default RankingQuestion;
