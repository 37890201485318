import axios from 'axios';
import {
	AUTH_STORAGE_KEY,
	getItemFromLocalStorage,
	saveToLocalStorage,
} from '../utils';
import { endPoints } from '../endPoints';
import moment from 'moment';

export const sorbunuApi = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

sorbunuApi.interceptors.response.use(
	res => {
		return res;
	},
	async err => {
		const originalConfig = err.config;
		if (err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;
				try {
					const authData = getItemFromLocalStorage(AUTH_STORAGE_KEY);
					const refreshTokenTimestamp = moment(
						authData.refresh_token_expiration,
					).unix();
					const currentTimestamp = moment().unix();

					if (refreshTokenTimestamp <= currentTimestamp) {
						localStorage.removeItem(AUTH_STORAGE_KEY);
						window.location.reload();
						return;
					}

					if (authData.refresh_token) {
						const response = await sorbunuApi.post(
							endPoints.auth.REFRESH_TOKEN(),
							{
								refresh: authData.refresh_token,
							},
						);

						const access_token = response.data.access;
						const access_token_expiration =
							response.data.access_token_expiration;
						const resfreshedAuthData = {
							...authData,
							access_token,
							access_token_expiration,
						};

						saveToLocalStorage(AUTH_STORAGE_KEY, resfreshedAuthData);
						sorbunuApi.defaults.headers.common['Authorization'] =
							'Bearer' + access_token;
					}

					return sorbunuApi(originalConfig);
				} catch (_error) {
					if (_error.response && _error.response.data) {
						return Promise.reject(_error.response.data);
					}
					return Promise.reject(_error);
				}
			}
			if (err.response.status === 403 && err.response.data) {
				return Promise.reject(err.response.data);
			}
		}
		return Promise.reject(err);
	},
);
