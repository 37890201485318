import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCommentsLastRead } from '../../utils/Comment';
import { setUnreadComments } from '../../store/projectEditorSlice';
import Spinner from '../Spinner';
import Comment from '../Comment';

export default function CommentList() {
	const dispatch = useDispatch();
	const comments = useSelector(
		state => state.projectEditor.comments.commentList,
	);
	const reversedComments = JSON.parse(JSON.stringify(comments)).reverse();
	const status = useSelector(state => state.projectEditor.comments.status);

	const projectNanoId = useSelector(
		state => state.projectEditor.project.nanoid,
	);
	const divRef = useRef(null);

	useEffect(() => {
		if (comments.length > 0) {
			setCommentsLastRead(projectNanoId);
			dispatch(setUnreadComments({ hasUnread: false }));
		}
	}, [dispatch, comments.length, projectNanoId]);

	useEffect(() => {
		divRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
	});

	return (
		<>
			{status === 'loading' && (
				<div className="flex justify-center align-center">
					<Spinner className="h-12 w-12" />
				</div>
			)}
			<ul ref={divRef} role="list" className="-my-4 divide-y divide-gray-200">
				{reversedComments.map((comment, ind) => (
					<Comment key={ind} comment={comment} />
				))}
			</ul>
		</>
	);
}
