import { Field } from 'formik';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils';

export const FormInput = ({
	name,
	placeholder,
	type,
	id,
	label,
	defaultValue,
	value,
	errorMessage = '',
	helperText = '',
	addOn = '',
	...inputProps
}) => {
	return (
		<Field name={name}>
			{({ field }) => {
				return (
					<div className="flex flex-col gap-1">
						<label
							htmlFor={id ?? ''}
							className="block text-sm font-medium text-gray-700"
						>
							{label}
						</label>
						{helperText && (
							<span className="text-xs font-light text-slate-400 mb-1">
								{helperText}
							</span>
						)}
						<div className="flex relative rounded-md shadow-sm">
							{addOn && (
								<span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
									{addOn}
								</span>
							)}
							<input
								{...field}
								type={type}
								name={name}
								id={id}
								className={classNames(
									'block w-full pr-10',
									'block w-full pr-10',
									addOn ? 'rounded-r-md' : 'rounded-md',
									errorMessage
										? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500 text-red-900'
										: 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
									'focus:outline-none  sm:text-sm',
									'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
								)}
								placeholder={placeholder}
								defaultValue={defaultValue}
								value={value}
								aria-invalid="true"
								{...inputProps}
							/>
							{errorMessage && (
								<div className="absolute inset-y-0  right-0 pr-3 flex items-center pointer-events-none">
									<ExclamationCircleIcon
										className="h-5 w-5 text-red-500"
										aria-hidden="true"
									/>
								</div>
							)}
						</div>
						{errorMessage && (
							<p className="mt-2 text-sm text-red-600" id={`${id}-error`}>
								{errorMessage}
							</p>
						)}
					</div>
				);
			}}
		</Field>
	);
};
