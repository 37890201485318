export default function ProgressBar({ progress }) {
	return (
		<div className="w-full bg-gray-200 rounded-full h-1.5">
			<div
				className={`bg-indigo-600 h-1.5 rounded-full`}
				style={{ width: `${progress}%` }}
			></div>
		</div>
	);
}
