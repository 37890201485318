import { classNames } from '../../utils';
import { Menu } from '@headlessui/react';
import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import { ChevronDownIcon, BarsArrowUpIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { sendGoogleEvent } from '../../utils/analytics';
import ProjectListItem from '../ProjectListItem';
import { PROJECT_STATUSES, SORTING_OPTIONS } from '../../constants';
import Pagination from '../Pagination/index';
import { useDispatch, useSelector } from 'react-redux';

export default function ProjectList({
	projects,
	totalProjectsLength,
	currentPage,
	setCurrentPage,
	ordering,
	setOrdering,
	status,
	setStatus,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const currentLanguage = localStorage.getItem('i18nextLng');
	const isPaginationFetching = useSelector(
		state => state.project.getAllProjectsData.fetch,
	);

	const startIndex =
		(currentPage - 1) * (+process.env.REACT_APP_PROJECT_LIMIT || 15);
	const endIndex = startIndex + (+process.env.REACT_APP_PROJECT_LIMIT || 15);

	useEffect(() => {
		currentLanguage && currentLanguage === 'tr-TR'
			? moment.locale('tr')
			: moment.locale('en-gb');
	}, [currentLanguage]);

	return (
		<>
			<div className="pl-4 pt-4 pr-6 pb-4 border-b border-t border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-0 xl:border-t-0">
				<div className="flex items-center">
					<div className="flex flex-col w-full">
						<h1 className="flex-1 text-lg font-medium">{t('Projects')}</h1>
						<span className="text-sm text-gray-500 font-medium">
							{t('Showing {{projectCount}} projects', {
								projectCount: projects.length,
							})}
						</span>
					</div>
					<Menu as="div" className="relative mr-3">
						<Menu.Button className="w-max bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth={1.5}
								stroke="currentColor"
								className="mr-2 h-5 w-5 text-gray-400"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
								/>
							</svg>
							{t(PROJECT_STATUSES[status].label)}
							<ChevronDownIcon
								className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</Menu.Button>
						<Menu.Items className="origin-top-right z-40 absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								{Object.keys(PROJECT_STATUSES).map((item, index) => (
									<Menu.Item key={item + index}>
										{({ active }) => (
											<button
												onClick={() => {
													dispatch(setStatus(PROJECT_STATUSES[item].value));

													sendGoogleEvent('project_list_filter', {
														filter: item,
													});
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm w-full text-left',
												)}
											>
												{t(PROJECT_STATUSES[item].label)}
											</button>
										)}
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Menu>

					<Menu as="div" className="relative">
						<Menu.Button className="w-max bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
							<BarsArrowUpIcon
								className="mr-3 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
							{t(SORTING_OPTIONS[ordering.option].label)}
							<ChevronDownIcon
								className="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
								aria-hidden="true"
							/>
						</Menu.Button>
						<Menu.Items className="origin-top-right z-50 absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div className="py-1">
								{Object.keys(SORTING_OPTIONS).map((item, index) => (
									<Menu.Item key={item + index}>
										{({ active }) => (
											<button
												onClick={() => {
													dispatch(
														setOrdering({
															label: SORTING_OPTIONS[item].label,
															option: SORTING_OPTIONS[item].option,
															value: SORTING_OPTIONS[item].value,
														}),
													);
													sendGoogleEvent('project_list_filter', {
														filter: item,
													});
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900'
														: 'text-gray-700',
													'block px-4 py-2 text-sm w-full text-left',
												)}
											>
												{t(SORTING_OPTIONS[item].label)}
											</button>
										)}
									</Menu.Item>
								))}
							</div>
						</Menu.Items>
					</Menu>
				</div>
			</div>
			<ul
				role="list"
				className="relative divide-y divide-gray-200 border-b border-gray-200"
			>
				{projects.map((project, ind) => (
					<ProjectListItem key={project.nanoid} ind={ind} project={project} />
				))}
			</ul>
			{totalProjectsLength > 0 && (
				<Pagination
					className="mt-4"
					currentPage={currentPage}
					startIndex={startIndex + 1}
					endIndex={
						endIndex <= totalProjectsLength ? endIndex : totalProjectsLength
					}
					totalItemCount={totalProjectsLength}
					handlePageChange={page => dispatch(setCurrentPage(page))}
					textSize="small"
					itemName={t('paginationItem.projects')}
					previousBtnText={t('previousPage')}
					nextBtnText={t('nextPage')}
					fetching={isPaginationFetching}
				/>
			)}
			{projects.length === 0 && status === 'all' && (
				<div className="text-center mt-10">
					<svg
						className="mx-auto h-12 w-12 text-gray-400"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						aria-hidden="true"
					>
						<path
							vectorEffect="non-scaling-stroke"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
						/>
					</svg>
					<h3 className="mt-2 text-sm font-medium text-gray-900">
						{t('projectList.noProjects.heading')}
					</h3>
					<p className="mt-1 text-sm text-gray-500">
						{t('projectList.noProjects.description')}
					</p>
					<div className="mt-6">
						<Link
							to={'/project/create'}
							className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						>
							<PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
							{t('New Project', { context: 'button' })}
						</Link>
					</div>
				</div>
			)}
			{projects.length <= 0 && status !== 'all' && (
				<div className="text-center mt-10">
					<div className="flex justify-around">
						<MagnifyingGlassIcon className=" text-center w-12 h-12 text-gray-400" />
					</div>

					<h3 className="mt-2 text-sm font-medium text-gray-900">
						{t('projectList.noProjectsMatchingFilter.heading')}
					</h3>
					<p className="mt-1 text-sm text-gray-900">
						{t('projectList.noProjectsMatchingFilter.description', {
							status: t(PROJECT_STATUSES[status].label),
						})}
					</p>
				</div>
			)}
		</>
	);
}
