export default function PersonalInformationProcessing() {
	return (
		<div>
			<div className="mt-3 sm:mt-5 ">
				<div className="text-sm text-gray-900">
					<div>
						<b>
							Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
							Ticaret Anonim Şirketi (“Şirket”)
						</b>{' '}
						olarak, işbu Aydınlatma Metni ile, 6698 sayılı Kişisel Verilerin
						Korunması Kanunu (“<b>Kanun</b>”) ve Aydınlatma Yükümlülüğünün
						Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ
						kapsamında aydınlatma yükümlüğümüzün yerine getirilmesi
						amaçlanmaktadır.
						<p>
							Bu kapsamda bilgi vermekle yükümlü olduğumuz konular aşağıdaki
							gibidir:
						</p>
					</div>
					<h3 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
						1. Veri sorumlusunun ve varsa temsilcisinin kimliği
					</h3>
					<div>
						Veri sorumlusu; Esentepe Mahallesi Büyükdere Cad. Loft Residance
						Apt. No: 201/122 Şişli/İstanbul adresinde mukim, İstanbul Ticaret
						Sicili Müdürlüğü’ne 45256-5 sicil numarası ile kayıtlı{' '}
						<b>
							Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
							Ticaret Anonim Şirketi
						</b>
						’dir.
					</div>
					<h3 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
						2. Kişisel verilerin hangi amaçla işleneceği
					</h3>
					<div className="mb-2">
						Ad, soyad, telefon numarası, e-posta adresi, fatura adres bilgisi,
						teslimat adres bilgisi ve bunlarla sınırlı olmamak üzere Şirket’e
						ait <u>https://www.sorbunu.com/</u> Sorbunu internet sitesi
						üzerinden veya çağrı merkezi aracılığıyla iletmiş olduğunuz{' '}
						<b>kategorilerdeki kişisel verileriniz</b>, internet sitesinde
						üyeliğinizin oluşturulması, siparişinizin alınması ve yerine
						getirilmesi, sipariş talebinize istinaden sizinle iletişime
						geçilmesi, bu doğrultuda tarafınıza mal, ürün veya hizmet
						sağlanması, müşteri ilişkilerinde sağlıklı ve uzun süreli etkileşim
						kurulması, mal, ürün ve hizmet satın alım süreçlerinin yürütülmesi,
						müşteri ilişkileri yönetimi süreçlerinin yürütülmesi, müşteri
						memnuniyetine ilişkin aktivitelerin yürütülmesi, talep ve
						şikayetlerinizin takibi ile ilerde oluşabilecek uyuşmazlık ve
						sorunların çözülmesi, mevzuattan kaynaklanan zamanaşımı süresi
						doğrultusunda bu kişisel verilerinizin Şirketimiz tarafından
						saklanması ve <b>onay vermeniz halinde</b> tarafınıza ticari
						elektronik ileti gönderilmesi <b>amaçları ile</b> işlenmektedir.
					</div>
					<div>
						Ayrıca, internet sitesini ziyaretiniz ve kullanımınız sırasında
						internet sayfası sunucusu tarafından sabit sürücünüze iletilen küçük
						metin dosyaları (<b>Çerezler</b>) aracılığıyla elde edilen
						kullanılan tarayıcı, IP adresi, internet bağlantınız, site
						kullanımlarınız hakkındaki bilgiler, bilgisayarınızdaki işletim
						sistemi ve benzeri <b>kategorilerdeki kişisel verileriniz</b>,
						internet sitesinin düzgün bir şekilde çalışabilmesi, ziyaret
						edilebilmesi ve özelliklerinden faydalanılması, işlem güvenliğinin
						sağlanması, internet sitesinde sayfalar arasında bilgileri
						taşıyabilmek ve bilgileri tekrardan girmek zorunluluğunu ortadan
						kaldırmak ile <b>onay vermeniz halinde</b> sayfaların ziyaret
						sıklığı, varsa ilgili hata iletileri, sayfalarda geçirilen zaman ve
						internet sitesini kullanma şeklinizle ilgili bilgileri
						toplayabilmek, internet sitesinin performansının arttırılması, site
						içinde yapmış olduğunuz seçeneklerin hatırlanması ve böylelikle
						tarafınıza kolaylık sağlanması, internet sitesinin ve reklamların
						ilgi alanlarınıza ve tercihlerinize göre düzenlemesi ve gelişmiş
						internet özelliklerinden yararlanmanız <b>amaçları ile</b>{' '}
						işlenmektedir.
					</div>
					<h3 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
						3. Şirket tarafından işlenen kişisel verilerin kimlere ve hangi
						amaçla aktarılabileceği
					</h3>
					<div>
						Kişisel verileriniz 2. maddede belirtilen amaçların yerine
						getirilebilmesi için Şirket’in iş ortakları, hizmet aldığı şirketler
						ile talep edildiği takdirde yetkili kamu kurum ve kuruluşlarına
						aktarılabilecektir.
					</div>
					<h3 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
						4. Kişisel veri toplamanın yöntemi ve hukuki sebebi
					</h3>
					<div>
						Şirketimizin internet sitesi veya çağrı merkezi aracılığıyla,
						tamamen veya kısmen otomatik yollarla elde edilen kişisel
						verileriniz, kanunlarda açıkça öngörülmesi, Şirketimiz ile aranızda
						sözleşmenin kurulması ve ifasıyla doğrudan doğruya ilgili olması
						sebebiyle tarafınıza ait kişisel verilerin işlenmesinin gerekli
						olması ve açık rızanız <b>hukuki sebepleri</b> ile toplanmaktadır.
					</div>
					<h3 className="mt-4 mb-4 text-lg font-medium leading-6 text-gray-900">
						5. Kişisel verileriniz ile ilgili Kanun kapsamındaki haklarınız
						aşağıdaki şekildedir:
					</h3>
					<div>
						<b>(a)</b> Kişisel verilerinizin işlenip işlenmediğini öğrenme,{' '}
						<b>(b)</b> Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
						etme, <b>(c)</b> Kişisel verilerinizin işlenme amacını ve bunların
						amacına uygun kullanılıp kullanılmadığını öğrenme, <b>(ç)</b> Yurt
						içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
						kişileri bilme, <b>(d)</b> Kişisel verilerinizin eksik veya yanlış
						işlenmiş olması hâlinde bunların düzeltilmesini isteme, <b>(e)</b>{' '}
						Kişisel verilerinizin işlenmesini gerektiren sebeplerin ortadan
						kalkması halinde kişisel verilerinizin silinmesini veya yok
						edilmesini isteme, <b>(f)</b> (d) ve (e) bentleri uyarınca yapılan
						işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere
						bildirilmesini isteme, <b>(g)</b> İşlenen verilerin münhasıran
						otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin
						kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,{' '}
						<b>(ğ)</b> Kişisel verilerin kanuna aykırı olarak işlenmesi
						sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.
					</div>
					<div>
						Bu haklarınızı; kimliğinizi tevsik edici belgeler ve talebinizi
						içeren dilekçeniz, Esentepe Mahallesi Büyükdere Cad. Loft Residance
						Apt. No: 201/122 Şişli/İstanbul adresine yazılı olarak bizzat elden
						iletebilir veya güvenli elektronik imza, mobil imza ya da Şirket’e
						daha önce bildirilen ve Şirket’in sisteminde kayıtlı bulunan
						elektronik posta adresinizi kullanmak suretiyle (Bu kapsamda{' '}
						<u>info@sorbunu.com</u> e-posta adresi üzerinden Şirket’e
						ulaşabilirsiniz) veya başvuru amacına yönelik geliştirilmiş bir
						yazılım ya da uygulama vasıtasıyla Şirket’e iletebilirsiniz.
					</div>
					<div>Bilginize sunarız.</div>
					<h3 className="mt-4 mb-4 font-medium leading-6 text-gray-900">
						Denebunu Bilgi Teknolojileri Reklam Hizmetleri Pazarlama Sanayi Ve
						Ticaret Anonim Şirketi
					</h3>
				</div>
			</div>
		</div>
	);
}
