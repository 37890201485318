import thunk from 'redux-thunk';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import addressReducer from './addressReducer';
import projectReducer from './projectSlice';
import projectEditorReducer from './projectEditorSlice';
import analysisReducer from './analysisSlice';
import userProfileReducer from './userProfileSlice';
import activitiesReducer from './activitiesSlice';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({ history: createBrowserHistory() });

const sagaMiddleware = createSagaMiddleware();

const combinedReducers = combineReducers({
	router: routerReducer,
	auth: authReducer,
	activities: activitiesReducer,
	project: projectReducer,
	projectEditor: projectEditorReducer,
	analysis: analysisReducer,
	addresses: addressReducer,
	userProfile: userProfileReducer,
});

const rootReducer = (state, action) => {
	if (action.type === 'auth/userLogOut') {
		state = undefined;
	}
	return combinedReducers(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	middleware: [thunk, routerMiddleware, sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export default store;
