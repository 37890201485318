import moment from 'moment';
import { LOCAL_STORAGE_COMMENTS } from '../constants';
import { getItemFromLocalStorage, saveToLocalStorage } from '../utils';

export function setCommentsLastRead(projectNanoId) {
	let commentsLocalStorage = getItemFromLocalStorage(LOCAL_STORAGE_COMMENTS);
	if (!commentsLocalStorage) {
		commentsLocalStorage = {
			projects: {},
		};
		commentsLocalStorage.projects[projectNanoId] = {
			lastCommentsRead: null,
		};
	} else if (
		!(
			commentsLocalStorage.projects &&
			commentsLocalStorage.projects[projectNanoId] &&
			commentsLocalStorage.projects[projectNanoId].lastCommentsRead
		)
	) {
		commentsLocalStorage['projects'][projectNanoId] = {
			lastCommentsRead: null,
		};
	}

	commentsLocalStorage.projects[projectNanoId].lastCommentsRead = moment();
	saveToLocalStorage(LOCAL_STORAGE_COMMENTS, commentsLocalStorage);
}

export function getCommentsLastRead(projectNanoId) {
	const commentsLocalStorage = getItemFromLocalStorage(LOCAL_STORAGE_COMMENTS);

	if (
		commentsLocalStorage &&
		commentsLocalStorage.projects &&
		commentsLocalStorage.projects[projectNanoId] &&
		commentsLocalStorage.projects[projectNanoId].lastCommentsRead
	) {
		return moment(
			commentsLocalStorage.projects[projectNanoId].lastCommentsRead,
		);
	}
	return null;
}
