import { Handle, Position } from 'reactflow';
import { QUESTION_META_DATA } from '../../constants';
import QuestionIcon from '../QuestionIcon';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';

export default function QuestionNode({ data }) {
	const { t } = useTranslation();

	return (
		<div className="flex">
			<div
				className={classNames(
					QUESTION_META_DATA[data.question_type].bgColor,
					'flex items-center justify-between px-3 w-16 border-1 text-white text-sm font-medium rounded-l-md shrink-0',
				)}
			>
				<QuestionIcon type={data.question_type} />
				<span className="font-mono">{data.index}</span>
			</div>
			<div
				className={classNames(
					data.active
						? QUESTION_META_DATA[data.question_type].borderColor
						: 'border-gray-200',
					'flex-1 flex items-center justify-between border-l-0 border-2 bg-white rounded-r-md  w-56',
				)}
			>
				<div className="flex-1 p-2 text-sm w-36truncate">
					<div className="flex-1 p-2 text-sm w-36 truncate">
						<div className="text-gray-900 font-medium">
							{data.title && data.title}
							{!data.title && <>&nbsp;</>}
						</div>

						<p className="text-gray-500 flex flex-auto">
							{t(QUESTION_META_DATA[data.question_type].title)}
						</p>
					</div>
				</div>
			</div>
			{data.index !== 1 && (
				<Handle type="target" position={Position.Left} id="a" />
			)}
			<Handle type="source" position={Position.Right} id="b" />
		</div>
	);
}
