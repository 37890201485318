import { classNames } from '../../utils';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';
import TabDropdown from '../TabDropdown';

export const CustomTabs = ({ customArray, setSelectedTabs, selectedTab }) => {
	const questionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.hasErrors,
	);
	const screeningQuestionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionnaireHasErrors,
	);

	const audienceQuotaErrors = useSelector(
		state => state.projectEditor.audience.errors,
	);

	const remainingTotal = useSelector(
		state => state.projectEditor.transactions.remainingTotal,
	);

	const conditionChecks = ({ tab }) => {
		return (
			(tab === 'questionnaire' && questionnaireHasErrors) ||
			(tab === 'audience' && screeningQuestionnaireHasErrors) ||
			(tab === 'checkout' && remainingTotal > 0)
		);
	};
	return (
		<div className="relative z-0 inline-flex shadow-sm rounded-md ">
			{/* **********dropdown************** */}
			<TabDropdown
				tabs={customArray}
				setCurrentTab={setSelectedTabs}
				currentTab={selectedTab}
				conditionChecks={conditionChecks}
			/>
			{/* **********dropdown************** */}

			<span className="hidden xl:flex">
				{customArray.map((tab, index) => (
					<button
						key={index}
						onClick={() => setSelectedTabs(tab.value)}
						className={classNames(
							selectedTab === tab.value
								? 'bg-indigo-600 text-white hover:bg-indigo-500'
								: 'bg-white text-gray-700 hover:bg-gray-50',
							`relative flex items-center px-3.5 py-2 border text-sm  ${
								index === 0 ? 'rounded-l-md border' : ''
							} ${
								index === customArray.length - 1 ? 'rounded-r-md border' : ''
							} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`,
						)}
					>
						{tab.iconStart}
						<div className="mx-1">
							{(tab.value === 'questionnaire' && questionnaireHasErrors) ||
							(tab.value === 'audience' &&
								(screeningQuestionnaireHasErrors ||
									audienceQuotaErrors.length > 0)) ||
							(tab.value === 'checkout' && remainingTotal > 0) ? (
								<div className="flex">
									<ExclamationCircleIcon
										className={classNames(
											selectedTab === tab.value
												? 'text-white'
												: 'text-gray-500',
											'h-5 w-5 pr-1 -ml-1',
										)}
										aria-hidden="true"
									/>
									{tab.label}
								</div>
							) : (
								tab.label
							)}
						</div>
						{tab.iconEnd}
					</button>
				))}
			</span>
		</div>
	);
};
