import { Menu, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TotalCost from '../../../components/TotalCost/TotalCost';
import SlideOver from '../../../components/SlideOver';
import BillDetails from '../../../components/BillDetails';
import AnalysisPage from '../../projects/AnalysisPage';
import AudiencePage from '../AudiencePage';
import CommentPoster from '../../../components/CommentPoster';
import CommentList from '../../../components/CommentList';
import { CustomTabs } from '../../../components/CustomTab';
import { PROJECT_STATUSES } from '../../../constants';
import CheckoutPage from '../CheckoutPage';
import { useTranslation } from 'react-i18next';
import FeasibilityIndicator from '../../../components/FeasibilityIndicator';
import CommentButton from '../../../components/CommentButton';
import ProjectSubmitButton from '../../../components/ProjectSubmitButton';
import { classNames } from '../../../utils';
import Badge from '../../../components/Badge';
import QuestionnairePage from '../QuestionnairePage';
import { setRemainingTotal } from '../../../store/projectEditorSlice';
import SubscriptionDetails from '../../../components/SubscriptionDetails';

export default function ProjectEditorPage() {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { nanoid, tab } = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [isCommentsOpen, setCommentsOpen] = useState(false);
	const [title, setTitle] = useState();
	const [isEditable, setIsEditable] = useState(false);
	const [showInput, setShowInput] = useState(false);
	const [fetchFirstTime, setFetchFirstTime] = useState(true);
	const [showDetails, setShowDetails] = useState(false);
	const [showSubscriptionDetails, setShowSubscriptionDetails] = useState(false);

	const transactions = useSelector(state => state.projectEditor.transactions);
	const costData = useSelector(state => state.projectEditor.cost.data);
	const audienceNanoId = useSelector(
		state => state.projectEditor.audience.nanoid,
	);

	const project = useSelector(state => state.projectEditor.project);

	useEffect(() => {
		if (
			location.pathName === `/project/${nanoid}/checkout` &&
			project.payment_mode === 'subscription'
		) {
			navigate(`/project/${nanoid}/audience`);
		}
	}, [navigate, project.payment_mode, nanoid, location.pathName]);

	useEffect(() => {
		if (project.status) {
			setTitle(project.title);
			setIsEditable(PROJECT_STATUSES[project.status].isEditable);
		}
	}, [project]);

	useEffect(() => {
		dispatch({
			type: 'projectEditor/loadProject',
			payload: { projectNanoId: nanoid },
		});
		if (fetchFirstTime) {
			dispatch({
				type: 'projectEditor/loadComments',
				payload: { projectNanoId: nanoid },
			});
			setFetchFirstTime(false);
		} else {
			const loadCommentsInterval = setInterval(() => {
				dispatch({
					type: 'projectEditor/loadComments',
					payload: { projectNanoId: nanoid },
				});
			}, 60000);
			return () => clearInterval(loadCommentsInterval);
		}
	}, [dispatch, fetchFirstTime, nanoid]);

	useEffect(() => {
		dispatch({
			type: 'projectEditor/calculateProjectCostRequest',
			payload: {
				projectNanoId: nanoid,
			},
		});
	}, [dispatch, nanoid]);

	useEffect(() => {
		if (costData) {
			const remaining = transactions.isPaidMode
				? costData.cost.raw_value - transactions.paid.sum
				: costData.cost.raw_value - transactions.provisions.sum;

			dispatch(setRemainingTotal(remaining));
		}
	}, [dispatch, transactions, costData]);

	useEffect(() => {
		// We make the first check to make sure the project editor state is successfully reset
		if (nanoid === project.nanoid && audienceNanoId) {
			dispatch({
				type: 'projectEditor/calculateProjectFeasibilityStart',
				payload: {
					projectNanoId: nanoid,
					audienceNanoId: audienceNanoId,
				},
			});
		}
	}, [dispatch, nanoid, audienceNanoId, project.nanoid]);

	const PageTabs = [
		{
			label: t('Audience'),
			value: 'audience',
		},
		{
			label: t('Questionnaire'),
			value: 'questionnaire',
		},
		{
			label: t('Checkout'),
			value: 'checkout',
		},
		{
			label: t('Analysis'),
			value: 'analysis',
		},
	];

	const filteredTabs = PageTabs.filter(tab => {
		if (project.payment_mode === 'subscription') {
			return tab.value !== 'checkout';
		}
		return true;
	});

	const tabExists =
		filteredTabs.findIndex(pageTab => pageTab.value === tab) != -1;

	const handleTabChange = newTab => {
		navigate(`/project/${nanoid}/${newTab}`);
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="w-full h-14 bg-white shadow-sm z-30 flex justify-between items-center relative">
					<div className=" flex basis-3/12 ml-1.5 mr-4 sm:ml-4 sm:basis-4/12  lg:basis-2/12 justify-start group">
						{!showInput && (
							<div className="flex justify-center items-center">
								<div
									className={classNames(
										isEditable ? 'cursor-pointer' : '',
										'ml-3 text-sm text-gray-800 line-clamp-2 max-w-sm',
									)}
									onClick={() => {
										if (!isEditable) return;
										setShowInput(true);
									}}
									title={project.title}
								>
									{project.title}
								</div>
								{project.status && (
									<div className=" grow-0">
										<Badge
											className="ml-2 "
											type={PROJECT_STATUSES[project.status].badgeType}
										>
											{t(PROJECT_STATUSES[project.status].label)}
										</Badge>
									</div>
								)}
							</div>
						)}
						{showInput && (
							<input
								autoFocus
								className="border border-gray-400 rounded-md p-0.5 pl-1.5 text-sm"
								value={title}
								onChange={e => setTitle(e.target.value)}
								onBlur={() => {
									title !== project.title &&
										dispatch({
											type: 'projectEditor/changeProjectNameStarted',
											payload: {
												nanoid,
												title,
											},
										});
									setShowInput(false);
								}}
							/>
						)}
					</div>
					<div className="flex basis-8/12 lg:basis-10/12 pl-2 md:pl-12 lg:pl-2 justify-between  ">
						<CustomTabs
							customArray={filteredTabs}
							setSelectedTabs={handleTabChange}
							selectedTab={tabExists ? tab : filteredTabs[0].value}
						/>

						<div className="hidden lg:flex justify-end mr-4 2xl:mr-8">
							<CommentButton setCommentsOpen={setCommentsOpen} />
							{project.status !== 'completed' && <FeasibilityIndicator />}
							<div className="mr-3">
								<TotalCost
									project={project}
									data={costData}
									showDetails={showDetails}
									setShowDetails={setShowDetails}
									showSubscriptionDetails={showSubscriptionDetails}
									setShowSubscriptionDetails={setShowSubscriptionDetails}
								/>
							</div>
							{((project.subscription &&
								(project.status === 'draft' ||
									project.status === 'under_edit')) ||
								project.status === 'under_edit' ||
								((project.status === 'draft' ||
									project.status === 'under_edit') &&
									transactions.paymentMode === 'purchase_order')) && (
								<ProjectSubmitButton
									nanoid={nanoid}
									subscription={project.subscriptionDetail}
								/>
							)}
						</div>
					</div>
					{/* ************dropdown*************** */}
					<div className="absolute right-0 flex justify-end  sm:pr-2 ">
						<Menu
							as="div"
							className=" relative inline-block lg:hidden text-left"
						>
							<div>
								<Menu.Button className="mr-2 flex items-center p-2 bg-white text-gray-600 rounded-md  hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white">
									<span className="sr-only">Open options</span>
									<Bars3Icon className="h-6 w-6" aria-hidden="true" />
								</Menu.Button>
							</div>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items className="absolute right-0 z-50 mt-2 w-48 divide-y divide-gray-100 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
									<div className="py-1 pl-3">
										<Menu.Item>
											<div>
												<CommentButton setCommentsOpen={setCommentsOpen} />
											</div>
										</Menu.Item>
									</div>
									{project.status !== 'completed' && (
										<div className="py-1 pl-3">
											<Menu.Item>
												<>
													<FeasibilityIndicator />
												</>
											</Menu.Item>
										</div>
									)}
									<div className="py-1 z-0 flex pl-3">
										<Menu.Item>
											<>
												<TotalCost
													project={project}
													data={costData}
													showDetails={showDetails}
													setShowDetails={setShowDetails}
													showSubscriptionDetails={showSubscriptionDetails}
													setShowSubscriptionDetails={
														setShowSubscriptionDetails
													}
												/>
											</>
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>

						{/* **********************************dropdown end********************* */}
					</div>

					<SlideOver
						title={t('projectEditor.slideOver.costDetailTitle')}
						description={t('projectEditor.slideOver.costDetailDescription')}
						open={showDetails}
						setOpen={setShowDetails}
					>
						<BillDetails data={costData} />
					</SlideOver>
					{project.payment_mode === 'subscription' && (
						<SlideOver
							title={t('subscription.slideOver.title')}
							description={t('subscription.slideOver.description')}
							open={showSubscriptionDetails}
							setOpen={setShowSubscriptionDetails}
						>
							<SubscriptionDetails subscription={project.subscription} />
						</SlideOver>
					)}
				</div>
				<div className="flex">
					{(tab === 'audience' || !tabExists) && (
						<AudiencePage
							isEditable={isEditable}
							subscription={project.subscriptionDetail}
						/>
					)}

					{tab === 'questionnaire' && (
						<QuestionnairePage isEditable={isEditable} />
					)}

					{tab === 'checkout' && <CheckoutPage isEditable={isEditable} />}
					{tab === 'analysis' && (
						<>
							<div className="w-screen h-screen items-center">
								<AnalysisPage />
							</div>
						</>
					)}
				</div>
			</div>
			<SlideOver
				title={t('projectEditor.Comments')}
				description={t('projectEditor.slideOver.commentsDescription')}
				open={isCommentsOpen}
				setOpen={setCommentsOpen}
				footerComponent={<CommentPoster nanoid={nanoid} />}
			>
				<CommentList />
			</SlideOver>
		</>
	);
}
