import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAddressList() {
	const result = await sorbunuApi.get(endPoints.addresses.ADDRESS);
	return result.data;
}

export async function getAddress({ addressNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.addresses.ADDRESS_DETAIL(addressNanoId),
	);
	return result.data;
}

export async function createAddress({ addressPayload }) {
	const result = await sorbunuApi.post(
		endPoints.addresses.ADDRESS,
		addressPayload,
	);
	return { status: true, data: result.data };
}

export async function updateAddress({ addressNanoId, addressPayload }) {
	const result = await sorbunuApi.put(
		endPoints.addresses.ADDRESS_DETAIL(addressNanoId),
		addressPayload,
	);
	return { status: true, data: result.data };
}

export async function deleteAddress({ addressNanoId }) {
	const result = await sorbunuApi.delete(
		endPoints.addresses.ADDRESS_DETAIL(addressNanoId),
	);
	return { status: true, data: result.data };
}
