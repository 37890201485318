import { BiDetail } from 'react-icons/bi';
import Spinner from '../Spinner';
import { sendGoogleEvent } from '../../utils/analytics';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';

export default function TotalCost({
	project,
	data,
	showDetails,
	setShowDetails,
	showSubscriptionDetails,
	setShowSubscriptionDetails,
}) {
	const { t } = useTranslation();

	const showDetailsButtonClicked = () => {
		if (project.payment_mode === 'payg') {
			setShowDetails(!showDetails);
			sendGoogleEvent('cost_details_open');
		} else if (project.payment_mode === 'subscription') {
			setShowSubscriptionDetails(!showSubscriptionDetails);
			sendGoogleEvent('subscription_details_open');
		}
	};
	return (
		<div>
			<div className="flex rounded-md shadow-sm">
				<>
					<div className="relative flex items-stretch flex-grow focus-within:z-10">
						<div
							className={classNames(
								project.payment_mode === 'payg'
									? 'font-mono'
									: 'font-medium text-gray-700',
								'flex items-center justify-center border border-gray-300 rounded-none rounded-l-md p-2 sm:text-sm pointer-events-none',
							)}
						>
							{!data && project.payment_mode === 'payg' && (
								<span className="mx-4">
									<Spinner className={'w-4 h-4'} />
								</span>
							)}
							{project.payment_mode === 'payg' && (
								<>
									{data && (
										<>
											<span title={data.cost.currency}>
												{data && getSymbolFromCurrency(data.cost.currency)}
											</span>
											<span>{data && data.cost.value}</span>
										</>
									)}
								</>
							)}
							{project.payment_mode === 'subscription' && project.subscription && (
								<span>
									{t('projectList.paymentModes.subscription')}:{' '}
									{project.subscription.plan.name}
								</span>
							)}
						</div>
					</div>
					<button
						type="button"
						disabled={!data}
						className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
						onClick={showDetailsButtonClicked}
					>
						<BiDetail className="h-5 w-5 text-indigo-700" aria-hidden="true" />
					</button>
				</>
			</div>
		</div>
	);
}
