import { getInitialNameLetter } from '../../pages/Account/ProfilePage/constant';
import { classNames } from '../../utils';

export default function ProfilePicture({
	firstName,
	lastName,
	companyName,
	profilePicture,
	light,
	size,
}) {
	return (
		<div
			className={classNames(
				size === 'xxs' ? 'w-5 h-5' : '',
				size === 'xs' ? 'w-6 h-6' : '',
				size === 'mid' ? 'w-12 h-12' : '',
				size !== 'xxs' && size !== 'xs' && size !== 'mid' ? 'w-8 h-8' : '',
			)}
			title={`${firstName} ${lastName}${
				companyName ? ` (${companyName})` : ''
			}`}
		>
			{!profilePicture && (
				<div
					className={classNames(
						size === 'xxs' ? 'w-5 h-5' : '',
						size === 'xs' ? 'w-6 h-6' : '',
						size === 'mid' ? 'w-12 h-12' : '',
						size !== 'xxs' && size !== 'xs' && size !== 'mid' ? 'w-8 h-8' : '',
						light
							? 'bg-gray-100 text-gray-700 border'
							: 'bg-gray-700 text-white',
						'rounded-full flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white',
					)}
				>
					<p
						className={classNames(
							size === 'xxs' ? 'text-[8.5px]' : '',
							size === 'xs' ? ' text-xs' : '',
							size === 'mid' ? 'text-xl' : '',
							size !== 'xxs' && size !== 'xs' && size !== 'mid'
								? 'text-sm'
								: '',
							'w-full text-center',
						)}
					>
						{getInitialNameLetter(firstName, lastName)}
					</p>
				</div>
			)}
			{profilePicture && (
				<img
					className="w-full h-full rounded-full"
					src={profilePicture}
					alt=""
				/>
			)}
		</div>
	);
}
