export function Logo(props) {
	return (
		<svg
			version="1.0"
			xmlns="http://www.w3.org/2000/svg"
			width="2156.000000pt"
			height="476.000000pt"
			viewBox="0 0 2156.000000 476.000000"
			preserveAspectRatio="xMidYMid meet"
			{...props}
		>
			<g
				transform="translate(0.000000,476.000000) scale(0.100000,-0.100000)"
				fill={props.color ? props.color : '#4F46E5'}
				stroke="none"
			>
				<path
					d="M11165 4368 c-3 -7 -4 -717 -3 -1578 l3 -1565 343 -3 342 -2 0 95 c0
					52 3 95 6 95 3 0 30 -21 59 -48 183 -163 485 -236 768 -187 528 92 907 583
					907 1174 0 360 -130 677 -372 907 -129 122 -260 198 -435 250 -70 21 -116 27
					-228 31 -275 10 -466 -49 -645 -199 l-60 -50 0 546 0 546 -340 0 c-263 0 -342
					-3 -345 -12z m1331 -1489 c187 -34 340 -178 388 -365 19 -72 21 -229 5 -306
					-55 -264 -308 -429 -595 -388 -210 30 -368 170 -421 373 -21 81 -21 233 0 314
					72 276 324 426 623 372z"
				/>
				<path
					d="M2642 4321 c-64 -41 -62 -10 -62 -881 0 -655 2 -796 14 -823 8 -18
					252 -269 572 -589 610 -608 591 -591 679 -567 44 12 92 59 181 176 230 304
					347 688 331 1087 -13 319 -106 607 -284 876 -102 153 -304 357 -450 454 -260
					172 -547 270 -828 282 -110 5 -123 4 -153 -15z"
				/>
				<path
					d="M1740 4185 c-632 -99 -1153 -499 -1397 -1075 -180 -423 -198 -868
					-52 -1305 224 -673 825 -1152 1538 -1226 137 -14 370 -7 492 16 360 65 705
					239 940 474 66 65 78 83 89 129 10 43 10 62 0 100 -12 43 -55 89 -571 607
					l-558 560 -3 790 c-3 787 -3 790 -25 830 -27 50 -58 80 -105 100 -47 19 -225
					19 -348 0z m60 -1118 c0 -625 2 -709 16 -753 15 -44 67 -99 515 -549 275 -275
					499 -504 499 -507 0 -12 -142 -96 -240 -142 -114 -54 -240 -92 -375 -112 -133
					-21 -384 -13 -505 15 -250 58 -474 179 -663 361 -300 287 -450 650 -434 1055
					8 212 44 365 128 545 182 388 552 684 970 775 35 7 70 14 77 14 9 1 12 -146
					12 -702z"
				/>
				<path
					d="M5561 3519 c-298 -63 -502 -217 -594 -449 -34 -86 -47 -164 -47 -274
					0 -181 51 -307 170 -426 125 -124 247 -183 576 -280 114 -34 230 -71 256 -82
					89 -38 121 -106 77 -162 -41 -52 -73 -61 -209 -61 -122 0 -127 1 -192 33 -76
					37 -126 89 -164 169 -13 29 -27 53 -29 53 -8 0 -569 -320 -578 -330 -13 -12
					-1 -42 55 -127 172 -264 455 -404 843 -420 449 -17 802 145 935 429 49 105 64
					180 64 313 0 207 -48 331 -178 461 -129 128 -249 186 -584 278 -275 76 -332
					106 -332 176 0 76 55 110 177 110 119 0 200 -49 284 -171 l15 -22 292 157
					c331 179 306 150 229 256 -137 187 -351 320 -594 369 -135 28 -339 28 -472 0z"
				/>
				<path
					d="M7865 3526 c-239 -46 -447 -152 -615 -313 -111 -105 -171 -187 -240
					-328 -203 -412 -159 -892 115 -1257 167 -221 394 -367 685 -438 125 -31 380
					-35 507 -7 506 109 862 479 945 981 15 93 15 292 -1 386 -80 468 -414 832
					-879 956 -83 23 -122 27 -272 30 -119 2 -197 -1 -245 -10z m350 -671 c278 -73
					432 -367 349 -665 -45 -157 -154 -275 -310 -332 -48 -17 -81 -21 -174 -21 -93
					0 -126 4 -174 21 -173 64 -290 203 -323 387 -51 285 97 539 358 609 72 19 201
					20 274 1z"
				/>
				<path
					d="M17630 3534 c-88 -17 -193 -50 -255 -80 -71 -35 -195 -126 -195 -144
					0 -6 -4 -10 -10 -10 -6 0 -10 37 -10 90 l0 90 -345 0 -345 0 0 -1130 0 -1130
					345 0 345 0 0 653 c0 694 3 734 50 832 71 147 245 227 430 196 87 -14 141 -40
					198 -95 51 -48 85 -110 101 -184 7 -36 11 -266 11 -727 l0 -675 345 0 345 0 0
					739 c0 472 -4 767 -11 817 -44 319 -204 558 -454 679 -134 65 -186 76 -365 80
					-85 2 -166 1 -180 -1z"
				/>
				<path
					d="M10860 3523 c-249 -26 -462 -152 -558 -330 l-31 -58 -1 173 0 172
					-345 0 -345 0 0 -1130 0 -1130 345 0 345 0 0 543 c0 346 4 565 11 603 29 160
					140 288 304 349 77 29 205 46 293 38 l62 -6 -2 389 c-3 437 5 396 -78 387z"
				/>
				<path
					d="M13882 2688 l3 -793 27 -99 c106 -384 383 -613 769 -633 246 -13 471
					59 624 199 l55 50 0 -96 0 -96 343 2 342 3 0 1125 0 1125 -342 3 -343 2 0
					-624 c0 -646 -4 -733 -41 -833 -29 -75 -103 -153 -181 -189 -59 -27 -71 -29
					-188 -29 -107 0 -132 3 -172 22 -71 32 -141 103 -174 176 l-29 62 -5 705 -5
					705 -343 3 -343 2 3 -792z"
				/>
				<path
					d="M19013 2703 c3 -699 5 -786 21 -852 82 -349 272 -563 583 -658 79
					-24 104 -27 248 -27 135 0 173 3 243 22 147 40 255 97 339 182 l42 42 3 -94 3
					-93 343 -3 342 -2 0 1130 0 1130 -345 0 -345 0 0 -660 c0 -446 -4 -676 -11
					-712 -27 -127 -94 -220 -197 -270 -65 -32 -70 -33 -192 -33 -121 0 -128 1
					-192 33 -102 50 -164 136 -188 262 -6 32 -10 312 -10 716 l0 664 -346 0 -345
					0 4 -777z"
				/>
			</g>
		</svg>
	);
}
