import { classNames } from '../../utils';

export default function TourTooltip({
	index,
	isLastStep,
	size,
	step,
	primaryProps,
	skipProps,
	tooltipProps,
}) {
	return (
		<div {...tooltipProps} className="rounded shadow-lg overflow-hidden">
			<div className="bg-white w-96 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
				<div className="sm:flex sm:items-start">
					<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							{step.title}
						</h3>
						<div className="mt-2">
							<div className="md:flex md:gap-4">
								<p className="text-sm text-gray-500">{step.content}</p>
								{step.image && (
									<img
										src={step.image.src}
										className={classNames(
											'mx-auto md:mx-0 mt-4 md:mt-0',
											step.image.className,
										)}
									/>
								)}
							</div>
						</div>
						<div className="mt-2 md:hidden">
							<p className="text-sm text-gray-400">
								{index + 1} / {size}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-gray-50 sm:px-6 px-4 py-3 items-center md:flex md:justify-between">
				<p className="pl-4 text-gray-400 text-sm font-light hidden md:block">
					{index + 1} / {size}
				</p>
				<div className="sm:flex sm:flex-row-reverse ">
					<button
						type="button"
						className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
						{...primaryProps}
					>
						{primaryProps.title}
					</button>
					{!isLastStep && (
						<button
							type="button"
							className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
							{...skipProps}
						>
							{skipProps.title}
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
