import {
	Routes,
	Route,
	Navigate,
	Outlet,
	useLocation,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import SignInPage from './pages/AuthPages/SignIn';
import SignUpPage from './pages/AuthPages/SignUp';
import SignedInLayout from './pages/AuthPages/SignedInLayout';
import ForgotPasswordPage from './pages/AuthPages/ForgotPassword';
import VerifyEmailPage from './pages/AuthPages/SignUp/VerifyEmailPage';
import { signIn } from './store/authSlice';
import {
	AUTH_STORAGE_KEY,
	getItemFromLocalStorage,
	saveToLocalStorage,
} from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, Suspense, useCallback, useState } from 'react';
import ResetPassword from './pages/AuthPages/ResetPassword';
import CheckoutCallbackPage from './pages/projects/CheckoutCallbackPage';
import ToastArea from './components/ToastArea';
import { install } from 'ga-gtag';
import PaymentCompletePage from './pages/projects/PaymentCompletePage';
import i18n from 'i18next';

if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
	install(process.env.REACT_APP_GA_MEASUREMENT_ID, {
		cookie_flags: 'SameSite=None;Secure',
	});
}

const ProtectedRoute = ({
	isLoggedIn,
	redirectPath = '/sign-in',
	children,
}) => {
	const [searchParams] = useSearchParams();
	const language = searchParams.get('lang');

	const [path, setPath] = useState(redirectPath);

	//language parameter, if it exists, eventually turns to null,
	//here we catch its first value before turning to null
	useEffect(() => {
		const redirectedPath = language
			? `${redirectPath}?lang=${language}`
			: redirectPath;
		setPath(redirectedPath);
		//this code should only run once, so missing dependency error messages are disabled by the next line
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (!isLoggedIn) {
		return <Navigate to={path} replace />;
	}

	return children ? children : <Outlet />;
};

function App() {
	const isLoggedIn =
		useSelector(state => state.auth.isLoggedIn) ||
		!!getItemFromLocalStorage(AUTH_STORAGE_KEY)?.access_token;

	const authData = useSelector(state => state.auth.auth);
	const { user } = useSelector(state => state.auth);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const changeLanguage = useCallback(lng => {
		i18n.changeLanguage(lng);
	}, []);

	useEffect(() => {
		let auth;

		if (!authData && isLoggedIn) {
			auth = getItemFromLocalStorage(AUTH_STORAGE_KEY);
		}

		if (auth) {
			dispatch(signIn(auth));
		}
	}, [authData, dispatch, isLoggedIn]);

	useEffect(() => {
		if (location.pathname === '/') {
			navigate('/dashboard');
		}
	}, [location.pathname, navigate]);

	useEffect(() => {
		if (user) {
			saveToLocalStorage('i18nextLng', user.preferred_language);
			changeLanguage(user.preferred_language);
		}
	}, [user, changeLanguage]);

	useEffect(() => {
		const script = document.createElement('script');

		const forceRecordPaths = ['/sign-in', '/sign-up', '/forgot-password'];

		const recordCurrentPath =
			forceRecordPaths.findIndex(i => i === location.pathname) > -1 ||
			location.pathname.startsWith('/verify-email/') ||
			location.pathname.startsWith('/reset-password/');

		const doLoad =
			process.env.NODE_ENV === 'production' &&
			(recordCurrentPath || (user !== null && !user.is_staff));

		if (doLoad) {
			window._mfq = window._mfq || [];

			script.src =
				'//cdn.mouseflow.com/projects/1b22f5eb-2e90-4e25-a1e7-b4ccfc5a928a.js';
			script.type = 'text/javascript';
			script.defer = true;

			document.getElementsByTagName('head')[0].appendChild(script);
		}

		//if _mfq object exists(script has been loaded) and if the user is staff, stop the mouseflow recording
		if (window._mfq !== undefined && user && user.is_staff) {
			window._mfq.push(['stop']);
		}

		return () => {
			if (doLoad) {
				// clean up the script when the component in unmounted
				document.getElementsByTagName('head')[0].removeChild(script);
			}
		};
	}, [location, user]);

	return (
		<Suspense fallback="loading">
			<Routes>
				<Route path="/sign-in" element={<SignInPage />} />
				<Route path="/sign-up" element={<SignUpPage />} />
				<Route path="/forgot-password" element={<ForgotPasswordPage />} />
				<Route path="/" element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
					<Route path="checkout/callback" element={<CheckoutCallbackPage />} />
					<Route path="checkout/payment" element={<PaymentCompletePage />} />
					<Route path="*" element={<SignedInLayout />} />
				</Route>
				<Route path="/verify-email/:token" element={<VerifyEmailPage />} />
				<Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
			</Routes>
			<ToastArea />
		</Suspense>
	);
}

export default App;
