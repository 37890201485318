import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function createCheckoutForm({
	projectNanoId,
	addressNanoId,
	paymentType,
}) {
	const body = {
		address: addressNanoId,
		project: projectNanoId,
		payment_type: paymentType,
	};

	const result = await sorbunuApi.post(endPoints.checkout.CHECKOUT, body);
	return { status: true, data: result.data };
}

export async function getCheckoutContracts({
	projectNanoId,
	addressNanoId,
	paymentType,
}) {
	const body = {
		address: addressNanoId,
		project: projectNanoId,
		payment_type: paymentType,
	};

	const result = await sorbunuApi.post(
		endPoints.checkout.GET_CHECKOUT_CONTRACTS,
		body,
	);
	return result.data;
}

export async function getPromoCodes() {
	const result = await sorbunuApi.get(endPoints.checkout.GET_PROMO_CODES);

	return result.data;
}

export async function addPromoCode({ projectNanoId, promoCode }) {
	const result = await sorbunuApi.post(endPoints.checkout.ADD_PROMO_CODE, {
		project: projectNanoId,
		promo_code: promoCode,
	});
	return result.data;
}

export async function deletePromoCode({ projectNanoId, promoCode }) {
	const result = await sorbunuApi.delete(endPoints.checkout.DELETE_PROMO_CODE, {
		data: { project: projectNanoId, promo_code: promoCode },
	});
	return result.data;
}
