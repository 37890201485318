import { useMemo } from 'react';
import BarChart from './BarChart';
import { indexOf } from 'lodash';
import { formatNumberAsThousands } from '../../utils';
import { useTranslation } from 'react-i18next';
import CountTable from './CountTable';
import StatsTable from './StatsTable';

function autoBin(arr, binWidth, keys) {
	const newArr = arr.map(el => el.number);
	let min, max, numberOfBins;

	// Automatically calculate bin width if required
	if (binWidth === 'auto') {
		min = Math.floor(Math.min(...newArr));
		max = Math.ceil(Math.max(...newArr));
		// Estimate a reasonable number of bins (e.g., using the square root of array length)
		numberOfBins = Math.ceil(Math.sqrt(newArr.length));
		// Calculate and round the bin width
		binWidth = Math.ceil((max - min) / numberOfBins);
		// Adjust max to align with the rounded bin width
		max = min + binWidth * numberOfBins;
	} else {
		// If binWidth is a number, use it directly and adjust min and max
		binWidth = Number(binWidth);
		min = Math.floor(Math.min(...newArr) / binWidth) * binWidth;
		max = Math.ceil(Math.max(...newArr) / binWidth) * binWidth;
		numberOfBins = Math.ceil((max - min) / binWidth);
	}

	// Initialize bins
	const bins = [];
	for (let i = 0; i < numberOfBins; i++) {
		bins.push({
			lower: min + i * binWidth,
			upper: min + (i + 1) * binWidth,
			count: 0,
		});
	}

	// Place each number in the correct bin
	arr.forEach(num => {
		if (num.number >= min && num.number < max) {
			const binIndex = Math.floor((num.number - min) / binWidth);
			keys.forEach(key => {
				if (num.key === key) {
					bins[binIndex][key] = bins[binIndex][key] + 1 || 1;
				}
			});

			bins[binIndex].count++;
		}
	});

	return bins;
}

export default function NumberDistributionChart({
	binWidth,
	data,
	question,
	appliedComparisonItems,
	isCompareMode = false,
	comparison,
	keys,
	labels,
}) {
	const { t } = useTranslation();

	const formattedBins = useMemo(() => {
		const numbers = [];

		if (isCompareMode) {
			Object.keys(appliedComparisonItems).forEach(comparisonItem => {
				const item = appliedComparisonItems[comparisonItem];

				if (item.checked) {
					const comparisonArr = data.filter(d => {
						const dataType = typeof d[item.comparisonKey];
						if (dataType === 'string' || dataType === 'number') {
							// For single choice filter items
							return d[item.comparisonKey] == item.comparisonItemKey;
						} else if (dataType === 'object') {
							// For multiple choice filter items
							const innerDataType =
								d[item.comparisonKey][0] == null
									? 'string'
									: typeof d[item.comparisonKey][0];

							if (innerDataType === 'string') {
								//for multiple choice
								return (
									indexOf(d[item.comparisonKey], item.comparisonItemKey) > -1
								);
							} else if (innerDataType === 'object') {
								let foundItemIndex = d[item.comparisonKey].findIndex(
									answer =>
										answer.order == item.rankKey &&
										answer.choice === item.comparisonItemKey,
								);

								return foundItemIndex > -1;
							}
						}
					});

					const compareKey = item.rankKey
						? `${item.comparisonKey}_${item.comparisonItemKey}_${item.rankKey}`
						: `${item.comparisonKey}_${item.comparisonItemKey}`;

					comparisonArr.forEach(response => {
						numbers.push({
							number: response[question.nanoid],
							key: compareKey,
						});
					});
				}
			});
		} else {
			data.forEach(response => {
				numbers.push({ number: response[question.nanoid] });
			});
		}

		const bins = autoBin(numbers, binWidth, keys);
		let maxCount = 0;

		const _formattedBins =
			numbers.length === 0
				? []
				: bins.map(bin => {
						if (bin.count > maxCount) {
							maxCount = bin.count;
						}

						return {
							...bin,
							country: `${formatNumberAsThousands(
								bin.lower,
							)}-${formatNumberAsThousands(bin.upper)}`,
						};
				  });

		//create comparison table data
		const tempTableData = [];

		Object.keys(appliedComparisonItems).forEach(comparisonItem => {
			const compareTableChoices = [];
			const item = appliedComparisonItems[comparisonItem];

			if (item.checked) {
				const compareKey = item.rankKey
					? `${item.comparisonKey}_${item.comparisonItemKey}_${item.rankKey}`
					: `${item.comparisonKey}_${item.comparisonItemKey}`;

				_formattedBins.forEach(bin => {
					compareTableChoices.push({
						choiceTitle: bin.country,
						responseCount: bin[compareKey],
					});
				});
				tempTableData.push({
					answer: labels[compareKey],
					choices: compareTableChoices,
				});
			}
		});

		return {
			maxCount: maxCount,
			bins: _formattedBins,
			tableData: tempTableData,
		};
	}, [
		data,
		binWidth,
		question.nanoid,
		appliedComparisonItems,
		isCompareMode,
		keys,
		labels,
	]);

	return (
		<div className=" flex flex-col gap-4">
			<div className={'w-full h-96'}>
				<BarChart
					legend={t('analysis.questions.number.distributionChart.legend')}
					type="count"
					keys={isCompareMode ? keys : ['count']}
					groupMode="grouped"
					layout="vertical"
					data={formattedBins.bins}
					minValue={0}
					maxValue={formattedBins.maxCount}
					legendLabels={labels}
					isCompareMode={isCompareMode}
				/>
			</div>
			<StatsTable
				data={data}
				question={question}
				isCompareMode={isCompareMode}
				comparison={comparison}
				labels={labels}
			/>
			<CountTable
				firstColName={t('Bins')}
				secondColName={t('Distribution')}
				sortedRows={formattedBins.bins}
				compareRows={formattedBins.tableData}
				paginationItemName={t('paginationItem.bins')}
				isCompareMode={isCompareMode}
				textSize="small"
			/>
		</div>
	);
}
