import { useDispatch, useSelector } from 'react-redux';
import { Combobox } from '@headlessui/react';
import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils';
import { push } from 'redux-first-history';
import { startSearchProjects } from '../../store/projectSlice';
import { useTranslation } from 'react-i18next';

export default function ProjectSearch() {
	const dispatch = useDispatch();
	const projects = useSelector(state => state.project.search.projects);
	const { t } = useTranslation();

	return (
		<Combobox
			as="div"
			className="w-full"
			onChange={selectedProject =>
				selectedProject.status === 'completed' ||
				selectedProject.status === 'running'
					? dispatch(push(`/project/${selectedProject.nanoid}/analysis`))
					: dispatch(push(`/project/${selectedProject.nanoid}/audience`))
			}
		>
			<div className=" relative text-indigo-200 focus-within:text-gray-400">
				<Combobox.Input
					className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-indigo-400 bg-opacity-25 text-indigo-100 placeholder-indigo-200 focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
					onChange={event => {
						dispatch(
							startSearchProjects({
								searchString: event.target.value,
							}),
						);
					}}
					displayValue={project => project?.title}
					placeholder={t('Search projects')}
					type="search"
				/>
				<Combobox.Button className="absolute inset-y-0 left-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
				</Combobox.Button>

				{projects.length > 0 && (
					<Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						{projects.map(project => (
							<Combobox.Option
								key={project.nanoid}
								value={project}
								className={({ active }) =>
									classNames(
										'relative cursor-default select-none py-2 pl-3 pr-9',
										active ? 'bg-indigo-600 text-white' : 'text-gray-900',
									)
								}
							>
								{({ active, selected }) => (
									<>
										<span
											className={classNames(
												'block truncate',
												selected && 'font-semibold',
											)}
										>
											{project.title}
										</span>

										{selected && (
											<span
												className={classNames(
													'absolute inset-y-0 right-0 flex items-center pr-4',
													active ? 'text-white' : 'text-indigo-600',
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</Combobox.Option>
						))}
					</Combobox.Options>
				)}
			</div>
		</Combobox>
	);
}
