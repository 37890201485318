import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import Pulse from '../Pulse';
import Spinner from '../Spinner';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../utils/analytics';
import { useSelector } from 'react-redux';

export default function CommentButton({ setCommentsOpen }) {
	const { t } = useTranslation();
	const hasUnreadComments = useSelector(
		state => state.projectEditor.comments.hasUnread,
	);
	const commentsStatus = useSelector(
		state => state.projectEditor.comments.status,
	);
	return (
		<div className="relative inline-flex  xl:mr-3">
			<button
				type="button"
				className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
				onClick={() => {
					setCommentsOpen(true);
					sendGoogleEvent('comments_open');
				}}
			>
				{commentsStatus === 'idle' && (
					<ChatBubbleLeftRightIcon className="-ml-1 mr-2 h-5 w-5" />
				)}
				{commentsStatus === 'loading' && (
					<Spinner className="-ml-1 mr-2 h-5 w-5" />
				)}
				{t('Comments')}
			</button>
			{hasUnreadComments && (
				<span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
					<Pulse size={3} />
				</span>
			)}
		</div>
	);
}
