import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	createAudienceField,
	deleteAudienceField,
	getAudienceFields,
	getCategoryFields,
} from '../../api/services/Audience';
import AudienceCategoryFieldList from '../AudienceCategoryFieldList';
import AudienceCategorySearchSelector from '../AudienceCategorySearchSelector';
import { sendGoogleEvent } from '../../utils/analytics';
import { StarIcon } from '@heroicons/react/24/solid';
import Switch from '../Switch';
import QuotaEditor from '../QuotaEditor';
import { useTranslation } from 'react-i18next';
import { deleteQuotaFromAudience } from '../../store/projectEditorSlice';

export default function AudienceCategory({
	category,
	isEditable,
	subscription,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [categoryFields, setCategoryFields] = useState([]);
	const [audienceFields, setAudienceFields] = useState([]);
	const [isQuotaEnabled, setIsQuotaEnabled] = useState(false);
	const [refreshCount, setRefreshCount] = useState(0); // Shouldn't need this once this whole thing goes to reducer/saga

	const projectNanoId = useSelector(
		state => state.projectEditor.project.nanoid,
	);
	const audienceNanoId = useSelector(
		state => state.projectEditor.audience.nanoid,
	);

	const loadingQuotaFields = useSelector(
		state => state.projectEditor.audience.loadingQuotaField,
	);

	const quotas = useSelector(state => state.projectEditor.audience.quotas);

	let categoryQuota = null;
	if (quotas.length > 0) {
		const catQuota = quotas.find(quota => quota.category === category.nanoid);
		categoryQuota = catQuota || null;
	}

	const showOnlySelected = category.data_type === 'dropdown';

	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			if (!showOnlySelected) {
				const result = await getCategoryFields({
					categoryNanoId: category.nanoid,
				});

				if (isMounted) {
					setCategoryFields(result);
				}
			}

			const audienceFieldsResult = await getAudienceFields({
				projectNanoId: projectNanoId,
				audienceNanoId: audienceNanoId,
			});

			const selectedFilterCount = audienceFieldsResult.filter(
				field => field.category.nanoid === category.nanoid,
			).length;
			selectedFilterCount >= 2
				? setIsQuotaEnabled(true)
				: setIsQuotaEnabled(false);

			if (selectedFilterCount < 2 && categoryQuota) {
				dispatch(deleteQuotaFromAudience(categoryQuota.nanoid));
			}

			if (isMounted) {
				setAudienceFields(audienceFieldsResult);

				if (showOnlySelected) {
					// `audienceFieldsResult` contains all the audience filters selected in this audience. Therefore we need to filter out
					// the ones we don't need to render in this category.
					const filteredAudienceFieldResult = audienceFieldsResult.filter(
						item => {
							return item.category.nanoid === category.nanoid;
						},
					);

					// Items in `audienceFieldsResult` does not contain a `name` property so we map `label` to `name`
					const resultWithNameField = filteredAudienceFieldResult.map(item => {
						return {
							...item,
							name: item.value.label,
						};
					});
					setCategoryFields(resultWithNameField);
				}
			}
		};

		fetchData();

		return function cleanup() {
			isMounted = false;
		};
	}, [
		audienceNanoId,
		category.nanoid,
		projectNanoId,
		setCategoryFields,
		categoryQuota,
		dispatch,
		showOnlySelected,
		refreshCount,
	]);

	const handleSelectionChange = async ({
		mode,
		audienceFieldNanoId,
		categoryNanoId,
		categoryFieldNanoId,
		categoryFieldSlug,
	}) => {
		if (mode === 'delete') {
			await deleteAudienceField({
				projectNanoId: projectNanoId,
				audienceNanoId: audienceNanoId,
				audienceFieldNanoId: audienceFieldNanoId,
			});
			sendGoogleEvent('audience_category_field_remove', {
				field: categoryFieldSlug,
			});
		} else {
			await createAudienceField({
				projectNanoId: projectNanoId,
				audienceNanoId: audienceNanoId,
				categoryNanoId: categoryNanoId,
				categoryFieldNanoId: categoryFieldNanoId,
				audienceFieldNanoId: audienceFieldNanoId,
			});
			sendGoogleEvent('audience_category_field_add', {
				field: categoryFieldSlug,
			});
		}

		setRefreshCount(refreshCount + 1);

		dispatch({
			type: 'projectEditor/calculateProjectCostRequest',
			payload: {
				projectNanoId: projectNanoId,
			},
		});

		dispatch({
			type: 'projectEditor/calculateProjectFeasibilityStart',
			payload: {
				projectNanoId: projectNanoId,
				audienceNanoId: audienceNanoId,
			},
		});
	};

	const handleSwitchChange = () => {
		if (!categoryQuota) {
			dispatch({
				type: 'addQuotaToAudience',
				payload: {
					categoryNanoId: category.nanoid,
					projectNanoId: projectNanoId,
					audienceNanoId: audienceNanoId,
				},
			});
		} else {
			dispatch({
				type: 'deleteQuotaFromAudience',
				payload: {
					projectNanoId: projectNanoId,
					audienceNanoId: audienceNanoId,
					quotaNanoId: categoryQuota.nanoid,
				},
			});
		}
	};

	const areFieldsDisabled = () => {
		return (
			loadingQuotaFields[categoryQuota?.nanoid]?.length > 0 ||
			categoryQuota?.quotaFields?.some(quotaField => quotaField.isLoading)
		);
	};

	if (category.data_type !== 'boolean' && category.data_type !== 'dropdown') {
		return <></>;
	}
	return (
		<div className="bg-white shadow rounded border-l-4 border-indigo-600 mb-4 ">
			<div className="flex justify-between border-b border-gray-200 px-4 py-4 sm:px-6">
				<div className="">
					<h4 className="text-lg font-medium leading-6 text-gray-700 flex">
						{category.name}
						{subscription && category.tier === 'advanced' && (
							<StarIcon className="h-5 mt-0.5 ml-1 text-yellow-400" />
						)}
					</h4>
					{category.description && (
						<p className="text-xs mt-1 text-gray-600">{category.description}</p>
					)}
				</div>
				<div className="shrink-0 w-20">
					<Switch
						title={t('Quota')}
						lighterLabel={true}
						checked={categoryQuota}
						isDisabled={
							!isEditable ||
							!isQuotaEnabled ||
							areFieldsDisabled() ||
							(subscription && !subscription.plan.quotas_enabled)
						}
						onChange={handleSwitchChange}
					/>
				</div>
			</div>
			<div className="px-4 pb-4 sm:px-6 flex">
				<div className="flex w-2/4 md:w- md:2/4 flex-col flex-auto py-4 gap-4">
					{category.data_type === 'dropdown' && (
						<AudienceCategorySearchSelector
							category={category}
							categoryFields={categoryFields}
							setCategoryFields={setCategoryFields}
							handleSelectionChange={handleSelectionChange}
							isEditable={isEditable}
						/>
					)}
					<fieldset>
						<legend className="sr-only">{category.name}</legend>
						<div className="flex flex-row flex-wrap gap-3">
							<AudienceCategoryFieldList
								category={category}
								categoryFields={categoryFields}
								audienceFields={audienceFields}
								handleSelectionChange={handleSelectionChange}
								isEditable={isEditable}
							/>
						</div>
					</fieldset>
				</div>
				{categoryQuota && (
					<QuotaEditor
						key={categoryQuota.nanoid}
						projectNanoId={projectNanoId}
						audienceNanoId={audienceNanoId}
						category={category}
						categoryQuota={categoryQuota}
						categoryFields={categoryFields}
						audienceFields={audienceFields}
						isEditable={isEditable}
						areFieldsDisabled={areFieldsDisabled}
					/>
				)}
			</div>
		</div>
	);
}
