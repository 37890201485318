import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAudienceQuotas({ projectNanoId, audienceNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.quotas.LIST_AUDIENCE_QUOTAS(projectNanoId, audienceNanoId),
	);
	return result.data.results;
}

export async function getQuotaFields({
	projectNanoId,
	audienceNanoId,
	quotaNanoId,
}) {
	const result = await sorbunuApi.get(
		endPoints.quotas.LIST_QUOTA_FIELDS(
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
		),
	);

	return result.data.results;
}

export async function addQuotaToAudience({
	projectNanoId,
	audienceNanoId,
	payload,
}) {
	const result = await sorbunuApi.post(
		endPoints.quotas.CREATE_QUOTA(projectNanoId, audienceNanoId),
		payload,
	);
	return result.data;
}

export async function addQuotaField({
	projectNanoId,
	audienceNanoId,
	quotaNanoId,
	payload,
}) {
	const result = await sorbunuApi.post(
		endPoints.quotas.CREATE_QUOTA_FIELD(
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
		),
		payload,
	);
	return result.data;
}

export async function deleteQuotaFromAudience({
	projectNanoId,
	audienceNanoId,
	quotaNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.quotas.DELETE_QUOTA(projectNanoId, audienceNanoId, quotaNanoId),
	);
	return result.data.results;
}

export async function deleteQuotaField({
	projectNanoId,
	audienceNanoId,
	quotaNanoId,
	quotaFieldNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.quotas.DELETE_QUOTA_FIELD(
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
			quotaFieldNanoId,
		),
	);
	return result.data.results;
}

export async function updateQuotaField({
	projectNanoId,
	audienceNanoId,
	quotaNanoId,
	quotaFieldNanoId,
	payload,
}) {
	const result = await sorbunuApi.patch(
		endPoints.quotas.UPDATE_QUOTA_FIELD(
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
			quotaFieldNanoId,
		),
		payload,
	);
	return result.data;
}
