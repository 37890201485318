import { useTranslation } from 'react-i18next';
import {
	classNames,
	selectProjectFilterCategories,
	getOrdinalNumber,
	percentage,
	getQuestionTitle,
} from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { QUESTION_META_DATA } from '../../constants';
import { useState } from 'react';
import { ArrowUturnDownIcon } from '@heroicons/react/20/solid';
import { resetComparison, resetFilters } from '../../store/analysisSlice';
import { getTranslatedQuestionText } from '../../utils/Translation';
import Tabs from '../Tabs';

export default function AnalysisFilterCompareOptions({
	mode,
	availableOptions,
	selectedOptions,
	handleSelectedOptionChange,
	translations,
	language,
	nanoid,
	displayOption,
	changeDisplayOption,
}) {
	const quotas = useSelector(state => state.projectEditor.audience.quotas);
	const { audienceFields } = useSelector(state => state.projectEditor.audience);
	const audienceReach = useSelector(
		state => state.projectEditor.audience.reach,
	);

	const displayQuota = (filterNanoId, filterFieldNanoId, displayOption) => {
		let quotaValue = null;
		const selectedFilter = audienceFields.find(
			field =>
				field.category.nanoid === filterNanoId &&
				field.category_field.nanoid === filterFieldNanoId,
		);
		if (selectedFilter) {
			let quota = quotas.find(quota => quota.category === filterNanoId);
			if (quota && quota.quotaFields) {
				const selectedQuota = quota.quotaFields.find(
					q => q.audience_field === selectedFilter.nanoid,
				);
				quotaValue = selectedQuota ? selectedQuota.response_quota : null;
			}
		}
		if (quotaValue && displayOption === 'percentage') {
			quotaValue = `(${percentage(quotaValue, audienceReach).toFixed(2)}%) `;
		}
		if (quotaValue && displayOption === 'count') {
			quotaValue = `(${quotaValue})`;
		}
		return quotaValue;
	};

	const [displayRanks, setDisplayRanks] = useState([]);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentLanguage = localStorage.getItem('i18nextLng');
	const _questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);
	const screeningQuestionList = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionList,
	);

	const questionList = [...screeningQuestionList, ..._questionList];

	const questionTypeMap = {};
	questionList.forEach(question => {
		questionTypeMap[question.nanoid] = question.question_type;
	});

	const isFilterFieldChecked = ({ filter, filterField }) => {
		return selectedOptions[filter.slug]
			? selectedOptions[filter.slug][filterField.nanoid] === true
			: false;
	};

	const isRankingComparisonItemChecked = ({ filter, filterField }) => {
		return selectedOptions[filter.nanoid]
			? selectedOptions[filter.nanoid][filterField.nanoid]
				? true
				: false
			: false;
	};
	const isRankingFilterFieldChecked = ({ filter, filterField, rank }) => {
		return selectedOptions[filter.nanoid] &&
			selectedOptions[filter.nanoid][filterField.nanoid]
			? selectedOptions[filter.nanoid][filterField.nanoid][rank] === true
			: false;
	};

	const isRankingChoiceChecked = ({ filter, filterField }) => {
		return (
			displayRanks.findIndex(q => q === filterField.nanoid) > -1 ||
			isRankingComparisonItemChecked({ filter, filterField })
		);
	};

	const handleRankingOptionChange = (e, filterFieldNanoid, filterNanoid) => {
		if (e.target.checked) {
			setDisplayRanks([...displayRanks, filterFieldNanoid]);
		} else {
			setDisplayRanks(displayRanks.filter(q => q !== e.target.value));
			//remove any applied ranking filter/compare items when ranking choice box is unchecked
			if (selectedOptions[filterNanoid]) {
				handleSelectedOptionChange({
					filterItem: filterNanoid,
					filterField: filterFieldNanoid,
					selected: false,
				});
			}
		}
	};

	if (nanoid == 'EdM97FTD075TNba2') {
		const updatedCategorySet = availableOptions[2]?.category_set.filter(
			option => option.name !== 'Evcil hayvanı',
		);
		availableOptions = availableOptions.map((item, index) => {
			if (index === 2) {
				return { ...item, category_set: updatedCategorySet };
			}
			return item;
		});
	}

	const handleResetButtonClick = () => {
		if (mode === 'filter') {
			dispatch(
				resetFilters({
					projectNanoId: nanoid,
				}),
			);
		} else if (mode === 'compare') {
			dispatch(
				resetComparison({
					projectNanoId: nanoid,
				}),
			);
		}
		setDisplayRanks([]);
	};

	return (
		<>
			<div className="flex justify-end ">
				<Tabs
					size="xs"
					tabs={[
						{ key: 'percentage', title: '%' },
						{ key: 'count', title: '#' },
					]}
					selectedTab={displayOption}
					isEditable={true}
					onChange={newTab => {
						changeDisplayOption(newTab);
					}}
				/>
			</div>
			<div className="mb-40">
				{availableOptions.map((section, index) => (
					<div key={index}>
						{section.category_set
							.filter(category =>
								selectProjectFilterCategories(nanoid, category.slug),
							)
							.filter(
								cat =>
									!(cat.type === 'question' && cat.question_type === 'matrix'),
							)
							.map((filter, sectionIdx) => (
								<div key={sectionIdx} className="mb-5">
									<fieldset>
										<legend className="flex gap-1 text-sm font-medium text-gray-600">
											<div
												title={
													questionTypeMap[filter.nanoid]
														? t(
																QUESTION_META_DATA[
																	questionTypeMap[filter.nanoid]
																].title,
														  )
														: t('Demographics')
												}
												className={classNames(
													'flex-none mt-1 h-3.5 w-3.5 border rounded',
													questionTypeMap[filter.nanoid]
														? QUESTION_META_DATA[questionTypeMap[filter.nanoid]]
																.bgColor
														: '',
													questionTypeMap[filter.nanoid]
														? QUESTION_META_DATA[questionTypeMap[filter.nanoid]]
																.borderColor
														: '',
													!questionTypeMap[filter.nanoid]
														? 'bg-gradient-to-r from-cyan-500 to-indigo-500'
														: '',
												)}
											></div>
											<div className="grow">
												{getQuestionTitle(
													filter.name ? filter.name : t('common.emptyString'),
													questionList,
													translations,
													language,
													filter.nanoid,
													true,
												)}
											</div>
										</legend>

										{(filter.slug === 'sehir' || filter.slug === 'bolge') && (
											<span className="text-xs text-gray-400 ">
												{t('cityFilterText')}
											</span>
										)}
										<div className="space-y-1 pt-2">
											{filter.categoryfield_set.map(
												(filterField, filterFieldIndex) => {
													return (
														<div
															key={filterFieldIndex}
															className="cursor-pointer"
														>
															<div
																className={classNames(
																	isFilterFieldChecked({
																		filter: filter,
																		filterField: filterField,
																	}) ||
																		isRankingChoiceChecked({
																			filter: filter,
																			filterField: filterField,
																		})
																		? questionTypeMap[filter.nanoid]
																			? `${
																					QUESTION_META_DATA[
																						questionTypeMap[filter.nanoid]
																					].analysisBgColor
																			  } ${
																					QUESTION_META_DATA[
																						questionTypeMap[filter.nanoid]
																					].analysisTextColor
																			  } hover:${
																					QUESTION_META_DATA[
																						questionTypeMap[filter.nanoid]
																					].analysisHoverBgColor
																			  }`
																			: 'text-white bg-gradient-to-r from-cyan-500 to-indigo-500'
																		: 'text-gray-500 hover:bg-gray-200',
																	' flex items-center justify-between p-1 rounded',
																)}
															>
																{filter.question_type === 'ranking' && (
																	<input
																		id={`${filterField.nanoid}-${filterFieldIndex}`}
																		name={`${filterField.nanoid}[]`}
																		type="checkbox"
																		value={filterField.nanoid}
																		checked={isRankingChoiceChecked({
																			filter: filter,
																			filterField: filterField,
																		})}
																		onChange={e => {
																			handleRankingOptionChange(
																				e,
																				filterField.nanoid,
																				filter.nanoid,
																			);
																		}}
																		className="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-400 focus:ring-yellow-500"
																	/>
																)}
																{filter.question_type !== 'ranking' && (
																	<input
																		id={`${filter.nanoid}-${filterField.nanoid}-${filterFieldIndex}`}
																		name={`${filterField.nanoid}[]`}
																		type="checkbox"
																		value={filterField.nanoid}
																		checked={isFilterFieldChecked({
																			filter: filter,
																			filterField: filterField,
																		})}
																		onChange={e => {
																			handleSelectedOptionChange({
																				filterItem: filter.slug,
																				filterField: e.target.value,
																				selected: e.target.checked,
																			});
																		}}
																		className={classNames(
																			questionTypeMap[filter.nanoid]
																				? `${
																						QUESTION_META_DATA[
																							questionTypeMap[filter.nanoid]
																						].inputCheckboxColor
																				  }`
																				: "'text-indigo-600 focus:ring-indigo-500",
																			'cursor-pointer h-4 w-4 rounded border-gray-300',
																		)}
																	/>
																)}
																<label
																	htmlFor={`${filter.nanoid}-${filterField.nanoid}-${filterFieldIndex}`}
																	className="cursor-pointer ml-2 text-xs font-medium flex-grow"
																>
																	{getTranslatedQuestionText(
																		translations,
																		language,
																		filterField.nanoid,
																		filterField.name
																			? filterField.name
																			: t('common.emptyString'),
																	)}
																</label>
																<div className="cursor-pointer text-xs font-mono gap-1 flex">
																	<span title={t('Quota')}>
																		{displayQuota(
																			filter.nanoid,
																			filterField.nanoid,
																			displayOption,
																		) != null &&
																			displayQuota(
																				filter.nanoid,
																				filterField.nanoid,
																				displayOption,
																			)}
																	</span>
																	<span>
																		{displayOption === 'percentage' &&
																			!isNaN(filterField.percentage) && (
																				<>
																					{`${parseFloat(
																						filterField.percentage * 100,
																					).toFixed(2)}%`}
																				</>
																			)}
																		{displayOption === 'count' &&
																			!isNaN(filterField.count) && (
																				<>{filterField.count}</>
																			)}
																	</span>
																</div>
															</div>
															{filterField.ranks &&
																isRankingChoiceChecked({
																	filter: filter,
																	filterField: filterField,
																}) &&
																filterField.ranks.map((rank, rankIdx) => (
																	<div
																		key={`${filterFieldIndex}-${rankIdx}`}
																		className={classNames(
																			isRankingFilterFieldChecked({
																				filter: filter,
																				filterField: filterField,
																				rank: rank.rank,
																			})
																				? 'bg-yellow-200 hover:bg-yellow-300 text-gray-700'
																				: 'text-gray-500 hover:bg-gray-200',
																			'cursor-pointer flex items-center justify-between p-1 rounded ml-6 mt-1',
																		)}
																	>
																		<input
																			id={`${filterField.nanoid}-${filterFieldIndex}-${rank.rank}`}
																			name={`${rank.rank}[]`}
																			type="checkbox"
																			value={rank.rank}
																			checked={isRankingFilterFieldChecked({
																				filter: filter,
																				filterField: filterField,
																				rank: rank.rank,
																			})}
																			onChange={e => {
																				handleSelectedOptionChange({
																					filterItem: filter.slug,
																					filterField: filterField.nanoid,
																					rank: e.target.value,
																					selected: e.target.checked,
																					questionType: filter.question_type,
																				});
																			}}
																			className="cursor-pointer h-4 w-4 rounded border-gray-300 text-yellow-400 focus:ring-yellow-500"
																		/>
																		<label
																			htmlFor={`${filterField.nanoid}-${filterFieldIndex}-${rank.rank}`}
																			className="cursor-pointer ml-2 text-xs font-medium flex-grow"
																		>
																			{`${getOrdinalNumber(
																				currentLanguage,
																				rank.rank,
																			)} ${t('rank')}`}
																		</label>
																		<span className="text-xs font-mono">
																			{displayOption === 'percentage' &&
																				!isNaN(rank.percentage) && (
																					<>
																						{`${parseFloat(
																							rank.percentage * 100,
																						).toFixed(2)}%`}
																					</>
																				)}
																			{displayOption === 'count' &&
																				!isNaN(rank.count) && <>{rank.count}</>}
																		</span>
																	</div>
																))}
														</div>
													);
												},
											)}
										</div>
									</fieldset>
								</div>
							))}
					</div>
				))}
			</div>
			{Object.keys(selectedOptions).length > 0 && (
				<div className="fixed bottom-16 md:bottom-10 right-0 md:left-0 text-center w-[28rem] md:w-[19rem]">
					<button
						type="button"
						className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-lg hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						onClick={handleResetButtonClick}
					>
						<ArrowUturnDownIcon
							className="-ml-0.5 h-5 w-5"
							aria-hidden="true"
						/>
						{t('analysis.filterCompareOptions.reset')}
					</button>
				</div>
			)}
		</>
	);
}
