import React from 'react';
import ChoiceList from './ChoiceList';

const YesNoQuestion = ({
	question,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	isEditable,
	isScreeningQuestion,
	handleChoiceQualifyChange,
}) => {
	return (
		<ChoiceList
			question={question}
			isEditable={isEditable}
			canAdd={false}
			canDelete={false}
			canReorder={false}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			isScreeningQuestion={isScreeningQuestion}
			handleChoiceQualifyChange={handleChoiceQualifyChange}
		/>
	);
};

export default YesNoQuestion;
