import React from 'react';

const NumberSelectionQuestion = () => {
	return (
		<input
			type="number"
			disabled={true}
			className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
		/>
	);
};

export default NumberSelectionQuestion;
