import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/en-gb';
import { CreditCardIcon, PlusIcon } from '@heroicons/react/24/solid';
import getSymbolFromCurrency from 'currency-symbol-map';
import { cloneDeep, find } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { createCheckoutForm } from '../../../api/services/Checkout';
import { getCheckoutContracts } from '../../../api/services/Checkout';
import AddressForm from '../../../components/AddressForm';
import RadioGroup2 from '../../../components/RadioGroup2';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import {
	createAddressStart,
	loadAddressesStart,
} from '../../../store/addressReducer';
import { classNames, setDocumentTitle } from '../../../utils';
import { useTranslation } from 'react-i18next';
import Badge from '../../../components/Badge';
import Alert from '../../../components/Alert';
import { VAT_RATE } from '../../../constants';
import PromoCodeDialogue from '../../../components/PromoCodeDialogue';
import { deletePromoCodeErrors } from '../../../store/projectEditorSlice';
import TourGuide from '../../../components/TourGuide';
import SlideOver from '../../../components/SlideOver';

function TransactionList({ paymentMode, transactions }) {
	const { t } = useTranslation();

	const filteredTransactions = transactions.filter(
		item => item.transaction_type === paymentMode,
	);

	return (
		<>
			<div className="flex flex-col">
				<div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3 pl-4 pr-3 text-left text-xs font-medium tracking-wide text-gray-500 sm:pl-6"
										>
											{t('ID')}
										</th>

										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium tracking-wide text-gray-500"
										>
											{t('USER')}
										</th>
										<th
											scope="col"
											className="px-3 py-3 text-left text-xs font-medium  tracking-wide text-gray-500"
										>
											{t('STATUS')}
										</th>

										<th
											scope="col"
											className="px-3 py-3 text-right text-xs font-medium tracking-wide text-gray-500"
										>
											{t('AMOUNT')}
										</th>

										<th
											scope="col"
											className="px-3 py-3 text-right text-xs font-medium tracking-wide text-gray-500"
										>
											{t('DATE')}
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{filteredTransactions.length === 0 && (
										<tr>
											<td
												className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
												colSpan={5}
											>
												{t('There is no record to show here yet.')}
											</td>
										</tr>
									)}
									{filteredTransactions.map(item => (
										<tr key={item.nanoid}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
												{item.transaction_id}
											</td>

											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{item.user.first_name} {item.user.last_name}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												<Badge type={item.is_succeeded ? 'success' : 'error'}>
													{item.is_succeeded ? t('Sucessful') : t('Failed')}
												</Badge>
											</td>

											<td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 text-right">
												{`${getSymbolFromCurrency(item.amount.currency)}${
													item.amount.value
												}`}
											</td>

											<td
												className="whitespace-nowrap px-3 py-4 text-sm  text-gray-500 text-right"
												title={moment(item.created).format('LLL')}
											>
												{moment(item.created).format('L')}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default function CheckoutPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [searchParams] = useSearchParams('');
	const paymentSuccessMode = searchParams.get('success');

	const { nanoid } = useParams();

	const addresses = useSelector(state => state.addresses.addresses);
	const isAddressesLoading = useSelector(state => state.addresses.isLoading);
	const addressCreationStatus = useSelector(
		state => state.addresses.addressCreationStatus,
	);
	const transactions = useSelector(state => state.projectEditor.transactions);
	const cost = useSelector(state => state.projectEditor.cost);
	const questionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.hasErrors,
	);
	const screeningQuestionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionnaireHasErrors,
	);
	const feasibilityData = useSelector(
		state => state.projectEditor.feasibility.data,
	);

	const costData = useSelector(state => state.projectEditor.cost.data);
	const error = useSelector(state => state.projectEditor.promoCodes.error);
	const isApplyingPromoCode = useSelector(
		state => state.projectEditor.promoCodes.isApplyingPromoCode,
	);
	const promoCodes = useSelector(
		state => state.projectEditor.promoCodes.promoCodes,
	);
	const { user } = useSelector(state => state.auth);
	const project = useSelector(state => state.projectEditor.project);

	const [selectedAddress, setSelectedAddress] = useState(null);
	const [isPaymentFormLoading, setIsPaymentFormLoading] = useState(false);
	const [paymentPageUrl, setPaymentPageUrl] = useState();
	const [isContractChecked, setIsContractChecked] = useState(false);
	const [preliminaryInfo, setPreliminaryInfo] = useState();
	const [salesAgreementInfo, setSalesAgreementInfo] = useState();
	const [open, setOpen] = useState(false);
	const [promoCode, setPromoCode] = useState('');
	const [promoCodeType, setPromoCodeType] = useState('');
	const [showPreliminaryInformation, setShowPreliminaryInformation] =
		useState(false);
	const [showSalesAgreement, setSalesAgreement] = useState(false);

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.checkout.payment.title'),
			content: t('tour.content.checkout.payment.content'),
			disableBeacon: true,
		},
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.checkout.afterPayment.title'),
			content: t('tour.content.checkout.afterPayment.content'),
			disableBeacon: true,
		},
	];

	const actionTypeForManualCode =
		costData?.promo_code && costData?.promo_code.code === promoCode
			? 'delete'
			: 'add';

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.checkout'));
	}, [t]);

	useEffect(() => {
		dispatch(loadAddressesStart());
		dispatch({
			type: 'projectEditor/getPromoCodesStart',
		});
	}, [dispatch]);

	useEffect(() => {
		if (addresses.length > 0 && selectedAddress === null) {
			setSelectedAddress(addresses[0]);
		}
	}, [addresses, selectedAddress]);

	useEffect(() => {
		if (costData?.promo_code?.apply_type === 'manual') {
			setPromoCode(costData.promo_code.code);
		}
	}, [costData]);

	useEffect(() => {
		if (
			project.status !== 'under_approval' &&
			project.country_code === 'GB' &&
			paymentSuccessMode == 1
		) {
			const timeoutID = setTimeout(() => {
				window.location.reload();
			}, 10000);

			return () => {
				clearTimeout(timeoutID);
			};
		}
	}, [project.status, project.country_code, paymentSuccessMode]);

	useEffect(() => {
		const fetchCheckoutContracts = async () => {
			const result = await getCheckoutContracts({
				projectNanoId: nanoid,
				addressNanoId: selectedAddress.nanoid,
				paymentType: 'credit_card',
			});
			const preliminaryInfo = result.preliminary_information;
			const salesAgreementInfo = result.sales_agreement;
			setPreliminaryInfo(preliminaryInfo);
			setSalesAgreementInfo(salesAgreementInfo);
		};
		selectedAddress && fetchCheckoutContracts();
	}, [selectedAddress, nanoid]);

	useEffect(() => {
		if (error) {
			if (promoCodeType === 'manual') {
				let errorMessage = error[0];
				if (errorMessage === 'invalid_code_error') {
					errorMessage = t('Invalid Promo Code');
				}
				if (!Array.isArray(error)) {
					errorMessage = error.promo_code;
				}
				NiceModal.show(Modal, {
					info: 'error',
					message: errorMessage,
					secondaryButtonLabel: t('Close'),
					showPrimaryButton: false,
					onSecondaryButtonClick: () => {
						dispatch(deletePromoCodeErrors());
					},
				});
			}
		}
	}, [error, t, dispatch, promoCodeType]);

	const handleAddressChange = item => {
		const foundAddress = find(addresses, _item => _item.nanoid === item.id);
		setSelectedAddress(foundAddress);
	};

	const submitPromoCode = (actionType, promoCode) => {
		if (actionType === 'add') {
			dispatch({
				type: 'projectEditor/addPromoCodeStart',
				payload: {
					projectNanoId: nanoid,
					promoCode: promoCode,
				},
			});
		} else {
			dispatch({
				type: 'projectEditor/deletePromoCodeStart',
				payload: {
					projectNanoId: nanoid,
					promoCode: promoCode,
				},
			});
		}
	};

	const handlePromoCodeSubmit = e => {
		e.preventDefault();
		setPromoCodeType('manual');
		submitPromoCode(actionTypeForManualCode, promoCode);
	};

	const openMyPromoCodes = () => {
		setOpen(true);
	};

	const applyPromoCodeFromList = (promoCodeFromList, actionType) => {
		setPromoCodeType('auto');
		submitPromoCode(actionType, promoCodeFromList);
	};

	const handlePreliminaryModal = e => {
		e.preventDefault();
		setShowPreliminaryInformation(true);
	};

	const handleDistanceSalesModal = e => {
		e.preventDefault();
		setSalesAgreement(true);
	};

	const formattedAddressData = addresses
		.filter(address => address.country.code === project.country_code)
		.map(address => {
			return {
				id: address.nanoid,
				name: `${address.title} - ${t(
					address.address_type.charAt(0).toUpperCase() +
						address.address_type.slice(1),
				)}`,
				description: `${address.line} ${
					address.country.name === 'Türkiye'
						? address.district.name
						: address.post_code
				}/${address.city.name}`,
			};
		});

	const handleAddressSave = async values => {
		const payload = cloneDeep(values);

		payload['country'] = values.country.nanoid;
		payload['city'] = values.city.nanoid;
		payload['district'] = values.district.nanoid;

		dispatch(createAddressStart({ addressPayload: payload }));
	};

	const handleProceedToPaymentClick = () => {
		let errorMessages = [];
		if (feasibilityData && !feasibilityData.is_feasible) {
			errorMessages.push(t('projectEditor.projectSubmit.audienceIssue'));
		}
		if (questionnaireHasErrors) {
			errorMessages.push(t('projectEditor.projectSubmit.questionnaireIssue'));
		}
		if (screeningQuestionnaireHasErrors) {
			errorMessages.push(t('screeningQuestions.screeningQuestionnaireIssue'));
		}
		if (!user.is_verified) {
			errorMessages.push(
				t('projectEditor.projectSubmit.emailVerificationIssue'),
			);
		}
		if (errorMessages.length > 0) {
			NiceModal.show(Modal, {
				icon: 'error',
				title: t('projectEditor.projectSubmit.issueModalTitle'),
				message: errorMessages,
				showPrimaryButton: false,
				secondaryButtonLabel: t('Close'),
				buttonColorClass: 'bg-indigo-600 hover:bg-indigo-700',
			});
		}
		if (errorMessages.length === 0) {
			NiceModal.show(Modal, {
				icon: 'warning',
				title: t('paymentConfirmationPopUp.title'),
				message: t('paymentConfirmationPopUp.message'),
				primaryButtonLabel: t('withdrawProject.yes'),
				secondaryButtonLabel: t('withdrawProject.no'),
				onPrimaryButtonClick: () => {
					handlePayment();
				},
				buttonColorClass: 'bg-indigo-600 hover:bg-indigo-700',
			});
		}
	};

	const handlePayment = async () => {
		setIsPaymentFormLoading(true);

		const checkoutFormResult = await createCheckoutForm({
			projectNanoId: nanoid,
			addressNanoId: selectedAddress.nanoid,
			paymentType: 'credit_card',
		});

		if (project.country_code === 'TR') {
			setPaymentPageUrl(
				`${checkoutFormResult.data.payment_page_url}&iframe=true`,
			);
		} else if (project.country_code === 'GB') {
			window.location = checkoutFormResult.data.payment_page_url;
		}

		setIsPaymentFormLoading(false);
	};

	if (cost.data === null) {
		return <Spinner className="mx-auto mt-10 w-10 h-10 text-indigo-600" />;
	}

	if (project.payment_mode === 'subscription') {
		return <></>;
	}

	return (
		<>
			<div className="h-screen overflow-y-auto pb-40 w-full">
				<main className="mx-auto max-w-7xl px-4 pt-8 pb-24 sm:px-6 lg:px-8">
					<div className="mx-auto max-w-2xl lg:max-w-none">
						<h1 className="sr-only">{t('Checkout')}</h1>
						<div className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
							<div>
								<div className="flex justify-between">
									<h2
										className={classNames(
											paymentPageUrl ? 'opacity-30' : '',
											'text-lg font-medium text-gray-900',
										)}
									>
										{t('Billing Address')}
									</h2>
									<button
										type="button"
										disabled={paymentPageUrl}
										onClick={() =>
											navigate(
												`/account/billing/addresses/create?next=/project/${nanoid}/checkout`,
											)
										}
										className="disabled:cursor-not-allowed disabled:opacity-30 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									>
										<PlusIcon
											className="-ml-0.5 mr-1 h-4 w-4"
											aria-hidden="true"
										/>
										{t('New Address')}
									</button>
								</div>
								<div className="mt-4">
									{isAddressesLoading && (
										<Spinner className="mx-auto mt-10 w-10 h-10 text-indigo-600" />
									)}
									{!isAddressesLoading && addresses.length > 0 && (
										<div
											className={classNames(paymentPageUrl ? 'opacity-30' : '')}
										>
											<RadioGroup2
												data={formattedAddressData}
												selected={selectedAddress}
												disabled={paymentPageUrl}
												setSelected={handleAddressChange}
											/>
										</div>
									)}
									{!isAddressesLoading && addresses.length === 0 && (
										<AddressForm
											isLoading={addressCreationStatus === 'creating'}
											handleAddressSave={handleAddressSave}
										/>
									)}
								</div>
								{/* Promo Code */}
								<div className="flex flex-col mt-8">
									<h2
										className={classNames(
											paymentPageUrl ? 'opacity-30' : '',
											'text-lg font-medium text-gray-900',
										)}
									>
										{t('Promo Code')}
									</h2>
									<form>
										<input
											type="text"
											name="promoCode"
											id="promo_code"
											className="mt-4 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm disabled:bg-neutral-100 "
											onChange={e => setPromoCode(e.target.value)}
											value={promoCode}
											disabled={cost.data.promo_code}
										/>
										<button
											type="button"
											className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm enabled:hover:bg-indigo-700 disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={handlePromoCodeSubmit}
											disabled={
												(costData?.promo_code &&
													costData?.promo_code?.code !== promoCode) ||
												promoCode === ''
											}
										>
											{isApplyingPromoCode &&
												isApplyingPromoCode === promoCode && (
													<Spinner className="w-4 h-4" />
												)}
											{!isApplyingPromoCode &&
												costData.promo_code &&
												costData?.promo_code.code === promoCode &&
												t('Unapply')}

											{!isApplyingPromoCode &&
												(!costData.promo_code ||
													costData?.promo_code.code !== promoCode) &&
												(isApplyingPromoCode !== promoCode ||
													promoCode === '') &&
												t('Apply')}
										</button>
									</form>
								</div>

								<span>
									<button
										className="pt-2 text-indigo-600 underline disabled:opacity-30"
										onClick={openMyPromoCodes}
										disabled={
											transactions.remainingTotal === 0 || paymentPageUrl
										}
									>
										{t('My Promo Codes')}
									</button>
								</span>

								{/* Promo Code end */}
								<div className="mt-8">
									<h2
										className={classNames(
											paymentPageUrl ? 'opacity-30' : '',
											'text-lg font-medium text-gray-900',
										)}
									>
										{!transactions.paymentMode === 'post_form'
											? t('Provisions')
											: t('Payments')}
									</h2>
								</div>
								<div className="mt-4">
									{transactions.status === 'loading' && (
										<Spinner className="mx-auto mt-10 w-10 h-10 text-indigo-600" />
									)}
									{transactions.status === 'idle' && (
										<TransactionList
											paymentMode={transactions.paymentMode}
											transactions={transactions.transactionList}
										/>
									)}
								</div>
							</div>
							<PromoCodeDialogue
								promoCodes={promoCodes}
								open={open}
								setOpen={setOpen}
								handlePromoCodeSubmit={applyPromoCodeFromList}
								promoCodeApplied={cost.data.promo_code}
								disabled={transactions.remainingTotal === 0}
								isApplyingPromoCode={isApplyingPromoCode}
								promoCodeType={promoCodeType}
							/>
							{/* Payment form */}
							{paymentPageUrl && (
								<div className="mt-10 lg:mt-0">
									<h2 className="text-lg font-medium text-gray-900">
										{t('Payment form')}
									</h2>

									<div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
										<div className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
											<iframe
												className="w-full h-96"
												src={paymentPageUrl}
											></iframe>
										</div>
									</div>
								</div>
							)}

							{/* Order summary */}

							{cost.status === 'loading' && (
								<Spinner className="mx-auto mt-10 w-10 h-10 text-indigo-600" />
							)}
							{cost.status !== 'loading' && !paymentPageUrl && (
								<div className="mt-10 lg:mt-0">
									<h2 className="text-lg font-medium text-gray-900">
										{t('Cost summary')}
									</h2>

									<div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
										<dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
											<div className="flex items-center justify-between">
												<dt className="text-sm">{t('Standard CPC')}</dt>
												<dd className="text-sm font-mono text-gray-900">
													{getSymbolFromCurrency(costData.base_price.currency)}
													{costData.base_price.value}
												</dd>
											</div>
											{cost.data.items.map(item => (
												<div
													key={item.name}
													className="flex items-center justify-between"
												>
													<dt className="text-sm">{item.name}</dt>
													<dd className="text-sm font-mono text-gray-900">
														{getSymbolFromCurrency(item.cost.currency)}
														{item.cost.value}
													</dd>
												</div>
											))}
										</dl>

										<dl className="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">
											<div className="flex items-center justify-between">
												<dt className="text-sm">{t('Cost per complete')}</dt>
												<dd className="text-sm font-mono text-gray-900">
													{`${getSymbolFromCurrency(cost.data.cpc.currency)}${
														cost.data.cpc.value
													}`}
												</dd>
											</div>
											<div className="flex items-center justify-between">
												<dt className="text-sm">{t('billDetailsCompletes')}</dt>
												<dd className="text-sm font-mono text-gray-900">
													{cost.data.completes}
												</dd>
											</div>

											<div className="flex items-center align-middle justify-between border-t border-gray-200 pt-6">
												<dt className="text-base font-medium">
													<span>
														{cost.data.promo_code ? t('Sub Total') : t('Total')}
													</span>
													<span className=" text-xs font-light text-gray-500">
														{' '}
														{t('(Includes {{VAT}}% VAT)', { VAT: VAT_RATE })}
													</span>
												</dt>
												<dd className="text-base font-mono text-gray-900">
													{`${getSymbolFromCurrency(cost.data.cost.currency)}${
														cost.data.original_cost.value
													}`}
												</dd>
											</div>
											{cost.data.promo_code && cost.data.discount_amount && (
												<div className="flex items-center justify-between">
													<dt className="text-base font-medium">
														<span>{t('Discount')}</span>
													</dt>
													<dd className="text-base font-mono text-gray-900">
														{`${getSymbolFromCurrency(
															cost.data.discount_amount.currency,
														)}${cost.data.discount_amount.value}`}
													</dd>
												</div>
											)}

											<div className="flex items-center justify-between">
												<dt className="text-base font-medium">
													{t('Paid Total')}
												</dt>
												<dd className="text-base font-mono text-gray-900">
													{transactions.paymentMode === 'post_auth' ||
													transactions.paymentMode === 'purchase_order'
														? new Intl.NumberFormat('tr-TR', {
																style: 'currency',
																currency: cost.data.cost.currency,
														  }).format(transactions.paid.sum)
														: new Intl.NumberFormat('tr-TR', {
																style: 'currency',
																currency: cost.data.cost.currency,
														  }).format(transactions.provisions.sum)}
												</dd>
											</div>
											<div className="flex items-center justify-between">
												<dt className="text-base font-medium">
													{t('Remaining Total')}
												</dt>
												<dd className="text-base font-mono text-gray-900">
													{new Intl.NumberFormat('tr-TR', {
														style: 'currency',
														currency: cost.data.cpc.currency,
													}).format(transactions.remainingTotal)}
												</dd>
											</div>
										</dl>
										{project.status !== 'under_approval' &&
										paymentSuccessMode == 1 &&
										project.country_code === 'GB' ? (
											<>
												<Alert type={'warning'}>
													<span>{t('ukPaymentConfirmationWarning')}</span>
													<Spinner className="inline-block ml-6 mb-1 w-6 h-6" />
												</Alert>
											</>
										) : transactions.remainingTotal <= 0 ? (
											<Alert type={'info'}>
												<span>{t('noRemainingPaymentInfo')}</span>
											</Alert>
										) : (
											<div className="border-t border-gray-200 py-6 px-4 sm:px-6">
												{!isAddressesLoading && addresses.length === 0 && (
													<div className="text-xs mb-4 font-light text-slate-500">
														{t('Please create a billing address first.')}
													</div>
												)}

												{!isAddressesLoading && addresses.length > 0 && (
													<div className="flex items-center mb-4">
														<input
															id="Sales-agreement"
															name="Sales-agreement"
															className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
															type="checkbox"
															onChange={() =>
																setIsContractChecked(!isContractChecked)
															}
														/>

														<label className="ml-2 block text-xs text-gray-900">
															{t('CheckoutContractTextStart')}
															<button
																type="button"
																className="text-indigo-600"
																onClick={handlePreliminaryModal}
															>
																{t('the Preliminary Information Form')}
															</button>
															{t("'nu")} {t('and')}{' '}
															<button
																type="button"
																className="text-indigo-600"
																onClick={handleDistanceSalesModal}
															>
																{t('the Distance Sales Agreement')}
															</button>
															{t("'ni")}
															{t('CheckoutContractTextEnd')}
														</label>
													</div>
												)}

												<div>
													<button
														type="submit"
														onClick={handleProceedToPaymentClick}
														disabled={
															!selectedAddress ||
															isPaymentFormLoading ||
															!isContractChecked
														}
														className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 w-full inline-flex rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
													>
														<div className="mx-auto inline-flex items-center">
															<CreditCardIcon
																className="-ml-1 mr-3 h-5 w-5"
																aria-hidden="true"
															/>
															{isPaymentFormLoading && (
																<>{t('Please wait') + '...'}</>
															)}
															{!isPaymentFormLoading && (
																<>{t('Proceed to Payment')}</>
															)}
														</div>
													</button>
												</div>
												<p className="text-xs font-light text-gray-500 my-3">
													{t('checkoutHelpText')}
												</p>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</main>
			</div>
			<SlideOver
				title={t('legal.checkout.preliminaryInformationForm.title')}
				open={showPreliminaryInformation}
				setOpen={setShowPreliminaryInformation}
			>
				<div dangerouslySetInnerHTML={{ __html: preliminaryInfo }}></div>
			</SlideOver>
			<SlideOver
				title={t('legal.checkout.salesAgreement.title')}
				open={showSalesAgreement}
				setOpen={setSalesAgreement}
			>
				<div dangerouslySetInnerHTML={{ __html: salesAgreementInfo }}></div>
			</SlideOver>

			<TourGuide tourName={'checkoutPage'} steps={steps} />
		</>
	);
}
