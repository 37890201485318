import { useDispatch, useSelector } from 'react-redux';
import { updateDraftComment } from '../../store/projectEditorSlice';
import { useEffect, useRef, useState } from 'react';
import Tooltip from '../Tooltip';
import ProfilePicture from '../ProfilePicture';
import { useTranslation } from 'react-i18next';

export default function CommentPoster({ nanoid }) {
	const { t } = useTranslation();
	const comment = useSelector(
		state => state.projectEditor.comments.postComment.draftComment,
	);
	const [draftComment, setDraftComment] = useState(comment);

	const user = useSelector(state => state.auth.user);
	const dispatch = useDispatch();
	const commentTextArea = useRef();
	const userOS =
		navigator.userAgent.indexOf('Mac') !== -1 ? 'MacOS' : 'Windows';

	useEffect(() => {
		const keyPressHandler = e => {
			if ((e.metaKey || e.ctrlKey) && e.code === 'Enter') {
				dispatch({
					type: 'projectEditor/postComment',
					payload: {
						projectNanoId: nanoid,
						values: { body: draftComment.trim() },
					},
				});
				commentTextArea.current.focus();
			}
		};
		document.addEventListener('keydown', keyPressHandler);
		return () => {
			document.removeEventListener('keydown', keyPressHandler);
		};
	}, [dispatch, draftComment, nanoid]);

	return (
		<div className="flex flex-grow items-start space-x-4">
			<div className="flex-shrink-0">
				<ProfilePicture
					firstName={user.first_name}
					lastName={user.last_name}
					profilePicture={user.profile_picture}
				/>
			</div>
			<div className="min-w-0 flex-1">
				<form action="#" className="relative">
					<div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
						<label htmlFor="comment" className="sr-only">
							{t('Add your comment')}
						</label>
						<textarea
							ref={commentTextArea}
							rows={3}
							name="comment"
							id="comment"
							disabled={status === 'loading'}
							className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
							placeholder={`${t('Add your comment')}...`}
							value={draftComment}
							onChange={e => {
								setDraftComment(e.target.value);
								dispatch(updateDraftComment({ draftComment: e.target.value }));
							}}
						/>

						{/* Spacer element to match the height of the toolbar */}
						<div className="py-2" aria-hidden="true">
							{/* Matches height of button in toolbar (1px border + 36px content height) */}
							<div className="py-px">
								<div className="h-9" />
							</div>
						</div>
					</div>

					<div className="absolute start-0 bottom-0 flex flex-row-reverse py-2 pl-3 pr-2">
						<Tooltip text={userOS === 'MacOS' ? 'Cmd+Enter' : 'Ctrl+Enter'}>
							<button
								type="button"
								disabled={draftComment.trim().length === 0}
								className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
								onClick={() => {
									dispatch({
										type: 'projectEditor/postComment',
										payload: {
											projectNanoId: nanoid,
											values: { body: draftComment.trim() },
										},
									});
									commentTextArea.current.focus();
									setDraftComment('');
								}}
							>
								{t('Post')}
							</button>
						</Tooltip>
					</div>
				</form>
			</div>
		</div>
	);
}
