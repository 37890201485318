import { tidy, filter, select, map } from '@tidyjs/tidy';
import { indexOf } from 'lodash';
import BulletChart from './BulletChart';
import Table2 from './Table2';
import { useTranslation } from 'react-i18next';

function calculateNpsFromArray(responseArray) {
	let promoterCount = 0;
	let passiveCount = 0;
	let detractorCount = 0;

	responseArray.forEach(response => {
		if (response >= 9) {
			promoterCount++;
		} else if (response < 7) {
			detractorCount++;
		} else {
			passiveCount++;
		}
	});

	return {
		promoterCount: promoterCount,
		passiveCount: passiveCount,
		detractorCount: detractorCount,
		nps:
			responseArray.length > 0
				? Math.round(
						((promoterCount - detractorCount) / responseArray.length) * 100,
				  )
				: 0,
	};
}

export default function NpsQuestion({
	data,
	question,
	comparison,
	labels,
	isCompareMode,
}) {
	const { t } = useTranslation();

	let bullets = [];
	let npsCalculationResult = { nps: 0 };
	let columnHeads = [
		{
			title: '',
			alignLeft: true,
		},
		{
			title: t('Detractors (0-6)'),
		},
		{
			title: t('Passives (7-8)'),
		},
		{
			title: t('Promoters (9-10)'),
		},
		{
			title: 'NPS',
		},
	];
	let rows = [];

	if (Object.keys(comparison).length > 0) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					const key =
						rankKey == -1
							? `${comparisonKey}_${comparisonItemKey}`
							: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;

					// TODO: Use analysisFilter util
					const responseArray = tidy(
						data,
						filter(d => {
							const dataType = typeof d[comparisonKey];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[comparisonKey] == comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice & ranking filter items
								const innerDataType =
									d[comparisonKey][0] == null
										? 'string'
										: typeof d[comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									return indexOf(d[comparisonKey], comparisonItemKey) > -1;
								} else if (innerDataType === 'object') {
									let foundItemIndex = d[comparisonKey].findIndex(
										answer =>
											answer.order == rankKey &&
											answer.choice === comparisonItemKey,
									);

									return foundItemIndex > -1;
								}
							}
						}),
						map(d => d[question.nanoid]),
					);

					const npsCalculationResultComparisonItem =
						calculateNpsFromArray(responseArray);

					bullets.push({
						id: key,
						title: labels[key],
						ranges: [-100, 100],
						measures: [],
						markers: [npsCalculationResultComparisonItem['nps']],
					});

					rows.push([
						{
							value: labels[key],
							alignLeft: true,
						},
						{
							value: npsCalculationResultComparisonItem['detractorCount'],
							mono: true,
						},
						{
							value: npsCalculationResultComparisonItem['passiveCount'],
							mono: true,
						},
						{
							value: npsCalculationResultComparisonItem['promoterCount'],
							mono: true,
						},
						{
							value: npsCalculationResultComparisonItem['nps'],
							mono: true,
						},
					]);
				});
			});
		});
	} else {
		const responseArray = tidy(
			data,
			select(question.nanoid),
			map(d => d[question.nanoid]),
		);

		npsCalculationResult = calculateNpsFromArray(responseArray);

		rows.push([
			{
				value: t('Overall'),
				alignLeft: true,
			},
			{
				value: npsCalculationResult['detractorCount'],
				mono: true,
			},
			{
				value: npsCalculationResult['passiveCount'],
				mono: true,
			},
			{
				value: npsCalculationResult['promoterCount'],
				mono: true,
			},
			{
				value: npsCalculationResult['nps'],
				mono: true,
			},
		]);

		bullets.push({
			id: '',
			title: '',
			ranges: [-100, 100],
			measures: [],
			markers: [npsCalculationResult['nps']],
		});
	}

	return (
		<div>
			<div className="mt-5 mb-10">
				{isCompareMode && <BulletChart data={bullets} />}

				{!isCompareMode && (
					<div className=" text-center text-gray-700">
						<div className="text-7xl font-semibold tabular-nums">
							{npsCalculationResult['nps']}
						</div>
						<div>{t('NPS score')}</div>
						<BulletChart data={bullets} />
					</div>
				)}
			</div>
			<div className="px-4 pb-4 sm:px-6">
				<Table2 columnHeads={columnHeads} rows={rows} />
			</div>
		</div>
	);
}
