import React from 'react';
import Alert from '../Alert';

const FormAlert = ({ errorMessage }) => {
	return (
		<Alert type={'error'}>
			<ul>
				{Object.keys(errorMessage).map((key, index) => (
					<li key={index} type={'error'}>
						{errorMessage[key]}
					</li>
				))}
			</ul>
		</Alert>
	);
};

export default FormAlert;
