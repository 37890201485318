import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetBulkChoiceUpdateState } from '../store/projectEditorSlice';
import { useTranslation } from 'react-i18next';
import { isChoiceUsedInLogic } from '../utils';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../components/Modal';

export default function useBulkEdit(question) {
	const dispatch = useDispatch();
	const [showTextArea, setShowTextArea] = useState(false);
	const { t } = useTranslation();
	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);
	const hideTextArea = () => {
		setShowTextArea(false);
	};

	const showWarningModal = choices => {
		return choices.some(choice => isChoiceUsedInLogic(choice, questionList));
	};

	const handleBulkUpdate = () => {
		if (showWarningModal(question.choices)) {
			NiceModal.show(Modal, {
				icon: 'warning',
				title: t('Cannot Bulk Edit Choices'),
				message: t('bulkEditWarning'),
				showPrimaryButton: false,
				secondaryButtonLabel: t('Close'),
			});
		} else {
			dispatch(resetBulkChoiceUpdateState({ question: question }));
			setShowTextArea(true);
		}
	};

	return [showTextArea, hideTextArea, handleBulkUpdate];
}
