import { Formik, Form } from 'formik';
import { FormInput } from '../../../components/TextInput';
import * as Yup from 'yup';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../../components/Alert';
import Spinner from '../../../components/Spinner';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { createProjectStarted } from '../../../store/projectSlice';
import { useEffect, useState } from 'react';
import { setDocumentTitle } from '../../../utils';
import RadioGroup from '../../../components/RadioGroup';
import SimpleRadioTable from '../../../components/SimpleRadioTable';
import { getSubscriptionStatusLabel } from '../../../utils/Subscription';

const CreateProjectValidationSchema = Yup.object().shape({
	title: Yup.string()
		.min(1, 'This title is too short') //t('This title is too short')
		.max(100, 'This title is too long') //t('This title is too long')
		.required('Please give your project a title'), //t('Please give your project a title')
});

export default function CreateProjectPage() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const countryList = [
		{
			id: 'TR',
			name: t('countries.tr.name'),
		},
		{
			id: 'GB',
			name: t('countries.gb.name'),
		},
	];

	const [selectedSubscription, setSelectedSubscription] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(
		window.location.hostname === 'app.sorbunu.co.uk'
			? countryList[1]
			: countryList[0],
	);
	const { subscriptionList, status } = useSelector(
		state => state.projectEditor.subscriptions,
	);

	const projectCreationState = useSelector(
		state => state.project.createProjectData,
	);

	const paymentTypes = [
		{
			id: 'payg',
			title: t('newProject.subscription.paygLabel'),
			description: t('newProject.subscription.paygDescription'),
		},
		{
			id: 'subscription',
			title: t('newProject.subscription.subscriptionLabel'),
			description: t('newProject.subscription.subscriptionDescription'),
		},
	];

	useEffect(() => {
		setDocumentTitle(t('pageTitles.createProject'));
	}, [t]);

	useEffect(() => {
		dispatch({ type: 'projectEditor/loadSubscriptionsStart' });
	}, [dispatch]);

	const subscriptionRadioGroupList = subscriptionList
		.filter(sub => sub.status !== 'canceled')
		.map(sub => {
			return {
				id: sub.nanoid,
				name: sub.plan.name,
				status: getSubscriptionStatusLabel({ t: t, subscription: sub }),
			};
		});

	useEffect(() => {
		if (
			subscriptionRadioGroupList.length > 0 &&
			selectedSubscription === null
		) {
			setSelectedSubscription(subscriptionRadioGroupList[0]);
		}
	}, [subscriptionRadioGroupList, selectedSubscription]);

	return (
		<>
			<div className="h-screen overflow-y-auto flex flex-col justify-center pt-12 pb-24 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						{t('createProject.heading')}
					</h2>
					<p className="mt-2 text-center text-sm text-gray-600">
						{t('createProject.description')}
					</p>
				</div>

				<div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md lg:max-w-2xl">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						{projectCreationState.error && (
							<Alert className="mb-8" type={'error'}>
								{t('serverErrorOccured')}
							</Alert>
						)}
						<Formik
							initialValues={{
								title: '',
								payment_type: 'payg',
								subscription: null,
								country: selectedCountry.id,
							}}
							onSubmit={values => {
								dispatch(
									createProjectStarted({
										values: {
											title: values.title,
											payment_mode: values.payment_type,
											subscription: values.subscription,
											country_code: values.country,
										},
									}),
								);
							}}
							validationSchema={CreateProjectValidationSchema}
						>
							{props => {
								return (
									<Form className="space-y-6">
										<div>
											<label
												htmlFor="email"
												className="block text-sm font-medium text-gray-700"
											>
												{t('Project Title')}
											</label>
											<div className="mt-1">
												<FormInput
													id="title"
													name="title"
													type="text"
													placeholder={t('createProject.marketResearch')}
													errorMessage={t(props.errors.title)}
												/>
											</div>
										</div>

										<div>
											<SimpleRadioTable
												label={t('newProject.country.selectCountryLabel')}
												data={countryList}
												selected={selectedCountry}
												handleChange={item => {
													props.setFieldValue('country', item.id);
													setSelectedCountry(item);
												}}
											/>
										</div>

										<RadioGroup
											label={t('newProject.subscription.paymentModeLabel')}
											selectedItem={find(
												paymentTypes,
												o => o.id === props.values.payment_type,
											)}
											data={paymentTypes}
											handleChange={item => {
												props.setFieldValue('payment_type', item.id);
												props.setFieldValue(
													'subscription',
													item.id === 'subscription' &&
														subscriptionList.length > 0
														? selectedSubscription.id
														: null,
												);
											}}
										/>
										{props.values.payment_type === 'subscription' &&
											status === 'idle' &&
											subscriptionList.length > 0 && (
												<>
													<SimpleRadioTable
														label={t(
															'newProject.subscription.selectSubscriptionLabel',
														)}
														data={subscriptionRadioGroupList}
														selected={selectedSubscription}
														handleChange={item => {
															props.setFieldValue('subscription', item.id);
															setSelectedSubscription(item);
														}}
													/>
												</>
											)}
										{props.values.payment_type === 'subscription' &&
											status === 'idle' &&
											subscriptionList.length === 0 && (
												<Alert type={'warning'}>
													<Trans i18nKey="createProject.noSubscriptionsAvailable">
														You do not have any subscription at the moment.
														Click{' '}
														<a
															className="underline"
															href="mailto:hello@sorbunu.com"
														>
															here
														</a>{' '}
														to reach us and we can get you started.
													</Trans>
												</Alert>
											)}

										{props.values.payment_type === 'subscription' &&
											status === 'loading' && (
												<div className="flex justify-center">
													<Spinner className="w-6 h-6 text,indigo-600" />
												</div>
											)}
										<div>
											<button
												type="submit"
												disabled={
													projectCreationState.fetch ||
													(props.values.payment_type === 'subscription' &&
														subscriptionList.length === 0)
												}
												className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 enabled:hover:bg-indigo-700  disabled:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												{projectCreationState.fetch ? (
													<Spinner className="h-5 w-5" />
												) : (
													t('Continue')
												)}
											</button>
											<Link
												to={'/dashboard'}
												className="w-full  inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2  xl:ml-0  xl:mt-3 xl:w-full"
											>
												{t('Back')}
											</Link>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
}
