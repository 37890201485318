import QuestionPill from '../QuestionPill';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import {
	setActiveQuestion,
	reorderQuestionList,
} from '../../store/projectEditorSlice';

export default function QuestionList({
	questionList,
	activeQuestionIndex,
	isEditable = true,
	isScreeningQuestion = false,
	setShowLogicVisualizer = () => {},
}) {
	const dispatch = useDispatch();

	const handleDrop = droppedItem => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;

		dispatch(
			reorderQuestionList({
				source: droppedItem.source.index,
				destination: droppedItem.destination.index,
				is_screening: isScreeningQuestion,
			}),
		);
	};

	return (
		<div className="mb-80">
			<div className="flex items-center justify-end mb-4"></div>
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId="list-container">
					{provided => (
						<ul
							role="list"
							className="list-container flex flex-col gap-3"
							{...provided.droppableProps}
							ref={provided.innerRef}
						>
							{questionList.map((question, index) => (
								<Draggable
									isDragDisabled={!isEditable}
									key={question.nanoid}
									draggableId={question.nanoid}
									index={index}
								>
									{provided => (
										<li
											className="item-container col-span-1 flex shadow-sm rounded-l-md rounded-r-md"
											ref={provided.innerRef}
											{...provided.dragHandleProps}
											{...provided.draggableProps}
											onClick={() => {
												setShowLogicVisualizer(false);
												dispatch(
													setActiveQuestion({
														question: question,
													}),
												);
											}}
										>
											<QuestionPill
												active={index == activeQuestionIndex}
												index={index}
												questionList={questionList}
												question={question}
												isEditable={isEditable}
												canDelete={
													(isEditable && isScreeningQuestion) ||
													(isEditable &&
														!isScreeningQuestion &&
														questionList.length > 1)
												}
											/>
										</li>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
}
