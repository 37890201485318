import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function postSignOut() {
	const result = await sorbunuApi.post(endPoints.auth.SIGN_OUT);
	return result.data;
}

export async function updateProfileInfo({ nanoid, values }) {
	const result = await sorbunuApi.patch(
		endPoints.users.GET_USER_PROFILE(nanoid),
		values,
	);
	return result.data;
}

export async function resendVerificationEmail({ email }) {
	const result = await sorbunuApi.post(
		endPoints.auth.RESEND_VERIFICATION_EMAIL(),
		{
			email: email,
		},
	);
	return result.data;
}

export async function verifyEmail({ key }) {
	const result = await sorbunuApi
		.post(endPoints.auth.VERIFY_EMAIL(), {
			key: key,
		})
		.then(() => {
			return { status: true };
		})
		.catch(() => {
			return {
				status: false,
			};
		});
	return result;
}
