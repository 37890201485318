import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getQuestionnaireLabels({ questionnaireNanoId }) {
	const result = await sorbunuApi.get(
		endPoints.labels.QUESTIONNAIRE_LABELS(questionnaireNanoId),
	);
	return result.data.results;
}

export async function addLabelToQuestionnaire({
	questionnaireNanoId,
	payload,
}) {
	const result = await sorbunuApi.post(
		endPoints.labels.ADD_QUESTIONNAIRE_LABEL(questionnaireNanoId),
		payload,
	);

	return result.data;
}

export async function addLabelToResponse({ responseNanoId, payload }) {
	const result = await sorbunuApi.post(
		endPoints.labels.ADD_RESPONSE_LABEL(responseNanoId),
		payload,
	);
	return result.data;
}

export async function deleteResponseLabel({
	responseNanoId,
	labelNanoId,
	questionNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.labels.DELETE_RESPONSE_LABEL(
			responseNanoId,
			labelNanoId,
			questionNanoId,
		),
	);
	return result.data.results;
}
