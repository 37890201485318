import React from 'react';
import { useTranslation } from 'react-i18next';

const TextQuestion = ({ question }) => {
	const { t } = useTranslation();

	return (
		<div>
			<form>
				{question.question_type !== 'description' &&
					question.config.is_multiline && (
						<textarea
							disabled={true}
							rows={4}
							className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
							placeholder={t('formPlaceholders.answer')}
						/>
					)}
				{question.question_type !== 'description' &&
					!question.config.is_multiline && (
						<input
							type="text"
							disabled={true}
							placeholder={t('formPlaceholders.answer')}
							className="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
						/>
					)}
			</form>
		</div>
	);
};

export default TextQuestion;
