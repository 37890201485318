import { FolderArrowDownIcon } from '@heroicons/react/24/outline';
import DataListDisplay from './DataListDisplay';

export function FileUploadQuestion({
	data,
	question,
	labels,
	comparison,
	isCompareMode,
	language,
}) {
	const renderValue = files => {
		return (
			<div className="flex flex-wrap gap-2">
				{files.map((file, index) => {
					return (
						<a
							rel="noreferrer"
							target={'_blank'}
							href={file.file_url}
							key={index}
							className="w-1/6 h-32 border border-gray-300 shadow-sm text-center rounded bg-white hover:bg-gray-50 flex flex-col gap-1 justify-center"
						>
							{file.file_type === 'image' && (
								<div className="overflow-hidden pb-2/3">
									<img
										src={file.file_thumbnail}
										className=" h-full w-full object-cover"
									/>
								</div>
							)}

							{file.file_type !== 'image' && (
								<>
									<FolderArrowDownIcon className="w-10 h-10 text-gray-400 mx-auto" />
									<div className="text-gray-600 text-sm">Download</div>{' '}
								</>
							)}
						</a>
					);
				})}
			</div>
		);
	};
	return (
		<>
			<DataListDisplay
				question={question}
				data={data}
				labels={labels}
				comparison={comparison}
				isCompareMode={isCompareMode}
				renderValue={renderValue}
				language={language}
			/>
		</>
	);
}
