import { classNames } from '../../utils';
import { QUESTION_META_DATA } from '../../constants';

export default function Tabs({
	tabs,
	selectedTab,
	onChange,
	className,
	type = 'single_selection', //default indigo color
	size = 'md', // Can be 'xs', 'sm', 'md', 'lg', 'xl'
	isEditable = true,
}) {
	return (
		<span
			className={classNames(
				'relative z-0 inline-flex shadow-sm rounded-md',
				className,
			)}
		>
			{tabs.map((tab, index) => (
				<button
					key={tab.key}
					type="button"
					disabled={!isEditable}
					onClick={() => {
						onChange(tab.key);
					}}
					className={classNames(
						// Default classes
						`w-1/2 md:w-fit justify-center font-medium relative inline-flex items-center border-gray-300 focus:z-10 focus:outline-none focus:ring-1 focus:${QUESTION_META_DATA[type].ringColor} focus:${QUESTION_META_DATA[type].borderColor}`,

						// Styling the selected tab
						selectedTab === tab.key
							? `${QUESTION_META_DATA[type].bgColor} text-white hover:opacity-90`
							: 'bg-white text-gray-700 hover:bg-gray-50',

						// Styling the font
						size === 'xs' ? 'py-1 px-2 text-xs' : '',
						size === 'sm' ? 'px-2.5 py-1.5 text-xs' : '',
						size === 'md' ? 'py-1.5 px-2.5 text-sm' : '',
						size === 'lg' ? 'py-2 px-3 text-sm' : '',
						size === 'xl' ? 'py-2.5 px-3.5 text-sm' : '',

						// Styling the first tab
						index === 0 ? 'rounded-l-md border' : '',

						// Styling middle tabs
						index > 0 && index < tabs.length - 1
							? 'border-t border-b border-r'
							: '',

						// Styling the last tab
						index === tabs.length - 1 ? '-ml-px rounded-r-md border' : '',
					)}
				>
					{tab.title}
				</button>
			))}
		</span>
	);
}
