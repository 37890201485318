import { classNames } from '../../utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionList from '../QuestionList';
import Switch from '../Switch';
import { updateQuestionnaireIsRandomized } from '../../store/projectEditorSlice';
import { sendGoogleEvent } from '../../utils/analytics';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';

export default function QuestionListTabs({
	questionList,
	activeQuestionIndex,
	isEditable,
	setShowLogicVisualizer,
}) {
	const [currentTab, setCurrentTab] = useState('questions');
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const questionnaire = useSelector(state => state.projectEditor.questionnaire);

	const tabs = [
		{
			name: 'Questions',
			key: 'questions',
			current: currentTab === 'questions',
		},
		{
			name: 'Settings',
			className: 'tour-question-list-settings',
			key: 'settings',
			current: currentTab === 'settings',
		},
	];

	const handleQuestionnaireRandomizedChange = selected => {
		dispatch(
			updateQuestionnaireIsRandomized({
				nanoid: questionnaire.nanoid,
				isRandomized: selected,
			}),
		);
		sendGoogleEvent('questionnaire_randomized_change', {
			randomized: selected,
		});
	};
	const isLogicUsedInAnyQuestions = questionList => {
		let result = false;
		questionList.forEach(question => {
			if (question.logic.rules.length > 0) {
				result = true;
			}
		});
		return result;
	};
	const showModal = () => {
		NiceModal.show(Modal, {
			icon: 'warning',
			title: t('questionList.modalTitle'),
			message: t('questionList.modalMsg'),
			showPrimaryButton: false,
			secondaryButtonLabel: t('Close'),
		});
	};

	return (
		<div className="mb-80">
			<div>
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex" aria-label="Tabs">
						{tabs.map(tab => (
							<button
								key={tab.key}
								onClick={() => {
									setCurrentTab(tab.key);
									setShowLogicVisualizer(false);
								}}
								className={classNames(
									tab.className ? tab.className : '',
									tab.current
										? 'border-indigo-500 text-indigo-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									'w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm',
								)}
								aria-current={tab.current ? 'page' : undefined}
							>
								{t(tab.name)}
							</button>
						))}
					</nav>
				</div>
			</div>
			<div className="px-2 xl:px-3">
				{currentTab === 'questions' && (
					<QuestionList
						questionList={questionList}
						activeQuestionIndex={activeQuestionIndex}
						isEditable={isEditable}
						setShowLogicVisualizer={setShowLogicVisualizer}
					/>
				)}
				{currentTab === 'settings' && (
					<div className="mt-3 grid grid-cols-1 gap-6">
						<Switch
							title={t('Randomize')}
							description={t('questionList.switchDesc')}
							checked={questionnaire.isRandomized}
							isDisabled={!isEditable}
							onChange={selected => {
								if (selected && isLogicUsedInAnyQuestions(questionList)) {
									showModal();
								} else {
									handleQuestionnaireRandomizedChange(selected);
								}
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
