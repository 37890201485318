import { classNames } from '../../utils';
import { Switch as HeadlessSwitch } from '@headlessui/react';

export default function Switch({
	title,
	description,
	checked,
	onChange,
	isDisabled,
	lighterLabel = false,
}) {
	return (
		<HeadlessSwitch.Group
			as="div"
			className="flex items-center justify-between"
		>
			<span className="flex-grow flex flex-col">
				<HeadlessSwitch.Label
					as="span"
					className={classNames(
						lighterLabel ? 'text-gray-700' : 'font-medium text-gray-900',
						isDisabled ? 'opacity-30' : '',
						'text-sm',
					)}
					passive
				>
					{title}
				</HeadlessSwitch.Label>
				<HeadlessSwitch.Description as="span" className="text-xs text-gray-500">
					{description}
				</HeadlessSwitch.Description>
			</span>
			<HeadlessSwitch
				disabled={isDisabled}
				checked={checked}
				onChange={onChange}
				className={classNames(
					checked ? 'bg-indigo-600' : 'bg-gray-200',
					isDisabled ? '' : 'cursor-pointer',
					'disabled:cursor-not-allowed disabled:opacity-30',
					'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full  transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
				)}
			>
				<span
					aria-hidden="true"
					className={classNames(
						checked ? 'translate-x-5' : 'translate-x-0',
						'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
					)}
				/>
			</HeadlessSwitch>
		</HeadlessSwitch.Group>
	);
}
