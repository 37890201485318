import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import QuestionListTabs from '../../../components/QuestionListTabs';
import PreviewButton from '../../../components/PreviewButton';
import QuestionEditor from '../../../components/QuestionEditor';
import SlideOver from '../../../components/SlideOver';
import QuestionDetailTabs from '../../../components/QuestionDetailTabs';
import Questionnaire from '../../../components/Questionnaire';
import QuestionSettings from '../../../components/QuestionSettings';
import LogicVisualizer from '../../../components/LogicVisualizer';
import LogicDesigner from '../../../components/LogicDesigner';
import { setDocumentTitle } from '../../../utils';
import TourGuide from '../../../components/TourGuide';

export default function QuestionnairePage({ isEditable }) {
	const { t } = useTranslation();

	const [activateSlideOver, setActivateSlideOver] = useState(false);
	const [showLogicVisualizer, setShowLogicVisualizer] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const [showLogic, setShowLogic] = useState(false);
	const [showQuestionListTabs, setShowQuestionListTabs] = useState(false);
	const [previewQuestionnaireStatus, setPreviewQuestionnaireStatus] =
		useState('question');

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const preview = useSelector(
		state => state.projectEditor.questionnaire.preview,
	);

	const questionnaireNanoId = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);

	const activeQuestionIndex = useSelector(
		state => state.projectEditor.questionnaire.activeQuestionIndex,
	);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.questionnaire'));
	}, [t]);

	const onPreviewQuestionnaireSubmit = () => {
		setPreviewQuestionnaireStatus('thank_you');
	};

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.questionnaire.questionnaire.title'),
			content: t('tour.content.questionnaire.questionnaire.content'),
			title2: 'Anket',
			content2:
				'Bu bölümde, ihtiyacınızı en iyi şekilde adresleyen onlarca soru formuna erişebilir, araştırmanızı tasarlayabilirsiniz.',
			disableBeacon: true,
		},
		{
			target: '.tour-question-list',
			placement: 'auto',
			title: t('tour.content.questionnaire.questionForms.title'),
			content: t('tour.content.questionnaire.questionForms.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-list-settings',
			placement: 'auto',
			title: t('tour.content.questionnaire.questionFormSettings.title'),
			content: t('tour.content.questionnaire.questionFormSettings.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-editor',
			placement: 'bottom',
			title: t('tour.content.questionnaire.questionFormEditor.title'),
			content: t('tour.content.questionnaire.questionFormEditor.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-editor-add-choice',
			placement: 'auto',
			title: t('tour.content.questionnaire.addChoice.title'),
			content: t('tour.content.questionnaire.addChoice.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-editor-add-question',
			placement: 'auto',
			title: t('tour.content.questionnaire.addQuestion.title'),
			content: t('tour.content.questionnaire.addQuestion.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-settings',
			placement: 'auto',
			title: t('tour.content.questionnaire.questionSettings.title'),
			content: t('tour.content.questionnaire.questionSettings.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-question-settings-logic',
			placement: 'auto',
			title: t('tour.content.questionnaire.logic.title'),
			content: t('tour.content.questionnaire.logic.content'),
			disableBeacon: true,
		},
	];

	return (
		<>
			<div className="tour-question-list hidden md:block w-1/2 lg:w-1/3 h-screen bg-white  items-center shadow-md overflow-y-auto">
				<QuestionListTabs
					questionList={questionList}
					activeQuestionIndex={activeQuestionIndex}
					isEditable={isEditable}
					setShowLogicVisualizer={setShowLogicVisualizer}
				/>
			</div>
			<div className="w-full p-3 sm:p-6 sm:pt-3 lg:pt-6 h-screen overflow-y-auto pb-40">
				{activeQuestionIndex > -1 && (
					<div>
						<div className="flex justify-between md:justify-end lg:hidden">
							<div className="md:hidden">
								<button
									type="button"
									className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 mb-2 mr-0.5 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => {
										setShowQuestionListTabs(!showQuestionListTabs);
									}}
								>
									{t('Questions')}
								</button>
							</div>
							<div className="flex justify-center align-center">
								<PreviewButton
									questionnaireNanoId={questionnaireNanoId}
									questionType={questionList[activeQuestionIndex].question_type}
								/>

								<button
									type="button"
									className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 mb-2 mr-0.5 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => {
										setShowSettings(!showSettings);
									}}
								>
									{t('Settings')}
								</button>
								<button
									type="button"
									className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 mb-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
									onClick={() => {
										setShowLogic(!showLogic);
									}}
								>
									{t('Logic')}
								</button>
							</div>
						</div>

						{showLogicVisualizer ? (
							<LogicVisualizer
								questions={questionList}
								activeQuestionIndex={activeQuestionIndex}
							/>
						) : (
							<QuestionEditor
								index={activeQuestionIndex}
								question={questionList[activeQuestionIndex]}
								questionnaireNanoId={questionnaireNanoId}
								isEditable={isEditable}
								setOpen={setActivateSlideOver}
								totalQuestions={questionList.length}
							/>
						)}
					</div>
				)}
			</div>

			<div className="tour-question-settings hidden lg:block w-1/3 h-screen bg-white items-center shadow-md overflow-y-auto">
				{activeQuestionIndex > -1 && (
					<QuestionDetailTabs
						showLogicVisualizer={showLogicVisualizer}
						question={questionList[activeQuestionIndex]}
						isEditable={isEditable}
						index={activeQuestionIndex}
						questionnaireNanoId={questionnaireNanoId}
						setShowLogicVisualizer={setShowLogicVisualizer}
					/>
				)}
			</div>
			<SlideOver
				title={t('projectEditor.slideOver.questionPreviewTitle')}
				description={t('projectEditor.slideOver.questionPreviewDescription')}
				open={activateSlideOver}
				setOpen={setActivateSlideOver}
			>
				{preview.previewData && (
					<Questionnaire
						showPreviousButton={true}
						questionnaireData={preview.previewData}
						currentMode={previewQuestionnaireStatus}
						activeQuestionNanoId={questionList[activeQuestionIndex].nanoid}
						onSubmitQuestionnaire={onPreviewQuestionnaireSubmit}
					/>
				)}
			</SlideOver>

			<SlideOver
				title={t('Questions')}
				open={showQuestionListTabs}
				setOpen={setShowQuestionListTabs}
			>
				<QuestionListTabs
					questionList={questionList}
					activeQuestionIndex={activeQuestionIndex}
					isEditable={isEditable}
				/>
			</SlideOver>

			<SlideOver
				title={t('Settings')}
				open={showSettings}
				setOpen={setShowSettings}
			>
				<QuestionSettings
					question={questionList[activeQuestionIndex]}
					isEditable={isEditable}
					index={activeQuestionIndex}
					questionnaireNanoId={questionnaireNanoId}
				/>
			</SlideOver>
			<SlideOver title={t('Logic')} open={showLogic} setOpen={setShowLogic}>
				<LogicDesigner
					question={questionList[activeQuestionIndex]}
					isEditable={isEditable}
				/>
			</SlideOver>
			<TourGuide tourName={'questionnairePage'} steps={steps} />
		</>
	);
}
