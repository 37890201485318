import React from 'react';
import ChoiceList from '../ChoiceList';

const YesNoQuestion = ({
	question,
	showPreviousButton,
	handlePrevious,
	isPreviousDisabled,
	handleQuestionSubmit,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	lastQuestion,
}) => {
	return (
		<ChoiceList
			question={question}
			canAdd={false}
			canDelete={false}
			canReorder={false}
			showPreviousButton={showPreviousButton}
			isPreviousDisabled={isPreviousDisabled}
			handlePrevious={handlePrevious}
			handleQuestionSubmit={handleQuestionSubmit}
			handleChoiceImageChange={handleChoiceImageChange}
			handleChoiceImageTooLarge={handleChoiceImageTooLarge}
			lastQuestion={lastQuestion}
		/>
	);
};

export default YesNoQuestion;
