import React from 'react';
import ChoiceList from './ChoiceList';
import BulkEditTextArea from './BulkEditTextArea';
import useBulkEdit from '../../../hooks/useBulkEdit';

const SingleSelectionQuestion = ({
	question,
	answers,
	questionList,
	completeQuestionnaire,
	isEditable = true,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	isScreeningQuestion,
	handleChoiceQualifyChange,
}) => {
	const [showTextArea, hideTextArea, handleBulkUpdate] = useBulkEdit(question);
	return (
		<>
			{showTextArea ? (
				<BulkEditTextArea
					isEditable={isEditable}
					question={question}
					hideTextArea={hideTextArea}
					showTextArea={showTextArea}
				/>
			) : (
				<ChoiceList
					question={question}
					answers={answers}
					questionList={questionList}
					completeQuestionnaire={completeQuestionnaire}
					isEditable={isEditable}
					handleCreateChoice={handleCreateChoice}
					handleReorderChoiceList={handleReorderChoiceList}
					handleChoiceTitleChange={handleChoiceTitleChange}
					handleChoiceImageChange={handleChoiceImageChange}
					handleChoiceImageTooLarge={handleChoiceImageTooLarge}
					handleDeleteChoice={handleDeleteChoice}
					isScreeningQuestion={isScreeningQuestion}
					handleChoiceQualifyChange={handleChoiceQualifyChange}
					handleBulkUpdate={handleBulkUpdate}
				/>
			)}
		</>
	);
};

export default SingleSelectionQuestion;
