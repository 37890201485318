export function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export function scaleBase64Image(dataURL, maxWidth, maxHeight) {
	return new Promise(done => {
		var img = new Image();
		img.onload = () => {
			var scale, newWidth, newHeight, canvas, ctx;
			if (img.width > maxWidth) {
				scale = maxWidth / img.width;
			} else if (img.height > maxHeight) {
				scale = maxHeight / img.height;
			} else {
				scale = 1;
			}
			newWidth = img.width * scale;
			newHeight = img.height * scale;
			canvas = document.createElement('canvas');
			canvas.height = newHeight;
			canvas.width = newWidth;
			ctx = canvas.getContext('2d');

			ctx.drawImage(
				img,
				0,
				0,
				img.width,
				img.height,
				0,
				0,
				newWidth,
				newHeight,
			);
			done(canvas.toDataURL());
		};
		img.src = dataURL;
	});
}

export function displayMentionIfExists(title, answers, questions) {
	const re = /@@@(.{1,16})@@@/g;
	let newstr = title ? title : '';

	const mentions = [...newstr.matchAll(re)];

	if (mentions.length > 0) {
		mentions.forEach(mention => {
			const questionId = mention[1];
			const questionInMention = questions.find(
				question => question.nanoid === questionId,
			);

			let selectedChoiceTitle = '';

			if (questionInMention.question_type === 'multiple_selection') {
				selectedChoiceTitle = answers[questionId]
					.map(answer => {
						return questionInMention.choices.find(choice => {
							return choice.nanoid === answer;
						}).title;
					})
					.join(', ');
			}
			if (
				questionInMention.question_type === 'single_selection' ||
				questionInMention.question_type === 'yes_no'
			) {
				selectedChoiceTitle = questionInMention.choices.find(
					choice => choice.nanoid === answers[questionId],
				).title;
			}
			if (questionInMention.question_type === 'ranking') {
				selectedChoiceTitle = answers[questionId]
					.map(answer => {
						const choiceTitle = questionInMention.choices.find(
							choice => choice.nanoid === answer.choice,
						).title;
						return `${choiceTitle}: ${answer.order}`;
					})
					.join(', ');
			}
			if (questionInMention.question_type === 'matrix') {
				selectedChoiceTitle = answers[questionId]
					.map(answer => {
						const row = questionInMention.choices.find(
							choice => choice.nanoid === answer.choice,
						).title;

						const columns = answer.columns.map(column => {
							return questionInMention.choices.find(
								choice => choice.nanoid === column.choice,
							);
						});
						const multipleColumns = columns
							.map(col => `${col.title}`)
							.join(', ');
						return `${row}: ${multipleColumns}`;
					})
					.join(', ');
			}
			if (questionInMention.question_type === 'opinion_scale') {
				selectedChoiceTitle = answers[questionId].toString();
			}

			if (
				questionInMention.question_type === 'text' ||
				questionInMention.question_type === 'number' ||
				questionInMention.question_type === 'rating' ||
				questionInMention.question_type === 'nps'
			) {
				selectedChoiceTitle = answers[questionId];
			}

			newstr = newstr.replace(mention[0], selectedChoiceTitle);
		});
	}
	return newstr;
}
