import { object, string, ref } from 'yup';

export const signUpValidationSchema = object({
	email: string()
		.nullable()
		.required('You need to provide {{name}}')
		.email('Please enter a valid email'),
	password1: string()
		.nullable()
		.required('You need to provide {{name}}')
		.matches(
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^&*-.,/']).{9,}$/,

			{
				excludeEmptyString: true,
				message: 'Must contain characters message', //t('Must contain characters message')
			},
		),
	first_name: string().nullable().required('You need to provide {{name}}'),
	last_name: string().nullable().required('You need to provide {{name}}'),
	company_name: string().nullable().required('You need to provide {{name}}'),
	password2: string()
		.nullable()
		.required('You need to provide {{name}}')
		.oneOf([ref('password1'), null], 'Passwords must match'), //t('Passwords must match')
});

export const changePasswordValidationSchema = object({
	old_password: string().nullable().required('You need to provide {{name}}'),
	new_password1: string()
		.nullable()
		.required('You need to provide {{name}}')
		.matches(
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^&*-.,/']).{9,}$/,
			{
				excludeEmptyString: true,
				message: 'Must contain characters message',
			},
		),
	new_password2: string()
		.nullable()
		.required('You need to provide {{name}}')
		.oneOf([ref('new_password1'), null], 'Passwords must match'),
});

export const updateProfileValidationSchema = object({
	first_name: string()
		.nullable()
		.required('This field cannot be empty {{name}}'), //t('This field cannot be empty {{name}}')
	last_name: string()
		.nullable()
		.required('This field cannot be empty {{name}}'),
	email: string()
		.nullable()
		.required('This field cannot be empty {{name}}')
		.email('Please enter a valid email'),
});
