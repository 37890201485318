import { useToaster } from 'react-hot-toast';
import Toast from '../Toast';

export default function ToastArea() {
	const { toasts, handlers } = useToaster();
	const { startPause, endPause } = handlers;

	return (
		<div
			className="fixed w-96 right-0 z-10 pointer-events-none flex flex-col items-center px-4 py-6 sm:p-6 space-y-4 sm:items-end"
			onMouseEnter={startPause}
			onMouseLeave={endPause}
		>
			{toasts.map(toast => (
				<Toast
					key={toast.id}
					type={toast.type}
					visible={toast.visible}
					title={toast.title}
					message={toast.message}
				/>
			))}
		</div>
	);
}
