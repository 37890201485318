import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function updateNotificationSettings(nanoid, notificationPayload) {
	const result = await sorbunuApi.patch(
		endPoints.notifications.UPDATE_OFFERS_SETTING(nanoid),
		{ comms_optin_email: notificationPayload },
	);
	return result.data;
}
