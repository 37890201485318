import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Buttons } from '../../../components/Button';
import { FormInput } from '../../../components/TextInput';
import { ChangePasswordValues } from '../../../utils/Form/InitialValues';
import { changePasswordValidationSchema } from '../../AuthPages/SignUp/constant';
import { changePassword } from '../../../store/actions/authActions';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../../utils/analytics';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils';
import { toast } from 'react-hot-toast';
import Spinner from '../../../components/Spinner';

export default function PasswordSettingsPage() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const spin = useSelector(state => state.auth.spin);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.passwordSettings'));
	}, [t]);

	const submitChangePassword = async values => {
		const { message, success } = await dispatch(changePassword(values));

		sendGoogleEvent('account_password_change');
		success
			? toast.success(message, { title: t('Success') })
			: toast.error(message, { title: t('Error') });
	};

	return (
		<section aria-labelledby="payment-details-heading">
			<div className="shadow sm:rounded-md sm:overflow-hidden">
				<div className="bg-white ">
					<div className="p-6">
						<h2
							id="payment-details-heading"
							className="text-lg leading-6 font-medium text-gray-900"
						>
							{t('passwordSettings.heading')}
						</h2>
						<p className="mt-1 text-sm text-gray-500">
							{t('passwordSettings.description')}
						</p>
					</div>
					<div className="mt-1">
						<Formik
							initialValues={ChangePasswordValues}
							validationSchema={changePasswordValidationSchema}
							onSubmit={submitChangePassword}
						>
							{props => (
								<div>
									<Form className="space-y-6">
										<div className="space-y-1 px-6">
											<FormInput
												id="old_password"
												name="old_password"
												type="password"
												label={t('formLabels.currentPassword')}
												placeholder={t('formPlaceholders.password')}
												errorMessage={t(props.errors.old_password, {
													name: t('formLabels.password'),
												})}
											/>
										</div>

										<div className="space-y-1 px-6">
											<FormInput
												id="new_password1"
												name="new_password1"
												type="password"
												label={t('formLabels.newPassword')}
												placeholder={t('formPlaceholders.password')}
												errorMessage={t(props.errors.new_password1, {
													name: t('formLabels.password'),
												})}
											/>
										</div>
										<div className="space-y-1 px-6">
											<FormInput
												id="new_password2"
												name="new_password2"
												type="password"
												label={t('formLabels.newPasswordConfirm')}
												placeholder={t('formPlaceholders.password')}
												errorMessage={t(props.errors.new_password2, {
													name: t('formLabels.password'),
												})}
											/>
										</div>
										<div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
											<Buttons
												type="submit"
												kind="primary"
												disabled={!(props.dirty && props.isValid) || spin}
											>
												{spin ? <Spinner className="w-5 h-5" /> : t('Save')}
											</Buttons>
										</div>
									</Form>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</section>
	);
}
