import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../utils';
import { find } from 'lodash';

export default function SimpleRadioTable({
	label,
	data,
	selected,
	handleChange,
}) {
	const selectedItem = selected
		? find(data, item => item.id === selected.id)
		: null;

	return (
		<RadioGroup
			value={selectedItem}
			onChange={item => {
				handleChange(item);
			}}
		>
			<RadioGroup.Label className="text-sm font-medium text-gray-700">
				{label}
			</RadioGroup.Label>
			<div className="mt-1 relative -space-y-px rounded-md bg-white">
				{data.map((item, itemIndex) => (
					<RadioGroup.Option
						key={item.id}
						value={item}
						className={({ checked }) =>
							classNames(
								itemIndex === 0 ? 'rounded-tl-md rounded-tr-md' : '',
								itemIndex === data.length - 1
									? 'rounded-bl-md rounded-br-md'
									: '',
								checked
									? 'z-10 border-indigo-200 bg-indigo-50'
									: 'border-gray-200',
								'relative flex cursor-pointer flex-row justify-between border p-4 focus:outline-none md:pl-4 md:pr-6',
							)
						}
					>
						{({ active, checked }) => (
							<>
								<span className="flex items-center text-sm">
									<span
										className={classNames(
											checked
												? 'bg-indigo-600 border-transparent'
												: 'bg-white border-gray-300',
											active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
											'h-4 w-4 rounded-full border flex items-center justify-center',
										)}
										aria-hidden="true"
									>
										<span className="rounded-full bg-white w-1.5 h-1.5" />
									</span>
									<RadioGroup.Label
										as="span"
										className={classNames(
											checked ? 'text-indigo-900' : 'text-gray-900',
											'ml-3 font-medium',
										)}
									>
										{item.name}
									</RadioGroup.Label>
								</span>

								<RadioGroup.Description
									as="span"
									className={classNames(
										checked ? 'text-indigo-700' : 'text-gray-500',
										'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
									)}
								>
									{item.status}
								</RadioGroup.Description>
							</>
						)}
					</RadioGroup.Option>
				))}
			</div>
		</RadioGroup>
	);
}
