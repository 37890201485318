import { put, select, takeEvery } from 'redux-saga/effects';
import {
	createRule,
	updateRule,
	deleteRule,
} from '../../../../api/services/Rule';
import { getQuestionByNanoId } from '../../../../utils';
import { finishAddingRule } from '../../../projectEditorSlice';

function* createOrUpdateRuleSaga(mode, action) {
	const questionList = yield select(
		state => state.projectEditor.questionnaire.questionList,
	);

	let rule = null;

	const question = getQuestionByNanoId(
		questionList,
		action.payload.question.nanoid,
	);

	if (mode === 'create') {
		rule = action.payload.rule;
	} else if (mode === 'update') {
		const foundIndex = question.logic.rules.findIndex(_rule => {
			return _rule.nanoid === action.payload.rule.nanoid;
		});

		rule = question.logic.rules[foundIndex];
	}

	const conditions = [];

	rule.conditions.forEach(condition => {
		const _question = getQuestionByNanoId(questionList, condition.question);

		conditions.push({
			type: condition.type,
			question: _question.nanoid,
			check: condition.check,
			value: condition.value,
		});
	});

	const requestData = {
		questionnaireNanoId: action.payload.questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
		logicNanoId: action.payload.question.logic.nanoid,
		payload: {
			operator: action.payload.operator
				? action.payload.operator
				: action.payload.rule.operator,
			then: action.payload.rule.then, // Overriden below when in update mode
			conditions: conditions,
		},
	};

	if (mode === 'update') {
		requestData['questionnaireNanoId'] = action.payload.question.questionnaire;
		requestData['ruleNanoId'] = action.payload.rule.nanoid;
		requestData['payload']['then'] = action.payload.then
			? action.payload.then
			: action.payload.rule.then;
	}

	if (mode === 'create') {
		const newRule = yield createRule(requestData);

		yield put(
			finishAddingRule({
				question: question,
				rule: { nanoid: newRule.nanoid, ...action.payload.rule },
			}),
		);
	} else if (mode === 'update') {
		yield updateRule(requestData);
	}
}

function* deleteRuleSaga(action) {
	// TODO: Why not payload parameter?
	const questionnaireNanoId = yield select(
		state => state.projectEditor.questionnaire.nanoid,
	);

	yield deleteRule({
		questionnaireNanoId: questionnaireNanoId,
		preQuestionNanoId: action.payload.question.nanoid,
		logicNanoId: action.payload.question.logic.nanoid,
		ruleNanoId: action.payload.rule.nanoid,
	});
}

export function* watchAddRule() {
	yield takeEvery('projectEditor/addRule', createOrUpdateRuleSaga, 'create');
}

export function* watchUpdateRule() {
	yield takeEvery(
		[
			'projectEditor/addCondition',
			'projectEditor/updateConditionCheck',
			'projectEditor/updateConditionValue',
			'projectEditor/updateConditionQuestion',
			'projectEditor/deleteCondition',
			'projectEditor/updateRuleTarget',
			'projectEditor/updateRuleOperator',
		],
		createOrUpdateRuleSaga,
		'update',
	);
}

export function* watchDeleteRule() {
	yield takeEvery('projectEditor/deleteRule', deleteRuleSaga);
}
