import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { classNames } from '../../utils';

export default function MenuLink({ name, href, item }) {
	const resolved = useResolvedPath(href);
	const current = useMatch({ path: resolved.pathname, end: true });

	return (
		<Link
			to={href}
			className={classNames(
				current
					? 'bg-gray-50 text-indigo-600 hover:bg-white'
					: 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
				'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
			)}
			aria-current={current ? 'page' : undefined}
		>
			<item.icon
				className={classNames(
					current
						? 'text-indigo-500'
						: 'text-gray-400 group-hover:text-gray-500',
					'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
				)}
				aria-hidden="true"
			/>
			<span className="truncate">{name}</span>
		</Link>
	);
}
