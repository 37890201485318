import { Fragment } from 'react';
import { Listbox as HeadlessListbox, Transition } from '@headlessui/react';
import { CheckIcon, ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Listbox({
	multiple,
	label,
	value,
	items,
	onChange,
	className,
	isEditable = true,
}) {
	const { t } = useTranslation();
	let defaultLabel = 'Select';

	if (value !== null) {
		if (typeof value === 'string' || typeof value === 'boolean') {
			const selectedItem = _.find(items, item => {
				return item.value == value;
			});
			defaultLabel = selectedItem.label;
		} else if (typeof value === 'object' && value.length > 0) {
			const listOfLabels = [];
			_.forEach(value, valueItem => {
				const selectedItem = _.find(items, item => {
					return item.value == valueItem;
				});
				listOfLabels.push(selectedItem.label);
			});
			defaultLabel = _.join(listOfLabels, ', ');
		}
	}

	return (
		<div className={className}>
			<HeadlessListbox
				disabled={!isEditable}
				value={value}
				onChange={onChange}
				multiple={multiple}
			>
				{({ open }) => (
					<>
						<HeadlessListbox.Label className="block text-sm font-medium text-gray-700">
							{label}
						</HeadlessListbox.Label>
						<div className="mt-1 relative">
							<HeadlessListbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
								<span className="block truncate">{t(defaultLabel)}</span>
								<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
									<ArrowsUpDownIcon
										className="h-5 w-5 text-gray-400"
										aria-hidden="true"
									/>
								</span>
							</HeadlessListbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<HeadlessListbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
									{items.map(item => (
										<HeadlessListbox.Option
											key={item.value}
											className={({ active }) =>
												classNames(
													active ? 'text-white bg-indigo-600' : 'text-gray-900',
													'cursor-default select-none relative py-2 pl-3 pr-9',
												)
											}
											value={`${item.value}`}
										>
											{({ selected, active }) => (
												<>
													<span
														className={classNames(
															selected ? 'font-semibold' : 'font-normal',
															'block truncate',
														)}
													>
														{t(item.label)}
													</span>

													{selected ? (
														<span
															className={classNames(
																active ? 'text-white' : 'text-indigo-600',
																'absolute inset-y-0 right-0 flex items-center pr-4',
															)}
														>
															<CheckIcon
																className="h-5 w-5"
																aria-hidden="true"
															/>
														</span>
													) : null}
												</>
											)}
										</HeadlessListbox.Option>
									))}
								</HeadlessListbox.Options>
							</Transition>
						</div>
					</>
				)}
			</HeadlessListbox>
		</div>
	);
}
