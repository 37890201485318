import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getCommentList(projectNanoId) {
	const result = await sorbunuApi.get(
		endPoints.projects.COMMENT_LIST(projectNanoId),
	);
	return result.data.results;
}

export async function postComment(projectNanoId, body) {
	const result = await sorbunuApi.post(
		endPoints.projects.COMMENT_LIST(projectNanoId),
		body,
	);
	return { status: true, data: result.data };
}
