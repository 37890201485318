import { Route, Routes } from 'react-router-dom';
import CrossTabAnalysisPage from './CrossTabAnalysisPage';
import DefaultAnalysisPage from './DefaultAnalysisPage';
import DemographicsPage from './DemographicsPage';
import AnalysisLayout from './AnalysisLayout';

export default function AnalysisPage() {
	return (
		<>
			<Routes>
				<Route element={<AnalysisLayout />}>
					<Route path="/" element={<DefaultAnalysisPage />} />
					<Route path="/demographics" element={<DemographicsPage />} />
				</Route>
				<Route path="/cross-tab" element={<CrossTabAnalysisPage />} />
			</Routes>
		</>
	);
}
