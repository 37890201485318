import { classNames } from '../../utils';
import { useEffect, useState } from 'react';
import QuestionSettings from '../QuestionSettings';
import LogicDesigner from '../LogicDesigner';
import { useTranslation } from 'react-i18next';

export default function QuestionDetailTabs({
	index,
	question,
	questionnaireNanoId,
	isEditable = true,
	setShowLogicVisualizer,
	showLogicVisualizer,
}) {
	const [currentTab, setCurrentTab] = useState('settings');
	const { t } = useTranslation();

	const tabs = [
		{ name: 'Settings', key: 'settings', current: currentTab === 'settings' },
		{
			name: 'Logic',
			className: 'tour-question-settings-logic',
			key: 'logic',
			current: currentTab === 'logic',
		},
	];
	useEffect(() => {
		if (!showLogicVisualizer) setCurrentTab('settings');
	}, [showLogicVisualizer]);
	return (
		<div className="mb-80">
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav className="-mb-px flex" aria-label="Tabs">
						{tabs.map(tab => (
							<button
								key={tab.name}
								onClick={() => {
									if (tab.key === 'logic') {
										setShowLogicVisualizer(true);
									} else {
										setShowLogicVisualizer(false);
									}
									setCurrentTab(tab.key);
								}}
								className={classNames(
									tab.className ? tab.className : '',
									tab.current
										? 'border-indigo-500 text-indigo-600'
										: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
									'w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm',
								)}
								aria-current={tab.current ? 'page' : undefined}
							>
								{t(tab.name)}
							</button>
						))}
					</nav>
				</div>
			</div>
			<div className="p-3">
				{currentTab === 'settings' && (
					<QuestionSettings
						question={question}
						isEditable={isEditable}
						index={index}
						questionnaireNanoId={questionnaireNanoId}
					/>
				)}
				{currentTab === 'logic' && (
					<LogicDesigner question={question} isEditable={isEditable} />
				)}
			</div>
		</div>
	);
}
