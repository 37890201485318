import {
	PaperAirplaneIcon,
	ExclamationCircleIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import NiceModal from '@ebay/nice-modal-react';
import Modal from '../Modal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../Spinner';
import { useEffect, useState } from 'react';
import { clearProjectSubmissionStatus } from '../../store/projectEditorSlice';

export default function ProjectSubmitButton({ nanoid, subscription }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const feasibilityData = useSelector(
		state => state.projectEditor.feasibility.data,
	);
	const feasibilityLoading = useSelector(
		state => state.projectEditor.feasibility.status,
	);
	const { loading, submissionStatus, status } = useSelector(
		state => state.projectEditor.project,
	);

	const costLoading = useSelector(state => state.projectEditor.cost.status);

	const questionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.hasErrors,
	);
	const remainingTotal = useSelector(
		state => state.projectEditor.transactions.remainingTotal,
	);

	const { reach: audienceReach, errors: audienceErrors } = useSelector(
		state => state.projectEditor.audience,
	);

	const [hasErrors, setHasErrors] = useState(true);
	const [errorTexts, setErrorTexts] = useState([]);

	const isSubscription = subscription === null ? false : true;
	const subscriptionStatus = subscription ? subscription.status : null;

	const responseLimit =
		subscription?.plan.response_limit - subscription?.usage.responses;

	const isAudienceReachPlausible = responseLimit - audienceReach >= 0;

	const isSubscriptionStatusOk =
		subscriptionStatus === 'active' || subscriptionStatus === 'canceling';

	useEffect(() => {
		let newErrorState = true;
		if (
			//check if project has any errors preventing submission
			feasibilityData &&
			feasibilityData.is_feasible &&
			audienceErrors.length === 0 &&
			!questionnaireHasErrors &&
			(remainingTotal <= 0 ||
				(isSubscription && isSubscriptionStatusOk && isAudienceReachPlausible))
		) {
			newErrorState = false;
		}

		setHasErrors(newErrorState);
	}, [
		feasibilityData,
		questionnaireHasErrors,
		audienceErrors,
		remainingTotal,
		isSubscription,
		isSubscriptionStatusOk,
		isAudienceReachPlausible,
	]);

	const handleClick = () => {
		if (!user.is_verified) {
			NiceModal.show(Modal, {
				icon: 'warning',
				message: t('emailVerification.notVerifiedYetMessage'),
				showPrimaryButton: false,
				secondaryButtonLabel: t('Close'),
				closeOnOutsideClick: false,
			});
		} else {
			dispatch({
				type: 'projectEditor/updateProjectStatus',
				payload: {
					nanoid: nanoid,
					status: 'under_approval',
				},
			});
		}
	};
	useEffect(() => {
		const errorMessages = [];
		//check if payment mode is subscription and if subscription is "active" or "canceling"
		if (isSubscription && !isSubscriptionStatusOk) {
			if (subscriptionStatus === 'cancelled') {
				errorMessages.push(
					t('projectEditor.projectSubmit.subscriptionStatusIssue.cancelled'),
				);
			}
			if (subscriptionStatus === 'expired') {
				errorMessages.push(
					t('projectEditor.projectSubmit.subscriptionStatusIssue.expired'),
				);
			}
			setErrorTexts(errorMessages);
			return;
		}
		//if project payment mode is subscription check if audience reach is not more than remaining response limit
		if (isSubscription && !isAudienceReachPlausible) {
			errorMessages.push(t('projectEditor.projectSubmit.responseLimitIssue'));
		}
		//check if quota has errors
		if (audienceErrors.length > 0) {
			errorMessages.push(t('projectEditor.projectSubmit.audienceQuotaIssue'));
		}
		//check if project is feasible
		if (feasibilityData && !feasibilityData.is_feasible) {
			errorMessages.push(t('projectEditor.projectSubmit.audienceIssue'));
		}
		//check if questionnaire has any errors
		if (questionnaireHasErrors) {
			errorMessages.push(t('projectEditor.projectSubmit.questionnaireIssue'));
		}
		//check if project payment mode is subscription or payg,if payg, check if payment received
		if (!isSubscription && remainingTotal > 0) {
			errorMessages.push(t('projectEditor.projectSubmit.paymentIssue'));
		}

		setErrorTexts(errorMessages);
	}, [
		feasibilityData,
		questionnaireHasErrors,
		audienceErrors.length,
		remainingTotal,
		isSubscription,
		isSubscriptionStatusOk,
		subscriptionStatus,
		isAudienceReachPlausible,
		t,
	]);

	useEffect(() => {
		if (submissionStatus === 'submitted') {
			NiceModal.show(Modal, {
				title: t('projectEditor.projectSubmit.successTitle'),
				message: t('projectEditor.projectSubmit.submitSuccessText'),
				showPrimaryButton: false,
				secondaryButtonLabel: t('Continue'),
				closeOnOutsideClick: false,
				icon: 'success',
				onSecondaryButtonClick: () => {
					navigate('/dashboard');
				},
			});
			dispatch(clearProjectSubmissionStatus());
		}
	}, [submissionStatus, dispatch, navigate, t]);

	return (
		<div className="group relative">
			<span>
				{errorTexts.length > 0 && (
					<span className="w-96 shadow hidden lg:block z-50 pointer-events-none absolute border border-gray-300 text-xs mb-1 top-12 -translate-x-3/4 rounded bg-white text-gray-800 opacity-0 transition  before:absolute before:right-10 before:bottom-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-b-gray-400 group-hover:opacity-100">
						<div className="flex flex-col gap-2 p-4">
							{errorTexts.map((errorText, index) => {
								return (
									<div key={index} className="flex gap-2">
										<div className="w-5">
											<ExclamationCircleIcon
												className="h-5 w-5 text-gray-500"
												aria-hidden="true"
											/>
										</div>
										<div>{errorText}</div>
									</div>
								);
							})}
						</div>
					</span>
				)}
				<button
					type="submit"
					className="transition ease-in-out hover:scale-110 duration-300 disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
					onClick={handleClick}
					disabled={
						loading ||
						hasErrors ||
						(!isSubscription &&
							status !== 'draft' &&
							status !== 'under_edit') ||
						feasibilityLoading === 'loading' ||
						costLoading === 'loading'
					}
				>
					{loading ? (
						<Spinner className="-ml-0.5 mr-2 h-4 w-4" />
					) : (
						<PaperAirplaneIcon
							className="-ml-0.5 mr-2 h-4 w-4"
							aria-hidden="true"
						/>
					)}

					{t('Submit')}
				</button>
			</span>
		</div>
	);
}
