import { slice } from 'lodash';
import Pagination from '../Pagination';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PAGE_SIZE = 5;

export default function CountTable({
	sortedRows,
	paginationItemName,
	firstColName,
	secondColName,
	isCompareMode = false,
	compareRows,
	textSize,
}) {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);

	const startIndex = (page - 1) * PAGE_SIZE;
	const endIndex = startIndex + PAGE_SIZE;

	return (
		<div className="overflow-x-scroll shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-2 mb-4">
			<table className="min-w-full divide-y divide-gray-300">
				<thead className="bg-gray-50">
					{!isCompareMode && (
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left"
							>
								{firstColName}
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right"
							>
								{secondColName}
							</th>
						</tr>
					)}
					{isCompareMode && (
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 w-48 text-left "
							>
								{t('Comparison Item')}
							</th>
							{sortedRows.map((item, index) => (
								<th
									key={index}
									scope="col"
									className="px-3 py-3.5 text-xs font-medium text-gray-900 text-right w-20 break-words"
								>
									{item.country}
								</th>
							))}
						</tr>
					)}
				</thead>
				<tbody className="divide-y divide-gray-200 bg-white">
					{!isCompareMode && (
						<>
							{sortedRows.length === 0 && (
								<tr>
									<td colSpan={2} className="text-center text-gray-500 py-4">
										{t('No responses to display here.')}
									</td>
								</tr>
							)}
							{sortedRows.length > 0 && (
								<>
									{slice(sortedRows, startIndex, endIndex).map((row, index) => {
										return (
											<tr key={index}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6">
													<div className="flex flex-col gap-2">
														{row['country']}
													</div>
												</td>
												<td className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono">
													<span>{row['count']}</span>
												</td>
											</tr>
										);
									})}
									<tr className="border-none">
										<td colSpan={4}>
											<Pagination
												currentPage={page}
												startIndex={startIndex + 1}
												endIndex={
													endIndex <= sortedRows.length
														? endIndex
														: sortedRows.length
												}
												totalItemCount={sortedRows.length}
												handlePageChange={setPage}
												textSize={textSize}
												itemName={paginationItemName}
											/>
										</td>
									</tr>
								</>
							)}
						</>
					)}
					{isCompareMode &&
						compareRows.map((row, index) => (
							<tr key={index}>
								<td className="whitespace-pre-lines py-4 pl-4 pr-3 text-xs text-gray-900 sm:pl-6 break-words">
									{row.answer}
								</td>
								{row.choices.map((item, index) => (
									<td
										key={index}
										className="whitespace-nowrap text-right px-3 py-4 text-xs text-gray-500 font-mono"
									>
										<span>{item.responseCount || 0}</span>
									</td>
								))}
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
}
