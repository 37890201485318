import TextareaAutosize from 'react-textarea-autosize';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useRef, useState } from 'react';
import { intToExcelCol } from 'excel-column-name';
import { ArrowsUpDownIcon } from '@heroicons/react/24/solid';
import Spinner from '../../Spinner';
import { useEffect } from 'react';
import { scaleBase64Image } from '../../../utils';
import { useTranslation } from 'react-i18next';
import ValidationMessage from '../ValidationMessage';
import { find } from 'lodash';
import { classNames } from '../../../utils';
import Switch from '../../Switch';

export default function ChoicePill({
	question,
	index,
	choice,
	canDelete,
	isEditable = true,
	isLetterCodeHidden,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
	handleChoiceQualifyChange,
	handleOnKeyDown,
	refs,
	isScreeningQuestion = false,
}) {
	const [picture, setPicture] = useState(choice.media);
	const [isUploading, setIsUploading] = useState(false);
	const inputFile = useRef(null);
	const { t } = useTranslation();

	const choiceErrorObject = question.errors?.choices
		? find(question.errors?.choices, i => i.nanoid === choice.nanoid)
		: null;
	const errors = choiceErrorObject ? choiceErrorObject.errors.title : [];

	useEffect(() => {
		setIsUploading(false);
	}, [choice.media]);

	const handleImage = event => {
		setIsUploading(true);
		var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);

		const maxFileSize = 7 * 1024 * 1024;

		if (event.target.files[0].size > maxFileSize) {
			setIsUploading(false);
			handleChoiceImageTooLarge();
			return;
		}

		reader.onload = () => {
			scaleBase64Image(reader.result, 1280, 800).then(
				async resizedImageBase64 => {
					handleChoiceImageChange({
						question: question,
						choiceIndex: index,
						choice: choice,
						newImage: resizedImageBase64,
					});
				},
			);
		};
		reader.onerror = () => {
			setIsUploading(false);
		};
	};

	return (
		<>
			<>
				<div
					className={
						'relative  w-full sm:w-9/12 group-one p-2 bg-indigo-100 border border-1 border-indigo-600 rounded'
					}
				>
					{picture && (
						<>
							<input
								type="file"
								ref={inputFile}
								onChange={handleImage}
								disabled={!isEditable}
								style={{ display: 'none' }}
							/>
							{!choice.media && (
								<div
									className="bg-indigo-200 rounded p-2 mb-2 place-content-center h-36 min-h-full flex items-center"
									onClick={() => inputFile.current.click()}
								>
									{!isUploading && (
										<PhotoIcon className="w-8 h-8 text-indigo-600" />
									)}
									{isUploading && (
										<Spinner className="h-6 w-6 text-indigo-600" />
									)}
								</div>
							)}
							{choice.media && (
								<div className="relative group">
									{isUploading && (
										<div className="absolute left-1/2 top-1/2 -ml-5 -mt-5 p-2 bg-indigo-100 rounded opacity-90">
											<Spinner className="h-10 w-10 text-indigo-800" />
										</div>
									)}
									{!isUploading && isEditable && (
										<div className="absolute left-1/2 top-1/2 -ml-3 -mt-3 p-2 bg-indigo-100 rounded transition-opacity ease-in-out opacity-0 group-hover:opacity-90">
											<TrashIcon
												onClick={() => {
													handleChoiceImageChange({
														question: question,
														choiceIndex: index,
														choice: choice,
														newImage: '',
													});
												}}
												className="cursor-pointer w-6 h-6 text-indigo-600"
											/>
										</div>
									)}
									<img
										className={classNames(
											isEditable ? 'cursor-pointer' : '',
											'rounded mb-2 w-full min-h-full',
										)}
										src={choice.media.url}
										disabled={!isEditable}
										onClick={() => {
											if (isUploading) return;
											inputFile.current.click();
										}}
									/>
								</div>
							)}
						</>
					)}
					<div className="flex flex-row gap-2">
						<span className="p-0.5 w-6 h-6 text-sm text-center font-semibold font-mono bg-indigo-50 border border-1 border-indigo-400 text-indigo-600 rounded">
							{isLetterCodeHidden && (
								<ArrowsUpDownIcon className="mt-0.5 w-4 h-4" />
							)}
							{!isLetterCodeHidden && intToExcelCol(index + 1)}
						</span>
						<TextareaAutosize
							ref={element => (refs.current[index] = element)}
							name="name"
							id="description"
							disabled={question.question_type === 'yes_no' || !isEditable}
							value={choice.title}
							onChange={e => {
								handleChoiceTitleChange({
									question: question,
									choiceIndex: index,
									choice: choice,
									title: e.target.value,
								});
							}}
							onKeyDown={handleOnKeyDown(index)}
							className="resize-none p-0 pr-11  placeholder:italic w-full border-0 border-transparent bg-indigo-100 focus:ring-0 sm:text-sm"
							placeholder={t('Your choice here.')}
							minRows={1}
							maxRows={3}
						/>

						{isEditable && (
							<>
								<div
									className={
										'right-1 absolute bg-indigo-100 transition-opacity opacity-0 group-one-hover:opacity-100 flex'
									}
								>
									{!choice.media && choice.choice_type !== 'other' && (
										<PhotoIcon
											title={choice.choice_type}
											className="cursor-pointer w-6 h-6 text-indigo-600"
											onClick={() => {
												setPicture(!picture);
											}}
										/>
									)}

									{canDelete && choice.choice_type !== 'other' && (
										<TrashIcon
											className="cursor-pointer w-6 h-6 text-indigo-600"
											onClick={() =>
												handleDeleteChoice({
													question: question,
													index: index,
													choice: choice,
												})
											}
										/>
									)}
								</div>
							</>
						)}
						{isScreeningQuestion && (
							<div className="absolute -right-14 flex items-center justify-center inline-block">
								<Switch
									isDisabled={!isEditable}
									checked={choice.is_qualified}
									onChange={checked => {
										handleChoiceQualifyChange({
											question: question,
											choice: choice,
											isQualified: checked,
										});
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</>

			{errors.map((error, index) => (
				<ValidationMessage key={index} message={error} className="mt-1" />
			))}
		</>
	);
}
