import { resetPassword } from '../../../store/actions/authActions';
import { Form, Formik } from 'formik';
import { FormInput } from '../../../components/TextInput';
import { Buttons } from '../../../components/Button';
import { Logo } from '../../../components/Logo';
import { ResetPasswordValues } from '../../../utils/Form/InitialValues';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../../utils/analytics';
import FormAlert from '../../../components/FormAlert';
import { useEffect } from 'react';
import { setDocumentTitle } from '../../../utils';

const ResetPasswordValidationSchema = Yup.object().shape({
	new_password1: Yup.string()
		.nullable()
		.required('You need to provide {{name}}')
		.matches(
			/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[!@#$%^&*-.,/']).{9,}$/,
			{
				excludeEmptyString: true,
				message: 'Must contain characters message',
			},
		),
	new_password2: Yup.string()
		.nullable()
		.required('You need to provide {{name}}')
		.oneOf([Yup.ref('new_password1'), null], 'Passwords must match'),
});

export default function ResetPassword() {
	const { uid, token } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { error, serverError, formError, tokenError } = useSelector(
		state => state.auth.resetPassword,
	);

	const submitResetPassword = values => {
		dispatch(resetPassword(values, uid, token));
		sendGoogleEvent('reset_password_complete');
	};

	useEffect(() => {
		setDocumentTitle(t('pageTitles.resetPassword'));
	}, [t]);

	return (
		<>
			<div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<Logo className="mx-auto h-12 w-auto" />
					<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
						{t('resetPasswordHeading')}
					</h2>
				</div>

				<div className="sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
					<div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
						{error && (
							<FormAlert
								errorMessage={{
									errorMessage: serverError
										? t('serverErrorOccured')
										: tokenError
										? t('resetPasswordError')
										: formError,
								}}
							/>
						)}

						<Formik
							initialValues={ResetPasswordValues}
							validationSchema={ResetPasswordValidationSchema}
							onSubmit={values => {
								submitResetPassword(values);
							}}
						>
							{props => (
								<div className="mt-6">
									<Form className="space-y-6">
										<div className="space-y-1">
											<FormInput
												id="new_password1"
												name="new_password1"
												type="password"
												label={t('formLabels.newPassword')}
												placeholder={t('formPlaceholders.password')}
												errorMessage={t(props.errors.new_password1, {
													name: t('new password'),
												})}
											/>
										</div>
										<div className="space-y-1">
											<FormInput
												id="new_password2"
												name="new_password2"
												type="password"
												label={t('formLabels.newPasswordConfirm')}
												placeholder={t('formPlaceholders.password')}
												errorMessage={t(props.errors.new_password2, {
													name: t('password confirmation'),
												})}
											/>
										</div>

										<Buttons type="submit" fullWidth>
											{t('Save')}
										</Buttons>
									</Form>
								</div>
							)}
						</Formik>
					</div>
				</div>
			</div>
		</>
	);
}
