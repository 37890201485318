import {
	createSlice,
	// createAction
} from '@reduxjs/toolkit';

const initialState = {
	auth: null,
	failAuthData: {
		errors: null,
	},
	isLoggedIn: false,
	spin: false,
	user: null,
	message: {
		isLoading: false,
		success: false,
		error: false,
		message: '',
	},
	removeAccountData: {
		error: false,
		success: false,
		fetch: false,
		message: '',
	},
	emailVerification: {
		status: 'idle',
	},
	resetPassword: {
		error: false,
		success: false,
		message: '',
		serverError: false,
		tokenError: false,
		formError: '',
	},
};

// eslint-disable-next-line import/no-unused-modules
export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		signIn: (state, action) => {
			state.auth = action.payload;
			state.isLoggedIn = true;
			state.failAuthData.errors = null;
		},
		signUp: (state, action) => {
			state.auth = action.payload;
			state.failAuthData.errors = null;
		},
		failSignUp: (state, action) => {
			state.failAuthData.errors = action.payload;
		},
		failSignIn: (state, action) => {
			state.failAuthData.errors = action.payload;
		},
		startSpin: state => {
			state.spin = true;
		},
		stopSpin: state => {
			state.spin = false;
		},

		startUpdateProfile: state => {
			state.spin = true;
		},
		successLoadProfile: (state, action) => {
			state.user = action.payload;
		},
		successUpdateProfile: (state, action) => {
			state.user = action.payload;
			state.message.success = true;
			state.message.error = false;
			state.spin = false;
		},
		failUpdateProfile: (state, action) => {
			state.message.success = false;
			state.message.error = true;
			state.message.message = action.payload;
			state.spin = false;
		},
		resetSuccessState: state => {
			state.message.success = false;
		},
		successEmailVerification: (state, action) => {
			state.emailVerification = {
				data: action.payload,
				isVerified: true,
			};
		},
		failEmailVerification: (state, action) => {
			state.emailVerification = {
				message: action.payload,
				isVerified: false,
			};
		},
		successResetPassword: (state, action) => {
			state.resetPassword = {
				message: action.payload,
				success: true,
				error: false,
				serverError: false,
				formError: '',
				tokenError: false,
			};
		},
		failResetPassword: (state, action) => {
			state.resetPassword = {
				message: action.payload,
				success: false,
				error: true,
				tokenError: action.payload.tokenError,
				serverError: action.payload.serverError,
				formError: action.payload.formError,
			};
		},
		startEmailVerification: state => {
			state.emailVerification.status = 'loading';
		},
		setEmailVerified: state => {
			state.auth.user.is_verified = true;
			state.emailVerification.status = 'succeeded';
		},
		verifyEmailFailed: state => {
			state.emailVerification.status = 'failed';
		},
	},
});

// eslint-disable-next-line import/no-unused-modules
export const {
	signIn,
	failSignIn,
	signUp,
	failSignUp,
	startSpin,
	stopSpin,
	startUpdateProfile,
	successUpdateProfile,
	failUpdateProfile,
	successLoadProfile,
	successEmailVerification,
	failEmailVerification,
	resetSuccessState,
	successResetPassword,
	failResetPassword,
	startEmailVerification,
	setEmailVerified,
	verifyEmailFailed,
} = authSlice.actions;

export default authSlice.reducer;
