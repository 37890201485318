import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NextButton from '../NextButton';
import { classNames } from '../utils/utils';

function CharacterCount({ currentValue, maxCharacters }) {
	return (
		<div
			className={classNames(
				currentValue.length > maxCharacters ? 'text-red-600' : 'text-gray-600',
				'text-xs font-medium text-right mt-2',
			)}
		>
			{currentValue.length}/{maxCharacters}
		</div>
	);
}

const TextQuestion = ({
	question,
	showPreviousButton,
	isPreviousDisabled,
	handlePrevious,
	handleQuestionSubmit,
	lastQuestion,
}) => {
	const { t } = useTranslation();
	const isRequired = question.config.required;
	const minCharacters = question.config.min_characters;
	const maxCharacters = question.config.max_characters;
	const [values, setValues] = useState('');
	const [error, setError] = useState(isRequired);
	const [message, setMessage] = useState('');
	const inputChangeHandler = e => {
		setValues(e.target.value);
	};

	useEffect(() => {
		if (question.question_type === 'description') {
			setError(false);
		} else {
			if (isRequired) {
				values.length >= minCharacters && values.length <= maxCharacters
					? setError(false)
					: setError(true);
			}
			if (values) {
				if (values.length < minCharacters) {
					setMessage(
						t(
							'previewQuestionnaire.questionTypes.text.validations.minCharacters',
							{
								minCharacters: minCharacters,
							},
						),
					);
				} else if (values.length > maxCharacters) {
					setMessage(
						t(
							'previewQuestionnaire.questionTypes.text.validations.maxCharacters',
							{
								maxCharacters: maxCharacters,
							},
						),
					);
				} else {
					setMessage(null);
				}
			} // `You must type at least ${minCharacters} characters`
			// `You must type at most ${maxCharacters} characters`
		}
	}, [
		isRequired,
		maxCharacters,
		minCharacters,
		question.question_type,
		t,
		values,
		values.length,
	]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		handleQuestionSubmit({
			question: question,
			answer: values,
		});
	};

	return (
		<div>
			{question.question_type !== 'description' &&
				question.config.is_multiline && (
					<>
						<textarea
							rows={4}
							className="shadow-sm focus:ring-mint-500 focus:border-mint-500 block w-full sm:text-sm border-gray-300 rounded-md"
							placeholder={t(
								'previewQuestionnaire.questionTypes.text.placeholder',
							)}
							onChange={inputChangeHandler}
							value={values}
						/>
						<CharacterCount
							currentValue={values}
							maxCharacters={question.config.max_characters}
						/>
					</>
				)}
			{question.question_type !== 'description' &&
				!question.config.is_multiline && (
					<>
						<input
							type="text"
							value={values}
							placeholder={t(
								'previewQuestionnaire.questionTypes.text.placeholder',
							)}
							className="block w-full shadow-sm focus:ring-mint-500 focus:border-mint-500 sm:text-sm border-gray-300 rounded-md"
							onChange={inputChangeHandler}
						/>
						<CharacterCount
							currentValue={values}
							maxCharacters={question.config.max_characters}
						/>
					</>
				)}
			{message && <p className="p-2 text-xs text-red-500">{message}</p>}
			<NextButton
				disabled={error}
				showPreviousButton={showPreviousButton}
				isPreviousDisabled={isPreviousDisabled}
				handleNext={formSubmissionHandler}
				handlePrevious={handlePrevious}
				lastQuestion={lastQuestion}
			/>
		</div>
	);
};

export default TextQuestion;
