import { Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { classNames, getQuestionTitle } from '../../utils';
import { useSelector } from 'react-redux';
import { QUESTION_META_DATA } from '../../constants';

function Icon({ className }) {
	return (
		<svg
			className={className}
			width="6"
			height="14"
			viewBox="0 0 12 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="4" height="4" fill="#9ca3af" />
			<rect x="8" width="4" height="4" fill="#9ca3af" />
			<rect y="8" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="8" width="4" height="4" fill="#9ca3af" />
			<rect y="16" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="16" width="4" height="4" fill="#9ca3af" />
			<rect y="24" width="4" height="4" fill="#9ca3af" />
			<rect x="8" y="24" width="4" height="4" fill="#9ca3af" />
		</svg>
	);
}

export default function FilterPill({ filter, index, translations, language }) {
	const { t } = useTranslation();

	const _questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);
	const screeningQuestionList = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionList,
	);

	const questionList = [...screeningQuestionList, ..._questionList];

	const questionTypeMap = {};
	questionList.forEach(question => {
		questionTypeMap[question.nanoid] = question.question_type;
	});

	return (
		<Draggable draggableId={filter.nanoid} index={index}>
			{provided => (
				<div
					ref={provided.innerRef}
					className={classNames(
						// Differentiate question filters from audience ones
						'bg-gray-300 rounded-sm',
						questionTypeMap[filter.nanoid]
							? QUESTION_META_DATA[questionTypeMap[filter.nanoid]].bgColor
							: '',
						!questionTypeMap[filter.nanoid]
							? 'bg-gradient-to-b from-cyan-500 to-indigo-500'
							: '',
					)}
					{...provided.dragHandleProps}
					{...provided.draggableProps}
				>
					<div className="flex ml-1 gap-2 py-2 px-1.5 text-xs text-gray-500 bg-gray-100 rounded-sm border border-l-0 border-gray-300 cursor-grab hover:shadow transition-all">
						<Icon className="shrink-0" />
						<span>
							{getQuestionTitle(
								filter.name ? filter.name : t('common.emptyString'),
								questionList,
								translations,
								language,
								filter.nanoid,
								true,
							)}
						</span>
					</div>
				</div>
			)}
		</Draggable>
	);
}
