import { sorbunuApi } from '../axios';
import { endPoints } from '../../endPoints';

export async function createRule({
	questionnaireNanoId,
	preQuestionNanoId,
	logicNanoId,
	payload,
}) {
	const data = {
		operator: payload.operator,
		then: payload.then,
		conditions: payload.conditions,
	};

	const result = await sorbunuApi.post(
		endPoints.rule.CREATE_RULE(
			questionnaireNanoId,
			preQuestionNanoId,
			logicNanoId,
		),
		data,
	);
	return result.data;
}

export async function updateRule({
	questionnaireNanoId,
	preQuestionNanoId,
	logicNanoId,
	ruleNanoId,
	payload,
}) {
	const data = {
		operator: payload.operator,
		then: payload.then,
		conditions: payload.conditions,
	};

	const result = await sorbunuApi.put(
		endPoints.rule.UPDATE_RULE(
			questionnaireNanoId,
			preQuestionNanoId,
			logicNanoId,
			ruleNanoId,
		),
		data,
	);
	return result.data;
}

export async function deleteRule({
	questionnaireNanoId,
	preQuestionNanoId,
	logicNanoId,
	ruleNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.rule.DELETE_RULE(
			questionnaireNanoId,
			preQuestionNanoId,
			logicNanoId,
			ruleNanoId,
		),
	);

	return result.data;
}
