import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	updateNotificationSettings: {
		error: false,
		success: false,
		fetch: false,
		message: '',
		comms_optin_email: false,
	},
};

// eslint-disable-next-line import/no-unused-modules
export const userProfileSlice = createSlice({
	name: 'userProfile',
	initialState,
	reducers: {
		updateNotificationSettingsStarted: state => {
			state.updateNotificationSettings = {
				error: false,
				success: false,
				fetch: true,
				message: '',
			};
		},
		updateNotificationSettingsFinished: (state, action) => {
			state.updateNotificationSettings = {
				error: false,
				success: true,
				fetch: false,
				message: '',
				comms_optin_email: action.payload,
			};
		},
		updateNotificationSettingsFailed: (state, action) => {
			state.updateNotificationSettings = {
				error: true,
				success: false,
				fetch: false,
				message: action.payload,
			};
		},
		resetNotificationSettings: () => initialState,
	},
});

// eslint-disable-next-line import/no-unused-modules
export const {
	updateNotificationSettingsStarted,
	updateNotificationSettingsFinished,
	updateNotificationSettingsFailed,
	resetNotificationSettings,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
