import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormInput } from '../TextInput';
import * as Yup from 'yup';
import Combobox from '../Combobox';
import { find } from 'lodash';
import RadioGroup from '../RadioGroup';
import { restrictInputType } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	loadCitiesStart,
	loadCountriesStart,
	loadDistrictsStart,
} from '../../store/addressReducer';
import { useTranslation } from 'react-i18next';

const addressTypes = [
	{
		id: 'commercial',
		title: 'Commercial', //t('Commercial')
		description: 'commercialDesc', //t('commercialDesc')
	},
	{
		id: 'personal',
		title: 'Personal', //t('Personal')
		description: 'personalDesc', //t('personalDesc')
	},
];

const phoneNumberRegExp =
	/^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;

const identityNumberRegExp = /^[1-9]{1}[0-9]{9}[02468]{1}$/;

const trPostCodeRegExp = /^\b\d{5}\b/;
const gbPostCodeRedExp = /^[A-Z]{1,2}[0-9][A-Z0-9]??[0-9][A-Z]{2}$/;

const AddressFormValidationSchema = Yup.object().shape({
	title: Yup.string().max(150, 'Too Long!').required('Required'), //t('Too Long!)
	first_name: Yup.string().max(150, 'Too Long!').required('Required'), //t('Required')
	last_name: Yup.string().max(150, 'Too Long!').required('Required'),
	email: Yup.string().email('Invalid email').required('Required'), //t('Invalid email')
	country: Yup.object().shape({
		nanoid: Yup.string().required('Please select a country'), //t('Please select a country')
		name: Yup.string(),
	}),
	city: Yup.object().required('Please select a city'), //t('Please select a city')
	district: Yup.object().when('country', {
		is: value => value.name === 'Türkiye',
		then: Yup.object().required('Please select a district'), //t('Please select a district')
		otherwise: Yup.object().notRequired().nullable(),
	}),
	line: Yup.string().max(255, 'Too long!').required('Required'),
	post_code: Yup.string()
		.when('country', {
			is: value => value.name === 'Türkiye',
			then: Yup.string().matches(trPostCodeRegExp, 'Invalid post code'), //t('Invalid post code'),
			otherwise: Yup.string().matches(gbPostCodeRedExp, 'Invalid post code'),
		})

		.required('Required'),
	phone_number: Yup.string()
		.when('country', {
			is: value => value.name === 'Türkiye',
			then: Yup.string().matches(phoneNumberRegExp, 'Invalid phone number'), //t('Invalid phone number')
		})

		.required('Required'),
	identity_number: Yup.string().when(['address_type', 'country'], {
		is: (address_type, country) =>
			address_type === 'personal' && country.name === 'Türkiye',
		then: Yup.string()
			.required('Required')
			.matches(identityNumberRegExp, 'Invalid identity number'), //t('Invalid identity number')
		otherwise: Yup.string().notRequired(),
	}),
	company_name: Yup.string().when('address_type', {
		is: 'commercial',
		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
	tax_office: Yup.string().when(['address_type', 'country'], {
		is: (address_type, country) =>
			address_type === 'commercial' && country.name === 'Türkiye',

		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
	tax_no: Yup.string().when('address_type', {
		is: 'commercial',
		then: Yup.string().required('Required'),
		otherwise: Yup.string().notRequired(),
	}),
});

export default function AddressForm({ address, isLoading, handleAddressSave }) {
	const { t } = useTranslation();
	const countryList = [
		{
			id: 'TR',
			name: t('countries.tr.name'),
		},
		{
			id: 'GB',
			name: t('countries.gb.name'),
		},
	];
	const dispatch = useDispatch();

	const [selectedCountry, setSelectedCountry] = useState(
		window.location.hostname === 'app.sorbunu.co.uk'
			? countryList[1].name
			: countryList[0].name,
	);

	const isCitiesLoading = useSelector(
		state => state.addresses.cities.isLoading,
	);
	const isDistrictsLoading = useSelector(
		state => state.addresses.districts.isLoading,
	);

	const isCountriesLoading = useSelector(
		state => state.addresses.countries.isLoading,
	);

	const citiesDistrictsLoading = isCitiesLoading || isDistrictsLoading;

	const countriesCitiesLoading = isCountriesLoading || isCitiesLoading;

	const countries = useSelector(state => state.addresses.countries.countryList);

	const cities = useSelector(state => state.addresses.cities.cityList);

	const cityDistrictList = useSelector(
		state => state.addresses.districts.cityDistrictList,
	);

	const [initialValues, setInitialValues] = useState({
		title: '',
		email: '',
		address_type: 'commercial',
		country: {},
		city: {},
		district: {},
		line: '',
		post_code: '',
		phone_number: '',
		company_name: '',
		tax_office: '',
		tax_no: '',
		first_name: '',
		last_name: '',
		identity_number: '',
	});

	useEffect(() => {
		if (countries.length === 0) {
			dispatch(
				loadCountriesStart({
					defaultCountryName: selectedCountry,
				}),
			);
		}
	}, [countries.length, dispatch, selectedCountry]);

	useEffect(() => {
		if (cities.length > 0) {
			const cityNanoIdToLoad = address ? address.city.nanoid : cities[0].nanoid;
			dispatch(loadDistrictsStart({ cityNanoId: cityNanoIdToLoad }));
		}
	}, [address, cities, dispatch]);

	useEffect(() => {
		if (!address && countries.length > 0) {
			if (
				Object.keys(cityDistrictList).length > 0 &&
				cityDistrictList[cities[0].nanoid]
			) {
				setInitialValues(values => ({
					...values,
					country: countries.find(country => country.name === selectedCountry),
					district: cityDistrictList[cities[0].nanoid][0],
				}));
			}
		}
	}, [cities, cityDistrictList, countries, selectedCountry, address]);

	useEffect(() => {
		if (!address && cities?.length > 0) {
			setInitialValues(vals => ({ ...vals, city: cities[0] }));
		}
	}, [cities, address]);

	useEffect(() => {
		if (address) {
			setInitialValues(address);
		}
	}, [address]);

	if (!initialValues) {
		return <></>;
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={AddressFormValidationSchema}
			enableReinitialize={true}
			onSubmit={handleAddressSave}
			validateOnChange={true}
			validateOnBlur={false}
		>
			{props => {
				return (
					<Form onSubmit={props.handleSubmit}>
						<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
							<div className="sm:col-span-3">
								<div className="space-y-6">
									<div className="mb-6">
										<FormInput
											id="title"
											name="title"
											type="text"
											label={t('formLabels.addressTitle') + '*'}
											onChange={e => {
												setInitialValues(val => ({
													...val,
													title: e.target.value,
												}));
												props.handleChange(e);
											}}
											value={props.values.title}
											errorMessage={
												props.touched.title && t(props.errors.title)
											}
										/>
									</div>
									<div className="pb-2">
										<RadioGroup
											label={t('Address type')}
											selectedItem={find(
												addressTypes,
												o => o.id === props.values.address_type,
											)}
											data={addressTypes}
											handleChange={item => {
												setInitialValues(val => ({
													...val,
													address_type: item.id,
												}));
												props.setFieldValue('address_type', item.id);
											}}
										/>
									</div>
									<div>
										<div className="flex flex-row gap-4">
											<div className="w-full">
												<FormInput
													id="first_name"
													name="first_name"
													type="text"
													label={t('formLabels.firstName') + '*'}
													onChange={e => {
														restrictInputType(e, props.handleChange, 'letter');
														setInitialValues(val => ({
															...val,
															first_name: e.target.value,
														}));
													}}
													value={props.values.first_name}
													errorMessage={
														props.touched.first_name &&
														t(props.errors.first_name)
													}
												/>
											</div>
											<div className="w-full">
												<FormInput
													id="last_name"
													name="last_name"
													type="text"
													label={t('formLabels.lastName') + '*'}
													value={props.values.last_name}
													onChange={e => {
														props.handleChange(e);
														setInitialValues(val => ({
															...val,
															last_name: e.target.value,
														}));
													}}
													errorMessage={
														props.touched.last_name && t(props.errors.last_name)
													}
												/>
											</div>
										</div>
									</div>
									<div>
										<FormInput
											id="email"
											name="email"
											type="text"
											label={t('formLabels.email') + '*'}
											onChange={e => {
												props.handleChange(e);
												setInitialValues(val => ({
													...val,
													email: e.target.value,
												}));
											}}
											value={props.values.email}
											errorMessage={
												props.touched.email && t(props.errors.email)
											}
										/>
									</div>
									<div>
										<div className="w-full">
											<Combobox
												label={t('formLabels.country')}
												type="text"
												data={countries}
												onChange={async item => {
													props.setFieldValue('country', item);

													setInitialValues(val => ({
														...val,
														country: item,
														city: {},
														district: {},
													}));

													setSelectedCountry(item.name);

													dispatch(
														loadCitiesStart({ countryNanoid: item.nanoid }),
													);

													props.setFieldValue('city', {});
													props.setFieldValue('district', {});
												}}
												selectedItem={props.values.country}
												disabled={countriesCitiesLoading}
											/>
											{props.errors.country && (
												<p
													className="mt-2 text-sm text-red-600"
													id={`country-error`}
												>
													{props.errors.country.nanoid}
												</p>
											)}
										</div>
									</div>

									<div>
										<div className="flex flex-row gap-4">
											<div className="w-full">
												<Combobox
													label={t('City')}
													type="text"
													data={cities}
													onChange={async item => {
														props.setFieldValue('city', item);
														setInitialValues(val => ({
															...val,
															city: item,
															district: {},
														}));

														dispatch(
															loadDistrictsStart({ cityNanoId: item.nanoid }),
														);

														props.setFieldValue('district', {});
													}}
													selectedItem={props.values.city}
													disabled={citiesDistrictsLoading}
												/>
												{props.errors.city && (
													<p
														className="mt-2 text-sm text-red-600"
														id={`city-error`}
													>
														{props.errors.city.nanoid}
													</p>
												)}
											</div>
											{props.values.country.name === 'Türkiye' && (
												<div className="w-full">
													<Combobox
														label={t('District')}
														type="text"
														data={
															cityDistrictList[props.values.city.nanoid]
																? cityDistrictList[props.values.city.nanoid]
																: []
														}
														selectedItem={props.values.district}
														onChange={async item => {
															props.setFieldValue('district', item);
															setInitialValues(val => ({
																...val,
																district: item,
															}));
														}}
														disabled={citiesDistrictsLoading}
													/>
													{props.errors.district && props.touched.district && (
														<p
															className="mt-2 text-sm text-red-600"
															id={`district-error`}
														>
															{t('Please select a district')}
														</p>
													)}
												</div>
											)}
										</div>
									</div>
									<div>
										<FormInput
											id="line"
											name="line"
											type="text"
											label={t('formLabels.addressLine') + '*'}
											onChange={e => {
												props.handleChange(e);
												setInitialValues(val => ({
													...val,
													line: e.target.value,
												}));
											}}
											value={props.values.line}
											errorMessage={props.touched.line && t(props.errors.line)}
										/>
									</div>
									<div>
										<div className="flex flex-row gap-4">
											<div className="w-full">
												<FormInput
													id="post_code"
													name="post_code"
													type="text"
													label={t('formLabels.postCode') + '*'}
													onChange={e => {
														props.handleChange(e);
														setInitialValues(val => ({
															...val,
															post_code: e.target.value,
														}));
													}}
													value={props.values.post_code}
													errorMessage={
														props.touched.post_code && t(props.errors.post_code)
													}
												/>
											</div>
											<div className="w-full">
												<FormInput
													id="phone_number"
													name="phone_number"
													type="text"
													maxLength={10}
													label={t('formLabels.phoneNumber') + '*'}
													placeholder={
														props.values.country.id === 'TR'
															? '5xx xxx xxxx'
															: ''
													}
													onChange={e => {
														restrictInputType(e, props.handleChange, 'number');
														setInitialValues(val => ({
															...val,
															phone_number: e.target.value,
														}));
													}}
													value={props.values.phone_number}
													errorMessage={
														props.touched.phone_number &&
														t(props.errors.phone_number)
													}
												/>
											</div>
										</div>
									</div>
									{props.values.address_type === 'commercial' && (
										<>
											<div>
												<FormInput
													id="company_name"
													name="company_name"
													type="text"
													label={t('formLabels.companyName') + '*'}
													placeholder={t('formPlaceholders.companyName2')}
													onChange={e => {
														props.handleChange(e);
														setInitialValues(val => ({
															...val,
															company_name: e.target.value,
														}));
													}}
													value={props.values.company_name}
													errorMessage={
														props.touched.company_name &&
														t(props.errors.company_name)
													}
												/>
											</div>

											<div>
												<div className="flex flex-row gap-4">
													{props.values.country.name === 'Türkiye' && (
														<div className="w-full">
															<FormInput
																id="tax_office"
																name="tax_office"
																type="text"
																label={t('formLabels.taxOffice') + '*'}
																onChange={e => {
																	props.handleChange(e);
																	setInitialValues(val => ({
																		...val,
																		tax_office: e.target.value,
																	}));
																}}
																value={props.values.tax_office}
																errorMessage={
																	props.touched.tax_office &&
																	t(props.errors.tax_office)
																}
															/>
														</div>
													)}

													<div className="w-full">
														<FormInput
															id="tax_no"
															name="tax_no"
															type="text"
															label={t('formLabels.taxNo') + '*'}
															addOn={
																props.values.country.code === 'GB' ? 'GB' : ''
															}
															onChange={e => {
																props.handleChange(e);
																setInitialValues(val => ({
																	...val,
																	tax_no: e.target.value,
																}));
															}}
															value={props.values.tax_no}
															errorMessage={
																props.touched.tax_no && t(props.errors.tax_no)
															}
														/>
													</div>
												</div>
											</div>
										</>
									)}
									<div>
										{props.values.address_type === 'personal' &&
											props.values.country.name === 'Türkiye' && (
												<FormInput
													id="identity_number"
													name="identity_number"
													type="text"
													maxLength={11}
													label={t('formLabels.identityNumber') + '*'}
													onChange={e => {
														restrictInputType(e, props.handleChange, 'number');
														setInitialValues(val => ({
															...val,
															identity_number: e.target.value,
														}));
													}}
													value={props.values.identity_number}
													errorMessage={
														props.touched.identity_number &&
														t(props.errors.identity_number)
													}
												/>
											)}
									</div>
									<div className="mb-6">
										<button
											type="submit"
											disabled={props.isSubmitting || isLoading}
											className="disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
										>
											{t('Save')}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}
