import { t } from 'i18next';
import { classNames } from '../utils/utils';

export default function NextButton({
	lastQuestion = false,
	showPreviousButton,
	isPreviousDisabled,
	handlePrevious,
	handleNext,
	disabled,
}) {
	const showNextButtonAsSubmitButton = !handleNext;

	return (
		<div
			className={classNames(
				'mt-8 -mx-6 -my-4 px-6 py-4 bg-gray-100',
				showPreviousButton ? 'flex justify-between' : 'text-right',
			)}
		>
			{showPreviousButton && (
				<button
					type="button"
					onClick={handlePrevious}
					disabled={isPreviousDisabled}
					className="disabled:cursor-not-allowed disabled:hover:bg-denepink-600 disabled:opacity-30 items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-denepink-600 hover:bg-denepink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-denepink-500"
				>
					<div className="inline-flex px-4 py-2">
						{t('previewQuestionnaire.previous')}
					</div>
				</button>
			)}
			<button
				disabled={disabled}
				type={showNextButtonAsSubmitButton ? 'submit' : 'button'}
				onClick={showNextButtonAsSubmitButton ? null : handleNext}
				className="disabled:cursor-not-allowed disabled:hover:bg-denepink-600 disabled:opacity-30 items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-denepink-600 hover:bg-denepink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-denepink-500"
			>
				<div className="inline-flex px-4 py-2">
					{lastQuestion
						? t('previewQuestionnaire.finish')
						: t('previewQuestionnaire.next')}
				</div>
			</button>
		</div>
	);
}
