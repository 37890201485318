import { tidy, filter, summarize, mean } from '@tidyjs/tidy';
import { classNames } from '../../utils';
import { RatingHoverIcon } from '../RatingIcon';
import Table from './Table';
import { indexOf } from 'lodash';
import { useTranslation } from 'react-i18next';

const calculateRatingAverage = (comparison, data, question, isCompareMode) => {
	let finalArray = data;
	if (isCompareMode && Object.keys(comparison).length > 0) {
		const comparisonCategory = Object.keys(comparison)[0]; //select the first occuring comparison category
		let comparisonArray = [];
		Object.keys(comparison[comparisonCategory]).forEach(comparisonItemKey => {
			const rankKeys = Object.keys(
				comparison[comparisonCategory][comparisonItemKey],
			);
			const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
			rankArray.forEach(rankKey => {
				const filteredData = tidy(
					data,
					filter(d => {
						const dataType = typeof d[comparisonCategory];
						if (dataType === 'object') {
							// For multiple choice & ranking filter items
							const innerDataType =
								d[comparisonCategory][0] == null
									? 'string'
									: typeof d[comparisonCategory][0];

							if (innerDataType === 'string') {
								//for multiple choice
								return indexOf(d[comparisonCategory], comparisonItemKey) > -1;
							} else if (innerDataType === 'object') {
								let foundItemIndex = d[comparisonCategory].findIndex(
									answer =>
										answer.order == rankKey &&
										answer.choice === comparisonItemKey,
								);

								return foundItemIndex > -1;
							}
						} else {
							return d[comparisonCategory] === comparisonItemKey;
						}
					}),
				);
				comparisonArray.push.apply(comparisonArray, filteredData);
			});
		});
		finalArray = comparisonArray;
	}

	const r = tidy(
		finalArray,
		summarize({
			avg: mean(question.nanoid),
		}),
	);
	const average = isNaN(r[0].avg) ? 0 : r[0].avg;

	return average;
};

export default function RatingQuestion({
	data,
	labels,
	question,
	comparison,
	isCompareMode,
}) {
	const { t } = useTranslation();
	return (
		<>
			<div className="my-10 text-center text-gray-700">
				<div className="text-7xl font-semibold">
					{calculateRatingAverage(
						comparison,
						data,
						question,
						isCompareMode,
					).toFixed(2)}
				</div>
				<div className="mt-4">{t('average')}</div>
				<div className="flex justify-center mt-2">
					{Array.from(
						{
							length: Math.round(
								calculateRatingAverage(
									comparison,
									data,
									question,
									isCompareMode,
								),
							),
						},
						(_, i) => {
							return (
								<RatingHoverIcon
									key={i}
									iconType={question.config.shape}
									className={classNames(
										question.config.shape === 'heart'
											? 'text-red-400'
											: 'text-amber-400',
										'w-7 h-7',
									)}
								/>
							);
						},
					)}
					{Array.from(
						{
							length:
								question.config.number_of_shape -
								Math.round(
									calculateRatingAverage(
										comparison,
										data,
										question,
										isCompareMode,
									),
								),
						},
						(_, i) => {
							return (
								<RatingHoverIcon
									key={i}
									iconType={question.config.shape}
									className={'text-stone-200 w-7 h-7'}
								/>
							);
						},
					)}
				</div>
			</div>
			<div className="px-4 pb-4 sm:px-6">
				<Table
					data={data}
					labels={labels}
					question={question}
					comparison={comparison}
					isCompareMode={isCompareMode}
				/>
			</div>
		</>
	);
}
