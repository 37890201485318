import React, { useState, useEffect, useRef } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { QUESTION_META_DATA } from '../../constants';
import { classNames } from '../../utils';

export default function ResponseSearch({ onChange, value }) {
	const [searchText, setSearchText] = useState(value);
	const inputRef = useRef(null);

	useEffect(() => {
		if (searchText.length > 0) {
			inputRef.current.focus();
		}
	}, [searchText]);

	const handleInputChange = event => {
		const text = event.target.value;
		setSearchText(text);
		onChange(text);
	};

	return (
		<div className="flex justify-end">
			<div className="relative rounded-md shadow-sm">
				<div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
					<MagnifyingGlassIcon
						className="h-5 w-5 text-gray-400"
						aria-hidden="true"
					/>
				</div>
				<input
					ref={inputRef}
					type="text"
					name="responseSearch"
					id="responseSearch"
					className={classNames(
						QUESTION_META_DATA['text'].focusRingColor,
						'ring-gray-300 block w-full rounded-md border-0 pl-10 ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-xs sm:leading-3',
					)}
					placeholder=""
					value={searchText}
					onChange={handleInputChange}
				/>
			</div>
		</div>
	);
}
