import {
	updateConditionQuestion,
	updateConditionValue,
	updateConditionCheck,
	deleteCondition,
} from '../../store/projectEditorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { intToExcelCol } from 'excel-column-name';
import _ from 'lodash';
import Listbox from '../Listbox';
import { TrashIcon } from '@heroicons/react/24/outline';
import { CONDITION_CHECKS } from '../../constants';
import { getQuestionByNanoId } from '../../utils';
import { sendGoogleEvent } from '../../utils/analytics';

export default function RuleCondition({
	question,
	rule,
	index,
	condition,
	canDelete,
	isEditable = true,
}) {
	const dispatch = useDispatch();

	const questionList = useSelector(
		state => state.projectEditor.questionnaire.questionList,
	);

	const eligibleQuestionList = [];

	let qIndex = 0;

	const conditionQuestion = getQuestionByNanoId(
		questionList,
		condition.question,
	);

	_.forEach(questionList, _question => {
		qIndex += 1;
		if (_question.question_type !== 'file_upload') {
			eligibleQuestionList.push({
				value: _question.nanoid,
				label: `Q${qIndex}: ${_question.title}`,
			});
		}
	});

	let multipleChoiceChecks = CONDITION_CHECKS[conditionQuestion.question_type];

	const choiceList = [];

	const choiceListSource =
		conditionQuestion.question_type === 'matrix'
			? conditionQuestion.columns
			: conditionQuestion.choices;

	let cIndex = 0;
	_.forEach(choiceListSource, choice => {
		cIndex += 1;

		const label =
			conditionQuestion.question_type === 'matrix'
				? `C${cIndex}: ${choice.title}`
				: `${intToExcelCol(cIndex)}: ${choice.title}`;
		choiceList.push({
			value: choice.nanoid,
			label: label,
		});
	});

	const rowList = [];
	let rIndex = 0;
	_.forEach(conditionQuestion.rows, row => {
		rIndex += 1;
		rowList.push({
			value: row.nanoid,
			label: `R${rIndex}: ${row.title}`,
		});
	});

	const values = [];

	let choiceNanoId = null;
	let conditionValue = condition.value;

	if (
		conditionQuestion.question_type === 'single_selection' ||
		conditionQuestion.question_type === 'multiple_selection' ||
		conditionQuestion.question_type === 'yes_no'
	) {
		condition.value.forEach(v => {
			values.push(v);
		});
	} else if (conditionQuestion.question_type === 'matrix') {
		condition.value.columns.forEach(column => {
			values.push(column);
		});

		choiceNanoId = condition.value.choice;
	} else if (conditionQuestion.question_type === 'ranking') {
		conditionValue = condition.value.order;
		choiceNanoId = condition.value.choice;
	}

	return (
		<div className="relative pt-2 group">
			{canDelete && (
				<div className="absolute -top-4 -right-2 z-10 transition-opacity ease-in-out opacity-0 group-hover:opacity-100">
					<div className="flex flex-row-reverse">
						<button
							type="button"
							className="p-1 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
							disabled={!isEditable}
							onClick={() => {
								dispatch(
									deleteCondition({
										rule: rule,
										question: question,
										index: index,
									}),
								);
								sendGoogleEvent(
									'questionnaire_question_logic_condition_remove',
									{ question_type: question.question_type },
								);
							}}
						>
							<TrashIcon className="w-4 h-4" />
						</button>
					</div>
				</div>
			)}
			{eligibleQuestionList.length > 0 && (
				<>
					<Listbox
						items={eligibleQuestionList}
						value={conditionQuestion.nanoid}
						isEditable={isEditable}
						className="tour-logic-designer-condition-question"
						onChange={v =>
							dispatch(
								updateConditionQuestion({
									rule: rule,
									question: question,
									index: index,
									newQuestionNanoId: v,
								}),
							)
						}
					/>
					{conditionQuestion.question_type === 'matrix' && (
						<Listbox
							className="mt-2"
							items={rowList}
							value={choiceNanoId}
							isEditable={isEditable}
							onChange={v =>
								dispatch(
									updateConditionValue({
										question: question,
										rule: rule,
										index: index,
										value: {
											choice: v,
											columns: values,
										},
									}),
								)
							}
						/>
					)}
					{conditionQuestion.question_type === 'ranking' && (
						<Listbox
							className="mt-2"
							items={choiceList}
							value={choiceNanoId}
							isEditable={isEditable}
							onChange={v =>
								dispatch(
									updateConditionValue({
										question: question,
										rule: rule,
										index: index,
										value: {
											choice: v,
											order: conditionValue,
										},
									}),
								)
							}
						/>
					)}
					<Listbox
						className="mt-2 tour-logic-designer-condition-check"
						items={multipleChoiceChecks}
						value={condition.check}
						isEditable={isEditable}
						onChange={v =>
							dispatch(
								updateConditionCheck({
									question: question,
									rule: rule,
									index: index,
									check: v,
								}),
							)
						}
					/>

					{(conditionQuestion.question_type === 'single_selection' ||
						conditionQuestion.question_type === 'multiple_selection' ||
						conditionQuestion.question_type === 'yes_no' ||
						conditionQuestion.question_type === 'matrix') && (
						<Listbox
							className="mt-2 tour-logic-designer-condition-value"
							multiple={true}
							items={choiceList}
							value={values}
							isEditable={isEditable}
							onChange={v => {
								let value = v;

								if (conditionQuestion.question_type === 'matrix') {
									value = {
										choice: choiceNanoId,
										columns: v,
									};
								}

								dispatch(
									updateConditionValue({
										question: question,
										rule: rule,
										index: index,
										value: value,
									}),
								);
							}}
						/>
					)}
					{conditionQuestion.question_type === 'text' && (
						<input
							type="text"
							defaultValue={condition.value}
							disabled={!isEditable}
							onChange={e =>
								dispatch(
									updateConditionValue({
										question: question,
										rule: rule,
										index: index,
										value: e.target.value,
									}),
								)
							}
							className="mt-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
						/>
					)}
					{(conditionQuestion.question_type === 'number' ||
						conditionQuestion.question_type === 'rating' ||
						conditionQuestion.question_type === 'ranking' ||
						conditionQuestion.question_type === 'opinion_scale' ||
						conditionQuestion.question_type === 'nps') && (
						<input
							type="number"
							defaultValue={conditionValue}
							disabled={!isEditable}
							min={1}
							onChange={e => {
								let newValue = parseInt(e.target.value);

								if (question.question_type === 'ranking') {
									newValue = {
										choice: choiceNanoId,
										order: newValue,
									};
								}

								dispatch(
									updateConditionValue({
										question: question,
										rule: rule,
										index: index,
										value: newValue,
									}),
								);
							}}
							onKeyDown={event => {
								event.preventDefault();
							}}
							className="mt-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
						/>
					)}
				</>
			)}
		</div>
	);
}
