import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Dialog, Transition } from '@headlessui/react';
import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Buttons } from '../../../components/Button';
import { FormInput } from '../../../components/TextInput';
import { CameraIcon, ArrowUpTrayIcon } from '@heroicons/react/24/solid';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile } from '../../../store/actions/authActions';
import Spinner from '../../../components/Spinner';
import { Form, Formik } from 'formik';
import { getInitialNameLetter } from './constant';
import { updateProfileValidationSchema } from '../../AuthPages/SignUp/constant';
import {
	startSpin,
	stopSpin,
	resetSuccessState,
} from '../../../store/authSlice';
import { useTranslation } from 'react-i18next';
import { sendGoogleEvent } from '../../../utils/analytics';
import { setDocumentTitle } from '../../../utils';

export default function ProfilePage() {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState();
	const [imageUrl, setImageUrl] = useState();
	const [profileImage, setProfileImage] = useState();
	const [isImgChanged, setIsImgChanged] = useState(false);
	const { t } = useTranslation();

	const imageLabelBorder = !imageUrl ? 'border-dashed border-4' : '';

	const { user, auth, spin, message } = useSelector(state => state.auth);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.profileSettings'));
	}, [t]);

	useEffect(() => {
		if (message.error) {
			toast.error(message.message, { title: t('Error') });
		}
	}, [message.message, message.error, t]);

	useEffect(() => {
		setProfileImage(user.profile_picture);
	}, [user]);

	useEffect(() => {
		if (message.success) {
			toast.success(t('profile.toastMessage'), { title: t('Success') });
		}
		dispatch(resetSuccessState());
	}, [message, dispatch, t]);

	const nanoid = auth?.user?.nanoid;

	useEffect(() => {
		nanoid && dispatch(getUserProfile(nanoid));
	}, [dispatch, nanoid]);

	const onImageChange = e => {
		dispatch(startSpin());
		setSelectedImage(e.target.files[0]);
		setImageUrl(URL.createObjectURL(e.target.files[0]));
		setTimeout(() => {
			dispatch(stopSpin());
		}, 1000);
	};

	const updateValues = (values, base64) => {
		if (base64) {
			return {
				...values,
				file: base64,
			};
		}
		return values;
	};

	const submitProfile = async values => {
		const base64 = isImgChanged && (await getBase64(selectedImage));

		dispatch({
			type: 'auth/startUpdateProfile',
			payload: {
				nanoid: nanoid,
				values: updateValues(values, base64),
			},
		});
		isImgChanged && sendGoogleEvent('account_profile_picture_update');
		sendGoogleEvent('account_update');
	};

	const isUpload = () => {
		if (imageUrl) {
			setOpen(false);
			setProfileImage(imageUrl);
			setIsImgChanged(true);
			setImageUrl(undefined);
		}
	};

	const deleteImage = () => {
		if (imageUrl) {
			setImageUrl(undefined);
		}
		setOpen(false);
	};

	const getBase64 = file => {
		return new Promise(resolve => {
			let baseURL = '';
			let reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onload = () => {
				baseURL = reader.result;
				resolve(baseURL);
			};
		});
	};

	return (
		<>
			<div className="shadow sm:rounded-md sm:overflow-hidden">
				<div className="p-6 bg-white">
					<h2
						id="payment-details-heading"
						className="text-lg leading-6 font-medium text-gray-900"
					>
						{t('profile.heading')}
					</h2>
					<p className="mt-1 text-sm text-gray-500">
						{t('profile.description')}
					</p>
				</div>
				<section aria-labelledby="payment-details-heading">
					<Formik
						enableReinitialize={true}
						initialValues={{
							first_name: user ? user.first_name : '',
							last_name: user ? user.last_name : '',
							email: user ? user.email : '',
							profile_picture: user ? user.profile_picture : '',
						}}
						validationSchema={updateProfileValidationSchema}
						onSubmit={values => {
							submitProfile(values);
						}}
					>
						{props => (
							<Form>
								<div className="flex flex-col w-full justify-between items-center bg-white md:flex-row-reverse">
									<div className="space-y-4 flex justify-center align-center relative md:mx-20 ">
										<div className="w-40 h-40 flex justify-center items-center object-cover shadow-lg rounded-full  cursor-pointer bg-gray-400">
											{profileImage ? (
												<img
													type="file"
													className="object-cover w-40 h-40  shadow-lg rounded-full cursor-pointer"
													onClick={() => setOpen(true)}
													src={profileImage}
												/>
											) : (
												<p className="text-6xl top-14 left-5 text-white">
													{getInitialNameLetter(
														user.first_name,
														user.last_name,
													)}
												</p>
											)}
										</div>
										<Transition.Root show={open} as={Fragment}>
											<Dialog
												as="div"
												className="fixed z-10 inset-0 overflow-y-auto"
												onClose={setOpen}
											>
												<div className="flex items-center md:items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
													<Transition.Child
														as={Fragment}
														enter="ease-out duration-300"
														enterFrom="opacity-0"
														enterTo="opacity-100"
														leave="ease-in duration-200"
														leaveFrom="opacity-100"
														leaveTo="opacity-0"
													>
														<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
													</Transition.Child>

													<span
														className="hidden sm:inline-block sm:align-middle sm:h-screen"
														aria-hidden="true"
													>
														&#8203;
													</span>
													<Transition.Child
														as={Fragment}
														enter="ease-out duration-300"
														enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
														enterTo="opacity-100 translate-y-0 sm:scale-100"
														leave="ease-in duration-200"
														leaveFrom="opacity-100 translate-y-0 sm:scale-100"
														leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
													>
														<div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
															<div className="absolute top-0 right-0 pt-4 pr-4">
																<button
																	type="button"
																	className="bg-white rounded-md text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
																	onClick={() => setOpen(false)}
																>
																	<span className="sr-only">{t('Close')}</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
															<div className="sm:flex justify-center items-center">
																<div className="flex justify-center items-center mt-8">
																	<div className="rounded-lg  ">
																		<div className="m-4">
																			<label className="inline-block mb-5 text-sm text-gray-500">
																				{t('profile.uploadImageLabel')}
																			</label>
																			<div className="flex items-center justify-center  w-full">
																				<label
																					className={`flex flex-col w-48 h-48 mt-3shadow-md rounded-full hover:bg-gray-100 hover:border-gray-300 relative ${imageLabelBorder}`}
																				>
																					<input
																						type="file"
																						accept="image/*"
																						className="opacity-0"
																						onChange={onImageChange}
																					/>

																					<div className="flex flex-col items-center mt-4 justify-center pt-2 cursor-pointer">
																						<svg
																							xmlns="http://www.w3.org/2000/svg"
																							className="w-12 h-12 text-gray-400 group-hover:text-gray-600 "
																							viewBox="0 0 20 20"
																							fill="currentColor"
																						>
																							<path
																								fillRule="evenodd"
																								d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
																								clipRule="evenodd"
																							/>
																						</svg>
																						<p className="text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
																							{t('profile.selectPhoto')}
																						</p>
																					</div>

																					{imageUrl && (
																						<img
																							key={imageUrl}
																							src={imageUrl}
																							className="absolute w-48 h-48 rounded-full inset-0"
																						/>
																					)}
																					{spin && (
																						<div className="flex justify-center align-center">
																							<Spinner className="w-6 h-6" />
																						</div>
																					)}
																				</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-between">
																<div className="mb-0.5">
																	<Buttons
																		disabled={!imageUrl}
																		onClick={() => {
																			isUpload();
																			props.setFieldValue(
																				'profile_picture',
																				imageUrl,
																			);
																		}}
																	>
																		<ArrowUpTrayIcon className="w-4 " />{' '}
																		{t('profile.uploadButton')}
																	</Buttons>
																</div>
																<div>
																	{imageUrl && (
																		<Buttons kind="white" onClick={deleteImage}>
																			<TrashIcon className="w-4 " />
																			{t('profile.deleteButton')}
																		</Buttons>
																	)}
																</div>
															</div>
														</div>
													</Transition.Child>
												</div>
											</Dialog>
										</Transition.Root>
										<button
											type="button"
											onClick={() => setOpen(true)}
											className=" flex absolute "
										>
											<CameraIcon className="bg-white shadow-lg w-8 p-1 rounded-full mt-20 ml-36" />
										</button>
									</div>
									<div className="mt-3 space-y-2 mb-6 w-full">
										<div className="px-6">
											<FormInput
												id="first_name"
												name="first_name"
												type="text"
												label={t('formLabels.firstName')}
												value={props.values.first_name}
												onChange={props.handleChange}
												errorMessage={t(props.errors.first_name, {
													name: t('formLabels.firstName'),
												})}
											/>
										</div>
										<div className="px-6">
											<FormInput
												id="last_name"
												name="last_name"
												type="text"
												label={t('formLabels.lastName')}
												value={props.values.last_name}
												onChange={props.handleChange}
												errorMessage={t(props.errors.last_name, {
													name: t('formLabels.lastName'),
												})}
											/>
										</div>

										<div className="px-6">
											<FormInput
												id="email"
												name="email"
												type="email"
												label={t('formLabels.email')}
												value={props.values.email}
												onChange={props.handleChange}
												errorMessage={t(props.errors.email, {
													name: t('formLabels.email'),
												})}
												helperText={`${t('formLabels.emailHelpText')}`}
												disabled
											/>
										</div>
									</div>
								</div>

								<div className="py-3 bg-gray-50 text-right px-6 rounded-b-lg ">
									<Buttons
										type="submit"
										kind="primary"
										disabled={!(props.dirty && props.isValid) || spin}
									>
										{spin ? <Spinner className="w-5 h-5" /> : t('Save')}
									</Buttons>
								</div>
							</Form>
						)}
					</Formik>
				</section>
			</div>
		</>
	);
}
