import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NextButton from '../NextButton';

const NumberSelectionQuestion = ({
	question,
	showPreviousButton,
	handlePrevious,
	isPreviousDisabled,
	handleQuestionSubmit,
	lastQuestion,
}) => {
	const { t } = useTranslation();
	const isRequired = question.config.required;
	const minValue = question.config.min_value;
	const maxValue = question.config.max_value;
	const isLimited = question.config.is_custom_range;
	const [error, setError] = useState(isRequired);
	const [values, setValues] = useState(null);
	const [message, setMessage] = useState('');

	const inputChangeHandler = e => {
		setValues(e.target.value);
	};

	useEffect(() => {
		if (isRequired) {
			if (values !== null && values !== undefined) {
				if (isLimited) {
					if (values <= minValue) {
						setMessage(
							t(
								'previewQuestionnaire.questionTypes.number.validations.minValue',
								{
									minValue: minValue,
								},
							),
						);

						setError(true);
					} else if (values >= maxValue) {
						setMessage(
							t(
								'previewQuestionnaire.questionTypes.number.validations.maxValue',
								{
									maxValue: maxValue,
								},
							),
						);
						setError(true);
					} else {
						setError(false);
						setMessage(null);
					}
				} else {
					setError(false);
				}
			} else {
				setError(true);
			}
		} else {
			if (values) {
				if (isLimited) {
					if (values <= minValue) {
						setMessage(
							t(
								'previewQuestionnaire.questionTypes.number.validations.minValue',
								{
									minValue: minValue,
								},
							),
						);
						setError(true);
					} else if (values >= maxValue) {
						setMessage(
							t(
								'previewQuestionnaire.questionTypes.number.validations.maxValue',
								{
									maxValue: maxValue,
								},
							),
						);
						setError(true);
					} else {
						setError(false);
						setMessage(null);
					}
				} else {
					setError(false);
				}
			} else {
				setError(false);
			}
		}
	}, [isLimited, isRequired, maxValue, minValue, t, values]);

	const formSubmissionHandler = e => {
		e.preventDefault();

		handleQuestionSubmit({
			question: question,
			answer: values,
		});
	};

	return (
		<>
			<input
				type="number"
				className="shadow-sm focus:ring-mint-500 focus:border-mint-500 block w-full sm:text-sm border-gray-300 rounded-md"
				onChange={inputChangeHandler}
			/>
			{message && <p className="p-2 text-xs text-red-500">{message}</p>}
			{
				<NextButton
					disabled={error}
					showPreviousButton={showPreviousButton}
					isPreviousDisabled={isPreviousDisabled}
					handlePrevious={handlePrevious}
					handleNext={formSubmissionHandler}
					lastQuestion={lastQuestion}
				/>
			}
		</>
	);
};

export default NumberSelectionQuestion;
