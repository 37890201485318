import { useTranslation } from 'react-i18next';
import { tidy, filter } from '@tidyjs/tidy';
import { indexOf } from 'lodash';
import Table2 from './Table2';
import {
	calculateArithmeticMean,
	calculateMedian,
	calculateStandardDeviation,
} from '../../utils/analysis';

export default function StatsTable({
	data,
	question,
	isCompareMode,
	comparison,
	labels,
}) {
	const { t } = useTranslation();

	const statsColumnHeads = [
		{
			title: '',
			alignLeft: true,
		},
		{
			title: t('analysis.opinionScale.stats.average'),
		},
		{
			title: t('analysis.opinionScale.stats.median'),
		},
		{
			title: t('analysis.opinionScale.stats.stdev.s'),
		},
		{
			title: t('analysis.opinionScale.stats.stdev.p'),
		},
	];

	let statRows = [];

	if (isCompareMode) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					const comparisonResponses = tidy(
						data,
						filter(d => {
							const dataType = typeof d[comparisonKey];
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								return d[comparisonKey] == comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice & ranking filter items
								const innerDataType =
									d[comparisonKey][0] == null
										? 'string'
										: typeof d[comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									return indexOf(d[comparisonKey], comparisonItemKey) > -1;
								} else if (innerDataType === 'object') {
									let foundItemIndex = d[comparisonKey].findIndex(
										answer =>
											answer.order == rankKey &&
											answer.choice === comparisonItemKey,
									);

									return foundItemIndex > -1;
								}
							}
						}),
					);

					const key =
						rankKey == -1
							? `${comparisonKey}_${comparisonItemKey}`
							: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
					const label = labels[key];

					const selectedNumbers = comparisonResponses
						.filter(d => d[question.nanoid])
						.map(d => d[question.nanoid]);

					const newRow = [
						{
							value: label,
							alignLeft: true,
						},
						{
							value: calculateArithmeticMean(selectedNumbers),
							mono: true,
						},
						{
							value: calculateMedian(selectedNumbers),
							mono: true,
						},
						{
							value: calculateStandardDeviation(selectedNumbers, 'S'),
							mono: true,
						},
						{
							value: calculateStandardDeviation(selectedNumbers, 'P'),
							mono: true,
						},
					];
					statRows.push(newRow);
				});
			});
		});
	} else {
		const selectedNumbers = data
			.filter(d => d[question.nanoid])
			.map(d => d[question.nanoid]);

		statRows = [
			[
				{
					value: t('analysis.opinionScale.stats.allResponses'),
					alignLeft: true,
				},
				{
					value: calculateArithmeticMean(selectedNumbers),
					mono: true,
				},
				{
					value: calculateMedian(selectedNumbers),
					mono: true,
				},
				{
					value: calculateStandardDeviation(selectedNumbers, 'S'),
					mono: true,
				},
				{
					value: calculateStandardDeviation(selectedNumbers, 'P'),
					mono: true,
				},
			],
		];
	}

	return (
		<>
			<Table2 columnHeads={statsColumnHeads} rows={statRows} />
		</>
	);
}
