import moment from 'moment';
import 'moment/locale/tr';
import 'moment/locale/en-gb';

import EmailVerificationStatus from '../../components/EmailVerificationStatus';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import ProfilePicture from '../../components/ProfilePicture';
import { PlusIcon, BuildingOffice2Icon } from '@heroicons/react/24/outline';
import ProjectList from '../../components/ProjectList';
import { useTranslation } from 'react-i18next';
import Spinner from '../../components/Spinner';
import Tooltip from '../../components/Tooltip';
import { Logo2 } from '../../components/Logo2';
import { Link } from 'react-router-dom';
import {
	loadActivitiesStart,
	resetActivities,
} from '../../store/activitiesSlice';
import TourGuide from '../../components/TourGuide';
import { setDocumentTitle } from '../../utils';
import { resetProjectEditor } from '../../store/projectEditorSlice';
import {
	setCurrentPage,
	setOrdering,
	setStatus,
} from '../../store/projectSlice';

export default function DashboardPage() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const currentLanguage = localStorage.getItem('i18nextLng');

	const [page, setPage] = useState(1);

	const activities = useSelector(state => state.activities.activities);
	const activityNextPage = useSelector(state => state.activities.next);
	const activitiesIsLoading = useSelector(state => state.activities.isLoading);

	const totalProjectsLength = useSelector(
		state => state.project.getAllProjectsData.totalProjectsLength,
	);

	const currentPage = useSelector(state => state.project.currentPage);
	const ordering = useSelector(state => state.project.ordering);
	const status = useSelector(state => state.project.status);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectList'));
	}, [t]);

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.dashboard.hello.title'),
			content: t('tour.content.dashboard.hello.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-project-list',
			placement: 'auto',
			title: t('tour.content.dashboard.projectList.title'),
			content: t('tour.content.dashboard.projectList.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-activity-list',
			placement: 'auto',
			title: t('tour.content.dashboard.activityList.title'),
			content: t('tour.content.dashboard.activityList.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-new-project-button',
			placement: 'auto',
			title: t('tour.content.dashboard.newProject.title'),
			content: t('tour.content.dashboard.newProject.content'),
			disableBeacon: true,
		},
	];

	useEffect(() => {
		currentLanguage && currentLanguage === 'tr-TR'
			? moment.locale('tr')
			: moment.locale('en-gb');
	}, [currentLanguage]);

	useEffect(() => {
		const storedPage = sessionStorage.getItem('currentPage');
		if (storedPage) {
			dispatch(setCurrentPage(parseInt(storedPage)));
		} else {
			dispatch(setCurrentPage(1));
		}
	}, [dispatch, totalProjectsLength]);

	useEffect(() => {
		if (status !== sessionStorage.getItem('status')) {
			dispatch(setCurrentPage(1));
		}
	}, [dispatch, status]);

	useEffect(() => {
		sessionStorage.setItem('currentPage', currentPage);
		sessionStorage.setItem('status', status);
		dispatch({
			type: 'project/getAllProjectsStarted',
			payload: {
				limit: +process.env.REACT_APP_PROJECT_LIMIT || 15,
				page: currentPage,
				status: status,
				ordering: ordering.value,
			},
		});
		dispatch(resetProjectEditor());
	}, [dispatch, currentPage, ordering.value, status]);

	const { user } = useSelector(state => state.auth);
	const projectsData = useSelector(state => state.project.getAllProjectsData);

	const handleMoreActivities = () => {
		setPage(page => page + 1);
	};

	useEffect(() => {
		dispatch(resetActivities());
		setPage(1);
	}, [dispatch]);

	useEffect(() => {
		dispatch(loadActivitiesStart({ pageNumber: page }));
	}, [dispatch, page]);

	return (
		<>
			{/* 3 column wrapper */}
			<div className="flex-grow h-screen w-full lg:flex overflow-auto">
				{/* Left sidebar & main wrapper */}
				<div className="flex-1 min-w-0 pl-8 bg-white xl:flex">
					{/* Account profile */}
					<div className="xl:flex-shrink-0 xl:w-64 xl:border-r xl:border-gray-200 bg-white">
						<div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
							<div className="flex items-center justify-between">
								<div className="flex-1 space-y-8">
									<div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
										{/* Profile */}
										<div className="flex items-center space-x-3">
											<div className="flex-shrink-0 h-12 w-12">
												<ProfilePicture
													firstName={user.first_name}
													lastName={user.last_name}
													profilePicture={user.profile_picture}
													size={'mid'}
												/>
											</div>
											<div className="space-y-1">
												<div className="text-sm font-medium text-gray-900">
													{user.first_name} {user.last_name}
												</div>
												<span className="group flex items-center space-x-2.5 text-gray-500 text-sm capitalize">
													<BuildingOffice2Icon className="h-5 w-5 text-gray-400 mr-2" />
													<div
														className="hidden xl:block"
														style={{ marginLeft: '-2px' }}
													>
														{user.company?.name &&
															(user.company.name.length < 20 ? (
																user.company.name
															) : (
																<Tooltip text={user.company.name}>
																	{[...user.company.name]
																		.splice(0, 17)
																		.join('') + '...'}
																</Tooltip>
															))}
													</div>
													<div className="block xl:hidden">
														{user.company?.name}
													</div>
												</span>
											</div>
										</div>
										<div className="flex flex-col sm:flex-row xl:flex-col">
											<Link
												to={'/project/create'}
												className="tour-new-project-button inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												<PlusIcon
													className="-ml-1 mr-2 h-5 w-5"
													aria-hidden="true"
												/>
												{t('New Project', { context: 'button' })}
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="bg-white lg:min-w-0 lg:flex-1  xl:mb-20 max-h-[70vh] lg:max-h-[80vh] xl:max-h-full overflow-scroll">
						<div className="pl-2 pr-6 pt-4 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6">
							<EmailVerificationStatus
								className={'mb-4'}
								email={user.email}
								initialVerificationStatus={user.is_verified}
							/>
						</div>
						<div className="tour-project-list">
							{projectsData.isLoading && projectsData?.projects.length === 0 ? (
								<div className="flex justify-center">
									<Spinner className="mt-10 xl:mt-10 h-10 w-10 text-indigo-800" />
								</div>
							) : (
								<>
									<ProjectList
										projects={projectsData?.projects}
										totalProjectsLength={totalProjectsLength}
										currentPage={currentPage}
										setCurrentPage={setCurrentPage}
										ordering={ordering}
										setOrdering={setOrdering}
										status={status}
										setStatus={setStatus}
									/>
								</>
							)}
						</div>
					</div>
				</div>
				{/* Activity feed */}
				<div className="tour-activity-list bg-gray-50 pr-8 lg:flex-shrink-0 lg:border-l lg:border-gray-200 max-h-[70vh]  md:max-h-full mb-16 overflow-scroll">
					<div className="pl-6 lg:w-80">
						<div className="pt-6 pb-2">
							<h2 className="text-sm font-semibold">{t('Activity')}</h2>
						</div>
						{activitiesIsLoading && page === 1 && (
							<div className="flex justify-center align-center">
								<Spinner className="h-8 w-8 text-indigo-800" />
							</div>
						)}
						{activities.length > 0 && (
							<div>
								<ul role="list" className="divide-y divide-gray-200">
									{activities.map(item => {
										const { actor, action_title, action_object, timestamp } =
											item;
										const name = actor.is_staff
											? 'Sorbunu'
											: `${actor.first_name} ${actor.last_name} `;

										return (
											<li key={timestamp} className="py-3.5">
												<div className="flex space-x-3">
													{actor.is_staff ? (
														<Logo2 className="w-6 h-6" />
													) : (
														<ProfilePicture
															firstName={actor.first_name}
															lastName={actor.last_name}
															profilePicture={
																actor.nanoid === user.nanoid
																	? user.profile_picture
																	: actor.profile_picture
															}
															size={'xs'}
														/>
													)}
													<div className="flex-1 space-y-1 overflow-hidden">
														<div className="flex items-center justify-between mb-2">
															<h3 className="text-sm font-medium">
																{name}
																{actor.nanoid === user.nanoid ? t('(You)') : ''}
															</h3>
															<Tooltip
																text={moment(timestamp).format(
																	'DD.MM.YYYY, H:mm:ss ',
																)}
																className={
																	'text-xs left-3/4 before:left-3/4 -translate-x-3/4  -top-8'
																}
															>
																<p className="text-sm text-gray-500">
																	{moment(timestamp).fromNow(true)}
																</p>
															</Tooltip>
														</div>

														<p className="text-sm text-gray-500">
															{action_object ? (
																<Link
																	to={`${action_object.url}/audience`}
																	className="hover:underline"
																>
																	{action_title}
																</Link>
															) : (
																action_title
															)}
														</p>
													</div>
												</div>
											</li>
										);
									})}
								</ul>

								{activityNextPage && (
									<div className="flex justify-end py-4 text-sm border-t text-right border-gray-200">
										{activitiesIsLoading ? (
											<Spinner className="h-6 w-6 text-indigo-800" />
										) : (
											<a
												onClick={handleMoreActivities}
												href="#"
												className="text-indigo-600 font-medium hover:text-indigo-900"
											>
												{t('Show more')}...
											</a>
										)}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
			{projectsData?.projects &&
				!projectsData.isLoading &&
				!activitiesIsLoading && (
					<TourGuide tourName={'dashboard'} steps={steps} />
				)}
		</>
	);
}
