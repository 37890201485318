import { filter, tidy, arrange, asc, desc } from '@tidyjs/tidy';
import { indexOf } from 'lodash';
import BarChart from './BarChart';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OtherResponsesTable from './OtherResponsesTable';
import { calculateHeightForBarChart } from '../../utils/analysis';

export default function LabelChart({
	question,
	data,
	totalCount,
	isCompareMode,
}) {
	const { t } = useTranslation();
	const { nanoid } = useParams();
	const projectLabels = useSelector(
		state => state.analysis.projects[nanoid].labels,
	);

	if (projectLabels.status.loadingStatus !== 'loaded') {
		return <></>;
	}

	let barData = [];
	let tableData = [];

	//filter the labels in the question
	const questionLabelList = projectLabels.labelList.filter(
		label => label.question === question.nanoid,
	);

	questionLabelList.forEach(label => {
		const l = tidy(
			data,
			filter(d => {
				return indexOf(d.labels[question.nanoid], label.nanoid) > -1;
			}),
		);

		barData.push({
			country: label.name,
			responseCount: l.length,
			responsePercentage: totalCount > 0 ? l.length / totalCount : 0,
		});

		const labelPercentage = totalCount > 0 ? l.length / totalCount : 0;

		tableData.push({
			itemText: label.name,
			itemCount: l.length,
			itemPercentage: `${parseFloat(labelPercentage * 100).toFixed(2)}%`,
		});
	});

	const l = tidy(
		data,
		filter(d => {
			if (
				!d.labels ||
				!d.labels[question.nanoid] ||
				d.labels[question.nanoid].length === 0
			) {
				return true;
			}
			return false;
		}),
	);

	const unlabeledPercentage = totalCount > 0 ? l.length / totalCount : 0;

	tableData.push({
		itemText: t('analysis.labels.unlabeled'),
		itemCount: l.length,
		itemPercentage: `${parseFloat(unlabeledPercentage * 100).toFixed(2)}%`,
	});

	barData.push({
		country: t('analysis.labels.unlabeled'),
		responseCount: l.length,
		responsePercentage: totalCount > 0 ? l.length / totalCount : 0,
	});

	tableData = tidy(tableData, arrange(desc('itemCount')));

	barData = tidy(barData, arrange(asc('responseCount')));

	barData = barData.length > 50 ? barData.slice(-50) : barData;

	return (
		<>
			<div
				className="w-full"
				style={{
					height: calculateHeightForBarChart(barData.length),
				}}
			>
				<BarChart
					legend={t('% of all responses')}
					keys={['responsePercentage']}
					data={barData}
					legendLabels={projectLabels.labelNames}
					isCompareMode={isCompareMode}
				/>

				<OtherResponsesTable
					sortedRows={tableData}
					paginationItemName={t('paginationItem.labels')}
					firstColName={t('Label')}
				/>
			</div>
		</>
	);
}
