import { Fragment } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';

function ItemIcon({ icon, className }) {
	const CustomTag = icon;
	return <CustomTag className={className} />;
}

export default function Dropdown({
	label,
	icon,
	items,
	disabled = false,
	direction = 'down',
	headerComponent = null,
	onChange,
}) {
	const { t } = useTranslation();
	return (
		<Menu as="div" className="relative inline-block text-left z-10">
			<>
				<Menu.Button
					disabled={disabled}
					className="disabled:cursor-not-allowed disabled:opacity-30 inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
				>
					<div className="inline-flex shrink-0">
						{icon && (
							<ItemIcon
								icon={icon}
								className="shrink-0 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
							/>
						)}
						{label}
					</div>
					<ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
				</Menu.Button>
			</>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					className={classNames(
						direction === 'up'
							? 'top-28 md:top-60 transform -translate-y-full -left-60'
							: '',
						'origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none',
					)}
				>
					{headerComponent && (
						<div className="px-4 py-3">{headerComponent}</div>
					)}

					{items.map((item, index) => (
						<div key={index} className="py-1">
							{item.items.map((subitem, subindex) => {
								return (
									<Menu.Item key={subindex} disabled={subitem.disabled}>
										{({ active }) => (
											<div
												onClick={() => {
													onChange(subitem, index);
												}}
												className={classNames(
													active
														? 'bg-gray-100 text-gray-900 '
														: 'text-gray-700',
													subitem.disabled
														? 'opacity-30 cursor-not-allowed'
														: 'cursor-pointer',
													subindex === item.items.length - 1
														? 'border-t border-gray-200'
														: '',
													'group flex items-center px-4 py-2 text-sm ',
												)}
											>
												<subitem.icon
													className={classNames(
														'shrink-0 mr-3 h-5 w-5 text-gray-400 ',
														subitem.disabled ? '' : 'group-hover:text-gray-500',
													)}
													aria-hidden="true"
												/>
												{t(subitem.title)}
											</div>
										)}
									</Menu.Item>
								);
							})}
						</div>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
