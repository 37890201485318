import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getAllProjects({ limit, page, status, ordering }) {
	try {
		const result = await sorbunuApi.get(
			endPoints.projects.GET_PROJECTS(limit, page, status, ordering),
			{ limit, page, status, ordering },
		);
		return result.data;
	} catch (error) {
		return error.message;
	}
}

export async function changeProjectName(action) {
	try {
		const result = await sorbunuApi.patch(
			endPoints.projects.CHANGE_PROJECT_NAME(action.nanoid),
			{
				title: action.title,
			},
		);
		return result.data;
	} catch (error) {
		return error.message;
	}
}

export async function updateProjectStatus(action) {
	try {
		const result = await sorbunuApi.patch(
			endPoints.projects.UPDATE_PROJECT_STATUS(action.nanoid),
			{
				status: action.status,
			},
		);
		return result.data;
	} catch (error) {
		return error.message;
	}
}

export async function createProject(values) {
	const result = await sorbunuApi.post(endPoints.projects.PROJECTS(), values);
	return result.data;
}

export async function retrieveProject(projectNanoId) {
	const result = await sorbunuApi.get(
		endPoints.projects.RETRIEVE_PROJECT(projectNanoId),
	);
	return result.data;
}

export async function getProjectAudienceList(projectNanoId) {
	const result = await sorbunuApi.get(
		endPoints.projects.AUDIENCE_LIST(projectNanoId),
	);
	return result.data.results;
}

export async function getProjectCost(projectNanoId) {
	const result = await sorbunuApi.get(
		endPoints.projects.GET_COST_DATA(projectNanoId),
	);
	return result.data;
}
