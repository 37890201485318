import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function createPreChoice(
	questionnaireNanoId,
	preQuestionNanoId,
	payload,
) {
	const data = {
		title: payload.title,
		choice_type: payload.choice_type,
	};

	const result = await sorbunuApi.post(
		endPoints.preChoice.CREATE_PRE_CHOICE(
			questionnaireNanoId,
			preQuestionNanoId,
		),
		data,
	);
	return result.data;
}

export async function updatePreChoice({
	questionnaireNanoId,
	preQuestionNanoId,
	preChoiceNanoId,
	payload,
}) {
	const data = {
		title: payload.title,
	};

	if (payload.image !== undefined) {
		data['image'] = payload.image;
	}

	if (payload.choice_type !== undefined) {
		data['choice_type'] = payload.choice_type;
	}
	data['is_qualified'] = payload.is_qualified;

	const result = await sorbunuApi.put(
		endPoints.preChoice.UPDATE_PRE_CHOICE(
			questionnaireNanoId,
			preQuestionNanoId,
			preChoiceNanoId,
		),
		data,
	);

	return result.data;
}

export async function deletePreChoice({
	questionnaireNanoId,
	preQuestionNanoId,
	preChoiceNanoId,
}) {
	const result = await sorbunuApi.delete(
		endPoints.preChoice.DELETE_PRE_CHOICE(
			questionnaireNanoId,
			preQuestionNanoId,
			preChoiceNanoId,
		),
	);

	return result.data;
}

export async function bulkOrderPreChoiceList({
	questionnaireNanoId,
	preQuestionNanoId,
	orderList,
}) {
	const result = await sorbunuApi.post(
		endPoints.preChoice.BULK_ORDER(questionnaireNanoId, preQuestionNanoId),
		orderList,
	);

	return result.data;
}
