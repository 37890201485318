export const checkUrlRating = rating => {
	if (rating) {
		let intRating = parseInt(rating);
		if (isNaN(intRating)) {
			return null;
		}
		if (intRating > 10) {
			intRating = 10;
		} else if (intRating < 0) {
			intRating = 0;
		}
		return intRating;
	} else {
		return null;
	}
};

export const checkIfNumber = val => {
	if (typeof val === 'number' && val >= 0) {
		return true;
	} else {
		return false;
	}
};
