import { put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { getCommentList, postComment } from '../../../api/services/Comment';
import { getCommentsLastRead } from '../../../utils/Comment';
import {
	finishLoadingComments,
	setCommentAsPosted,
	setUnreadComments,
} from '../../projectEditorSlice';
import { sendGoogleEvent } from '../../../utils/analytics';

function* loadCommentsSaga(action) {
	const comments = yield getCommentList(action.payload.projectNanoId);
	yield put(finishLoadingComments({ comments: comments }));

	if (comments.length > 0) {
		const commentsLastRead = getCommentsLastRead(action.payload.projectNanoId);

		let setAsUnread = false;

		const currentUserNanoId = yield select(state => state.auth.user.nanoid);

		comments.forEach(comment => {
			if (comment.user.nanoid !== currentUserNanoId) {
				if (commentsLastRead) {
					if (commentsLastRead.isBefore(comment.created)) {
						setAsUnread = true;
					}
				} else {
					setAsUnread = true;
				}
			}
		});

		yield put(setUnreadComments({ hasUnread: setAsUnread }));
	}
}

export function* watchLoadComments() {
	yield takeLatest('projectEditor/loadComments', loadCommentsSaga);
}

function* postCommentSaga(action) {
	const result = yield postComment(
		action.payload.projectNanoId,
		action.payload.values,
	);
	sendGoogleEvent('comments_add');
	if (result) {
		yield put(
			setCommentAsPosted({
				nanoid: result.data.nanoid,
				created: result.data.created,
			}),
		);
		const comments = yield getCommentList(action.payload.projectNanoId);
		yield put(finishLoadingComments({ comments: comments }));
	}
}

export function* watchPostComment() {
	yield takeEvery('projectEditor/postComment', postCommentSaga);
}
