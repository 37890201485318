import getSymbolFromCurrency from 'currency-symbol-map';
import { useTranslation } from 'react-i18next';
import { VAT_RATE } from '../../constants';
import { useSelector } from 'react-redux';

export default function BillDetails({ data }) {
	const { t } = useTranslation();
	const transactions = useSelector(state => state.projectEditor.transactions);

	if (!data) {
		return <></>;
	}

	return (
		<div className="flex flex-col justify-between h-full">
			<div className="w-full bg-white border rounded-md">
				<div>
					<div className="p-4 sm:px-6 lg:px-8 sm:flex sm:items-center bg-gray-50 rounded-t-md overflow-hidden w-full">
						<h1 className="text-xl font-semibold text-gray-900">
							{t('Summary')}
						</h1>
					</div>
					<div className="w-full p-4 pb-8 flex flex-col ">
						<table className="table-auto min-w-full divide-y divide-gray-300">
							<thead>
								<tr className="border-b border-gray-200">
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
									>
										{t('Invoice Items')}
									</th>
									<th
										scope="col"
										className="py-4 pl-3 pr-4 text-right text-sm text-gray-900 sm:pr-6 md:pr-0"
									>
										{t('Costs')}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="border-b border-gray-200">
									<td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
										<div className="font-medium text-gray-900">
											{t('Standard CPC')}
										</div>
									</td>

									<td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
										{getSymbolFromCurrency(data.base_price.currency)}
										{data.base_price.value}
									</td>
								</tr>
								{data.items.map(item => (
									<tr key={item.name} className="border-b border-gray-200">
										<td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
											<div className="font-medium text-gray-900">
												{item.name}
											</div>
										</td>

										<td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
											{getSymbolFromCurrency(item.cost.currency)}
											{item.cost.value}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div className="w-full bg-white border rounded-md mb-1">
				<div className="w-full p-4 flex flex-col ">
					<div className="">
						<div className="flex flex-row justify-between">
							<div
								scope="row"
								className="py-1 pl-4 pr-3 text-sm  font-semibold text-gray-900 sm:pl-6 md:pl-0"
								title={t('costPerComplete')}
							>
								{t('CPC')}:
							</div>
							<div className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
								{`${getSymbolFromCurrency(data.cpc.currency)}${data.cpc.value}`}
							</div>
						</div>
						<div className="flex flex-row justify-between">
							<div
								scope="row"
								className="py-1 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
							>
								{t('billDetailsCompletes')}:
							</div>
							<div className="pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
								{data.completes}
							</div>
						</div>
						<div className="flex flex-row justify-between">
							<div
								scope="row"
								className="py-1 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
							>
								<span>{data.promo_code ? t('Sub Total') : t('Total')}:</span>
								<span className=" text-xs font-light text-slate-500">
									{' '}
									{t('(Includes {{VAT}}% VAT)', { VAT: VAT_RATE })}
								</span>
							</div>
							<div className="pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
								{`${getSymbolFromCurrency(data.cost.currency)}${
									data.original_cost.value
								}`}
							</div>
						</div>
						{data.promo_code && data.discount_amount && (
							<>
								<div className="flex flex-row justify-between">
									<div
										scope="row"
										className="py-1 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
									>
										{t('Discount')}:
									</div>
									<div className="pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
										{`${getSymbolFromCurrency(data.discount_amount.currency)}${
											data.discount_amount.value
										}`}
									</div>
								</div>
								<div className="flex flex-row justify-between">
									<div
										scope="row"
										className="py-1 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
									>
										{t('Final Total')}
									</div>
									<div className="pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
										{new Intl.NumberFormat('tr-TR', {
											style: 'currency',
											currency: data.cpc.currency,
										}).format(transactions.remainingTotal)}
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
