import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils';

export default function ValidationMessage({ message, className }) {
	return (
		<div
			className={classNames(
				className,
				'text-xs font-semibold text-gray-500 flex gap-0.5 items-start',
			)}
		>
			<ExclamationCircleIcon className="w-4 h-4" />
			{message}
		</div>
	);
}
