import { RadioGroup } from '@headlessui/react';
import { classNames } from '../../../utils';
import { range } from 'lodash';

export default function OpinionScaleQuestion({ question, isEditable }) {
	return (
		<form>
			<RadioGroup className="mt-2">
				<div className="flex gap-2">
					{range(question.config.minimum, question.config.maximum + 1).map(
						i => (
							<RadioGroup.Option
								key={i}
								value={i}
								disabled={!isEditable}
								className={({ active, checked }) =>
									classNames(
										active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
										checked
											? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700'
											: 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
										isEditable ? 'cursor-pointer' : '',
										'focus:outline-none border rounded-md py-2 flex items-center justify-center text-sm font-medium uppercase sm:flex-1',
									)
								}
							>
								<RadioGroup.Label as="span">{i}</RadioGroup.Label>
							</RadioGroup.Option>
						),
					)}
				</div>
			</RadioGroup>
			<div className="flex justify-between mt-3 text-sm text-indigo-800">
				<span className="w-1/5 text-left">{question.config.min_label}</span>
				<span className="w-1/5 text-center">{question.config.mid_label}</span>
				<span className="w-1/5 text-right">{question.config.max_label}</span>
			</div>
		</form>
	);
}
