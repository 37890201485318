import { ChevronRightIcon } from '@heroicons/react/24/solid';

import Badge from '../Badge';
import { classNames } from '../../utils';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { PROJECT_STATUSES } from '../../constants';
import CircularProgress from '../CircularProgress';
import ProfilePicture from '../ProfilePicture';

const ProjectListItem = ({ project }) => {
	const { t } = useTranslation();

	return (
		<li
			key={project.nanoid}
			className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
		>
			<div className="flex items-center justify-between space-x-4">
				{/* Repo name and link */}
				<div className="min-w-0 space-y-3">
					<div className="flex items-center space-x-3">
						<span
							className={classNames(
								project.status === 'draft' ? 'bg-gray-100' : '',
								project.status === 'under_approval' ? 'bg-yellow-100' : '',
								project.status === 'under_edit' ? 'bg-yellow-100' : '',
								project.status === 'approved' ? 'bg-green-100' : '',
								project.status === 'running' ? 'bg-green-100' : '',
								project.status === 'paused' ? 'bg-red-100' : '',
								project.status === 'completed' ? 'bg-indigo-100' : '',
								'h-4 w-4 rounded-full flex items-center justify-center',
							)}
							aria-hidden="true"
						>
							<span
								className={classNames(
									project.status === 'draft' ? 'bg-gray-400' : '',
									project.status === 'under_approval' ? 'bg-yellow-400' : '',
									project.status === 'under_edit' ? 'bg-yellow-400' : '',
									project.status === 'approved' ? 'bg-green-400' : '',
									project.status === 'running' ? 'bg-green-400' : '',
									project.status === 'paused' ? 'bg-red-400' : '',
									project.status === 'completed' ? 'bg-indigo-400' : '',
									'h-2 w-2 rounded-full',
								)}
							/>
						</span>

						<h2 className="text-sm font-medium cursor-pointer">
							<Link
								to={
									project.status === 'completed' || project.status === 'running'
										? `/project/${project.nanoid}/analysis`
										: `/project/${project.nanoid}/audience`
								}
							>
								<span className="absolute inset-0" aria-hidden="true" />
								{project.title}
							</Link>
						</h2>
					</div>

					<div className="ml-6 mt-5 flex gap-1" title="Selam">
						<Badge type={PROJECT_STATUSES[project.status].badgeType}>
							{t(PROJECT_STATUSES[project.status].label)}
						</Badge>

						<div className="relative">
							<ProfilePicture
								firstName={project.user.first_name}
								lastName={project.user.last_name}
								companyName={project.user.company?.name}
								profilePicture={project.user.profile_picture}
								light={true}
								size={'xxs'}
							/>
						</div>
					</div>
				</div>
				<div className="sm:hidden">
					<Link to={`/project/${project.nanoid}/audience`}>
						<ChevronRightIcon
							className="h-5 w-5 text-gray-400"
							aria-hidden="true"
						/>
					</Link>
				</div>
				<div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
					<div className="flex items-center">
						{project.payment_mode === 'payg' ? (
							<Badge type="info">{t('projectList.paymentModes.payg')}</Badge>
						) : (
							<div className="px-2 py-0.5 rounded-full text-xs font-medium text-white bg-indigo-600">
								{t('projectList.paymentModes.subscription') +
									': ' +
									project.subscription.plan.name}
							</div>
						)}
					</div>

					<div
						className="flex gap-1 text-gray-500 text-sm z-30"
						title={moment(project?.created).format('LLL')}
					>
						{t('common.createdTimeAgo', {
							ago: moment(project?.created).fromNow(),
						})}
						<div
							className="w-5 h-5"
							title={t('Completes {{completed}}/{{totalReach}}', {
								completed: project.metrics.completed,
								totalReach: project.metrics.total_reach,
							})}
						>
							<CircularProgress
								progress={
									project.metrics.completed === 0
										? 0
										: (project.metrics.completed /
												project.metrics.total_reach) *
										  100
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</li>
	);
};

export default ProjectListItem;
