import { updateNotificationSettings } from '../../../api/services/UserProfile';

import { updateNotificationSettingsFinished } from '../../userProfileSlice';

import { takeLatest, call, put } from 'redux-saga/effects';

function* updateNotificationSettingsSaga(action) {
	const { comms_optin_email } = yield call(
		updateNotificationSettings,
		action.payload.nanoid,
		action.payload.isOffersChecked,
	);

	yield put(updateNotificationSettingsFinished(comms_optin_email));
}

export function* watchUpdateNotificationSettings() {
	yield takeLatest(
		'userProfile/updateNotificationSettingsStarted',
		updateNotificationSettingsSaga,
	);
}
