import Spinner from '../Spinner';
import { BiGlassesAlt } from 'react-icons/bi';
import { sendGoogleEvent } from '../../utils/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { loadPreviewDataStart } from '../../store/projectEditorSlice';
import { useTranslation } from 'react-i18next';

export default function PreviewButton({
	questionType,
	questionnaireNanoId,
	setOpen,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const previewStatus = useSelector(
		state => state.projectEditor.questionnaire.preview.status,
	);
	return (
		<button
			type="button"
			disabled={previewStatus === 'loading'}
			className="hidden sm:inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:hover:bg-gray-300 disabled:opacity-30"
			onClick={() => {
				setOpen(true);
				dispatch(
					loadPreviewDataStart({
						questionnaireNanoId: questionnaireNanoId,
					}),
				);
				sendGoogleEvent('questionnaire_question_preview', {
					question_type: questionType,
				});
			}}
		>
			{previewStatus === 'loading' ? (
				<Spinner className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
			) : (
				<BiGlassesAlt className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
			)}
			{t('Preview')}
		</button>
	);
}
