import { classNames } from '../../utils';
import { Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

function ToastIcon({ type, className }) {
	let CustomIcon = null;

	if (type === 'success') {
		CustomIcon = CheckCircleIcon;
	} else if (type === 'error') {
		CustomIcon = XCircleIcon;
	}
	return <CustomIcon className={className} />;
}

export default function Toast({ type, title, message, visible }) {
	return (
		<Transition
			appear={true}
			show={visible}
			as={Fragment}
			enter="transform ease-out duration-300 transition"
			enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			enterTo="translate-y-0 opacity-100 sm:translate-x-0"
			leave="transition ease-in duration-100"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
			className="p-4 pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
		>
			<div className="p-4">
				<div className="flex items-start">
					<div className="flex-shrink-0">
						{(type === 'success' || type === 'error') && (
							<ToastIcon
								type={type}
								className={classNames(
									type === 'success' ? 'text-green-400' : '',
									type === 'error' ? 'text-red-400' : '',
									'h-6 w-6',
								)}
								aria-hidden="true"
							/>
						)}
					</div>
					<div className="ml-3 w-0 flex-1 pt-0.5">
						<p className="text-sm font-medium text-gray-900">{title}</p>
						<p className="mt-1 text-sm text-gray-500">{message}</p>
					</div>
				</div>
			</div>
		</Transition>
	);
}
