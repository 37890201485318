import { TrashIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Spinner from '../../Spinner';
import { classNames } from '../utils/utils';

export default function File({ file, handleRemove }) {
	return (
		<div
			className={classNames(
				file.status === 'error' ? '' : 'border-grey-300',
				'w-36 h-min rounded-md shadow',
			)}
		>
			<div className="relative group">
				<img
					src={file.thumbnail}
					className={classNames(
						file.status === 'uploaded' ? 'border-green-600' : '',
						file.status === 'error' ? 'border-red-600' : '',
						file.status === 'uploading' ? 'border-mint-600' : '',
						'top-0 w-full border-2 border-solid rounded-md',
					)}
				/>
				{file.status === 'uploading' && (
					<div className="absolute top-1/2 left-1/2 -ml-4 -mt-4 opacity-90 bg-mint-100 p-2 rounded-md">
						<Spinner className={'w-6 h-6 text-mint-600'} />
					</div>
				)}
				{file.status === 'error' && (
					<div className="absolute top-1/2 left-1/2 -ml-4 -mt-4 opacity-90 bg-red-100 p-2 rounded-md">
						<ExclamationCircleIcon className="w-6 h-6 text-red-600" />
					</div>
				)}
				{(file.status === 'error' || file.status === 'uploaded') && (
					<div className="absolute top-2 right-2">
						<button
							type="button"
							onClick={() => handleRemove(file.internalId)}
							className="inline-flex items-center rounded border border-red-600 bg-red-500 px-2.5 py-1.5 text-xs font-medium text-red-50 shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-mint-500 focus:ring-offset-2 opacity-0 group-hover:opacity-100"
						>
							<TrashIcon className="w-3 h-3" />
						</button>
					</div>
				)}
			</div>
		</div>
	);
}
