import { tidy, filter, arrange, desc } from '@tidyjs/tidy';
import { indexOf } from 'lodash';

export const calculateChartKeys = comparisonData => {
	const calculatedKeys = [];
	if (Object.keys(comparisonData).length > 0) {
		Object.keys(comparisonData).forEach(comparisonKey => {
			if (Object.keys(comparisonData[comparisonKey]).length > 0) {
				Object.keys(comparisonData[comparisonKey]).forEach(
					comparisonItemKey => {
						if (
							Object.keys(comparisonData[comparisonKey][comparisonItemKey])
								.length > 0
						) {
							Object.keys(
								comparisonData[comparisonKey][comparisonItemKey],
							).forEach(rank => {
								const key = `${comparisonKey}_${comparisonItemKey}_${rank}`;
								calculatedKeys.push(key);
							});
						} else {
							const key = `${comparisonKey}_${comparisonItemKey}`;
							calculatedKeys.push(key);
						}
					},
				);
			}
		});
	} else {
		calculatedKeys.push('responsePercentage');
	}
	return calculatedKeys;
};

export const wordCountHistogram = (stringList, excludeWords = [], groupBy) => {
	const wordCount = {};

	stringList.forEach(str => {
		// Remove punctuation from text
		// next line disables unnecessary escape character error
		// eslint-disable-next-line
		const punctuation = /[\.,?!():]/g;
		const newText = str.replace(punctuation, ' ');

		// Split the string into words using regular expression.
		let words = newText.split(/\s+/);

		const trimmedWords = [];

		words.forEach(word => {
			const trimmedWord = word.trim().toLowerCase();
			if (trimmedWord.length > 0 && !excludeWords.includes(trimmedWord))
				trimmedWords.push(trimmedWord);
		});

		let newWords = trimmedWords;

		if (groupBy > 1) {
			newWords = [];

			while (trimmedWords.length > groupBy) {
				const splicedWords = trimmedWords.splice(0, groupBy);
				newWords.push(splicedWords.join(' '));
			}
			newWords.push(trimmedWords.join(' '));
		}

		// Count the frequency of each word (case-insensitive) excluding the specified words.
		newWords.forEach(word => {
			wordCount[word] = (wordCount[word] || 0) + 1;
		});
	});

	// Sort the word count object by word frequency (value) in descending order.
	const sortedHistogram = Object.entries(wordCount).sort((a, b) => a[1] - b[1]);

	// Convert the sorted array back to an object.
	//const keys = ['country', 'count'];
	const sortedWordCount = sortedHistogram.map(entry => {
		return { country: entry[0], count: entry[1] };
	});

	//return only the top 25 words
	const limitedWordCount =
		sortedWordCount.length > 25 ? sortedWordCount.slice(-25) : sortedWordCount;

	return {
		barData: limitedWordCount,
		tableData: [...limitedWordCount].reverse(),
	};
};

export const generateTableData = (
	data,
	labels,
	question,
	comparison,
	tableAnswerKey,
	tableDataKey,
) => {
	// Generate data for the table
	// we are in comparison mode here so every row is a comparison item and columns are choices
	const tempTableData = [];
	if (Object.keys(comparison).length > 0) {
		Object.keys(comparison).forEach(comparisonKey => {
			Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
				const rankKeys = Object.keys(
					comparison[comparisonKey][comparisonItemKey],
				);
				const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];
				rankArray.forEach(rankKey => {
					const choices = [];
					question.choices.forEach(choice => {
						const choiceTitle = choice.title
							? choice.title
							: choice.name
							? choice.name
							: choice.value;
						const choiceValue = choice.nanoid ? choice.nanoid : choice.value;

						const nanoId = question.slug ? question.slug : question.nanoid;

						const choiceResponses = tidy(
							data,
							filter(d => {
								const dataType = typeof d[nanoId];
								if (dataType === 'string' || dataType === 'number') {
									// For single choice filter items
									return d[nanoId] === choiceValue;
								} else if (dataType === 'object') {
									//for multiple choice
									return indexOf(d[nanoId], choiceValue) > -1;
								}
							}),
						);

						const comparedChoiceCount = tidy(
							choiceResponses,
							filter(d => {
								const dataType = typeof d[comparisonKey];

								if (dataType === 'string' || dataType === 'number') {
									// For single choice filter items
									return d[comparisonKey] == comparisonItemKey;
								} else if (dataType === 'object') {
									// For multiple choice & ranking filter items
									const innerDataType =
										d[comparisonKey][0] == null
											? 'string'
											: typeof d[comparisonKey][0];

									if (innerDataType === 'string') {
										//for multiple choice
										return indexOf(d[comparisonKey], comparisonItemKey) > -1;
									} else if (innerDataType === 'object') {
										let foundItemIndex = d[comparisonKey].findIndex(
											answer =>
												answer.order == rankKey &&
												answer.choice === comparisonItemKey,
										);

										return foundItemIndex > -1;
									}
								}
							}),
						);

						choices.push({
							choiceTitle: choiceTitle,
							choice: choice, // This is the choice data coming from the API
							responseCount: comparedChoiceCount.length,
							media: choice.media,
						});
					});
					tempTableData.push({
						answer:
							rankKey == -1
								? labels[`${comparisonKey}_${comparisonItemKey}`]
								: labels[`${comparisonKey}_${comparisonItemKey}_${rankKey}`],
						comparisonKey: comparisonKey,
						comparisonItemKey: comparisonItemKey,
						comparisonRankKey: rankKey == -1 ? null : rankKey,
						choices: choices,
					});
				});
			});
		});

		return tidy(tempTableData, arrange(desc('responseCount')));
	} else {
		// Generate data for the table
		// we are not in comparison mode here so every row is just a choice

		question.choices.forEach(choice => {
			const choiceTitle = choice.title
				? choice.title
				: choice.name
				? choice.name
				: choice.value;
			const choiceValue = choice.nanoid ? choice.nanoid : choice.value;

			const nanoId = question.slug ? question.slug : question.nanoid;

			const choices = tidy(
				data,
				filter(d => {
					const dataType = typeof d[nanoId];

					if (dataType === 'string' || dataType === 'number') {
						// For single choice filter items
						return d[nanoId] === choiceValue;
					} else if (dataType === 'object') {
						// For multiple choice filter items
						return indexOf(d[nanoId], choiceValue) > -1;
					}
				}),
			);

			tempTableData.push({
				[tableAnswerKey]: choiceTitle,
				choice: choice, // This is the choice data coming from the API
				responseCount: choices.length,
				media: choice.media,
				[tableDataKey]:
					data.length > 0
						? `${parseFloat((choices.length / data.length) * 100).toFixed(2)}%`
						: `0%`,
			});
		});

		const arrangedTableData = tidy(
			tempTableData,
			arrange(desc('responseCount')),
		);

		//move other choice to the bottom if exists
		const otherChoiceIndex = arrangedTableData.findIndex(
			data => data.choice.choice_type === 'other',
		);

		if (otherChoiceIndex > -1) {
			const otherResponse = arrangedTableData.splice(otherChoiceIndex, 1);
			arrangedTableData.push(otherResponse[0]);
		}

		return arrangedTableData;
	}
};

export const comparisonChoiceCount = (
	data,
	nanoid,
	choice,
	comparisonKey,
	comparisonItemKey,
	rankKey,
) => {
	const comparedChoiceCount = tidy(
		data,
		filter(d => {
			const dataType = typeof d[nanoid];
			if (dataType === 'string' || dataType === 'number') {
				// For single choice filter items
				return d[nanoid] === choice.nanoid;
			} else if (dataType === 'object') {
				// For multiple choice filter items
				return indexOf(d[nanoid], choice.nanoid) > -1;
			}
		}),

		filter(d => {
			const dataType = typeof d[comparisonKey];
			if (dataType === 'string' || dataType === 'number') {
				// For single choice filter items
				return d[comparisonKey] == comparisonItemKey;
			} else if (dataType === 'object') {
				// For multiple choice filter items
				const innerDataType =
					d[comparisonKey][0] == null ? 'string' : typeof d[comparisonKey][0];

				if (innerDataType === 'string') {
					//for multiple choice
					return indexOf(d[comparisonKey], comparisonItemKey) > -1;
				} else if (innerDataType === 'object') {
					let foundItemIndex = d[comparisonKey].findIndex(
						answer =>
							answer.order == rankKey && answer.choice === comparisonItemKey,
					);

					return foundItemIndex > -1;
				}
			}
		}),
	);

	return comparedChoiceCount;
};
