import { useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import AudienceCategories from '../../../components/AudienceCategories';
import AudienceCategory from '../../../components/AudienceCategory';
import AudienceSettings from '../../../components/AudienceSettings';
import SlideOver from '../../../components/SlideOver';
import { classNames } from '../../../utils';
import { useTranslation } from 'react-i18next';
import TourGuide from '../../../components/TourGuide';
import { setDocumentTitle } from '../../../utils';
import QuestionList from '../../../components/QuestionList';
import QuestionEditor from '../../../components/QuestionEditor';
import QuestionSettings from '../../../components/QuestionSettings';
import { Buttons } from '../../../components/Button';
import TabDropdown from '../../../components/TabDropdown';
import Badge from '../../../components/Badge';
import AddQuestionButton from '../../../components/AddQuestionButton';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';

export default function AudiencePage({ isEditable, subscription }) {
	const { t } = useTranslation();

	const [currentTab, setCurrentTab] = useState('filters');
	const tabs = [
		{
			label: t('Filters'),
			value: 'filters',
			current: currentTab === 'filters',
		},
		{
			label: t('screeningQuestions.title'),
			value: 'questions',
			current: currentTab === 'questions',
		},
	];
	const availableCategories = useSelector(
		state => state.projectEditor.audience.availableCategories,
	);
	const questionnaireNanoId = useSelector(
		state => state.projectEditor.questionnaire.nanoid,
	);
	const selectedCategories = useSelector(
		state => state.projectEditor.audience.selectedCategories,
	);

	const screeningQuestionList = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionList,
	);
	const screeningQuestionnaireHasErrors = useSelector(
		state => state.projectEditor.questionnaire.screeningQuestionnaireHasErrors,
	);

	const screeningActiveQuestionIndex = useSelector(
		state => state.projectEditor.questionnaire.screeningActiveQuestionIndex,
	);

	const conditionChecks = ({ tab }) => {
		return tab === 'questions' && screeningQuestionnaireHasErrors;
	};
	const itemEls = useRef({});

	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [isQuestionsOpen, setIsQuestionsOpen] = useState(false);
	const [isScreeningSettingsOpen, setIsScreeningSettingsOpen] = useState(false);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.projectEditor.audience'));
	}, [t]);

	const getLastAddedCategory = id => {
		itemEls.current[id].scrollIntoView({
			behavior: 'smooth',
			alignToTop: true,
		});
	};

	const steps = [
		{
			target: 'body',
			placement: 'center',
			title: t('tour.content.audience.targetAudience.title'),
			content: t('tour.content.audience.targetAudience.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-audience-categories',
			placement: 'auto',
			title: t('tour.content.audience.respondents.title'),
			content: t('tour.content.audience.respondents.content'),
			image: {
				src: '/assets/images/tour/denebunu-audience.png',
				className: 'h-60',
			},
			disableBeacon: true,
		},
		{
			target: '.tour-audience-categories',
			placement: 'auto',
			title: t('tour.content.audience.targetAudiences.title'),
			content: t('tour.content.audience.targetAudiences.content'),
			disableBeacon: true,
		},

		{
			target: '.tour-first-audience-category',
			placement: 'bottom',
			title: t('tour.content.audience.targetAudience2.title'),
			content: t('tour.content.audience.targetAudience2.content'),
			disableBeacon: true,
		},
		{
			target: '.tour-audience-settings',
			placement: 'auto',
			title: t('tour.content.audience.reach.title'),
			content: t('tour.content.audience.reach.content'),
			disableBeacon: true,
		},
	];

	return (
		<>
			<div className="tour-audience-categories hidden md:block w-1/2 lg:w-1/3 h-screen bg-white  items-center shadow-md overflow-y-auto">
				<div className="mb-80">
					<div>
						<div className="border-b border-gray-200">
							<nav className="-mb-px flex" aria-label="Tabs">
								{tabs.map(tab => (
									<button
										key={tab.value}
										onClick={() => setCurrentTab(tab.value)}
										className={classNames(
											tab.className ? tab.className : '',
											tab.current
												? 'border-indigo-500 text-indigo-600'
												: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
											'w-1/2 py-4  text-center border-b-2 font-medium text-sm',
										)}
										aria-current={tab.current ? 'page' : undefined}
									>
										<div className="flex flex-row align-center justify-center">
											{tab.value === 'questions' &&
												screeningQuestionnaireHasErrors && (
													<ExclamationCircleIcon
														className="h-4 w-4 mb-0.5 text-gray-500 m-auto mr-1 -ml-0.5 inline-flex shrink-0 "
														aria-hidden="true"
													/>
												)}
											<p className="truncate min-w-0">{t(tab.label)}</p>
											{tab.value === 'questions' &&
												screeningQuestionList.length > 0 && (
													<span className="ml-1 mr-0.5">
														<Badge type={'complete'}>
															{screeningQuestionList.length}
														</Badge>
													</span>
												)}
											{tab.value === 'filters' &&
												Object.keys(selectedCategories).length > 0 && (
													<span className="ml-1">
														<Badge type={'complete'}>
															{Object.keys(selectedCategories).length}
														</Badge>
													</span>
												)}
										</div>
									</button>
								))}
							</nav>
						</div>
					</div>
					<div className="px-2 xl:px-3">
						{currentTab === 'filters' && (
							<AudienceCategories
								isEditable={isEditable}
								categoryList={availableCategories}
								selectedCategories={selectedCategories}
								getLastAddedCategory={getLastAddedCategory}
								subscription={subscription}
							/>
						)}
						{currentTab === 'questions' && screeningQuestionList.length > 0 && (
							<QuestionList
								questionList={screeningQuestionList}
								activeQuestionIndex={screeningActiveQuestionIndex}
								isEditable={isEditable}
								isScreeningQuestion={true}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="w-full flex flex-col">
				{/* -------responsive elements, media breakpoint:up to medium------------ */}
				<div className="mt-4 ml-4">
					<TabDropdown
						tabs={tabs}
						setCurrentTab={setCurrentTab}
						currentTab={currentTab}
						conditionChecks={conditionChecks}
						width="xl"
						breakpoint="md"
					/>
				</div>
				{currentTab === 'filters' && (
					<div className="flex md:hidden justify-between mx-4 mt-2 mb-0">
						<Buttons
							measure="small"
							kind="white"
							onClick={() => {
								setIsFiltersOpen(true);
							}}
						>
							{t('Filters')}
						</Buttons>
						<Buttons
							measure="small"
							kind="white"
							onClick={() => {
								setIsSettingsOpen(true);
							}}
						>
							{t('Settings')}
						</Buttons>
					</div>
				)}
				{currentTab === 'questions' && screeningQuestionList.length > 0 && (
					<div className="flex md:hidden justify-between mx-4 mt-2 mb-0">
						<Buttons
							measure="small"
							kind="white"
							onClick={() => {
								setIsQuestionsOpen(true);
							}}
						>
							{' '}
							{t('screeningQuestions.title')}
						</Buttons>

						<Buttons
							measure="small"
							kind="white"
							onClick={() => {
								setIsScreeningSettingsOpen(true);
							}}
						>
							{' '}
							{t('Settings')}
						</Buttons>
					</div>
				)}
				{/* -------responsive elements, media breakpoint:up to medium------------ */}
				<div className="w-full px-4 mt-2.5 sm:mt-5 h-screen overflow-y-auto">
					{currentTab === 'filters' && (
						<>
							<SlideOver
								title={t('Filters')}
								open={isFiltersOpen}
								setOpen={setIsFiltersOpen}
							>
								<AudienceCategories
									isEditable={isEditable}
									categoryList={availableCategories}
									selectedCategories={selectedCategories}
									getLastAddedCategory={getLastAddedCategory}
									subscription={subscription}
								/>
							</SlideOver>
							<SlideOver
								title={t('Settings')}
								open={isSettingsOpen}
								setOpen={setIsSettingsOpen}
							>
								<AudienceSettings isEditable={isEditable} />
							</SlideOver>

							<div className="flex flex-col mb-96">
								{availableCategories.map((category, index) => (
									<div key={category.nanoid}>
										{category.category_set.map(subCategory => (
											<div
												key={subCategory.nanoid}
												ref={element =>
													(itemEls.current[subCategory.nanoid] = element)
												}
											>
												{selectedCategories[subCategory.nanoid] !==
													undefined && (
													<div
														className={
															index === 0 ? 'tour-first-audience-category' : ''
														}
													>
														<AudienceCategory
															category={subCategory}
															isEditable={isEditable}
															subscription={subscription}
														/>
													</div>
												)}
											</div>
										))}
									</div>
								))}
							</div>
						</>
					)}

					{currentTab === 'questions' && (
						<>
							<SlideOver
								title={t('Questions')}
								open={isQuestionsOpen}
								setOpen={setIsQuestionsOpen}
							>
								<QuestionList
									questionList={screeningQuestionList}
									activeQuestionIndex={screeningActiveQuestionIndex}
									isEditable={isEditable}
									isScreeningQuestion={true}
								/>
							</SlideOver>

							<SlideOver
								title={t('Settings')}
								open={isScreeningSettingsOpen}
								setOpen={setIsScreeningSettingsOpen}
							>
								<QuestionSettings
									question={screeningQuestionList[screeningActiveQuestionIndex]}
									isEditable={isEditable}
									index={screeningActiveQuestionIndex}
									questionnaireNanoId={questionnaireNanoId}
									isScreeningQuestion={true}
								/>
							</SlideOver>

							{screeningQuestionList.length > 0 && (
								<QuestionEditor
									index={screeningActiveQuestionIndex}
									question={screeningQuestionList[screeningActiveQuestionIndex]}
									questionnaireNanoId={questionnaireNanoId}
									isEditable={isEditable}
									isScreeningQuestion={true}
									length={screeningQuestionList.length}
								/>
							)}
							{screeningQuestionList.length === 0 && (
								<div className="text-center">
									<svg
										className="mx-auto h-12 w-12 text-gray-400"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
										aria-hidden="true"
									>
										<path
											vectorEffect="non-scaling-stroke"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth={2}
											d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
										/>
									</svg>
									<h3 className="mt-2 text-sm font-semibold text-gray-900">
										{t('screeningQuestions.noQuestions.heading')}
									</h3>
									<p className="mt-1 text-sm text-gray-500">
										{t('screeningQuestions.noQuestions.description')}
									</p>
									<div className="mt-6 flex justify-center">
										<AddQuestionButton
											isEditable={isEditable}
											isScreeningQuestion={true}
											length={screeningQuestionList.length}
										/>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
			{currentTab === 'filters' && (
				<div className="tour-audience-settings hidden md:block w-1/3 h-screen bg-white items-center shadow-md overflow-y-auto">
					<AudienceSettings isEditable={isEditable} />
				</div>
			)}
			{currentTab === 'questions' && screeningQuestionList.length > 0 && (
				<div className=" hidden md:block w-1/3 h-screen bg-white items-center shadow-md overflow-y-auto ">
					<div className="hidden sm:block">
						<div className="border-b border-gray-200">
							<nav className="-mb-px flex" aria-label="Tabs">
								<button
									className="border-indigo-500 text-indigo-600 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
									aria-current={'page'}
								>
									{t('Settings')}
								</button>
							</nav>
						</div>
					</div>

					<div className="p-3">
						<QuestionSettings
							question={screeningQuestionList[screeningActiveQuestionIndex]}
							isEditable={isEditable}
							index={screeningActiveQuestionIndex}
							questionnaireNanoId={questionnaireNanoId}
							isScreeningQuestion={true}
						/>
					</div>
				</div>
			)}
			<TourGuide tourName={'audiencePage'} steps={steps} />
		</>
	);
}
