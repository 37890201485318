import { endPoints } from '../../endPoints';
import { sorbunuApi } from '../axios';

export async function getActivitiesList(pageNumber) {
	const result = await sorbunuApi.get(
		endPoints.activities.GET_ACTIVITIES_LIST(pageNumber),
	);

	return result.data;
}
