import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Alert from '../../../components/Alert';
import { Logo } from '../../../components/Logo';
import Spinner from '../../../components/Spinner';
import { startEmailVerification } from '../../../store/authSlice';
import { setDocumentTitle } from '../../../utils';

export default function VerifyEmailPage() {
	const navigate = useNavigate();
	const { token } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const emailVerification = useSelector(state => state.auth.emailVerification);
	const user = useSelector(state => state.auth.user);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.verifyEmail'));
	}, [t]);

	useEffect(() => {
		dispatch(
			startEmailVerification({
				key: token,
			}),
		);
	}, [dispatch, token]);

	if (!user) {
		navigate('/sign-in');
		return <div></div>;
	}
	return (
		<>
			<div className="bg-gray-100 w-screen h-screen py-20 px-4 md:p-20">
				<Logo className="h-12 w-auto mx-auto" />
				<div className="mt-12 bg-white shadow rounded border-t-4 border-t-indigo-600 w-full md:w-1/3 p-10 mx-auto">
					{emailVerification.status === 'loading' && (
						<Spinner className="w-10 h-10 mx-auto text-indigo-800" />
					)}
					{emailVerification.status === 'failed' && (
						<Alert type="error">{t('emailVerification.failed')}</Alert>
					)}
					{emailVerification.status === 'succeeded' && (
						<Alert type="success">{t('emailVerification.succeeded')}</Alert>
					)}
					<div className="text-center">
						<button
							onClick={() => {
								navigate('/dashboard');
							}}
							type="submit"
							className="mt-4 disabled:cursor-not-allowed disabled:hover:bg-indigo-600 disabled:opacity-30 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						>
							{t('emailVerification.goToDashboard')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
}
