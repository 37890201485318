import { ResponsiveBullet } from '@nivo/bullet';
import { classNames } from '../../utils';

const heightMap = {
	1: 'h-[5rem]',
	2: 'h-[10rem]',
	3: 'h-[15rem]',
	4: 'h-[20rem]',
	5: 'h-[25rem]',
	6: 'h-[30rem]',
	7: 'h-[35rem]',
	8: 'h-[40rem]',
	9: 'h-[45rem]',
	10: 'h-[50rem]',
	11: 'h-[55rem]',
	12: 'h-[60rem]',
	13: 'h-[65rem]',
};

export default function BulletChart({ data }) {
	let chartContainerHeight =
		data.length <= 13 ? heightMap[data.length] : 'h-[70rem]';

	return (
		<div className={classNames(chartContainerHeight)}>
			<ResponsiveBullet
				data={data}
				minValue={-100}
				margin={{ top: 25, right: 50, bottom: 25, left: 50 }}
				spacing={60}
				markerSize={0.6}
				rangeColors={['#fef08a']}
				markerColors={['#ca8a04']}
				measureColors={['#fef08a']}
				measureSize={0.3}
				titleAlign={'start'}
				titleOffsetX={0}
				titleOffsetY={-25}
			/>
		</div>
	);
}
