import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
	updateNotificationSettingsStarted,
	resetNotificationSettings,
} from '../../../store/userProfileSlice';
import { Buttons } from '../../../components/Button';
import { sendGoogleEvent } from '../../../utils/analytics';
import { setDocumentTitle } from '../../../utils';
import SlideOver from '../../../components/SlideOver';
import CommsOptin from '../../../components/LegalText/commsOptin';
import Spinner from '../../../components/Spinner';

export default function NotificationSettingsPage() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { auth, user } = useSelector(state => state.auth);
	const comms_optin_email = user.comms_optin_email;
	const [isOffersChecked, setIsOffersChecked] = useState(comms_optin_email);
	const [showCommsOptinText, setShowCommsOptinText] = useState(false);
	const nanoid = auth?.user?.nanoid;

	const { fetch, success } = useSelector(
		state => state.userProfile.updateNotificationSettings,
	);

	useEffect(() => {
		setDocumentTitle(t('pageTitles.notificationSettings'));
	}, [t]);

	useEffect(() => {
		if (success) {
			toast.success(t('notificationSettings.successToastMessage'), {
				title: t('Success'),
			});
			dispatch(resetNotificationSettings());
		}
	}, [success, dispatch, t]);

	const handleOffersClick = e => {
		e.preventDefault();
		setShowCommsOptinText(true);
	};

	const handleFormSubmit = e => {
		e.preventDefault();
		dispatch(
			updateNotificationSettingsStarted({
				nanoid: nanoid,
				isOffersChecked: isOffersChecked,
			}),
		);

		sendGoogleEvent('account_notification_setting_change', {
			setting: 'offers',
			value: isOffersChecked,
		});
	};

	return (
		<>
			<section aria-labelledby="payment-details-heading">
				<form onSubmit={handleFormSubmit}>
					<div className="shadow sm:rounded-md sm:overflow-hidden">
						<div className="bg-white py-6 px-4 sm:p-6">
							<div>
								<h2
									id="payment-details-heading"
									className="text-lg leading-6 font-medium text-gray-900"
								>
									{t('notificationSettings.heading')}
								</h2>
								<p className="mt-1 text-sm text-gray-500">
									{t('notificationSettings.description')}
								</p>
							</div>

							<div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
								<div className="mt-6 sm:col-span-2">
									<div className="max-w-xl space-y-4">
										<div>
											<div className="relative flex items-start">
												<div className="flex items-center h-5">
													<input
														id="offers"
														name="offers"
														type="checkbox"
														className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
														checked={isOffersChecked}
														onChange={() =>
															setIsOffersChecked(!isOffersChecked)
														}
													/>
												</div>
												<div className="ml-3 text-sm">
													<label
														htmlFor="offers"
														className="font-medium text-gray-700"
													>
														{t('Offers')}
													</label>
													<p className="text-gray-500">
														{t(
															'notificationSettings.offerNotificationTextStart',
														)}
														<button
															className="text-indigo-600 underline"
															type="button"
															onClick={handleOffersClick}
														>
															{t(
																'notificationSettings.offerNotificationPopButton',
															)}
														</button>
														{t('notificationSettings.offerNotificationTextEnd')}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<Buttons type="submit" kind="primary" disabled={fetch}>
								{fetch ? <Spinner className="w-5 h-5" /> : t('Save')}
							</Buttons>
						</div>
					</div>
				</form>
			</section>
			<SlideOver
				title={t('legal.signUp.commsOptinLegalText.title')}
				open={showCommsOptinText}
				setOpen={setShowCommsOptinText}
			>
				<CommsOptin />
			</SlideOver>
		</>
	);
}
