import { tidy, filter } from '@tidyjs/tidy';
import { CalendarIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { wordCountHistogram } from './utils';
import BarChart from './BarChart';
import { calculateHeightForBarChart } from '../../utils/analysis';
import { indexOf, slice } from 'lodash';
import LabelChart from './LabelChart';
import Tabs from '../Tabs';
import { useSelector } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../Pagination';
import { useTranslation } from 'react-i18next';
import { classNames } from '../../utils';
import { QUESTION_META_DATA, EXCLUDED_WORDS } from '../../constants';
import { getTranslatedResponseText } from '../../utils/Translation';
import { useDispatch } from 'react-redux';
import LabelList from '../LabelList';
import NumberDistributionChart from './NumberDistributionChart';
import NumberPicker from '../NumberPicker';
import ResponseSearch from '../ResponseSearch';
import CountTable from './CountTable';
import { setAppliedComparisonItems } from '../../store/analysisSlice';
import moment from 'moment/moment';

const PAGE_SIZE = 10;

function ComparisonChecks({
	appliedComparisonItems,
	handleComparisonItemChange,
	comparison,
	labels,
	questionType,
}) {
	const items = [];
	Object.keys(comparison).forEach(comparisonKey => {
		Object.keys(comparison[comparisonKey]).forEach(comparisonItemKey => {
			const rankKeys = Object.keys(
				comparison[comparisonKey][comparisonItemKey],
			);
			const rankArray = rankKeys.length > 0 ? [...rankKeys] : [-1];

			rankArray.forEach(rankKey => {
				const key =
					rankKey == -1
						? `${comparisonKey}_${comparisonItemKey}`
						: `${comparisonKey}_${comparisonItemKey}_${rankKey}`;
				items.push({
					key: key,
					comparisonKey: comparisonKey,
					comparisonItemKey: comparisonItemKey,
					rankKey: rankKey == -1 ? null : rankKey,
					name: labels[key],
				});
			});
		});
	});

	return (
		<div className="flex flex-row flex-wrap gap-2">
			{items.map((item, index) => (
				<div
					key={index}
					className={classNames(
						QUESTION_META_DATA[questionType].borderColor,
						QUESTION_META_DATA[questionType].checkboxBgColor,
						'cursor-pointer flex items-start rounded  border-1 border px-3 py-2',
					)}
				>
					<div className="flex h-5 items-center">
						<input
							id={item.key}
							name={item.key}
							type="checkbox"
							checked={
								appliedComparisonItems[item.key]
									? appliedComparisonItems[item.key].checked === true
									: false
							}
							onChange={e => handleComparisonItemChange(item, e.target.checked)}
							value={item.key}
							className={classNames(
								QUESTION_META_DATA[questionType].textColor,
								`h-4 w-4 rounded border-gray-300 focus:${QUESTION_META_DATA[questionType].ringColor}`,
							)}
						/>
					</div>
					<div className="ml-1 text-sm">
						<label htmlFor={item.key} className={'cursor-pointe text-gray-700'}>
							{item.name}
						</label>
					</div>
				</div>
			))}
		</div>
	);
}

export default function DataListDisplay({
	question,
	data,
	labels,
	comparison,
	isCompareMode,
	renderValue,
	language,
}) {
	const dispatch = useDispatch();
	const { nanoid } = useParams();

	const [responseSearchData, setResponseSearchData] = useState('');
	const [filteredData, setFilteredData] = useState([]);
	const [wordCountData, setWordCountData] = useState([]);
	const [hasComparison, setHasComparison] = useState(false);
	const [groupBy, setGroupBy] = useState(1);

	const [selectedDistributionWidth, setSelectedDistributionWidth] =
		useState('auto');
	const [selectedTab, setSelectedTab] = useState(
		question.question_type === 'text' ? 'responses' : 'distribution',
	);

	const allAppliedComparisonItems = useSelector(
		state =>
			state.analysis.projects[nanoid].comparisonData.textNumberComparisonItems,
	);

	const user = useSelector(state => state.auth.user);

	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [keys, setKeys] = useState([]);

	const chartRef = useRef();

	const startIndex = (page - 1) * PAGE_SIZE;
	const endIndex = startIndex + PAGE_SIZE;

	const handleWordCountChange = newValue => {
		setGroupBy(newValue);
	};

	const handleResponseSearchChange = newValue => {
		setResponseSearchData(newValue);
	};

	useEffect(() => {
		let l = data;
		if (responseSearchData.length > 0) {
			l = l.filter(function (item) {
				const text = item[question.nanoid].toLowerCase();
				const filter = responseSearchData.toLowerCase();
				return text.includes(filter);
			});
		}

		let calculatedKeys = [];
		let appliedComparisonItemCount = 0;

		const appliedComparisonItems = allAppliedComparisonItems[question.nanoid];

		if (appliedComparisonItems) {
			Object.keys(appliedComparisonItems).forEach(k => {
				const appliedComparisonItem = appliedComparisonItems[k];
				if (appliedComparisonItem.checked === true) {
					appliedComparisonItemCount += 1;
					calculatedKeys.push(appliedComparisonItem.key);
				}
			});
		}

		setHasComparison(appliedComparisonItemCount > 0);

		if (appliedComparisonItemCount > 0) {
			// TODO: Try to refactor analysisFilter helper and use it here
			l = tidy(
				l,

				filter(d => {
					let itemMatchesAFilter = false;

					Object.keys(appliedComparisonItems).forEach(k => {
						if (itemMatchesAFilter) {
							return true;
						}

						const appliedComparisonItem = appliedComparisonItems[k];
						const dataType = typeof d[appliedComparisonItem.comparisonKey];

						if (appliedComparisonItem.checked === true) {
							if (dataType === 'string' || dataType === 'number') {
								// For single choice filter items
								itemMatchesAFilter =
									d[appliedComparisonItem.comparisonKey] ==
									appliedComparisonItem.comparisonItemKey;
							} else if (dataType === 'object') {
								// For multiple choice filter items

								const innerDataType =
									d[appliedComparisonItem.comparisonKey][0] == null
										? 'string'
										: typeof d[appliedComparisonItem.comparisonKey][0];

								if (innerDataType === 'string') {
									//for multiple choice
									itemMatchesAFilter =
										indexOf(
											d[appliedComparisonItem.comparisonKey],
											appliedComparisonItem.comparisonItemKey,
										) > -1;
								} else if (innerDataType === 'object') {
									itemMatchesAFilter =
										d[appliedComparisonItem.comparisonKey].findIndex(
											answer =>
												answer.order == appliedComparisonItem.rankKey &&
												answer.choice ===
													appliedComparisonItem.comparisonItemKey,
										) > -1;
								}
							}
						}
					});

					return itemMatchesAFilter;
				}),
			);
		}

		setFilteredData(l);
		setKeys(calculatedKeys);
	}, [
		allAppliedComparisonItems,
		data,
		dispatch,
		nanoid,
		question,
		comparison,
		responseSearchData,
	]);

	//calculate word count
	useEffect(() => {
		if (question.question_type === 'text') {
			let result = [];

			if (filteredData.length > 0) {
				const textAnswers = filteredData.map(response => {
					if (response[question.nanoid]) {
						return getTranslatedResponseText(
							response,
							question.nanoid,
							language,
						);
					} else {
						return '';
					}
				});

				result = wordCountHistogram(
					textAnswers,
					EXCLUDED_WORDS[language],
					groupBy,
				);
			} else {
				result = [{ country: 0, count: 0 }];
			}

			setWordCountData(result);
		}
	}, [groupBy, question, filteredData, language]);

	const handleComparisonItemChange = (item, checked) => {
		dispatch(
			setAppliedComparisonItems({
				item,
				checked,
				projectNanoId: nanoid,
				question: question.nanoid,
			}),
		);
	};

	const tabs = {
		text: [
			{
				key: 'responses',
				title: t('Responses'),
			},
			{
				key: 'labels',
				title: t('Labels'),
			},
			{
				key: 'wordCount',
				title: t('Word Count'),
			},
		],
		number: [
			{
				key: 'distribution',
				title: t('Distribution'),
			},
			{
				key: 'responses',
				title: t('Responses'),
			},
		],
	};

	const distributionWidthTabs = [
		{
			key: 'auto',
			title: t(
				'analysis.questions.number.distributionChart.binWidthSelections.auto',
			),
		},
		{ key: '5', title: '5' },
		{ key: '10', title: '10' },
		{ key: '25', title: '20' },
		{ key: '50', title: '50' },
		{ key: '100', title: '100' },
		{ key: '1000', title: '1000' },
		{ key: '10000', title: '10000' },
	];

	return (
		<>
			{isCompareMode && (
				<div className="px-4 py-4 sm:px-6 border-b border-gray-200">
					<ComparisonChecks
						appliedComparisonItems={
							allAppliedComparisonItems[question.nanoid] || {}
						}
						handleComparisonItemChange={handleComparisonItemChange}
						comparison={comparison}
						labels={labels}
						questionType={question.question_type}
					/>
				</div>
			)}
			{filteredData.length === 0 && (
				<>
					{selectedTab === 'responses' && responseSearchData.length > 0 && (
						<div className="py-2 px-6 border-b">
							<ResponseSearch
								value={responseSearchData}
								onChange={handleResponseSearchChange}
							/>
						</div>
					)}

					<div className="text-center my-10">
						<MagnifyingGlassIcon className="w-10 h-10 mx-auto text-gray-400" />
						<h3 className="mt-2 text-sm font-medium text-gray-900">
							{t('No responses')}
						</h3>
						<p className="mt-1 text-sm text-gray-500">
							{t('Please wait for a while or try changing filters.')}
						</p>
					</div>
				</>
			)}
			{filteredData.length > 0 && (
				<>
					{(question.question_type === 'text' ||
						question.question_type === 'number') && (
						<div className="py-2 px-6 border-b">
							<div className="flex justify-between">
								<Tabs
									size="sm"
									tabs={tabs[question.question_type]}
									type={question.question_type}
									selectedTab={selectedTab}
									onChange={newTab => {
										setSelectedTab(newTab);
									}}
								/>
								{selectedTab === 'wordCount' && (
									<NumberPicker
										label={t('analysis.questions.wordFrequencies.wordCount')}
										defaultValue={1}
										minValue={1}
										handleValueChange={handleWordCountChange}
									/>
								)}

								{selectedTab === 'responses' && (
									<ResponseSearch
										value={responseSearchData}
										onChange={handleResponseSearchChange}
									/>
								)}

								{question.question_type === 'number' &&
									selectedTab === 'distribution' && (
										<Tabs
											size="sm"
											tabs={distributionWidthTabs}
											type={question.question_type}
											selectedTab={selectedDistributionWidth}
											onChange={newTab => {
												setSelectedDistributionWidth(newTab);
											}}
										/>
									)}
							</div>
						</div>
					)}
					{selectedTab === 'labels' && (
						<div className="px-8">
							<LabelChart
								question={question}
								data={filteredData}
								totalCount={filteredData.length}
								isCompareMode={false}
							/>
						</div>
					)}
					{selectedTab === 'distribution' && (
						<div className="px-8 mb-4">
							<NumberDistributionChart
								data={filteredData}
								keys={keys}
								question={question}
								labels={labels}
								binWidth={selectedDistributionWidth}
								appliedComparisonItems={
									allAppliedComparisonItems[question.nanoid] || {}
								}
								isCompareMode={hasComparison}
								comparison={comparison}
							/>
						</div>
					)}

					{selectedTab === 'wordCount' && (
						<div className="px-8">
							<div
								ref={chartRef}
								className="w-full mb-4"
								style={{
									height: calculateHeightForBarChart(
										wordCountData.barData.length,
									),
								}}
							>
								<BarChart
									legend={t('Words')}
									type="count"
									minValue={0}
									maxValue={
										wordCountData.barData.length > 0
											? wordCountData.barData[wordCountData.barData.length - 1]
													.count
											: 0
									}
									keys={['count']}
									data={wordCountData.barData}
									legendLabels={[]}
								/>
								<CountTable
									firstColName={t('Words')}
									secondColName={t('Word Count')}
									sortedRows={wordCountData.tableData}
									paginationItemName={t('paginationItem.words')}
									textSize="small"
								/>
							</div>
						</div>
					)}
					{selectedTab === 'responses' && (
						<div>
							<ul role="list" className="divide-y divide-gray-200">
								{slice(filteredData, startIndex, endIndex).map(
									(response, index) => {
										return (
											<li
												key={index}
												className="group-two px-4 py-4 sm:px-6 flex flex-col gap-2 hover:bg-gray-50"
											>
												<div className="text-sm text-gray-700">
													{renderValue
														? renderValue(response[question.nanoid])
														: getTranslatedResponseText(
																response,
																question.nanoid,
																language,
														  )}
												</div>
												<div className="pt-1 text-xs text-gray-500 flex flex-row gap-1">
													<CalendarIcon className="w-3.5 h-3.5" />
													{moment(response.created).format(
														'MMMM Do YYYY, h:mm:ss a',
													)}
													{user?.is_staff && (
														<span>&middot; {response.nanoid}</span>
													)}
												</div>
												{question.question_type === 'text' && (
													<LabelList
														key={response.nanoid}
														responseNanoId={[response.nanoid]}
														responseLabels={response.labels}
														questionNanoId={question.nanoid}
													/>
												)}
											</li>
										);
									},
								)}
							</ul>
							<Pagination
								currentPage={page}
								startIndex={startIndex + 1}
								endIndex={
									endIndex <= filteredData.length
										? endIndex
										: filteredData.length
								}
								totalItemCount={filteredData.length}
								handlePageChange={setPage}
								textSize="small"
								itemName={t('paginationItem.responses')}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
}
