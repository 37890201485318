export const endPoints = {
	auth: {
		SIGN_UP: () => '/auth/registration/',
		SIGN_IN: () => '/auth/login/',
		SIGN_OUT: '/auth/logout/',
		CHANGE_PASSWORD: () => '/auth/password/change/',
		FORGOT_PASSWORD: () => '/auth/password/reset/',
		RESET_PASSWORD: () => '/auth/password/reset/confirm/',
		VERIFY_EMAIL: () => '/auth/registration/verify-email/',
		RESEND_VERIFICATION_EMAIL: () => '/auth/registration/resend-email/',
		REFRESH_TOKEN: () => '/auth/token/refresh/',
	},
	users: {
		USER_SUCCESS: () => '/auth/registration/verify-email/',
		REFRESH_TOKEN: () => '/auth/token/refresh/',
		GET_USER_PROFILE: nanoid => `/users/${nanoid}/`,
	},
	projects: {
		PROJECTS: () => '/projects/',
		SEARCH_PROJECTS: searchString =>
			`/projects/?title__icontains=${searchString}&limit=9999`,
		GET_PROJECTS: (
			limit = 10,
			page = 1,
			status = 'all',
			ordering = '-created',
		) =>
			`/projects/?ordering=${ordering}&limit=${limit}&page=${page}${
				status !== 'all' ? `&status=${status}` : ''
			}`,

		RETRIEVE_PROJECT: nanoid => `/projects/${nanoid}/`,
		CHANGE_PROJECT_NAME: nanoid => `/projects/${nanoid}/`,
		UPDATE_PROJECT_STATUS: nanoid => `/projects/${nanoid}/`,
		DELETE_PROJECT: nanoid => `/projects/${nanoid}/`,
		GET_COST_DATA: nanoid => `/projects/${nanoid}/cost/`,
		COMMENT_LIST: nanoid => `/projects/${nanoid}/comments/`,
		AUDIENCE_LIST: nanoid => `/projects/${nanoid}/audiences/`,
	},
	feedback: {
		GET_FEEDBACK: projectNanoid => `/projects/${projectNanoid}/feedbacks/`,
		POST_FEEDBACK: projectNanoid => `/projects/${projectNanoid}/feedbacks/`,
		UPDATE_FEEDBACK: (projectNanoid, feedbackNanoid) =>
			`/projects/${projectNanoid}/feedbacks/${feedbackNanoid}/`,
		DELETE_FEEDBACK: (projectNanoid, feedbackNanoid) =>
			`/projects/${projectNanoid}/feedbacks/${feedbackNanoid}/`,
	},
	analysis: {
		ANALYSIS_META: projectNanoId => `/projects/${projectNanoId}/analysis/`,
	},
	addresses: {
		ADDRESS: `/address/`,
		ADDRESS_DETAIL: addressNanoId => `/address/${addressNanoId}/`,
	},
	cities: {
		CITY_NO_LIMIT: countryNanoid =>
			`/cities/?country__nanoid=${countryNanoid}&limit=1000`,
	},
	countries: {
		COUNTRY_NO_LIMIT: '/countries/',
	},
	districts: {
		DISTRICTS_BY_CITY: cityNanoId => `/cities/${cityNanoId}/districts/`,
	},
	checkout: {
		CHECKOUT: '/checkout/',
		GET_CHECKOUT_CONTRACTS: '/checkout/contracts/',
		GET_PROMO_CODES: '/checkout/promo-codes/',
		ADD_PROMO_CODE: '/checkout/promo-codes/',
		DELETE_PROMO_CODE: '/checkout/promo-codes/',
	},
	audiences: {
		AVAILABLE_CATEGORIES: country_code =>
			`/categories/?country_code=${country_code}`,
		AUDIENCE: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/`,
		AUDIENCE_DETAILED: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/detailed/`,
		AUDIENCE_FIELDS: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/audience-fields/?limit=9999`,
		CATEGORY_FIELDS: categoryNanoId =>
			`/categories/${categoryNanoId}/category-fields/?ordering=order`,
		SEARCH_CATEGORY_FIELDS: (categoryNanoId, term) =>
			`/categories/${categoryNanoId}/category-fields/?ordering=order&search=${term}`,
		GET_AUDIENCE_FIELDS: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/audience-fields/`,
		DELETE_AUDIENCE_FIELD: (
			projectNanoId,
			audienceNanoId,
			audienceFieldNanoId,
		) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/audience-fields/${audienceFieldNanoId}/`,
		DELETE_AUDIENCE_FIELDS_BY_CATEGORY: (
			projectNanoId,
			audienceNanoId,
			categoryNanoId,
		) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/audience-fields/bulk-delete/${categoryNanoId}/`,
		PROJECT_FEASIBILITY: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/is-feasible/`,
	},
	activities: {
		GET_ACTIVITIES_LIST: pageNumber =>
			`/activities/?limit=7&page=${pageNumber}`,
	},
	notifications: {
		UPDATE_OFFERS_SETTING: nanoid => `/users/${nanoid}/`,
	},
	questionnaires: {
		QUESTIONNAIRE: nanoid => `questionnaires/${nanoid}/`,
		PREVIEW: nanoid => `questionnaires/${nanoid}/preview/`,
	},
	labels: {
		QUESTIONNAIRE_LABELS: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/labels/?limit=9999`,
		DELETE_RESPONSE_LABEL: (responseNanoId, labelNanoId, questionNanoId) =>
			`/responses/${responseNanoId}/labels/${labelNanoId}/?question=${questionNanoId}`,
		GET_RESPONSE_LABELS: responseNanoId =>
			`/responses/${responseNanoId}/labels/`,
		ADD_QUESTIONNAIRE_LABEL: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/labels/`,
		ADD_RESPONSE_LABEL: responseNanoId =>
			`/responses/${responseNanoId}/labels/`,
	},
	preQuestion: {
		CREATE_PRE_QUESTION: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/`,
		RETRIEVE_PRE_QUESTION: (questionnaireNanoId, preQuestionNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/`,
		UPDATE_PRE_QUESTION: (questionnaireNanoId, preQuestionNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/`,
		DELETE_PRE_QUESTION: (questionnaireNanoId, preQuestionNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/`,
		LIST_PRE_QUESTIONS: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/?limit=9999`,
		BULK_ORDER: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/bulk-order/`,
	},
	question: {
		LIST_QUESTIONS: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/questions/?limit=9999`,
	},
	preChoice: {
		CREATE_PRE_CHOICE: (questionnaireNanoId, preQuestionNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/pre-choices/`,
		UPDATE_PRE_CHOICE: (
			questionnaireNanoId,
			preQuestionNanoId,
			preChoiceNanoId,
		) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/pre-choices/${preChoiceNanoId}/`,
		DELETE_PRE_CHOICE: (
			questionnaireNanoId,
			preQuestionNanoId,
			preChoiceNanoId,
		) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/pre-choices/${preChoiceNanoId}`,
		BULK_ORDER: (questionnaireNanoId, preQuestionNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/pre-choices/bulk-order/`,
	},
	rule: {
		CREATE_RULE: (questionnaireNanoId, preQuestionNanoId, logicNanoId) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/logic/${logicNanoId}/rules/`,
		UPDATE_RULE: (
			questionnaireNanoId,
			preQuestionNanoId,
			logicNanoId,
			ruleNanoId,
		) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/logic/${logicNanoId}/rules/${ruleNanoId}/`,
		DELETE_RULE: (
			questionnaireNanoId,
			preQuestionNanoId,
			logicNanoId,
			ruleNanoId,
		) =>
			`/questionnaires/${questionnaireNanoId}/pre-questions/${preQuestionNanoId}/logic/${logicNanoId}/rules/${ruleNanoId}/`,
	},
	order: {
		LIST_ORDERS: projectNanoId => `/projects/${projectNanoId}/orders/`,
	},
	transaction: {
		LIST_TRANSACTIONS: (projectNanoId, orderNanoId) =>
			`/projects/${projectNanoId}/orders/${orderNanoId}/transactions/`,
	},
	subscription: {
		LIST_SUBSCRIPTION: () => `/subscriptions`,
		GET_SUBSCRIPTION_DETAIL: subscriptionNanoId =>
			`subscriptions/${subscriptionNanoId}/`,
	},
	translations: {
		LIST_QUESTIONNAIRE_LANGUAGES: questionnaireNanoId =>
			`/questionnaires/${questionnaireNanoId}/languages/?limit=10000`,
		LIST_TRANSLATED_QUESTION_TEXTS: (
			questionnaireNanoId,
			questionnaireLanguageNanoId,
		) =>
			`/questionnaires/${questionnaireNanoId}/languages/${questionnaireLanguageNanoId}/question-texts/?limit=10000`,
	},
	quotas: {
		LIST_AUDIENCE_QUOTAS: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/`,
		LIST_QUOTA_FIELDS: (projectNanoId, audienceNanoId, quotaNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/${quotaNanoId}/fields/`,
		CREATE_QUOTA: (projectNanoId, audienceNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/`,
		DELETE_QUOTA: (projectNanoId, audienceNanoId, quotaNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/${quotaNanoId}/`,
		CREATE_QUOTA_FIELD: (projectNanoId, audienceNanoId, quotaNanoId) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/${quotaNanoId}/fields/`,
		DELETE_QUOTA_FIELD: (
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
			quotaFieldNanoId,
		) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/${quotaNanoId}/fields/${quotaFieldNanoId}/`,
		UPDATE_QUOTA_FIELD: (
			projectNanoId,
			audienceNanoId,
			quotaNanoId,
			quotaFieldNanoId,
		) =>
			`/projects/${projectNanoId}/audiences/${audienceNanoId}/quotas/${quotaNanoId}/fields/${quotaFieldNanoId}/`,
	},
};
