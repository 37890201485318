import { MentionsInput, Mention } from 'react-mentions';
import { useTranslation } from 'react-i18next';
import { getQuestionTitle } from '../../utils';
import { QUESTION_META_DATA } from '../../constants';

export default function QuestionInput({
	value,
	onChange,
	disabled,
	questionList,
	qIndex,
}) {
	const { t } = useTranslation();

	const getEligibleQuestions = questionList => {
		return questionList.reduce((accumulator, currentValue, currentIndex) => {
			if (
				QUESTION_META_DATA[currentValue.question_type].isRecallable &&
				currentIndex !== qIndex
			) {
				accumulator.push({
					id: currentValue.nanoid,
					display: ` Q${currentIndex + 1}: ${getQuestionTitle(
						currentValue.title.trim(),
						questionList,
					)}  `,
				});
			}

			return accumulator;
		}, []);
	};

	const displayTransform = id => {
		const mentionQuestion = getEligibleQuestions(questionList).find(
			el => el.id === id,
		);

		return mentionQuestion.display;
	};

	return (
		<MentionsInput
			value={value}
			onChange={onChange}
			disabled={disabled}
			className="mentions"
			placeholder={t('Your question here.')}
			spellCheck="false"
		>
			<Mention
				trigger="@"
				data={() => getEligibleQuestions(questionList)}
				className="bg-indigo-300 rounded py-0.5"
				markup="@@@__id__@@@"
				displayTransform={displayTransform}
			/>
		</MentionsInput>
	);
}
