import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from '@headlessui/react';
import { classNames, setDocumentTitle } from '../../../utils';
import { sendGoogleEvent } from '../../../utils/analytics';
import Spinner from '../../../components/Spinner';
import { Buttons } from '../../../components/Button';
import { resetSuccessState } from '../../../store/authSlice';
import { toast } from 'react-hot-toast';

export default function SettingsPage() {
	const { i18n, t } = useTranslation();
	const currentLanguage = localStorage.getItem('i18nextLng');
	const dispatch = useDispatch();
	const { user, spin, message } = useSelector(state => state.auth);
	const [selectedLang, setSelectedLang] = useState('');

	const languages = [
		{ langValue: 'en-US', buttonLabel: t('settings.changeToEnglishButton') },
		{ langValue: 'tr-TR', buttonLabel: t('settings.changeToTurkishButton') },
	];

	useEffect(() => {
		setDocumentTitle(t('pageTitles.settings'));
	}, [t]);

	useEffect(() => {
		currentLanguage && currentLanguage === 'tr-TR'
			? setSelectedLang('tr-TR')
			: setSelectedLang('en-US');
	}, [currentLanguage]);

	useEffect(() => {
		if (message.success) {
			toast.success(t('settings.successToastMessage'), { title: t('Success') });
		}
		dispatch(resetSuccessState());
	}, [message, dispatch, t]);

	const handleOnSubmit = e => {
		e.preventDefault();

		dispatch({
			type: 'auth/startUpdateProfile',
			payload: {
				nanoid: user.nanoid,
				values: {
					preferred_language: selectedLang,
				},
			},
		});

		i18n.changeLanguage(selectedLang);
		sendGoogleEvent('account_language_change', { language: selectedLang });
	};

	const isPreSelectedLanguage = () => {
		if (currentLanguage === 'en' && selectedLang === 'en-US') {
			return true;
		} else if (currentLanguage === selectedLang) {
			return true;
		}
		return false;
	};

	return (
		<section aria-labelledby="payment-details-heading">
			<form onSubmit={handleOnSubmit}>
				<div className="shadow sm:rounded-md sm:overflow-hidden">
					<div className="bg-white py-6 px-4 sm:p-6">
						<div>
							<h2
								id="payment-details-heading"
								className="text-lg leading-6 font-medium text-gray-900"
							>
								{t('settings.heading')}
							</h2>
							<p className="mt-1 text-sm text-gray-500 pb-6">
								{t('settings.description')}
							</p>
						</div>

						<RadioGroup value={selectedLang} onChange={setSelectedLang}>
							<RadioGroup.Label className="text-sm font-medium text-gray-700">
								{' '}
								{t('Language Preference')}{' '}
							</RadioGroup.Label>
							<div className="-space-y-px rounded-md bg-white pt-2">
								{languages.map((language, languageIdx) => (
									<RadioGroup.Option
										key={language.langValue}
										value={language.langValue}
										className={({ checked }) =>
											classNames(
												languageIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
												languageIdx === languages.length - 1
													? 'rounded-bl-md rounded-br-md'
													: '',
												checked
													? 'bg-indigo-50 border-indigo-200 z-10'
													: 'border-gray-200',
												'relative border p-4 flex cursor-pointer focus:outline-none  ',
											)
										}
									>
										{({ active, checked }) => (
											<>
												<span
													className={classNames(
														checked
															? 'bg-indigo-600 border-transparent'
															: 'bg-white border-gray-300',
														active
															? 'ring-2 ring-offset-2 ring-indigo-500'
															: '',
														'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
													)}
													aria-hidden="true"
												>
													<span className="rounded-full bg-white w-1.5 h-1.5" />
												</span>
												<span className="ml-3 flex flex-col">
													<RadioGroup.Label
														as="span"
														className={classNames(
															checked ? 'text-indigo-900' : 'text-gray-900',
															'block text-sm font-medium',
														)}
													>
														{language.buttonLabel}
													</RadioGroup.Label>
												</span>
											</>
										)}
									</RadioGroup.Option>
								))}
							</div>
						</RadioGroup>
					</div>

					<div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
						<Buttons
							type="submit"
							disabled={isPreSelectedLanguage() || spin}
							kind="primary"
						>
							{spin ? <Spinner className="w-4 h-4" /> : t('Save')}
						</Buttons>
					</div>
				</div>
			</form>
		</section>
	);
}
