import ChoiceList from './ChoiceList';
import useBulkEdit from '../../../hooks/useBulkEdit';
import BulkEditTextArea from './BulkEditTextArea';

const RankingQuestion = ({
	question,
	isEditable = true,
	handleReorderChoiceList,
	handleCreateChoice,
	handleChoiceTitleChange,
	handleChoiceImageChange,
	handleChoiceImageTooLarge,
	handleDeleteChoice,
}) => {
	const [showTextArea, hideTextArea, handleBulkUpdate] = useBulkEdit(question);
	return (
		<>
			{showTextArea ? (
				<BulkEditTextArea
					isEditable={isEditable}
					question={question}
					hideTextArea={hideTextArea}
					showTextArea={showTextArea}
				/>
			) : (
				<ChoiceList
					question={question}
					isEditable={isEditable}
					handleReorderChoiceList={handleReorderChoiceList}
					handleCreateChoice={handleCreateChoice}
					handleChoiceTitleChange={handleChoiceTitleChange}
					handleChoiceImageChange={handleChoiceImageChange}
					handleChoiceImageTooLarge={handleChoiceImageTooLarge}
					handleDeleteChoice={handleDeleteChoice}
					handleBulkUpdate={handleBulkUpdate}
				/>
			)}
		</>
	);
};
export default RankingQuestion;
