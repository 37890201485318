import ReactFlow, { Controls, Background, Handle, Position } from 'reactflow';
import dagre from 'dagre';
import 'reactflow/dist/base.css';
import QuestionNode from '../QuestionNode';
import { useTranslation } from 'react-i18next';
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';

const nodeWidth = 222;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
	const dagreGraph = new dagre.graphlib.Graph();
	dagreGraph.setDefaultEdgeLabel(() => ({}));

	const isHorizontal = direction === 'LR';
	dagreGraph.setGraph({
		rankdir: direction,
		align: 'UL',
		nodesep: 50,
		edgesep: 50,
	});

	nodes.forEach(node => {
		dagreGraph.setNode(node.id, {
			width: nodeWidth * 1.5,
			height: nodeHeight * 1.5,
		});
	});

	edges.forEach(edge => {
		dagreGraph.setEdge(edge.source, edge.target);
	});

	dagre.layout(dagreGraph);

	nodes.forEach(node => {
		const nodeWithPosition = dagreGraph.node(node.id);
		node.targetPosition = isHorizontal ? 'left' : 'top';
		node.sourcePosition = isHorizontal ? 'right' : 'bottom';

		// We are shifting the dagre node position (anchor=center center) to the top left
		// so it matches the React Flow node anchor point (top left).
		node.position = {
			x: nodeWithPosition.x - nodeWidth / 2,
			y: nodeWithPosition.y - nodeHeight / 2,
		};

		return node;
	});

	return { nodes, edges };
};

const position = { x: 0, y: 0 };

function LastNode({ data }) {
	return (
		<div className="border-2 border-indigo-600 bg-indigo-600 shadow rounded p-2 text-white p-4">
			<div>{data.title}</div>
			<Handle type="target" position={Position.Left} id="a" />
		</div>
	);
}

const defaultViewport = { x: 50, y: 100, zoom: 0.5 };
const nodeTypes = { question: QuestionNode, lastNode: LastNode };
const edgeTypes = { smart: SmartBezierEdge };

const generateNodesAndEdges = (arr, activeQuestionIndex, t) => {
	let orderN = 1;
	const newList = arr.map(q => {
		return { ...q, order: orderN++ };
	});

	const layoutNodes = [];
	const layoutEdges = [];
	newList.map((item, index) => {
		layoutNodes.push({
			id: item.order.toString(),
			type: 'question',
			data: {
				title: item.title,
				question_type: item.question_type,
				index: item.order,
				active: activeQuestionIndex === index && true,
			},
			position,
		});
		layoutEdges.push({
			id: `e${item.order.toString()}${(item.order + 1).toString()}`,
			source: item.order.toString(),
			target: (item.order + 1).toString(),
			type: 'default',
			animated: false,
		});

		if (item.logic.rules.length > 0) {
			item.logic.rules.map(rule => {
				if (rule.then.action === 'go_to_question') {
					const q = newList.find(q => q.nanoid === rule.then.question);
					const newId = `e${item.order.toString()}${q.order.toString()}`;
					const edgeExists = layoutEdges.some(edge => edge.id === newId);
					if (!edgeExists) {
						layoutEdges.push({
							id: newId,
							source: item.order.toString(),
							target: q.order.toString(),
							type: 'smart',
							animated: false,
						});
					}
				}
				if (rule.then.action === 'end_survey') {
					const newId = `e${item.order.toString()}${(
						newList.length + 1
					).toString()}`;
					const edgeExists = layoutEdges.some(edge => edge.id === newId);
					if (!edgeExists) {
						layoutEdges.push({
							id: newId,
							source: item.order.toString(),
							target: (newList.length + 1).toString(),
							type: 'smart',
							animated: false,
						});
					}
				}
			});
		}
	});
	layoutNodes.push({
		id: (newList.length + 1).toString(),
		type: 'lastNode',
		data: {
			title: t('End of Survey'),
			index: (newList.length + 1).toString(),
		},
		position,
	});

	return { layoutNodes, layoutEdges };
};

export default function LogicVisualizer({ questions, activeQuestionIndex }) {
	const { t } = useTranslation();
	const { layoutNodes, layoutEdges } = generateNodesAndEdges(
		questions,
		activeQuestionIndex,
		t,
	);

	const { nodes, edges } = getLayoutedElements(layoutNodes, layoutEdges);

	return (
		<div className="w-full h-96 mb-10 bg-white rounded border border-indigo-600">
			<ReactFlow
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				nodes={nodes}
				edges={edges}
				proOptions={{ hideAttribution: true }}
				defaultViewport={defaultViewport}
			>
				<Background />
				<Controls showInteractive={false} />
			</ReactFlow>
		</div>
	);
}
