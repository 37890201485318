import { StarIcon } from '@heroicons/react/24/solid';
import {
	HeartIcon,
	StarIcon as StarIconOutline,
	HandThumbUpIcon,
} from '@heroicons/react/24/outline';
import {
	HeartIcon as HeartIconSolid,
	HandThumbUpIcon as HandThumbUpIconSolid,
} from '@heroicons/react/24/solid';

const ratingMetaData = {
	stars: {
		icon: StarIconOutline,
		hover: StarIcon,
		label: 'Stars',
	},
	hearts: {
		icon: HeartIcon,
		hover: HeartIconSolid,
		label: 'Hearts',
	},
	thumbs_up: {
		icon: HandThumbUpIcon,
		hover: HandThumbUpIconSolid,
		label: 'Thumbs-up',
	},
};
export default function RatingIcon({ iconType, className }) {
	const CustomTag = ratingMetaData[iconType].icon;
	return <CustomTag className={className} />;
}
export function RatingHoverIcon({ iconType, className }) {
	const CustomTag = ratingMetaData[iconType].hover;
	return <CustomTag className={className} />;
}
