import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import { classNames } from '../../utils';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function RadioGroup({
	label,
	selectedItem,
	data,
	handleChange,
}) {
	const { t } = useTranslation();
	return (
		<HeadlessRadioGroup value={selectedItem} onChange={handleChange}>
			<HeadlessRadioGroup.Label className="text-sm font-medium text-gray-700">
				{t(label)}
			</HeadlessRadioGroup.Label>

			<div className="mt-1 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
				{data.map(mailingList => (
					<HeadlessRadioGroup.Option
						key={mailingList.id}
						value={mailingList}
						className={({ checked, active }) =>
							classNames(
								checked ? 'border-transparent' : 'border-gray-300',
								active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
								'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
							)
						}
					>
						{({ checked, active }) => (
							<>
								<span className="flex flex-1">
									<span className="flex flex-col">
										<HeadlessRadioGroup.Label
											as="span"
											className="block text-sm font-medium text-gray-900"
										>
											{t(mailingList.title)}
										</HeadlessRadioGroup.Label>
										<HeadlessRadioGroup.Description
											as="span"
											className="mt-1 flex items-center text-sm text-gray-500"
										>
											{t(mailingList.description)}
										</HeadlessRadioGroup.Description>
									</span>
								</span>
								<CheckCircleIcon
									className={classNames(
										!checked ? 'invisible' : '',
										'h-5 w-5 text-indigo-600',
									)}
									aria-hidden="true"
								/>
								<span
									className={classNames(
										active ? 'border' : 'border-2',
										checked ? 'border-indigo-500' : 'border-transparent',
										'pointer-events-none absolute -inset-px rounded-lg',
									)}
									aria-hidden="true"
								/>
							</>
						)}
					</HeadlessRadioGroup.Option>
				))}
			</div>
		</HeadlessRadioGroup>
	);
}
