import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	createProjectData: {
		error: false,
		success: false,
		fetch: false,
		message: '',
	},
	getAllProjectsData: {
		error: false,
		success: false,
		fetch: false,
		message: '',
		projects: [],
		totalProjectsLength: 0,
	},
	search: {
		projects: [],
	},
	projectCostData: {
		cost: null,
		error: '',
	},
	projectFeedback: {
		isSlideOverOpen: false,
		feedback: null,
		loadingStatus: { post: 'idle', delete: 'idle' },
	},
	currentPage: 1,
	ordering: {
		label: 'Newest first',
		option: 'newest_first',
		value: '-created',
	},
	status: 'all',
};

// eslint-disable-next-line import/no-unused-modules
export const projectSlice = createSlice({
	name: 'project',
	initialState,
	reducers: {
		createProjectStarted: state => {
			state.createProjectData = {
				error: false,
				success: false,
				fetch: true,
				message: '',
			};
		},
		createProjectFinished: (state, action) => {
			state.createProjectData = {
				error: false,
				success: true,
				fetch: false,
				message: action.payload,
			};
		},
		createProjectFailed: (state, action) => {
			state.createProjectData = {
				error: true,
				success: false,
				fetch: false,
				message: action.payload,
			};
		},
		getAllProjectsStarted: state => {
			state.getAllProjectsData = {
				...state.getAllProjectsData,
				error: false,
				success: false,
				fetch: true,
				message: '',
				isLoading: true,
			};
		},
		getAllProjectsFinished: (state, action) => {
			state.getAllProjectsData = {
				error: false,
				success: true,
				fetch: false,
				message: '',
				projects: action.payload.results,
				isLoading: false,
				totalProjectsLength: action.payload.count,
			};
		},
		getAllProjectsFailed: (state, action) => {
			state.getAllProjectsData = {
				...state.getAllProjectsData,
				error: true,
				success: false,
				fetch: false,
				message: action.payload,
				isLoading: false,
			};
		},
		startSearchProjects: state => {
			state.search.projects = [];
		},
		completeSearchProjects: (state, action) => {
			state.search.projects = action.payload.projects;
		},
		getProjectsCostData: (state, action) => {
			state.projectCostData.cost = action.payload;
		},
		toggleProjectFeedbackSlideOver: (state, action) => {
			state.projectFeedback.isSlideOverOpen = action.payload;
		},

		setProjectFeedback: (state, action) => {
			state.projectFeedback.feedback = action.payload;
		},
		postProjectFeedbackStart: state => {
			state.projectFeedback.loadingStatus.post = 'loading';
		},
		postProjectFeedbackFinish: (state, action) => {
			state.projectFeedback.loadingStatus.post = 'success';
			state.projectFeedback.feedback = action.payload;
		},

		deleteProjectFeedbackStart: state => {
			state.projectFeedback.loadingStatus.delete = 'loading';
		},
		deleteProjectFeedbackFinish: state => {
			state.projectFeedback.loadingStatus.delete = 'success';
			state.projectFeedback.feedback = null;
		},

		resetFeedbackPostingStatus: state => {
			state.projectFeedback.loadingStatus.post = 'idle';
		},
		resetFeedbackDeletingStatus: state => {
			state.projectFeedback.loadingStatus.delete = 'idle';
		},

		failGetProjectCostData: (state, action) => {
			state.projectCostData.error = action.payload;
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload;
		},
		setOrdering: (state, action) => {
			state.ordering.label = action.payload.label;
			state.ordering.option = action.payload.option;
			state.ordering.value = action.payload.value;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
});

// eslint-disable-next-line import/no-unused-modules
export const {
	createProjectStarted,
	createProjectFinished,
	createProjectFailed,
	getAllProjectsStarted,
	getAllProjectsFinished,
	getAllProjectsFailed,
	startSearchProjects,
	completeSearchProjects,
	getProjectsCostData,
	toggleProjectFeedbackSlideOver,
	postProjectFeedbackFinish,
	postProjectFeedbackStart,
	deleteProjectFeedbackStart,
	deleteProjectFeedbackFinish,
	resetFeedbackPostingStatus,
	resetFeedbackDeletingStatus,
	setProjectFeedback,
	failGetProjectCostData,
	setCurrentPage,
	setOrdering,
	setStatus,
} = projectSlice.actions;

export default projectSlice.reducer;
